<template>
  <div class="rounded-2xl bg-1-static">
    <div v-if="modelValue.email && modelValue.cc" class="space-y-3 pb-8">
      <StringList
        v-model="modelValue.email"
        :min-values="1"
        :title="$t('email')"
        :add-value-text="$t('add_recipient')"
        class="my-3"
        @update:model-value="updateValue"
      />
      <p class="text-heading-3 text-color-label-1">
        {{ $t("add_cc_recipient") }}
      </p>
      <StringList
        v-model="modelValue.cc"
        :min-values="1"
        :title="$t('email')"
        :add-value-text="$t('add_cc_recipient')"
        @update:model-value="updateValue"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PDFEmailData } from "@/pdf-generator/types"

const props = defineProps<{
  modelValue: Partial<PDFEmailData>
}>()

const emit = defineEmits(["back", "update:modelValue"])

function updateValue() {
  emit("update:modelValue", props.modelValue)
}
</script>
