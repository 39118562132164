import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  RouteLocationRaw,
} from "vue-router"
import { useEmployeeController } from "@/stores/employeeController"
import { useGlobalStore } from "@/stores/globalStore"
import posthog from "posthog-js"
import {
  createRouter,
  createWebHistory,
} from "vue-router"
import { routes } from "./routes"

export interface Breadcrumb {
  textKey?: string
  text?: string
  to?: RouteLocationRaw
}
export type BreadcrumbsGenerator = (
  route: RouteLocationNormalizedLoaded,
  options?: { [x: string]: unknown },
) => Breadcrumb[]

declare module "vue-router" {
  interface RouteMeta {
    Breadcrumbs?: BreadcrumbsGenerator
    unprotected?: boolean
    noTenant?: boolean
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    // Track page leaves in PostHog
    if (from.path !== to.path) {
      posthog.capture("$pageleave", {
        $current_url: from.fullPath,
        path: from.path,
        name: from.name,
        params: from.params,
        query: from.query,
      })
    }

    const globalStore = useGlobalStore()
    const employeeController = useEmployeeController()
    globalStore.lastVisitedPage = to.fullPath?.toString() || "/"

    if (to.path === "/") {
      return next({ name: "quotes" })
    }

    if (to.meta.unprotected) {
      return next()
    }

    if (!globalStore.hydrated) {
      await globalStore.hydrate()
    }

    if (employeeController.shouldRedirectToProfile() && to.name !== "profile") {
      return next({ name: "profile" })
    }

    return next()
  },
)

router.afterEach((to: RouteLocationNormalized) => {
  // Apply update if available when navigating to quotes route
  // Only applies updates that are already available, without checking for new ones
  if (to.name === "quotes") {
    const versionStore = useVersionStore()
    if (versionStore.updateAvailable) {
      void versionStore.handleUpdate(false)
    }
  }

  // Track page views in PostHog
  posthog.capture("$pageview", {
    $current_url: to.fullPath,
    path: to.path,
    name: to.name,
    params: to.params,
    query: to.query,
  })
})

export default router
