import type { Customer } from "@/types"
import { client } from "@/api/directus"
import { createItem, updateItem } from "@directus/sdk"

async function updateCustomer(customerId: string, update: Customer): Promise<Customer> {
  return (await client.request(updateItem("customers", customerId, update))) as Customer
}

async function createCustomer(customer: Customer): Promise<Customer> {
  return (await client.request(createItem("customers", customer))) as Customer
}

export const customers = {
  updateCustomer,
  createCustomer,
}
