<template>
  <BaseButton
    :disabled="disabled || loading"
    :submit="submit"
    :variant="variant"
    :hide-border="hideBorder"
    :class="buttonClass"
    data-component="loadable-button"
    :data-variant="variant"
  >
    <IconAdd v-if="icon === 'plus' && !showSpinner" class="mr-1" />
    <AtomSpinner
      v-if="showSpinner"
      class="pr-2"
      :size="15"
      :color="spinnerColor"
    />
    <MaterialSymbolsCheckSmall v-if="!showSpinner && hasSpinnerBeenShown" />
    <slot />
    <IconChevronDown v-if="icon === 'chevron'" class="min-w-[24px]" />
  </BaseButton>
</template>

<script setup lang="ts">
import type { ButtonVariants } from "@/types/UiTypes"

interface Props {
  loading?: boolean
  disabled?: boolean
  submit?: boolean
  variant?: ButtonVariants
  hideBorder?: boolean
  icon?: "plus" | "chevron"
  buttonClass?: string | string[] | object
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  disabled: false,
  submit: false,
  variant: "primary",
  hideBorder: false,
  icon: undefined,
  buttonClass: "",
})

const showSpinner = ref(false)
const hasSpinnerBeenShown = ref(false)

// Computed property to determine the spinner color based on button variant
const spinnerColor = computed(() => {
  // Variants with dark backgrounds should use white spinner
  if (["primary", "solid-black", "danger"].includes(props.variant)) {
    return "white"
  }
  // Variants with light backgrounds should use blue spinner
  return "blue"
})

watch(
  () => props.loading,
  () => {
    if (!props.loading) {
      setTimeout(() => {
        showSpinner.value = false
      }, 300)
      setTimeout(() => {
        hasSpinnerBeenShown.value = false
      }, 1000)
    }
    else {
      showSpinner.value = true
      hasSpinnerBeenShown.value = true
    }
  },
  { immediate: true },
)
</script>
