<template>
  <HeaderOnboarding>
    <div>
      <div class="mb-4 flex flex-col items-center">
        <h2 class="text-heading-2">
          {{ $t("onboarding.profile_1") }}
        </h2>
        <p class="label1 mt-2 text-center">
          {{ $t("onboarding.profile_2") }}
        </p>
      </div>
      <div class="row-span-3">
        <form @submit.prevent="submitForm">
          <TextFieldVal
            text-size="normal"
            :label="t('full_name')"
            name="fullName"
            class="pb-2"
            :bold="false"
            rules="required"
            :focus="onboardingController.onboardingProcess === 'creatingProfile'"
          />
          <TextFieldVal
            text-size="normal"
            rules="email"
            type="email"
            :label="t('email')"
            name="email"
            :bold="false"
            class="mb-2"
          />
          <CheckBox
            v-model="defaultValue"
            class="mb-8"
            size="small"
            checkbox-location="left"
            name="allow_contact_and_marketing"
            :label="t('onboarding.profile_4_contact_and_marketing')"
          />
        </form>
      </div>
      <div class="row-span-1">
        <MessageAlert v-if="onboardingController.error" type="error">
          {{ t("onboarding.profile_3") }}
        </MessageAlert>
        <Button
          id="submit-profile"
          class="w-full"
          size="large"
          :loading="onboardingController.loading"
          @click="submitForm"
        >
          {{ $t("onboarding.continue_button") }}
        </Button>
      </div>
    </div>
  </HeaderOnboarding>
</template>

<script setup lang="ts">
import { t } from "@/localization/i18n"
import { useOnboardingController } from "@/stores/onboardingController"
import { useForm } from "vee-validate"

const onboardingController = useOnboardingController()
const defaultValue = ref(true)

const { handleSubmit } = useForm()
const submitForm = handleSubmit((values: any) => {
  onboardingController.handleEvent({
    type: "SUBMIT_PROFILE",
    email: values.email,
    fullName: values.fullName,
  })
})
</script>
