import { auditFieldsSchema, dateSchema, idSchema } from "@/types"
import { z } from "zod"

export const invoiceSchema = z.object({
  id: idSchema,
  tenant: idSchema,
  project: idSchema,
  status: z.enum([
    "draft",
    "invoiced",
    "overdue",
    "cancelled",
    "paid",
    "partially_paid",
    "refunded",
  ]),
  invoice_no: z.number(),
  reference: z.string(),
  external_reference: z.string(),
  description: z.string(),
  percentage: z.number(),
  amount: z.number(),
  paid_amount: z.number(),
  date_paid: dateSchema,
  date_sent: dateSchema,
  date_due: dateSchema,
  ...auditFieldsSchema.shape,
})

export type Invoice = z.infer<typeof invoiceSchema>
