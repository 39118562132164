<template>
  <PDFPage v-bind="{ colors, tenant, project }">
    <SharedPDFHeader v-bind="{ tenant, colors }" />
    <div class="pb-[9.8mm] text-heading-3">
      {{ $t("summary") }}
    </div>
    <div v-if="project.phases.length > 1">
      <div v-for="phase in project.phases" :key="phase.id" class="pb-[1.4mm]">
        <div class="gap-[2.8mm] rounded-[2.8mm] bg-secondary p-[5.6mm] text-caption-2">
          <div class="flex w-full justify-between text-label-bold-3">
            <div>{{ phase.name }}</div>
            <div>
              {{ formatCurrency(getPhaseTotal(phase)) }}
            </div>
          </div>
          <div class="text-caption-2 opacity-70">
            {{ $t("x-items", [phase.posts?.length]) }}
          </div>
        </div>
      </div>
    </div>
    <QuotePDFItemsSummary v-bind="{ project, colors, tenant }" />
  </PDFPage>
</template>

<script setup lang="ts">
import type { Color, PdfPhase, PdfProject, PdfTenant } from "@/pdf-generator/types"
import { formatCurrency } from "@/utils/companyUtils"

import SharedPDFHeader from "../../base/PDFHeader.vue"
import PDFPage from "../../base/PDFPage.vue"
import QuotePDFItemsSummary from "../items/QuotePDFItemsSummary.vue"

defineProps<{
  colors: Color
  tenant: PdfTenant
  project: PdfProject
}>()

function getPhaseTotal(phase: PdfPhase): number {
  let phaseTotal = 0

  for (const post of phase.posts ?? []) {
    phaseTotal += (Number(post.unit_price) ?? 0) * (Number(post.qty) ?? 0)
  }

  return phaseTotal
}
</script>
