import type { ISchema } from "@/types"
import type { DirectusContext } from "@/types/ApiTypes"

import type { ImageOptions, Photo } from "@capacitor/camera"

import api from "@/api"
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera"
import { Capacitor } from "@capacitor/core"

const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/png", "image/webp"] as const
const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB

export interface FileValidationResult {
  isValid: boolean
  error?: string
  file?: File
}

/**
 * Validates a file against accepted types and size constraints
 */
export function validateFile(file: File): FileValidationResult {
  if (!ACCEPTED_IMAGE_TYPES.includes(file.type as typeof ACCEPTED_IMAGE_TYPES[number])) {
    return {
      isValid: false,
      error: `File type not accepted. Accepted types: ${ACCEPTED_IMAGE_TYPES.join(", ")}`,
    }
  }

  if (file.size > MAX_FILE_SIZE) {
    return {
      isValid: false,
      error: "File size exceeds 10MB limit",
    }
  }

  return {
    isValid: true,
    file,
  }
}

/**
 * Gets camera configuration based on platform
 */
export function getCameraConfig(platform: string): ImageOptions {
  const baseConfig = {
    allowEditing: false,
    resultType: CameraResultType.Uri,
  }

  if (platform === "web") {
    return {
      ...baseConfig,
      quality: 90,
      source: CameraSource.Photos,
    }
  }

  // For iOS and Android
  return {
    ...baseConfig,
    quality: 95,
    source: CameraSource.Prompt,
    saveToGallery: true,
  }
}

/**
 * Captures an image using the device camera or photo library
 */
export async function captureImage(): Promise<Photo | null> {
  try {
    const platform = Capacitor.getPlatform()
    const config = getCameraConfig(platform)
    return await Camera.getPhoto(config)
  }
  catch (error) {
    console.error("Error accessing camera:", error)
    return null
  }
}

/**
 * Converts a URL to a File object
 */
export async function urlToFile(imagePath: string, isVideo = false): Promise<File | null> {
  try {
    const response = await fetch(imagePath)
    const blob = await response.blob()

    const fileType = isVideo ? "video" : "image"
    const extension = isVideo
      ? (blob.type.split("/")[1] || "mp4")
      : (blob.type.split("/")[1] || "jpeg")

    const file = new File([blob], `${fileType}.${extension}`, { type: blob.type })

    if (isVideo) {
      const validation = validateVideo(file)
      if (!validation.isValid) {
        console.error(validation.error)
        return null
      }
    }
    else {
      const validation = validateFile(file)
      if (!validation.isValid) {
        console.error(validation.error)
        return null
      }
    }

    return file
  }
  catch (error) {
    console.error("Error converting URL to file:", error)
    return null
  }
}

/**
 * Converts a video URL to a File object
 */
export async function videoUrlToFile(videoPath: string): Promise<File | null> {
  return urlToFile(videoPath, true)
}

/**
 * Uploads a file to the server and updates related context
 * Validates the file before upload
 */
export async function uploadFileToServer(
  file: File,
  tenantId: string,
  context: DirectusContext,
): Promise<string> {
  // Validate file first
  const validationResult = validateFile(file)
  if (!validationResult.isValid) {
    throw new Error(validationResult.error)
  }

  const responseId = await api.uploadFile(file, tenantId)
  if (!responseId) {
    throw new Error("No response id when saving")
  }

  const { collection, id, data } = context
  if (!collection || !id || !data) {
    throw new Error("Invalid context: missing collection, id or data")
  }
  const property = Object.keys(data)[0]

  if (collection === "directus_users") {
    await api.users.updateMe({
      [property]: responseId,
    })
  }
  else {
    await api.updateGeneric({ collection, id, data: { ...data, [property]: responseId } })
  }

  return responseId
}

/**
 * Uploads a file and creates a many-to-many relationship in a junction table.
 * Example usage for certificates:
 * ```typescript
 * uploadFileWithRelation(
 *   file,                // Certificate image file
 *   tenant.value?.id,    // Current tenant's ID
 *   'tenants_files',     // Junction table connecting tenants and files
 *   'tenants_id',        // Foreign key field pointing to tenants
 *   tenant.value?.id     // Tenant ID to link the file to
 * )
 * ```
 *
 * @param file - The file to upload (e.g., certificate image)
 * @param tenantId - ID of the tenant who owns the file
 * @param juntionCollection - Name of the junction table for many-to-many relationship (e.g., 'tenants_files')
 * @param propertyToCollection - Foreign key field in junction table pointing to the owner (e.g., 'tenants_id')
 * @param relationValue - ID of the record to link the file to (e.g., tenant ID)
 * @returns Promise<string> - ID of the uploaded file
 * @throws Error if file validation fails or upload is unsuccessful
 */
export async function uploadFileWithRelation(
  file: File,
  tenantId: string,
  juntionCollection: keyof ISchema,
  propertyToCollection: string,
  relationValue: string,
): Promise<string> {
  // Validate file first based on its type
  const isVideo = file.type.startsWith("video/")
  const validationResult = isVideo ? validateVideo(file) : validateFile(file)

  if (!validationResult.isValid) {
    throw new Error(validationResult.error)
  }

  const responseId = await api.uploadFile(file, tenantId)
  if (!responseId) {
    throw new Error("No response id when saving")
  }

  // Create a properly typed object with index signature
  const additionalData: Record<string, string> = {
    directus_files_id: responseId,
    tenant: tenantId,
  }

  // Only add type property if it's a video
  if (isVideo) {
    additionalData.type = "video"
  }

  // Add the dynamic property
  additionalData[propertyToCollection] = relationValue

  // For many-to-many relationships, we create the junction records
  await api.createGeneric({
    collection: juntionCollection,
    id: relationValue,
    data: additionalData,
  })

  return responseId
}
