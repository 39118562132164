<template>
  <PageLayout>
    <template #header>
      <div v-if="projectProcess === 'picking_from_template'" class="flex items-center justify-between w-full">
        <div class="flex items-center">
          <IconArrowLeft @click="goBack" />
        </div>
        <Button
          v-if="selectedTemplates.length > 0 && !mobile"
          size="medium"
          @click="addSelectedToQuote"
        >
          {{ $t('templates.add_to_quote', { count: selectedTemplates.length }) }}
        </Button>
      </div>
      <div class="flex items-center justify-between">
        <h2 class="text-heading-2">
          {{ $t("menu-mobile.templates") }}
        </h2>
        <Button
          v-if="!mobile && projectProcess !== 'picking_from_template'"
          size="medium"
          icon="plus"
          :loading="isLoading"
          @click="handleEvent({ type: 'NEW_POST_STARTED', phaseId: undefined, isTemplate: true })"
        >
          {{ $t("templates.new_template") }}
        </Button>
      </div>
      <SearchInputV2
        v-if="sortedPostTemplates.length > 7"
        v-model="searchTerm"
        class="mt-4"
        @reset="() => (searchTerm = '')"
      />
    </template>
    <template #default>
      <div>
        <PostList
          v-if="filteredPostTemplates.length"
          :posts="filteredPostTemplates"
          @post-click="toggleSelection"
        >
          <template #before-post="{ post }">
            <div
              v-if="projectProcess === 'picking_from_template'"
              class="flex items-center justify-center w-6 h-6 rounded-md cursor-pointer"
              :class="{
                'bg-primary': selectedTemplates.includes(post.id),
                'bg-2-static': !selectedTemplates.includes(post.id),
              }"
            >
              <img
                v-if="selectedTemplates.includes(post.id)"
                src="/icon/check.svg"
                class="w-4 h-4"
              >
            </div>
          </template>
        </PostList>

        <TemplatesPlaceholder
          v-if="sortedPostTemplates.length < 1"
        />
      </div>
      <div v-if="mobile && projectProcess !== 'picking_from_template'" class="fixed inset-x-0 w-full px-4 bottom-20 pb-safe">
        <Button
          icon="plus"
          class="w-full"
          size="large"
          :loading="isLoading"
          @click="handleEvent({ type: 'NEW_POST_STARTED', phaseId: undefined, isTemplate: true })"
        >
          {{ $t("templates.new_template") }}
        </Button>
      </div>
      <BottomDrawerSlot
        :header="false"
        title=""
        :show-options="postProcess === 'editing' "
        @close="closePost"
      >
        <Post v-if="postProcess === 'editing' " />
      </BottomDrawerSlot>
    </template>
    <template #footer-mobile>
      <Button
        v-if="projectProcess === 'picking_from_template' && selectedTemplates.length > 0"
        class="w-full"
        size="large"
        @click="addSelectedToQuote"
      >
        {{ $t('templates.add_to_quote', { count: selectedTemplates.length }) }}
      </Button>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import type { Post } from "@/types"
import PostList from "@/components/quotes/PostList.vue"
import { usePostController } from "@/stores/postController"
import { usePostModel } from "@/stores/postModel"

const { handleEvent } = usePostController()
const { isLoading } = storeToRefs(usePostController())
const postModel = usePostModel()
const { postProcess, sortedPostTemplates } = storeToRefs(postModel)
const { projectProcess, selectedTemplates } = storeToRefs(useProjectModel())
const { toggleTemplateSelection, clearSelectedTemplates } = useProjectModel()
const { handleEvent: handleProjectEvent } = useProjectController()

const showOptions = ref(false)
provide<Ref<boolean>>("showOptions", showOptions)

const searchTerm = ref("")
const filteredPostTemplates = computed(() => {
  if (!searchTerm.value) {
    return sortedPostTemplates.value
  }
  return sortedPostTemplates.value.filter((template: Post) =>
    template.name.toLowerCase().includes(searchTerm.value.toLowerCase()),
  )
})

function toggleSelection(postId: string) {
  if (projectProcess.value === "picking_from_template") {
    toggleTemplateSelection(postId)
  }
  else {
    handleEvent({ type: "EDIT_POST", postId })
  }
}

async function addSelectedToQuote() {
  if (selectedTemplates.value.length > 0) {
    await handleProjectEvent({ type: "POST_TEMPLATES_SELECTED", templateIds: selectedTemplates.value })
  }
}

async function goBack() {
  clearSelectedTemplates()

  if (projectProcess.value === "picking_from_template") {
    handleProjectEvent({ type: "PICK_TEMPLATE_CANCELLED" })
  }
  else {
    closePost()
  }
}

function closePost() {
  handleEvent({ type: "CLOSE_POST" })
}

const mobile = inject("isPhone") as Ref<boolean>
const showBottomNav = inject("showBottomNav") as Ref<boolean>

onMounted(() => {
  watch(() => projectProcess.value, (newProcess, oldProcess) => {
    showBottomNav.value = true
    if (newProcess === "picking_from_template") {
      showBottomNav.value = false
    }
    else if (oldProcess === "picking_from_template") {
      showBottomNav.value = true
      clearSelectedTemplates()
    }
  }, { immediate: true })
})

onUnmounted(() => {
  showBottomNav.value = false
})
</script>
