import router from "@/router"
import { useGlobalStore } from "@/stores/globalStore"
import axios from "axios"
import { readAndCompressImage } from "browser-image-resizer"
import { defineStore } from "pinia"

interface DrawImageState {
  fileInput: HTMLInputElement | null
  canvasRef: HTMLCanvasElement | null
  context: CanvasRenderingContext2D | null
  mode: "brush" | "line" | "eraser"
  drawingMode: "free" | "straight" | "arrow" | "square"
  isPaint: boolean
  lastPoint: { x: number, y: number } | null
  startPoint: { x: number, y: number } | null
  imageObj: HTMLImageElement | null
  imageSrc: string | null
  stageConfig: {
    width: number
    height: number
  }
  uploadingImage: boolean
  postModel: ReturnType<typeof usePostModel>
  imageId: string
  globalStore: ReturnType<typeof useGlobalStore>
  status: "new" | "edit"
  history: ImageData[]
  historyIndex: number
}

export const useImageStore = defineStore("image", {
  state: (): DrawImageState => ({
    fileInput: null,
    canvasRef: null,
    context: null,
    mode: "brush",
    drawingMode: "free",
    isPaint: false,
    lastPoint: null,
    startPoint: null,
    imageObj: null,
    imageSrc: null,
    stageConfig: {
      width: 0, // Initialized to 0, set dynamically by image
      height: 0, // Initialized to 0, set dynamically by image
    },
    uploadingImage: false,
    postModel: usePostModel(),
    imageId: "",
    globalStore: useGlobalStore(),
    status: "new",
    history: [],
    historyIndex: -1,
  }),
  actions: {
    initCanvas(canvas: HTMLCanvasElement) {
      this.canvasRef = canvas
      this.context = canvas.getContext("2d")
      if (this.context && this.imageObj) {
        // Set intrinsic resolution from image
        this.canvasRef.width = this.imageObj.naturalWidth
        this.canvasRef.height = this.imageObj.naturalHeight
        this.stageConfig.width = this.imageObj.naturalWidth
        this.stageConfig.height = this.imageObj.naturalHeight
        this.setContextStyle()
        this.context.drawImage(this.imageObj, 0, 0)
        this.saveToHistory()
      }
    },
    setContextStyle() {
      if (!this.context) { return }
      this.context.lineCap = "round"
      this.context.lineJoin = "round"
      this.context.strokeStyle = "#df4b26"
      this.context.lineWidth = 12
    },
    async loadImageBlob(blob: Blob) {
      // Configuration for image resizing - same config as used in save
      const config = {
        quality: 1.0, // Full quality for drawing
        maxWidth: 1920,
        maxHeight: 1920,
        autoRotate: true,
        debug: false,
        mimeType: "image/jpeg",
      }

      try {
        // Convert Blob to File before resizing
        const tempFile = new File([blob], "temp.jpg", { type: "image/jpeg", lastModified: Date.now() })

        // Resize the image blob before loading it
        const resizedBlob = await readAndCompressImage(tempFile, config)

        return new Promise<void>((resolve) => {
          const img = new Image()
          img.onload = () => {
            this.imageObj = img
            this.imageSrc = URL.createObjectURL(resizedBlob)
            this.stageConfig.width = img.naturalWidth
            this.stageConfig.height = img.naturalHeight

            if (this.context && this.canvasRef) {
              // Set canvas resolution to match the resized image
              this.canvasRef.width = img.naturalWidth
              this.canvasRef.height = img.naturalHeight
              this.setContextStyle()
              this.context.clearRect(0, 0, this.canvasRef.width, this.canvasRef.height)
              this.context.drawImage(img, 0, 0)

              // Reset history for undo/redo
              this.history = []
              this.historyIndex = -1
              this.saveToHistory()
            }
            resolve()
          }
          img.src = URL.createObjectURL(resizedBlob)
        })
      }
      catch (error) {
        console.error("Error resizing image:", error)
        throw error
      }
    },
    saveToHistory() {
      if (!this.context || !this.canvasRef) { return }

      // Remove any redo states
      if (this.historyIndex < this.history.length - 1) {
        this.history = this.history.slice(0, this.historyIndex + 1)
      }

      const imageData = this.context.getImageData(0, 0, this.canvasRef.width, this.canvasRef.height)
      this.history.push(imageData)
      this.historyIndex++
    },
    undo() {
      if (!this.context || !this.canvasRef || this.historyIndex <= 0) { return }

      this.historyIndex--
      const imageData = this.history[this.historyIndex]
      this.context.putImageData(imageData, 0, 0)
      this.setContextStyle() // Reset style after putImageData
    },
    redo() {
      if (!this.context || !this.canvasRef || this.historyIndex >= this.history.length - 1) { return }

      this.historyIndex++
      const imageData = this.history[this.historyIndex]
      this.context.putImageData(imageData, 0, 0)
      this.setContextStyle() // Reset style after putImageData
    },
    handleMouseDown(e: MouseEvent | TouchEvent) {
      this.isPaint = true
      const point = this.getPointerPosition(e)
      if (!point) { return }

      this.startPoint = point
      this.lastPoint = point

      if (this.context) {
        if (this.drawingMode === "free") {
          this.context.beginPath()
          this.context.moveTo(point.x, point.y)
        }
      }
    },
    handleMouseUp() {
      if (!this.isPaint) { return }

      if (this.drawingMode === "straight") {
        this.drawStraightLine()
      }
      else if (this.drawingMode === "arrow") {
        this.drawArrow()
      }
      else if (this.drawingMode === "square") {
        this.drawSquare()
      }
      this.isPaint = false
      this.lastPoint = null
      this.startPoint = null
      this.saveToHistory()
    },
    handleMouseMove(e: MouseEvent | TouchEvent) {
      if (!this.isPaint || !this.context) { return }

      const point = this.getPointerPosition(e)
      if (!point) { return }

      if (this.drawingMode === "free") {
        if (!this.lastPoint) { return }
        this.context.beginPath()
        this.context.moveTo(this.lastPoint.x, this.lastPoint.y)
        this.context.lineTo(point.x, point.y)
        this.context.stroke()
        this.lastPoint = point
      }
      else if (["straight", "arrow", "square"].includes(this.drawingMode)) {
        if (!this.startPoint) { return }
        if (this.drawingMode === "straight") {
          this.previewStraightLine(point)
        }
        else if (this.drawingMode === "arrow") {
          this.previewArrow(point)
        }
        else if (this.drawingMode === "square") {
          this.previewSquare(point)
        }
      }
    },
    drawStraightLine() {
      if (!this.context || !this.startPoint || !this.lastPoint) { return }

      this.context.beginPath()
      this.context.moveTo(this.startPoint.x, this.startPoint.y)
      this.context.lineTo(this.lastPoint.x, this.lastPoint.y)
      this.context.stroke()
    },
    previewStraightLine(currentPoint: { x: number, y: number }) {
      if (!this.context || !this.canvasRef || !this.startPoint) { return }

      // Restore the previous state to clear the preview
      if (this.historyIndex >= 0) {
        const imageData = this.history[this.historyIndex]
        this.context.putImageData(imageData, 0, 0)
        this.setContextStyle()
      }

      // Draw the preview line
      this.context.beginPath()
      this.context.moveTo(this.startPoint.x, this.startPoint.y)
      this.context.lineTo(currentPoint.x, currentPoint.y)
      this.context.stroke()

      this.lastPoint = currentPoint
    },
    drawArrow() {
      if (!this.context || !this.startPoint || !this.lastPoint) { return }

      const headLength = 60
      const dx = this.lastPoint.x - this.startPoint.x
      const dy = this.lastPoint.y - this.startPoint.y
      const angle = Math.atan2(dy, dx)

      this.context.beginPath()
      this.context.moveTo(this.startPoint.x, this.startPoint.y)
      this.context.lineTo(this.lastPoint.x, this.lastPoint.y)

      this.context.moveTo(this.lastPoint.x, this.lastPoint.y)
      this.context.lineTo(
        this.lastPoint.x - headLength * Math.cos(angle - Math.PI / 6),
        this.lastPoint.y - headLength * Math.sin(angle - Math.PI / 6),
      )
      this.context.moveTo(this.lastPoint.x, this.lastPoint.y)
      this.context.lineTo(
        this.lastPoint.x - headLength * Math.cos(angle + Math.PI / 6),
        this.lastPoint.y - headLength * Math.sin(angle + Math.PI / 6),
      )
      this.context.stroke()
    },
    previewArrow(currentPoint: { x: number, y: number }) {
      if (!this.context || !this.canvasRef || !this.startPoint) { return }

      // Restore the previous state to clear the preview
      if (this.historyIndex >= 0) {
        const imageData = this.history[this.historyIndex]
        this.context.putImageData(imageData, 0, 0)
        this.setContextStyle()
      }

      const headLength = 20
      const dx = currentPoint.x - this.startPoint.x
      const dy = currentPoint.y - this.startPoint.y
      const angle = Math.atan2(dy, dx)

      this.context.beginPath()
      this.context.moveTo(this.startPoint.x, this.startPoint.y)
      this.context.lineTo(currentPoint.x, currentPoint.y)

      this.context.moveTo(currentPoint.x, currentPoint.y)
      this.context.lineTo(
        currentPoint.x - headLength * Math.cos(angle - Math.PI / 6),
        currentPoint.y - headLength * Math.sin(angle - Math.PI / 6),
      )
      this.context.moveTo(currentPoint.x, currentPoint.y)
      this.context.lineTo(
        currentPoint.x - headLength * Math.cos(angle + Math.PI / 6),
        currentPoint.y - headLength * Math.sin(angle + Math.PI / 6),
      )
      this.context.stroke()

      this.lastPoint = currentPoint
    },
    drawSquare() {
      if (!this.context || !this.startPoint || !this.lastPoint) { return }

      const width = this.lastPoint.x - this.startPoint.x
      const height = this.lastPoint.y - this.startPoint.y

      this.context.beginPath()
      this.context.rect(this.startPoint.x, this.startPoint.y, width, height)
      this.context.stroke()
    },
    previewSquare(currentPoint: { x: number, y: number }) {
      if (!this.context || !this.canvasRef || !this.startPoint) { return }

      // Restore the previous state to clear the preview
      if (this.historyIndex >= 0) {
        const imageData = this.history[this.historyIndex]
        this.context.putImageData(imageData, 0, 0)
        this.setContextStyle()
      }

      const width = currentPoint.x - this.startPoint.x
      const height = currentPoint.y - this.startPoint.y

      this.context.beginPath()
      this.context.rect(this.startPoint.x, this.startPoint.y, width, height)
      this.context.stroke()

      this.lastPoint = currentPoint
    },
    getPointerPosition(e: MouseEvent | TouchEvent): { x: number, y: number } | null {
      if (!this.canvasRef) { return null }

      const rect = this.canvasRef.getBoundingClientRect()
      let clientX: number
      let clientY: number

      if (e instanceof MouseEvent) {
        clientX = e.clientX
        clientY = e.clientY
      }
      else {
        const touch = e.touches[0]
        if (!touch) { return null }
        clientX = touch.clientX
        clientY = touch.clientY
      }

      const scaleX = this.canvasRef.width / rect.width
      const scaleY = this.canvasRef.height / rect.height
      return {
        x: (clientX - rect.left) * scaleX,
        y: (clientY - rect.top) * scaleY,
      }
    },
    async handleImage(image: File) {
      try {
        this.status = "new"
        await this.loadImageBlob(image)
        await this.gotoImage()
      }
      catch (error) {
        console.error("Error handling image:", error)
      }
    },
    async hydrateStoreWithRemoteImage(url: string) {
      try {
        const response = await axios.get(url, { responseType: "arraybuffer" })
        const blob = new Blob([response.data], { type: response.headers["content-type"] })
        await this.loadImageBlob(blob)
        await this.gotoImage()
      }
      catch (error) {
        console.error("Error loading remote image:", error)
      }
    },
    async save() {
      this.uploadingImage = true
      const tenantId = this.globalStore.tenant?.id
      if (!tenantId || !this.canvasRef) {
        console.error("Missing tenant ID or canvas")
        this.uploadingImage = false
        return
      }

      try {
        const dataUrl = this.canvasRef.toDataURL("image/jpeg", 0.8)
        const response = await fetch(dataUrl)
        const blob = await response.blob()

        const file = new File([blob], "image.jpg", {
          type: "image/jpeg",
          lastModified: Date.now(),
        })

        const postId = this.postModel.activePostId
        if (!postId) {
          console.error("No post ID when saving")
          this.uploadingImage = false
          return
        }

        const responseId = await uploadFileWithRelation(
          file,
          tenantId,
          "posts_files",
          "posts_id",
          postId,
        )

        if (!responseId) {
          console.error("No response ID when saving")
          this.uploadingImage = false
          return
        }

        return dataUrl
      }
      catch (error) {
        console.error("Error saving image:", error)
        this.uploadingImage = false
        throw error
      }
      finally {
        this.uploadingImage = false
      }
    },
    async cancel() {
      const postId = this.postModel.activePostId
      if (!postId) {
        console.error("No post id when saving")
        return
      }
      await this.gotoPost(postId)
    },
    async gotoPost(id: string) {
      await router.push({
        name: "post",
        params: { postId: id },
      })
    },
    async gotoImage() {
      await router.push({
        name: "image",
      })
    },
  },
})
