<template>
  <div v-if="formData">
    <OptionsButton data-testid="quote-options-button">
      <div class="p-5 space-y-3">
        <Button type="secondary" size="large" class="w-full" data-testid="delete-quote-button" @click="handleDeleteQuote">
          <p class="text-color-error">
            {{ t("delete_quote") }}
          </p>
        </Button>
      </div>
    </OptionsButton>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/localization/i18n"

const { handleEvent } = useProjectController()
const projectController = useProjectController()
const { formData } = storeToRefs(projectController)
const projectModel = useProjectModel()
const deleteStore = useDeleteStore()

const router = useRouter()

async function refresh() {
  await router.push({ name: "quotes" })
}

function handleDeleteQuote() {
  if (projectModel.activeProject?.id) {
    deleteStore.setItemToDelete(projectModel.activeProject.id, "projects")
  }
  else {
    handleEvent({ type: "QUOTE_CLOSED" })
  }
}

watch(
  () => deleteStore.itemDeleted,
  async (newValue) => {
    if (newValue && deleteStore.itemToDelete?.id === projectModel.activeProject?.id) {
      await refresh()
      deleteStore.itemDeleted = false
    }
  },
)
</script>
