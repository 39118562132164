import type { DirectusCollection, ISchema } from "@/types"
import type { BaseEventPayload, StateHandler, StateMachine, StateTransitions } from "@/types/models/StateMachine"
import type { z } from "zod"
import { tryCatch } from "@/utils/errorHandling"
import { createStateMachine } from "@/utils/stateMachine"
import { computed, ref } from "vue"
import { useDirectusForm } from "./useForm"

/**
 * Form management process states
 */
export type FormManagementProcess =
  | "idle"
  | "creating"
  | "editing"

/**
 * Form event payload extending the base event payload
 */
export type FormEvent<ZodForm = any> = BaseEventPayload & (
  | { type: "NEW_ENTITY", entity?: ZodForm }
  | { type: "EDIT_ENTITY", id: string, entity?: ZodForm }
  | { type: "UPDATE_ENTITY", entity: ZodForm }
  | { type: "FIELD_CHANGED", field: keyof ZodForm & string, value: any }
  | { type: "ENTITY_CANCELED" }
  | { type: "AUTO_SUBMIT" }
  | { type: "SUBMIT_SUCCESS" }
  | { type: "SUBMIT_ERROR", error: Error }
)

/**
 * Form context for the state machine
 */
interface FormContext {
  submittedSuccessfully: boolean
}

/**
 * Type definitions for the form state machine
 */
export type FormStateHandler = StateHandler<FormManagementProcess, FormEvent, FormContext>
export type FormStateMachine = StateMachine<FormManagementProcess, FormEvent, FormContext>
export type FormStateTransitions = StateTransitions<FormManagementProcess, FormEvent, FormContext>

/**
 * Options for configuring the useDirectusAutoForm composable.
 */
export interface UseDirectusAutoFormOptions<ZodForm> {
  schema: z.ZodType<ZodForm> // Zod schema for form validation
  validateOnChange?: boolean // Whether to validate on every change (default: true)
  directusCollection: keyof ISchema // The Directus collection to interact with
  debug?: boolean // Enable debug logging (default: false)
  initialState?: FormManagementProcess // Initial state for the state machine (default: "idle")
}

/**
 * A Vue 3 composable for managing forms with Directus integration, Zod validation,
 * and state machine-based lifecycle management.
 *
 * @param options - Configuration options for the form.
 * @param options.schema - Zod schema for form validation
 * @param options.validateOnChange - Whether to validate on every change (default: true)
 * @param options.directusCollection - The Directus collection to interact with
 * @param options.debug - Enable debug logging (default: false)
 * @param options.initialState - Initial state for the state machine (default: "idle")
 * @returns An object with form state, validation methods, submission handlers, and state machine.
 */
export function useDirectusAutoForm<ZodForm, T extends DirectusCollection>({
  schema,
  validateOnChange = true,
  directusCollection,
  debug = false,
  initialState = "idle",
}: UseDirectusAutoFormOptions<ZodForm>) {
  // Use the base useDirectusForm composable
  const {
    formData,
    formErrors,
    resetForm,
    isValid,
    isDirty,
    isSubmitting,
    handleSubmit,
    handlePartialUpdate,
    updateFormData,
    validateField,
    validateForm,
    mode,
    id,
  } = useDirectusForm<ZodForm, T>({
    schema,
    validateOnChange,
    directusCollection,
    debug,
  })

  /**
   * State machine configuration for form management
   */
  const formProcess = ref<FormManagementProcess>(initialState)
  const contextRef = ref<FormContext>({ submittedSuccessfully: false })
  const stateTransitions: FormStateTransitions = {
    idle: {
      on: {
        NEW_ENTITY: [{ target: "creating" }],
        EDIT_ENTITY: [{ target: "editing" }],
      },
    },
    creating: {
      on: {
        FIELD_CHANGED: [{ target: "creating" }],
        ENTITY_CANCELED: [{ target: "idle" }],
        AUTO_SUBMIT: [{ target: "creating", cond: _ => !isValid.value }, { target: "editing" }],
      },
    },
    editing: {
      on: {
        FIELD_CHANGED: [{ target: "editing" }],
        ENTITY_CANCELED: [{ target: "idle" }],
        UPDATE_ENTITY: [{ target: "editing" }],
      },
    },
  }

  /**
   * Forward reference for the state machine's handleEvent function
   * Will be properly initialized after creating the state machine
   */
  const handleEventRef = ref<(payload: FormEvent<ZodForm>) => void>(() => {
    console.warn("State machine not initialized yet")
  })
  const stateHandlers: FormStateHandler = {
    idle: async (payload, _context) => {
      switch (payload.type) {
        case "NEW_ENTITY":
          resetForm()
          mode.value = "create"
          if (payload.entity) {
            const typedEntity = payload.entity as ZodForm
            updateFormData(typedEntity)
          }
          break
        case "EDIT_ENTITY":
          resetForm()
          mode.value = "update"
          id.value = payload.id

          if (payload.entity) {
            const typedEntity = payload.entity as ZodForm
            updateFormData(typedEntity)
          }
          break
      }
    },
    creating: async (payload, _context) => {
      switch (payload.type) {
        case "FIELD_CHANGED":{
          const field = payload.field as keyof ZodForm
          validateField(field)
        }
          break
        case "ENTITY_CANCELED":
          resetForm()
          break
        case "AUTO_SUBMIT": {
          const result = await tryCatch<T | undefined>(handleSubmit())
          const { data, error } = result

          if (error) {
            isSubmitting.value = false
          }

          if (data && !error) {
            if ("id" in data) {
              mode.value = "update"
              id.value = data.id
            }
          }
          break
        }
      }
    },
    editing: async (payload, _context) => {
      switch (payload.type) {
        case "FIELD_CHANGED":{
          const field = payload.field as keyof ZodForm
          validateField(field)
          if (isValid) {
            void tryCatch<T>(handlePartialUpdate([field]) as Promise<T>)
          }
        }
          break
        case "ENTITY_CANCELED":
          resetForm()
          break
        case "UPDATE_ENTITY": {
          const typedEntity = payload.entity as ZodForm
          updateFormData(typedEntity)
          break
        }
      }
    },
  }

  /**
   * Initialize the state machine with transitions and handlers
   */
  const { handleEvent } = createStateMachine(
    stateTransitions,
    stateHandlers,
    formProcess,
    contextRef,
    `SM:Form:${directusCollection}`,
  )

  handleEventRef.value = handleEvent

  /**
   * Auto-submits the form when it becomes valid in creating mode
   */
  watch(
    isValid,
    (newIsValid) => {
      if (newIsValid && (formProcess.value === "creating")) {
        handleEventRef.value({ type: "AUTO_SUBMIT" })
      }
    },
  )

  const changedFields = ref<Set<string>>(new Set())
  const previousFormData = ref<Partial<ZodForm>>({})
  /**
   * Tracks changes to form data and maintains a set of changed field names
   * This allows for batching field change events and processing them at appropriate times
   */
  watch(
    formData,
    (newValue) => {
      if (!newValue) {
        return
      }
      // Track changes between the current value and our stored snapshot
      // Compare with our stored snapshot instead of relying on oldValue
      Object.keys(newValue).forEach((key) => {
        const typedKey = key as keyof ZodForm
        // Only track changes if we have a previous value to compare with
        if (previousFormData.value && typedKey in previousFormData.value) {
          // Check if the value has changed using JSON serialization for deep comparison
          const newValueJson = JSON.stringify(newValue[typedKey])
          const prevValueJson = JSON.stringify(previousFormData.value[typedKey])

          if (newValueJson !== prevValueJson) {
            changedFields.value.add(key)
          }
        }
      })

      // Update our snapshot for the next change
      // Use JSON serialization for deep cloning (reliable across all environments)
      previousFormData.value = JSON.parse(JSON.stringify(newValue)) as Partial<ZodForm>
    },
    { deep: true },
  )

  /**
   * Processes all tracked field changes and dispatches appropriate events to the state machine
   * This should be called on field blur or other appropriate UI events
   * @returns Promise that resolves when all field changes have been processed
   */
  const handleFieldChanges = async () => {
    if (changedFields.value.size === 0) {
      return
    }

    for (const fieldName of Array.from(changedFields.value)) {
      if (!formData.value) {
        continue
      }

      const field = fieldName as keyof ZodForm & string
      const value = formData.value[field]

      const typedEvent: FormEvent<ZodForm> = {
        type: "FIELD_CHANGED",
        field,
        value,
      }

      handleEventRef.value(typedEvent)
    }

    changedFields.value.clear()
  }

  /**
   * Initializes a form for either creating a new entity or editing an existing one
   * @param options - Configuration options for form initialization
   * @param options.id - Optional ID of the entity to edit. If provided, the form will be in edit mode
   * @param options.initialData - Optional initial data for the form
   */
  const initForm = (options: { id?: string, initialData?: ZodForm }) => {
    const { id, initialData } = options

    if (id) {
      if (formProcess.value === "editing") {
        handleEventRef.value({
          type: "ENTITY_CANCELED",
        })
      }
      handleEventRef.value({
        type: "EDIT_ENTITY",
        id,
        entity: initialData,
      })
    }
    else {
      if (formProcess.value === "editing") {
        handleEventRef.value({
          type: "ENTITY_CANCELED",
        })
      }
      handleEventRef.value({
        type: "NEW_ENTITY",
        entity: initialData,
      })
    }
  }

  /**
   * Updates the form with new entity data from the server
   * Useful after creation or when an entity is updated externally
   * @param entity - The updated entity data from the server
   */
  const updateEntity = (entity: ZodForm) => {
    handleEventRef.value({
      type: "UPDATE_ENTITY",
      entity,
    })
  }

  /**
   * Function to explicitly cancel the current form operation
   */
  const cancelForm = () => {
    handleEventRef.value({ type: "ENTITY_CANCELED" })
  }

  return {
    formData,
    handleFieldChanges,
    validateForm,
    initForm,
    updateEntity,
    cancelForm,
    changedFields: computed(() => Array.from(changedFields.value)),
    formErrors: computed(() => formErrors.value),
    isValid: computed(() => isValid.value),
    isDirty: computed(() => isDirty.value),
    isSubmitting: computed(() => isSubmitting.value),
    formProcess: computed(() => formProcess.value),
    mode: computed(() => mode.value),
    id: computed(() => id.value),
  }
}
