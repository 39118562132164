import type { Customer, Employee, ID, Phase, Post, Project, ReleaseNotes, Tenant, User } from "@/types"
import { getCountriesConfig } from "@/config/countries"
import { projectSchema } from "@/types/models/ProjectTypes"

import { defineStore } from "pinia"
import { computed, ref, watchEffect } from "vue"

export const useDataStore = defineStore("data", () => {
  // State
  const currentUser = ref<User | null>(null)
  const projectsById = ref<Record<string, Project>>({})
  const customersById = ref<Record<string, Customer>>({})
  const postsById = ref<Record<string, Post>>({})
  const postTemplatesById = ref<Record<string, Post>>({})
  const phasesById = ref<Record<string, Phase>>({})
  const employeesById = ref<Record<string, Employee>>({})
  const tenantsById = ref<Record<string, Tenant>>({})
  const releaseNotesById = ref<Record<string, ReleaseNotes>>({})

  // Getters
  const projects = computed(() => {
    const asArray = Object.values(projectsById.value)
    return asArray.sort((a, b) => {
      if (!a.date_created) {
        console.warn(`Project ${a.id} is missing date_created property`)
        return 0
      }
      if (!b.date_created) {
        console.warn(`Project ${b.id} is missing date_created property`)
        return 0
      }
      return b.date_created.getTime() - a.date_created.getTime()
    })
  })

  function getCustomerProjects(customerId: string) {
    return Object.values(projectsById.value).filter(
      project => project.customer === customerId,
    )
  }

  function prepareProject(project: Project): Project {
    const convertedProject = convertTimestamps(project, [
      "date_issued",
      "date_expire",
      "date_created",
      "date_updated",
    ])

    // Normalize references
    if (convertedProject.customer && typeof convertedProject.customer === "object") {
      convertedProject.customer = (convertedProject.customer as { id: string }).id
    }
    if (convertedProject.tenant && typeof convertedProject.tenant === "object") {
      convertedProject.tenant = (convertedProject.tenant as { id: string }).id
    }

    return convertedProject
  }

  // Data Management Actions
  function setProjects(projects: Project[]) {
    projectsById.value = {}
    projects.forEach((project) => {
      if (project.status === "archived") {
        return
      }
      const convertedProject = prepareProject(project)
      const validatedProject = validateData(convertedProject, projectSchema, "Project")
      projectsById.value[validatedProject.id] = validatedProject
    })
  }

  function setProject(project: Project) {
    if (project.status === "archived") {
      return
    }
    const convertedProject = prepareProject(project)
    const validatedProject = validateData(convertedProject, projectSchema, "Project")
    projectsById.value[validatedProject.id] = validatedProject
  }

  function removeProject(projectId: ID<Project>) {
    delete projectsById.value[projectId]
  }

  function setCustomers(customers: Customer[]) {
    customersById.value = {}
    customers.forEach((customer) => {
      const convertedCustomer = convertTimestamps(customer, [
        "date_created",
        "date_updated",
      ])
      /* const validatedCustomer = validateData(convertedCustomer, customerSchema, 'Customer')
      customersById.value[validatedCustomer.id] = validatedCustomer */
      customersById.value[convertedCustomer.id] = convertedCustomer
    })
  }

  function setCustomer(customer: Customer) {
    const convertedCustomer = convertTimestamps(customer, [
      "date_created",
      "date_updated",
    ])
    /* const validatedCustomer = validateData(convertedCustomer, customerSchema, 'Customer')
    customersById.value[validatedCustomer.id] = validatedCustomer */
    customersById.value[convertedCustomer.id] = convertedCustomer
  }

  function removeCustomer(customerId: ID<Customer>) {
    delete customersById.value[customerId]
  }

  function setPosts(posts: Post[]) {
    postsById.value = {}
    posts.forEach((post) => {
      /* const validatedPost = validateData(post, postSchema, 'Post')
      setPost(validatedPost) */
      setPost(post)
    })
  }

  function setPost(post: Post) {
    if (postsById.value[post.id]) {
      removePost(post)
    }
    const convertedPost = convertTimestamps(post, ["date_created", "date_updated"])
    /* const validatedPost = validateData(convertedPost, postSchema, 'Post')
    postsById.value[post.phase][validatedPost.id] = validatedPost */
    postsById.value[convertedPost.id] = convertedPost
  }

  function removePost(post: Post) {
    if (postsById.value[post.id]) {
      delete postsById.value[post.id]
    }
  }

  function setEmployees(employees: Employee[]) {
    employeesById.value = {}
    employees.forEach((employee) => {
      const convertedEmployee = convertTimestamps(employee, [
        "date_created",
        "date_updated",
      ])
      /* const validatedEmployee = validateData(convertedEmployee, employeeSchema, 'Employee')
      employeesById.value[validatedEmployee.id] = validatedEmployee */
      employeesById.value[convertedEmployee.id] = convertedEmployee
    })
  }

  function setEmployee(employee: Employee) {
    const convertedEmployee = convertTimestamps(employee, [
      "date_created",
      "date_updated",
    ])
    /* const validatedEmployee = validateData(convertedEmployee, employeeSchema, 'Employee')
    employeesById.value[validatedEmployee.id] = validatedEmployee */
    employeesById.value[convertedEmployee.id] = convertedEmployee
  }

  function setUser(user: User) {
    const convertedUser = convertTimestamps(user, [
      "release_notes_last_seen",
    ])
    currentUser.value = convertedUser
  }

  function setTenants(tenants: Tenant[]) {
    tenantsById.value = {}
    tenants.forEach((tenant) => {
      const convertedTenant = convertTimestamps(tenant, [
        "date_created",
        "date_updated",
      ])
      /* const validatedTenant = validateData(convertedTenant, tenantSchema, 'Tenant')
      tenantsById.value[validatedTenant.id] = validatedTenant */
      convertedTenant.$countrySetting = getCountriesConfig().map.get(
        tenant.country_code,
      )
      tenantsById.value[convertedTenant.id] = convertedTenant
    })
  }

  function setTenant(tenant: Tenant) {
    const convertedTenant = convertTimestamps(tenant, [
      "date_created",
      "date_updated",
    ])
    /* const validatedTenant = validateData(convertedTenant, tenantSchema, 'Tenant')
    validatedTenant.$countrySetting = getCountriesConfig().map.get(
      tenant.country_code,
    )
    tenantsById.value[validatedTenant.id] = validatedTenant */
    convertedTenant.$countrySetting = getCountriesConfig().map.get(
      tenant.country_code,
    )
    tenantsById.value[convertedTenant.id] = convertedTenant
  }

  function removeTenant(tenantId: ID<Tenant>) {
    delete tenantsById.value[tenantId]
  }

  function setPhases(phases: Phase[]) {
    phasesById.value = {}
    phases.forEach(phase => setPhase(phase))
  }

  function setPhase(phase: Phase) {
    const convertedPhase = convertTimestamps(phase, ["date_created", "date_updated"])
    /* const validatedPhase = validateData(convertedPhase, phaseSchema, 'Phase')
    phasesById.value[phase.project][validatedPhase.id] = validatedPhase */
    phasesById.value[phase.id] = convertedPhase
  }

  function removePhase(phase: Phase) {
    if (phasesById.value[phase.id]) {
      delete phasesById.value[phase.id]
    }
  }

  function setPostTemplates(templates: Post[]) {
    postTemplatesById.value = {}
    templates.forEach((template) => {
      const convertedTemplate = convertTimestamps(template, [
        "date_created",
        "date_updated",
      ])
      /* const validatedTemplate = validateData(convertedTemplate, postSchema, 'Post')
      postTemplatesById.value[validatedTemplate.id] = validatedTemplate */
      postTemplatesById.value[template.id] = convertedTemplate
    })
  }

  function setPostTemplate(template: Post) {
    const convertedTemplate = convertTimestamps(template, [
      "date_created",
      "date_updated",
    ])
    /* const validatedTemplate = validateData(convertedTemplate, postSchema, 'Post')
    postTemplatesById.value[validatedTemplate.id] = validatedTemplate */
    postTemplatesById.value[template.id] = convertedTemplate
  }

  function removePostTemplate(templateId: ID<Post>) {
    delete postTemplatesById.value[templateId]
  }

  function setReleaseNotes(notes: ReleaseNotes[]) {
    releaseNotesById.value = {}
    notes.forEach((note) => {
      if (note.id) {
        releaseNotesById.value[note.id] = note
      }
    })
  }

  // Reactive relationships
  watchEffect(() => {
    // Update project relationships
    Object.values(projectsById.value).forEach((project) => {
      project.$customer = customersById.value[project.customer]
      project.$phases = Object.values(phasesById.value).filter(
        phase => phase.project === project.id,
      )
    })

    // Update phase relationships
    Object.values(phasesById.value).forEach((phase) => {
      phase.$posts = Object.values(postsById.value).filter(
        post => post.phase === phase.id,
      )
    })

    // Update user relationships if exists
    if (currentUser.value) {
      const employee = Object.values(employeesById.value).find(
        employee => employee.user.id === currentUser.value?.id,
      )
      if (employee) {
        currentUser.value.$employee = employee
      }
      if (currentUser.value.active_tenant) {
        currentUser.value.$tenant = tenantsById.value[currentUser.value.active_tenant]
      }
    }
  })

  return {
    // State
    currentUser,
    projectsById,
    customersById,
    postsById,
    postTemplatesById,
    phasesById,
    employeesById,
    tenantsById,
    releaseNotesById,

    // Getters
    projects,

    // Actions
    setProjects,
    setProject,
    removeProject,
    setCustomers,
    setCustomer,
    removeCustomer,
    setPosts,
    setPost,
    removePost,
    setEmployees,
    setEmployee,
    setUser,
    setTenants,
    setTenant,
    removeTenant,
    setPhases,
    setPhase,
    removePhase,
    setPostTemplates,
    setPostTemplate,
    removePostTemplate,
    setReleaseNotes,
    getCustomerProjects,
  }
})
