import type { Customer, Tenant } from "@/types"
import type { Currency } from "@/types/models/SharedModelTypes"
import type { TenantTypes } from "@/types/models/TenantTypes"
import api from "@/api"
import { tryCatch } from "@/utils/errorHandling"
import seperateFullName from "@/utils/seperateFullName"

/**
 * Sends SMS code to the provided mobile number
 * @param mobileNumber The mobile number to send the code to
 * @returns True if the operation was successful, false otherwise
 */
export async function sendSmsCode(mobileNumber: string): Promise<boolean> {
  const { data, error } = await tryCatch(api.users.sendUserSMSCode(mobileNumber))
  return !!data && !error
}

/**
 * Verifies the SMS code and creates a user if needed
 * @param code The verification code
 * @param mobileNumber The mobile number
 * @param tenantType The tenant type
 * @returns The verification result
 */
export async function verifySmsCode(code: number, mobileNumber: string, tenantType: TenantTypes): Promise<{
  status: boolean
  user?: { email: string }
}> {
  const { data, error } = await tryCatch(
    api.users.verifyUserSMSCode({
      code: code.toString(),
      mobile: mobileNumber,
      createUser: true,
      tenantType,
    })
  )
  
  if (error) {
    return { status: false }
  }
  
  return data || { status: false }
}

/**
 * Updates the user profile
 * @param values The profile values to update
 * @param values.email The user's email address
 * @param values.fullName The user's full name
 * @returns True if the operation was successful, false otherwise
 */
export async function updateUserProfile(values: { email: string, fullName: string }): Promise<boolean> {
  const { data: updateResult, error: updateError } = await tryCatch(
    api.users.updateMe({
      email: values.email,
      first_name: seperateFullName(values.fullName).firstName,
      last_name: seperateFullName(values.fullName).lastName,
    })
  )
  
  return !!updateResult && !updateError
}

/**
 * Updates the tenant with user information
 * @param tenantId The tenant ID
 * @param values The values to update
 * @returns True if the operation was successful, false otherwise
 */
export async function updateTenant(tenantId: string, values: Partial<Tenant>): Promise<boolean> {
  const { data, error } = await tryCatch(api.users.updateMyTenant(tenantId, values))
  return !!data && !error
}

/**
 * Sends a welcome email to the user
 * @param tenantId The tenant ID
 * @param firstName The user's first name
 * @param email The user's email
 * @param language The language code
 * @returns True if the operation was successful, false otherwise
 */
export async function sendWelcomeEmail(
  tenantId: string,
  firstName: string,
  email: string,
  language: string = "nb"
): Promise<boolean> {
  const { data, error } = await tryCatch(
    api.callN8nWorkflow({
      path: "/welcome-email",
      tenant_id: tenantId,
      first_name: firstName,
      email,
      language,
    })
  )
  
  return !!data && !error
}

/**
 * Updates the tenant owner information
 * @param owner The owner information
 * @param tenantId The tenant ID
 * @param currency The currency
 * @param phoneNumber The phone number
 * @param email The email
 * @returns The updated tenant or null if the operation failed
 */
export async function updateTenantOwner(
  owner: Partial<Customer>,
  tenantId: string,
  currency: Currency,
  phoneNumber: string,
  email: string
): Promise<Tenant | null> {
  // We need to use type assertion here because Customer and Tenant types have some overlapping properties
  // but are not directly compatible
  const payload = { ...owner } as any as Partial<Tenant>
  
  payload.email = email
  payload.phone_number = phoneNumber
  payload.currency = currency
  payload.country_code = currency === "NOK" ? "NO" : "ZA"
  
  const { data, error } = await tryCatch(
    api.users.updateMyTenant(tenantId, payload)
  )
  
  return error ? null : (data as Tenant)
}

/**
 * Updates the invoice color for a tenant
 * @param tenantId The tenant ID
 * @param color The color to set
 * @returns True if the operation was successful, false otherwise
 */
export async function updateInvoiceColor(tenantId: string, color: string): Promise<boolean> {
  const { data, error } = await tryCatch(
    api.users.updateMyTenant(tenantId, { invoice_color: color })
  )
  
  return !!data && !error
}

/**
 * Creates a new tenant for an existing user
 * @param userId The user ID
 * @returns The new tenant ID or null if the operation failed
 */
export async function createNewTenantForUser(userId: string): Promise<string | null> {
  const { data, error } = await tryCatch(
    api.callN8nWorkflow({
      tenant_id: "not-allocated-yet",
      path: "/user-tenant",
      action: "existing-user-new-tenant",
      user_id: userId,
    })
  )
  
  if (error || !data) {
    return null
  }
  
  const responseData = data as { data: { tenant_id: string } }
  return responseData.data.tenant_id
}
