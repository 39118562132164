<template>
  <div class="w-full flex flex-col items-center py-4">
    <video
      ref="videoPreview"
      :src="videoUrl"
      controls
      class="w-full max-h-[500px] rounded-lg overflow-hidden mb-6"
      playsinline
      webkit-playsinline
    />

    <div class="flex items-center justify-center">
      <!-- Use button -->
      <button
        class="flex items-center justify-center w-12 h-12 rounded-full bg-primary text-white hover:bg-primary-dark transition-colors duration-300"
        :title="$t('common.use') || 'Use'"
        @click="$emit('use')"
      >
        <IconCheckMark class="w-6 h-6" />
      </button>

      <!-- Repeat button -->
      <button
        class="flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 text-gray-800 hover:bg-gray-200 transition-colors duration-300 ml-4"
        :title="$t('common.repeat') || 'Record again'"
        @click="$emit('repeat')"
      >
        <IconRefresh class="w-6 h-6" />
      </button>

      <!-- Cancel button -->
      <button
        class="flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 text-gray-800 hover:bg-gray-200 transition-colors duration-300 ml-8"
        :title="$t('common.discard') || 'Discard'"
        @click="$emit('discard')"
      >
        <IconRemove class="w-6 h-6" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"

const props = defineProps<{
  videoUrl: string
}>()

defineEmits(["discard", "use", "repeat"])

const videoPreview = ref<HTMLVideoElement | null>(null)

// Auto-play the video when it's loaded
onMounted(() => {
  if (videoPreview.value) {
    videoPreview.value.play().catch((error) => {
      console.error("Error auto-playing video:", error)
    })
  }
})
</script>
