/**
 * Video utilities index
 * Exports all video-related functionality from a single entry point
 */

import type { RecordingUIElements } from "./webRecorder"
import { Capacitor } from "@capacitor/core"

export * from "./conversion"
export * from "./nativeRecorder"
export * from "./processing"
export * from "./ui"
export * from "./validation"
export * from "./webRecorder"

/**
 * Checks if the device has camera capabilities
 * @returns True if the device has camera capabilities
 */
export function hasCameraCapabilities(): boolean {
  // For web platform, check if getUserMedia is available
  if (Capacitor.getPlatform() === "web") {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
  }

  // For native platforms, check if Camera plugin is available
  return Capacitor.isPluginAvailable("Camera")
}

// Export the RecordingUIElements interface for type usage
export type { RecordingUIElements }
