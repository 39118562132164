<template>
  <div
    class="absolute z-10 transform -translate-x-1/2 left-1/2 "
    :class="{ 'top-24': !mobile, 'bottom-16': mobile }"
  >
    <div class="flex items-center gap-1 p-1.5 bg-white rounded-xl shadow-xl">
      <button
        v-for="tool in drawingTools"
        :key="tool.mode"
        class="p-2 transition-colors rounded hover:bg-gray-100"
        :class="imageStore.drawingMode === tool.mode ? 'bg-gray-100' : ''"
        :title="tool.title"
        @click="imageStore.drawingMode = tool.mode"
      >
        <svg class="w-5 h-5 text-gray-700" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <template v-if="tool.icon === 'free'">
            <path d="M3 21s3-3 9-3 9 3 9 3M12 3c3.5 0 7 3.5 7 7 0 2-1 3.5-2 4.5L12 20l-5-5.5C6 13.5 5 12 5 10c0-3.5 3.5-7 7-7z" />
          </template>
          <template v-else-if="tool.icon === 'straight'">
            <line x1="5" y1="12" x2="19" y2="12" />
          </template>
          <template v-else-if="tool.icon === 'arrow'">
            <line x1="5" y1="12" x2="19" y2="12" />
            <polyline points="12 5 19 12 12 19" />
          </template>
          <template v-else-if="tool.icon === 'square'">
            <rect x="4" y="4" width="16" height="16" rx="2" />
          </template>
        </svg>
      </button>

      <div class="self-center w-px h-6 mx-1 bg-gray-200" />

      <button
        class="p-2 transition-colors rounded hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
        :disabled="imageStore.historyIndex <= 0"
        title="Undo"
        @click="imageStore.undo()"
      >
        <svg class="w-5 h-5 text-gray-700" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M9 14L4 9l5-5" />
          <path d="M20 20v-7a4 4 0 00-4-4H4" />
        </svg>
      </button>
      <button
        class="p-2 transition-colors rounded hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
        :disabled="imageStore.historyIndex >= imageStore.history.length - 1"
        title="Redo"
        @click="imageStore.redo()"
      >
        <svg class="w-5 h-5 text-gray-700" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M15 14l5-5-5-5" />
          <path d="M4 20v-7a4 4 0 014-4h12" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useImageStore } from "@/stores/imageStore"

const imageStore = useImageStore()
const mobile = inject("isPhone") as Ref<boolean>

const drawingTools = [
  { mode: "free", title: "Free Draw", icon: "free" },
  { mode: "straight", title: "Line", icon: "straight" },
  { mode: "arrow", title: "Arrow", icon: "arrow" },
  { mode: "square", title: "Rectangle", icon: "square" },
] as const
</script>
