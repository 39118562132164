/**
 * Utility functions for handling errors in a type-safe way
 * Based on Theo's preferred way of handling try/catch in TypeScript
 * @see https://gist.github.com/t3dotgg/a486c4ae66d32bf17c09c73609dacc5b
 */
import * as Sentry from "@sentry/vue"

/**
 * A type representing the result of an operation that might fail
 * The presence of data or error indicates success or failure
 */
export type Result<T, E = Error> =
  | { data: T, error: null }
  | { data: null, error: E }

/**
 * Wraps an async operation in a try/catch block and returns a Result object
 *
 * @example
 * // Using with an async function
 * const result = await tryCatch(fetchData());
 * if (result.success) {
 *   // Use result.data
 * } else {
 *   // Handle result.error
 * }
 *
 * @param promise - The promise to handle
 * @returns A Result object containing either the data or the error
 */
/**
 * Wraps an async operation in a try/catch block, sends errors to Sentry, and returns a Result object
 *
 * @param promise - The promise to handle
 * @returns A Result object containing either the data or the error
 */
export async function tryCatch<T, E = Error>(
  promise: Promise<T>,
): Promise<Result<T, E>> {
  try {
    const data = await promise
    return { data, error: null }
  }
  catch (error) {
    // Convert non-Error objects to Error objects
    const errorObject = error instanceof Error
      ? error
      : new Error(JSON.stringify(error))

    // Send the error to Sentry
    Sentry.captureException(errorObject)

    // Log the error to console in development
    if (import.meta.env.MODE !== "production") {
      console.error("Error captured and sent to Sentry:", errorObject)
    }

    return {
      data: null,
      error: errorObject as unknown as E,
    }
  }
}
