<template>
  <div class="p-8 border border-l-0">
    <button
      class="flex justify-end w-full p-2 mb-8 transition duration-150 ease-in-out rounded-lg bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 active:bg-gray-200"
      @click="sideMenuOpen = !sideMenuOpen"
    >
      <img
        src="/icon/minimize-sidebar.svg"
        class="text-gray-400 transition-transform duration-200" :class="[
          sideMenuOpen ? 'rotate-0' : 'rotate-180',
        ]"
        alt="Toggle side menu"
      >
    </button>

    <ul data-testid="user-menu">
      <li v-for="item in menuItems" :key="item.name">
        <router-link
          v-if="item.display"
          :to="{ name: item.route }"
          :class="{
            'bg-blue-200': route.name === item.route,
          }"
          class="flex px-6 py-3 m-1 rounded-xl hover:border-blue-200 hover:bg-blue-200"
        >
          <component :is="item.icon" />
          <span :class="sideMenuOpen ? '' : 'hidden'" class="ml-4">{{
            $t(`menu-mobile.${item.name}`)
          }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import IconClient from "@/components/ui/icons/IconClient.vue"
import IconQuote from "@/components/ui/icons/IconQuote.vue"
import IconSettings from "@/components/ui/icons/IconSettings.vue"
import IconStats from "@/components/ui/icons/IconStats.vue"
import IconTemplate from "@/components/ui/icons/IconTemplate.vue"

const menuItems = [
  { route: "quotes", name: "offers", icon: IconQuote, display: true },
  { route: "templates", name: "templates", icon: IconTemplate, display: true },
  { route: "customers", name: "customers", icon: IconClient, display: true },
  { route: "stats", name: "stats", icon: IconStats, display: true },
  {
    route: "employees",
    name: "employees",
    icon: IconClient,
    display: false,
  },
  { route: "settings", name: "settings", icon: IconSettings, display: true },
]

const width = ref(0)
const isDesktop = computed(() => width.value > 1200)
const sideMenuOpenLocal = computed(
  () => window.localStorage.getItem("tredco-app-sidebar-open") !== "false",
)
const sideMenuOpen = ref(isDesktop.value && sideMenuOpenLocal.value)

const route = useRoute()
watch(
  () => route.name,
  () => {
    if (!isDesktop.value || !sideMenuOpenLocal.value) {
      sideMenuOpen.value = false
    }
  },
)

function handleResize() {
  width.value = window.innerWidth
  sideMenuOpen.value = isDesktop.value
}

onMounted(() => {
  window.addEventListener("resize", handleResize)
  handleResize()
})

onUnmounted(() => {
  window.removeEventListener("resize", handleResize)
})
</script>

<style scoped>
button:hover,
button:focus {
  outline: none; /* Removes default outline on focus */
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5); /* Adds a focus indicator */
}

img.transition-transform {
  transition: transform 0.3s ease;
}
</style>
