import { auditFieldsSchema, idSchema, postSchema } from "@/types"
import { z } from "zod"

// Base form schema for phase creation/editing
export const phaseFormSchema = z.object({
  name: z.string()
    .min(2, "Navnet må være minst 2 tegn")
    .max(100, "Navnet kan ikke være lengre enn 100 tegn"),
  project: idSchema.optional(),
  tenant: idSchema,
})

export type PhaseFormSchema = z.infer<typeof phaseFormSchema>

// Full phase schema including system fields
export const phaseSchema = phaseFormSchema.extend({
  id: idSchema,
  sort: z.number().nullable().optional(),
  status: z.string(),
  ...auditFieldsSchema.shape,
  $posts: z.array(z.lazy(() => postSchema)).optional(),
})

export type Phase = z.infer<typeof phaseSchema>
