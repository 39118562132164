<template>
  <PageLayout>
    <template #header>
      <div
        v-if="projectProcess !== 'customer'"
        class="flex items-center"
      >
        <h2 v-show="!showCustomerEdit" class="grow text-heading-2">
          {{ $t("menu-mobile.customers") }}
        </h2>
        <div>
          <Button v-if="!mobile" size="medium" icon="plus" @click="goToCustomer">
            {{ $t("new_customer") }}
          </Button>
        </div>
      </div>
      <div v-else class="flex items-center justify-between">
        <RouterLink :to="{ name: 'quotes' }">
          <IconExit />
        </RouterLink>
        <p class="text-label-bold-1">
          {{ $t("select_customer") }}
        </p>
        <Button size="medium" icon="plus" @click="goToCustomer">
          {{ $t("new_customer") }}
        </Button>
      </div>
      <SearchInputV2
        v-show="customerStore.customers.length > 7"
        v-model="searchTerm"
        class="mt-4"
        @reset="() => (searchTerm = '')"
      />
    </template>
    <template #default>
      <div v-show="!showCustomerEdit" class="mb-32 mt-6">
        <TransitionGroup name="fade">
          <div
            v-for="c in searchTerm.trim()
              ? customerStore.filtered
              : customerStore.customers"
            :key="c.id"
          >
            <CustomersSummary
              :customer="c"
              :mode="projectProcess === 'customer' ? 'select' : 'edit'"
              :disabled="!canSelectCustomer"
              data-testid="customer-item"
              @edit="goToCustomer(c)"
              @select="addClientToProject(c)"
            />
          </div>
        </TransitionGroup>
      </div>
      <CustomersPlaceholder v-if="customerStore.customers.length === 0" />
    </template>
    <template #footer-mobile>
      <div
        v-if="projectProcess === 'idle' && mobile"
        class="fixed inset-x-0 bottom-20 w-full px-4 pb-safe"
      >
        <Button
          v-if="!showCustomerEdit"
          icon="plus"
          class="w-full"
          size="large"
          @click="goToCustomer"
        >
          {{ $t("customers.new_customer") }}
        </Button>
      </div>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import type { Customer } from "@/types"
import type { Ref } from "vue"
import { storeToRefs } from "pinia"
import { computed, inject, onMounted, ref } from "vue"
import { useRouter } from "vue-router"

const projectController = useProjectController()
const projectModel = useProjectModel()
const { projectProcess } = storeToRefs(projectModel)

const customerStore = useCustomerStore()
const router = useRouter()
const showCustomerEdit = ref(false)
const searchTerm = ref("")

const canSelectCustomer = computed(() =>
  projectProcess.value === "customer",
)

function addClientToProject(customer: Customer) {
  if (!canSelectCustomer.value) {
    return
  }

  projectController.handleEvent({
    type: "CUSTOMER_PICKED",
    customerId: customer.id,
    street_address: customer.street_address,
  })
}

async function goToCustomer(customer?: Customer) {
  if (customer?.id) {
    await router.push({ name: "customer", params: { id: customer.id } })
  }
  else {
    await router.push({ name: "customer" })
  }
}

const showBottomNav = inject("showBottomNav") as Ref<boolean>

onMounted(() => {
  if (projectProcess.value === "idle") {
    showBottomNav.value = true
  }
  else {
    showBottomNav.value = false
  }
})
onUnmounted(() => {
  showBottomNav.value = false
})

const mobile = inject("isPhone") as Ref<boolean>

watchDebounced(
  searchTerm,
  () => {
    const term = searchTerm.value.trim()
    if (term) {
      customerStore.search(term)
    }
  },
  {
    debounce: 200,
  },
)
</script>
