<template>
  <div class="rounded-t-3xl bg-1-static ">
    <Logo
      :button-title="$t('onboarding.continue_button')"
      :secondary-button-title="$t('onboarding.logo_7')"
      @updated="onboardingController.handleEvent({ type: 'UPLOAD_LOGO' })"
      @secondry-clicked="onboardingController.handleEvent({ type: 'SKIP_LOGO' })"
    />
  </div>
</template>

<script setup lang="ts">
import { useOnboardingController } from "@/stores/onboardingController"

const onboardingController = useOnboardingController()
</script>
