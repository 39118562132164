<template>
  <div class="p-4 bg-1-static desktop:m-4 desktop:rounded-2xl">
    <Post />
  </div>
  <div class="w-full p-4">
    <Button
      icon="plus"
      type="secondary"
      class="w-full mt-4 mb-3"
      size="large"
      :loading="isSubmitting"
      @click="newPost"
    >
      {{ $t("quote.save_and_new_post") }}
    </Button>
    <Button class="w-full" size="large" type="primary" @click="handleEvent({ type: 'SAVE_POST' })">
      {{
        $t("shared.actions.save")
      }}
    </Button>
  </div>
</template>

<script setup lang="ts">
const postController = usePostController()
const { formData, isSubmitting } = storeToRefs(postController)
const { activeProject } = storeToRefs(useProjectModel())
const { handleEvent } = postController

const context = ref<"quote-view" | "stand-alone-view">("stand-alone-view")
const showOptions = ref(false)

provide<boolean>("editing", true)
provide<Ref<boolean>>("showOptions", showOptions)
provide("context", context)

function newPost() {
  if (!formData.value) {
    return
  }
  handleEvent({ type: "SAVE_POST" })
  const phaseId = activeProject.value?.$phases?.[0]?.id
  if (!phaseId) {
    console.warn("No phase found for the active project")
    return
  }
  if (formData.value.is_template) {
    handleEvent({ type: "NEW_POST_STARTED", phaseId: undefined, isTemplate: true })
  }
  else if (formData.value.phase) {
    handleEvent({ type: "NEW_POST_STARTED", phaseId })
  }
  else {
    console.warn("No phase found for the active post")
  }
}
</script>
