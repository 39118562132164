<template>
  <PageLayout v-if="formData">
    <template #header>
      <div class="flex items-center justify-between">
        <IconButton @click="projectController.handleEvent({ type: 'QUOTE_CLOSED' })" />
        <Button type="secondary" @click="goToCustomers">
          <p v-if="customersById[formData.customer]" class="truncate max-w-40">
            {{ customersById[formData.customer].name }}
          </p>
        </Button>
        <QuoteOptions />
      </div>
    </template>
    <hr>
    <div>
      <div>
        <div>
          <div class="flex items-center justify-between">
            <div class="w-1/5" />
          </div>
          <QuoteHeader />
          <div v-if="isValid">
            <div class="border-2 rounded-2xl">
              <Button
                icon="plus"
                type="secondary"
                class="w-full"
                size="large"
                rounded="rounded-t-xl"
                :hide-border="true"
                @click="newPost"
              >
                {{ $t("quote.new_post") }}
              </Button>
              <hr class="mx-4">

              <Button
                icon="plus"
                type="secondary"
                class="w-full"
                size="large"
                rounded="rounded-b-xl"
                :hide-border="true"
                @click="newPostFromTemplate"
              >
                {{ $t("quote.new_post_from_template") }}
              </Button>
            </div>

            <PostList
              v-if="sortedPosts?.length"
              :posts="sortedPosts"
              @post-click="postId => handleEvent({ type: 'EDIT_POST', postId })"
            />

            <BottomDrawerSlot
              :header="false"
              :show-options="postProcess === 'editing'"
              :level="0"
              @close="handleEvent({ type: 'CLOSE_POST' })"
            >
              <Post />
            </BottomDrawerSlot>
          </div>
        </div>
      </div>
    </div>
    <template #footer-mobile>
      <QuoteBottomAction />
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
const { customersById } = storeToRefs(useDataStore())

const projectController = useProjectController()
const { formData, isValid } = storeToRefs(projectController)

const { handleEvent } = usePostController()
const { formProcess } = storeToRefs(usePostController())
const { postProcess, sortedPosts, phase } = storeToRefs(usePostModel())

const { handleEvent: handleProjectEvent } = useProjectController()

const showOptions = ref(false)
const showQuoteOptions = ref(false)
const activePostId = ref<string | undefined>(undefined)

provide<Ref<boolean>>("showOptions", showOptions)
provide<Ref<string | undefined>>("activePostId", activePostId)

watch(showOptions, async (newShowOptions) => {
  if (!newShowOptions) {
    showQuoteOptions.value = false
  }
})

async function newPost() {
  if (!phase.value || !phase.value.id) {
    console.warn("No phases found for the active project")
    return
  }
  handleEvent({ type: "NEW_POST_STARTED", phaseId: phase.value.id })
}

async function goToCustomers() {
  handleProjectEvent({ type: "PICK_CUSTOMER" })
}

async function newPostFromTemplate() {
  if (!phase.value?.id) {
    console.warn("No phases found for the active project")
    return
  }
  handleProjectEvent({ type: "PICK_POST_TEMPLATE" })
}
</script>

<style scoped>
.swapy-dragging {
  opacity: 0.5;
  cursor: grabbing !important;
}

li > div {
  cursor: grab;
}
</style>
