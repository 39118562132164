<template>
  <template
    v-for="(content, ind) of contentPerPage"
    :key="content.posts[0]?.value?.id ?? 'last-page'"
  >
    <PDFPage v-bind="{ colors, tenant, project }">
      <SharedPDFHeader v-bind="{ colors, tenant }" />
      <QuotePDFPhaseDescriptor v-if="ind === 0" v-bind="{ project, phaseIndex }" />
      <QuotePDFItemsTable
        v-if="content.posts.length > 0"
        v-bind="{ project, content }"
        @ready="checkReadiness"
      />
      <QuotePDFItemsSummary v-if="content.total" v-bind="{ colors, project, tenant }" />
    </PDFPage>
  </template>
  <QuotePDFPhaseMeasurement
    v-bind="{ colors, tenant, project, phaseIndex }"
    @ready="calculateContentPerPage"
  />
</template>

<script setup lang="ts">
import type {
  Color,
  ItemPageContent,
  Measurements,
  PdfProject,
  PdfTenant,
} from "@/pdf-generator/types"

import type { Post } from "@/types"

import { computed, defineEmits, defineProps, ref } from "vue"
import SharedPDFHeader from "../../base/PDFHeader.vue"
import PDFPage from "../../base/PDFPage.vue"
import QuotePDFItemsSummary from "../items/QuotePDFItemsSummary.vue"
import QuotePDFItemsTable from "../items/QuotePDFItemsTable.vue"
import QuotePDFPhaseDescriptor from "./QuotePDFPhaseDescriptor.vue"
import QuotePDFPhaseMeasurement from "./QuotePDFPhaseMeasurement.vue"

const props = defineProps<{
  colors: Color
  tenant: PdfTenant
  project: PdfProject
  phaseIndex: number
}>()

const emit = defineEmits(["ready"])

const phase = computed(() => {
  return props.project.phases[props.phaseIndex]
})

const isSinglePhase = computed(() => {
  return props.project.phases.length === 1
})

const posts = computed(() => {
  return phase.value.posts as Post[]
})

const contentPerPage = ref<ItemPageContent[]>([])

function calculateContentPerPage(measurements: Measurements) {
  contentPerPage.value = []
  let currentPageContent: ItemPageContent = {
    previousPage: false,
    posts: [],
    nextPage: false,
    total: false,
  }
  let currentPageSpaceLeft
    = measurements.page
      - measurements.header
      - measurements.phaseDescriptor
      - measurements.table.headers
      - measurements.table.nextPage

  for (let i = 0; i < posts.value.length; i++) {
    const post = phase.value.posts?.[i]
    const postHeight = measurements.table.items.get(post?.id ?? "") ?? 0
    if (currentPageSpaceLeft > postHeight) {
      currentPageContent.posts.push({
        index: i + 1,
        value: posts.value[i],
      })
      currentPageSpaceLeft -= postHeight
    }
    else {
      currentPageContent.nextPage = true
      contentPerPage.value.push(currentPageContent)
      currentPageContent = {
        previousPage: true,
        posts: [
          {
            index: i + 1,
            value: posts.value[i],
          },
        ],
        nextPage: false,
        total: false,
      }
      currentPageSpaceLeft
        = measurements.page
          - measurements.header
          - postHeight
          - measurements.table.previousPage
          - measurements.table.headers
          - measurements.table.nextPage
    }
  }
  if (isSinglePhase.value) {
    if (currentPageSpaceLeft > measurements.summary) {
      currentPageContent.total = true
    }
    else {
      contentPerPage.value.push(currentPageContent)
      currentPageContent = {
        posts: [],
        nextPage: false,
        previousPage: true,
        total: true,
      }
    }
  }

  contentPerPage.value.push(currentPageContent)
  return contentPerPage
}

function checkReadiness() {
  emit("ready")
}
</script>
