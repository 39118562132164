<template>
  <PDFPage v-bind="{ colors, tenant, project }">
    <SharedPDFHeader v-bind="{ tenant, colors }" :bigger-logo="true" />

    <div
      class="mb-[5.6mm] rounded-[2.8mm] p-[5.6mm] text-caption-2 text-color-label-2"
      :style="`--border-color: ${colors.primary}30; background-color: ${colors.primary}1A;`"
    >
      <div
        class="project-details-element text-heading-3"
        :style="`color: ${colors.primary};`"
      >
        {{ project.name }}
      </div>
      <div>
        <div class="project-details-element flex justify-between py-[4.6mm]">
          <div>{{ $t("quote.quote_number") }}</div>
          <div>{{ project.quote_number }}</div>
        </div>
        <div
          v-if="project.date_issued"
          class="project-details-element flex justify-between py-[4.6mm]"
        >
          <div>{{ $t("project.date_issued") }}</div>
          <div>{{ formatDate(project.date_issued) }}</div>
        </div>
        <div
          v-if="project.date_expire"
          class="project-details-element flex justify-between py-[4.6mm]"
        >
          <div>{{ $t("project.date_expire") }}</div>
          <div>{{ formatDate(project.date_expire) }}</div>
        </div>
        <div
          v-if="project.invoice_due_in_days"
          class="project-details-element flex justify-between py-[4.6mm]"
        >
          <div>{{ $t("payment_due_x_days_after_invoice") }}</div>
          <div>{{ $t("no_days", [project.invoice_due_in_days]) }}</div>
        </div>
      </div>
    </div>
    <div class="mb-[5.6mm] p-[5.6mm]">
      <div class="pb-[2.8mm] text-label-bold-3" :style="`color: ${colors.primary};`">
        {{ $t("client_information") }}
      </div>
      <div class="text-label-bold-1">
        {{ project.customer.name }}
      </div>
      <div v-if="project.customer.type === 'company'">
        {{ $t("org_number_abbrev") }}
        {{ project.customer.company_registration_number }}
      </div>
      <div>{{ project.customer.street_address }}</div>
      <div>{{ project.customer.zip_code }}, {{ project.customer.city }}</div>
      <div>{{ project.customer.country_code }}</div>
      <div v-if="project.customer.phone_number">
        {{ getLocalPhoneNumber(project.customer.phone_number) }}
      </div>
    </div>
    <div class="p-[5.6mm]">
      <div class="pb-[2.8mm] text-label-bold-3" :style="`color: ${colors.primary};`">
        {{ $t("company_information") }}
      </div>
      <div class="text-label-bold-1">
        {{ tenant.name }}
      </div>
      <div>
        {{ $t("org_number_abbrev") }}
        {{ tenant.company_registration_number }}
      </div>
      <div>{{ tenant.street_address }}</div>
      <div>{{ tenant.zip_code }}, {{ tenant.city }}</div>
      <div>{{ tenant.country_code }}</div>
      <div>{{ getLocalPhoneNumber(tenant.phone_number) }}</div>
    </div>
  </PDFPage>
</template>

<script setup lang="ts">
import type { Color, PdfProject, PdfTenant } from "@/pdf-generator/types"
import { getLocalPhoneNumber } from "@/utils/sanitizeMobileNumber"
import { format } from "date-fns"

import SharedPDFHeader from "../base/PDFHeader.vue"
import PDFPage from "../base/PDFPage.vue"

defineProps<{
  colors: Color
  tenant: PdfTenant
  project: PdfProject
}>()

function formatDate(date: string) {
  return format(new Date(date), "dd/MM/yyyy")
}
</script>

<style>
.project-details-element {
  border-color: var(--border-color);
  @apply border-b pb-[4.6mm];
}

.project-details-element:last-child {
  @apply border-none pb-0;
}
</style>
