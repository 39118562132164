<template>
  <div class="hide-scrollbar flex max-h-[75dvh] flex-col overflow-y-auto rounded-2xl">
    <div v-if="formData" v-auto-animate class="mb-4 space-y-3 rounded-2xl bg-2-static">
      <!-- Quote Info Section -->
      <QuoteSettingSummary
        v-show="expandedSection === null || expandedSection === 'quote_info'"
        :title="$t('quote.settings.quote_info')"
        :sub-title="`${$t('quote.quote_number')} ${formData.quote_number}`"
        :expanded="expandedSection === 'quote_info'"
        @expand="(expanded) => handleExpand('quote_info', expanded)"
      >
        <QuoteSettingsInfo />
      </QuoteSettingSummary>

      <!-- Payment Plan Section -->
      <QuoteSettingSummary
        v-show="expandedSection === null || expandedSection === 'payment_plan'"
        :title="$t('quote.settings.payment_plan')"
        :sub-title="$t('quote.settings.payment_plan_description')"
        :expanded="expandedSection === 'payment_plan'"
        @expand="(expanded) => handleExpand('payment_plan', expanded)"
      >
        <QuoteSettingsPaymentPlan />
      </QuoteSettingSummary>

      <!-- Notes Section -->
      <QuoteSettingSummary
        v-show="expandedSection === null || expandedSection === 'notes'"
        :title="$t('notes')"
        :sub-title="formData.note"
        :expanded="expandedSection === 'notes'"
        @expand="(expanded) => handleExpand('notes', expanded)"
      >
        <QuoteSettingsNotes />
      </QuoteSettingSummary>

      <!-- Terms Section -->
      <QuoteSettingSummary
        v-show="expandedSection === null || expandedSection === 'terms'"
        :title="$t('quote.settings.terms')"
        :sub-title="formData.terms"
        :expanded="expandedSection === 'terms'"
        @expand="(expanded) => handleExpand('terms', expanded)"
      >
        <QuoteSettingsTerms />
      </QuoteSettingSummary>

      <!-- Discount Section -->
      <QuoteSettingSummary
        v-show="expandedSection === null || expandedSection === 'discount'"
        :title="$t('quote.settings.discount')"
        :sub-title="`${formatNumber(percentageDiscount)}%`"
        :expanded="expandedSection === 'discount'"
        @expand="(expanded) => handleExpand('discount', expanded)"
      >
        <QuoteSettingsDiscount />
      </QuoteSettingSummary>
    </div>

    <QuoteTotals />
  </div>
</template>

<script setup lang="ts">
import { useProjectModel } from "@/stores/projectModel"
import { useProjectController } from "@/stores/projectsController"
import { formatNumber } from "@/utils/companyUtils"
import { storeToRefs } from "pinia"

const emit = defineEmits<{ valid: [val: boolean] }>()
const { activeProject } = storeToRefs(useProjectModel())
const projectController = useProjectController()
const { formData } = storeToRefs(projectController)

const expandedSection = ref<string | null>(null)

function handleExpand(section: string, expanded: boolean) {
  // When a component wants to expand, make it the active section
  // When the currently expanded component wants to collapse, show all components
  if (expanded) {
    expandedSection.value = section
  }
  else {
    expandedSection.value = null
  }
}

watchEffect(() => {
  if (formData.value && !formData.value.contact_person) {
    emit("valid", true)
  }
  else {
    emit("valid", false)
  }
})

const percentageDiscount = computed(() => {
  if (!formData.value) {
    return 0
  }
  const discount = Number(formData.value.discount_amount)
  if (Number.isNaN(discount) || !discount) {
    return 0
  }

  const total = activeProject.value?.total_amount ?? 0
  return total > 0 ? (discount / total) * 100 : 0
})
</script>
