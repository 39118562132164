<template>
  <PageLayout :form-page="true">
    <template #header>
      <TopNav
        :title="pageTitle"
        :type="customerStore.status"
        :show-additiol-options="customerStore.status === 'edit'"
        :saving="saving"
        @exit="$router.back()"
        @back="$router.push({ name: 'customers' })"
        @save="submitForm"
        @options="true"
      >
        <template v-if="customerStore.customer" #options>
          <CustomerOptions :customer-id="customerStore.customer.id" />
        </template>
      </TopNav>
    </template>
    <template #default>
      <div v-if="customerStore.customer" :key="customerStore.customer.id" class="space-y-4 mb-8">
        <Toggle
          :value-left="$t('customers.types.company')"
          :value-right="$t('customers.types.person')"
          class="my-4 bg-1-static"
          :default="customerType"
          @left-click="handleCustomerTypeChange('company')"
          @right-click="handleCustomerTypeChange('person')"
        />
        <TextFieldWithAutoComplete
          v-if="customerStore.showBrregSearchBox"
          v-model="customerStore.query"
          :name="$t('customers.company_name')"
          :options="customerStore.companies ?? []"
          :loading="customerStore.loadingCounter > 0"
          @select="customerStore.handleSelect"
          @search="customerStore.searchBrreg"
        >
          <template #option="{ option }">
            <BrregSummary :company="option as BrregCompany" />
          </template>
        </TextFieldWithAutoComplete>
        <div>
          <div class="text-heading-3 text-color-label-1">
            {{ customerStore.customer.name }}
          </div>
          <div class="text-label-3 text-color-label-3">
            {{ orgNo }}
          </div>
        </div>
        <div v-show="!customerStore.showBrregSearchBox" class="mt-4">
          <form class="w-full space-y-8" @submit.prevent="submitForm">
            <!-- Contact Information Section -->
            <div class="space-y-4">
              <h3 class="text-heading-4 text-label-bold-1">
                {{ $t('customers.contact_info') }}
              </h3>
              <TextFieldVal
                v-model="customerStore.customer.email"
                rules="email|required"
                type="email"
                :label="$t('email')"
                name="email"
                :directus-context="customerStore.directusContext"
                directus-property="email"
                @saving="saving = $event"
              />
              <PhoneInput
                v-model="customerStore.customer.phone_number"
                name="phone_number"
                :label="$t('phone')"
                class="mb-3"
                :directus-context="customerStore.directusContext"
                directus-property="phone_number"
                :allow-all-countries="true"
              />
            </div>

            <!-- Company Information Section -->
            <div v-if="customerStore.customer.type === 'company'" class="space-y-4">
              <h3 class="text-heading-4 text-label-bold-1">
                {{ $t('customers.company_info') }}
              </h3>
              <TextFieldVal
                v-model="customerStore.customer.name"
                rules="required"
                type="text"
                :label="$t('shared.fields.name')"
                name="name"
                :directus-context="customerStore.directusContext"
                directus-property="name"
                @saving="saving = $event"
              />
              <TextFieldVal
                v-model="customerStore.customer.company_registration_number"
                type="text"
                :label="$t('org_number')"
                name="company_registration_number"
                :directus-context="customerStore.directusContext"
                directus-property="company_registration_number"
                @saving="saving = $event"
              />
            </div>

            <!-- Personal Information Section -->
            <div v-else class="space-y-4">
              <h3 class="text-heading-4 text-label-bold-1">
                {{ $t('customers.personal_info') }}
              </h3>
              <TextFieldVal
                v-model="customerStore.customer.name"
                rules="required"
                type="text"
                :label="$t('shared.fields.name')"
                name="name"
                :directus-context="customerStore.directusContext"
                directus-property="name"
                @saving="saving = $event"
              />
            </div>

            <!-- Address Information Section -->
            <div class="space-y-4">
              <h3 class="text-heading-4 text-label-bold-1">
                {{ $t('customers.address') }}
              </h3>
              <TextFieldVal
                v-model="customerStore.customer.street_address"
                type="text"
                :label="$t('street_address')"
                name="street_address"
                :directus-context="customerStore.directusContext"
                directus-property="street_address"
                @saving="saving = $event"
              />
              <div class="grid grid-cols-2 gap-3">
                <TextFieldVal
                  v-model="customerStore.customer.zip_code"
                  type="text"
                  :label="$t('postal_code')"
                  name="zip_code"
                  :directus-context="customerStore.directusContext"
                  directus-property="zip_code"
                  @saving="saving = $event"
                />
                <TextFieldVal
                  v-model="customerStore.customer.city"
                  type="text"
                  :label="$t('city')"
                  name="city"
                  :directus-context="customerStore.directusContext"
                  directus-property="city"
                  @saving="saving = $event"
                />
              </div>
              <TextFieldVal
                v-model="customerStore.customer.municipality"
                type="text"
                :label="$t('municipality')"
                name="municipality"
                :directus-context="customerStore.directusContext"
                directus-property="municipality"
                @saving="saving = $event"
              />
            </div>

            <Button
              v-if="!showInvoiceFields"
              type="secondary"
              class="w-full mt-4"
              @click="showInvoiceFields = true"
            >
              {{ $t('customers.add_invoice_address') }}
            </Button>

            <div v-if="showInvoiceFields" class="space-y-4">
              <div class="flex items-center justify-between mt-4 mb-2">
                <p class="text-heading-3 text-color-label-1">
                  {{ $t("invoice_information") }}
                </p>
                <Button

                  class="text-color-label-3"
                  @click="showInvoiceFields = false"
                >
                  {{ $t('customers.hide_billing_address') }}
                </Button>
              </div>

              <TextFieldVal
                v-model="customerStore.customer.billing_street_address"
                type="text"
                :label="$t('street_address')"
                name="billing_street_address"
                :directus-context="customerStore.directusContext"
                directus-property="billing_street_address"
                @saving="saving = $event"
              />
              <TextFieldVal
                v-model="customerStore.customer.billing_zip_code"
                type="text"
                :label="$t('postal_code')"
                name="billing_zip_code"
                :directus-context="customerStore.directusContext"
                directus-property="billing_zip_code"
                @saving="saving = $event"
              />
              <TextFieldVal
                v-model="customerStore.customer.billing_city"
                type="text"
                :label="$t('city')"
                name="billing_city"
                :directus-context="customerStore.directusContext"
                directus-property="billing_city"
                @saving="saving = $event"
              />
              <TextFieldVal
                v-model="customerStore.customer.billing_municipality"
                type="text"
                :label="$t('municipality')"
                name="billing_municipality"
                :directus-context="customerStore.directusContext"
                directus-property="billing_municipality"
                @saving="saving = $event"
              />
            </div>
          </form>
        </div>
      </div>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import type { BrregCompany } from "@/types/models/CustomerTypes"
import { useCustomerStore } from "@/stores/customerStore"
import { useGlobalStore } from "@/stores/globalStore"
import { computed, ref } from "vue"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"

const saving = ref(false)
const showInvoiceFields = ref(false)

const { t } = useI18n()
const globalStore = useGlobalStore()
const customerStore = useCustomerStore()
const router = useRouter()

const { projectProcess } = storeToRefs(useProjectModel())
const projectController = useProjectController()

// Computed properties for reactive updates
const pageTitle = computed(() =>
  customerStore.status === "edit"
    ? customerStore.customer?.name || t("customers.edit_customer")
    : t("customers.new_customer"),
)

const orgNo = computed(() => {
  if (!customerStore.customer?.company_registration_number) {
    return ""
  }
  return `${t("org_number_abbrev")}: ${customerStore.customer.company_registration_number}`
})

const customerType = computed(() =>
  customerStore.customer?.type === "company" ? "left" : "right",
)

const hasBillingInfo = computed(() => {
  const customer = customerStore.customer
  if (!customer) {
    return false
  }

  return Boolean(
    customer.billing_zip_code
    || customer.billing_city
    || customer.billing_street_address,
  )
})

// Initialize invoice fields visibility based on existing billing info
showInvoiceFields.value = hasBillingInfo.value

function handleCustomerTypeChange(type: "company" | "person") {
  if (customerStore.customer) {
    customerStore.customer.type = type
  }
}

async function submitForm() {
  if (!globalStore.tenant) {
    throw new Error("Tenant not found")
  }
  if (!customerStore.customer) {
    return
  }

  customerStore.customer.tenant = globalStore.tenant.id
  saving.value = true
  const customer = await customerStore.create()

  if (
    projectProcess.value === "customer"
  ) {
    projectController.handleEvent({
      type: "CUSTOMER_PICKED",
      customerId: customer.id,
      street_address: customer.street_address,
    })
  }
  else {
    router.push({ name: "customers" })
  }
  saving.value = false
}
</script>
