<template>
  <!-- Input Section -->
  <div
    class="min-h-[42px] items-center" :class="[
      $attrs.class,
      {
        'flex': props.labelPosition === 'left',
        'item-center': props.labelPosition === 'left',
      },
    ]"
  >
    <label
      v-if="name != ''"
      class="mb-1 mr-2 text-sm text-gray-400" :class="[
        { 'text-red-700': error },
        {
          'w-8/12': props.labelPosition === 'left',
          'text-right': props.labelPosition === 'left',
        },
        {
          'w-full': props.labelPosition === 'top',
          'text-left': props.labelPosition === 'top',
        },
      ]"
    >
      {{ name }}:
    </label>
    <div class="relative" :class="{ 'has-symbol': symbol }">
      <input
        ref="input"
        v-model="textValue"
        type="text"
        :class="[
          inputClassNames,
          { 'opacity-60': props.disabled, 'bg-white': !props.disabled },
        ]"
        :placeholder="placeholder"
        :disabled="disabled"
        class="block w-full bg-white rounded-lg drop-shadow-tr-shadow block w-full rounded-lg drop-shadow-tr-shadow"
        :style="$attrs.style as StyleValue"
        @keyup.enter="onEnterButton"
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DirectusContext } from "@/types/ApiTypes"
import api from "@/api"
import { useVModel, watchIgnorable, whenever } from "@vueuse/core"

interface Props {
  name?: string
  modelValue?: number
  placeholder?: string
  displayValue?: string
  disabled?: boolean
  showNameOnView?: boolean
  error?: boolean
  textSize?: "normal" | "lg" | "xl"
  bold?: boolean
  symbol?: string
  readonly?: boolean
  decimals?: number
  labelPosition?: "top" | "left"
  directusContext?: DirectusContext
}

// Default Props
const props = withDefaults(defineProps<Props>(), {
  name: "",
  modelValue: 0,
  placeholder: "",
  displayValue: undefined,
  disabled: false,
  showNameOnView: true,
  error: false,
  textSize: "normal",
  bold: false,
  symbol: undefined,
  readonly: false,
  decimals: 2,
  labelPosition: "top",
  directusContext: undefined,
})

// Emits
const emit = defineEmits(["update:modelValue", "keyup.enter"])
const value = useVModel(props, "modelValue", emit)
const textValue = ref<string>(`${value.value}`)

// Editing State
const editing = inject<Ref<boolean>>("editing", ref(true))
const input = ref<HTMLInputElement>()

// Update textValue
const { ignoreUpdates } = watchIgnorable(value, v => (textValue.value = `${value.value}`))

// Update value
watch(textValue, (val) => {
  ignoreUpdates(() => {
    const num = Number.parseFloat(
      val.replace("--", "-").replace("..", ".").replace(",,", ",").replace(",", "."),
    )
    if (isNaN(num)) { return }
    value.value = num
  })
})

// Update textValue when not editing
whenever(
  () => !editing.value,
  () => {
    textValue.value = `${value.value}`
  },
)

watch(
  () => props.modelValue,
  async (newValue, oldValue) => {
    if (newValue === oldValue) { return }
    if (!props.directusContext) { return }
    const { collection, property, id } = props.directusContext
    if (!collection || !property || !id) { return }
    await api.updateGeneric({ collection, id, data: { [property]: newValue } })
  },
)

// Events
function onEnterButton() {
  emit("keyup.enter")
}

// Expose Input
defineExpose({ input })

// Class Names
const inputClassNames = computed(() => ({
  "border-red-500 focus:ring-red-500 focus:border-red-500": props.error,
  "border-divider focus:ring-indigo-500 focus:border-indigo-500": !props.error,
  "text-lg leading-6": props.textSize === "lg",
  "text-xl leading-6": props.textSize === "xl",
  "text-sm": props.textSize === "normal",
  "font-extrabold": props.bold,
}))

// Symbol Styling
const symbolText = computed(() => `'${props.symbol}'`)
const fontWeight = computed(() => (props.bold ? 800 : "normal"))
const fontSize = computed(() => {
  switch (props.textSize) {
    case "lg":
      return "1.125rem"
    case "xl":
      return "1.25rem"
    default:
      return "0.85rem"
  }
})

// Text Width
const textWidth = ref<string>("0px")

watch(
  [textValue, input],
  () => {
    if (!input.value) { return }
    const canvas = document.createElement("canvas")
    const context = canvas.getContext("2d")
    if (!context) { return }
    context.font = window
      .getComputedStyle(input.value as Element, null)
      .getPropertyValue("font")
    textWidth.value = `${context.measureText(`${textValue.value}`).width + 6}px`
  },
  { immediate: true },
)
</script>

<style lang="postcss" scoped>
.has-symbol::before {
  content: v-bind(symbolText);
  @apply absolute top-1/2 -translate-y-1/2 transform pl-3;
  @apply pointer-events-none z-10 text-[#bfc6d2];
  left: v-bind(textWidth);
  font-weight: v-bind(fontWeight);
  font-size: v-bind(fontSize);
}
</style>
