<template>
  <div v-if="formData && tenant">
    <div class="p-4">
      <p class="pb-3 text-label-2 text-color-label-4">
        {{ $t("pdf_settings") }}
      </p>
      <div class="py mb-4 rounded-xl bg-1-static">
        <div class="flex justify-between border-b py-2">
          <p>{{ $t("show_price") }}</p>
          <CheckBox
            v-model="formData.visible_post_price"
            @update:model-value="handlePartialUpdate(['visible_post_price'])"
          />
        </div>
        <div class="flex justify-between border-b py-2">
          <p>{{ $t("show_post_details") }}</p>
          <CheckBox
            v-model="formData.visible_post_description"
            @update:model-value="handlePartialUpdate(['visible_post_description'])"
          />
        </div>
        <div class="flex justify-between border-b py-2">
          <p>{{ $t("show_quantities") }}</p>
          <CheckBox
            v-model="formData.visible_post_qty"
            @update:model-value="handlePartialUpdate(['visible_post_qty'])"
          />
        </div>
        <div class="flex justify-between border-b py-2">
          <p>{{ $t("show_photos") }}</p>
          <CheckBox
            v-model="formData.visible_post_photos"
            @update:model-value="handlePartialUpdate(['visible_post_photos'])"
          />
        </div>
        <div class="py-2">
          <SelectBox
            translation-path="post_image_sizes"
            :selected-value="formData.post_image_size"
            :label="$t('post_image_size')"
            @select="
              value => {
                if (!formData) return
                formData.post_image_size = value
                handlePartialUpdate(['post_image_size'])
              }
            "
          />
        </div>
        <div class="py-2">
          <TextFieldVal
            :model-value="formData.invoice_due_in_days"
            name="invoice_due_in_days"
            :label="$t('payment_terms')"
            type="number"
            text-size="normal"
            @update:model-value="formData.invoice_due_in_days = Number($event)"
            @blur="handlePartialUpdate(['invoice_due_in_days'])"
          />
        </div>

        <div class="flex py-2">
          <TextFieldVal
            model-value="formData.date_issued"
            name="date_issued"
            :label="$t('project.date_issued')"
            type="date"
            class="mr-1 w-1/2"
            @update:model-value="formData.date_issued = new Date($event) "
            @blur="handlePartialUpdate(['date_issued'])"
          />
          <TextFieldVal
            model-value="formData.date_expire"
            name="date_expire"
            :label="$t('project.date_expire')"
            type="date"
            class="ml-1 w-1/2 overflow-hidden"
            @update:model-value="formData.date_expire = new Date($event) "
            @blur="handlePartialUpdate(['date_expire'])"
          />
        </div>
      </div>
      <div class="space-y-2">
        <Button class="w-full" type="secondary" @click="router.push({ name: 'logo' })">
          {{ $t("company-settings.logo_colors") }}
        </Button>
        <Button class="w-full" type="secondary" @click="router.push({ name: 'certificates' })">
          {{ $t("company-settings.certificates.title") }}
        </Button>
      </div>
    </div>
  </div>
  <AtomSpinner v-else />
</template>

<script setup lang="ts">
import Button from "@/components/ui/buttons/Button.vue"
import { useGlobalStore } from "@/stores/globalStore"
import { useProjectController } from "@/stores/projectsController"
import { storeToRefs } from "pinia"
import { useRouter } from "vue-router"

const globalStore = useGlobalStore()
const { handlePartialUpdate } = useProjectController()
const { formData } = storeToRefs(useProjectController())
const { tenant } = storeToRefs(globalStore)
const router = useRouter()
</script>
