import type { BaseEventPayload, StateHandler, StateMachine, StateTransitions } from "@/types/models/StateMachine"
import { auditFieldsSchema, customerSchema, idSchema, paymentPlanSchema, phaseFormSchema, phaseSchema } from "@/types"
import { z } from "zod"

export const quoteStatusSchema = z.enum(["draft", "sent", "pending", "accepted", "done", "rejected", "archived"])
export type QuoteStatuses = z.infer<typeof quoteStatusSchema>

export const projectFormSchema = z.object({
  customer: idSchema,
  tenant: idSchema,
  name: z.string().min(1, "Name is required"),
  quote_status: quoteStatusSchema,
  quote_number: z.number(),
  visible_vat: z.boolean().optional(),
  visible_post_price: z.boolean().optional(),
  visible_post_description: z.boolean().optional(),
  visible_post_photos: z.boolean().optional(),
  discount_amount: z.number(),
  note: z.string().nullable(),
  terms: z.string().nullable(),
  payment_plan: z.array(paymentPlanSchema).optional(),
  date_issued: z.date().nullable(),
  date_expire: z.date().nullable(),
  post_image_size: z.enum(["sm", "md", "lg"]),
  invoice_due_in_days: z.number().optional(),
  visible_post_qty: z.boolean().optional(),
  street_address: z.string().nullable().optional(),
  country_code: z.string().nullable().optional(),
  zip_code: z.string().nullable().optional(),
  province: z.string().nullable().optional(),
  municipality: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  quote_expire_in_days: z.number().nullable().optional(),
  scope: z.string().nullable().optional(),
  contact_person: z.string().nullable(),
  contact_person_email: z.string().nullable(),
  contact_person_phone: z.string().nullable(),
  contact_person_role: z.string().nullable(),
  phases: z.array(z.lazy(() => phaseFormSchema)).optional(),
  email_data: z.object({
    email: z.array(z.string()),
    cc: z.array(z.string()),
    body: z.object({
      p_1: z.string(),
      p_2: z.string(),
    }),
  }).nullable().optional(),
})
export type ProjectFormSchema = z.infer<typeof projectFormSchema>

export const projectSchema = projectFormSchema.extend({
  id: idSchema,
  status: z.string(),
  picture: idSchema.nullable(),
  subtotal_amount: z.number(),
  vat_amount: z.number(),
  total_amount: z.number(),
  invoiced_amount: z.number(),
  paid_amount: z.number(),
  ...auditFieldsSchema.shape,
  $customer: z.lazy(() => customerSchema).optional(),
  $phases: z.array(z.lazy(() => phaseSchema)).optional(),
})

export type Project = z.infer<typeof projectSchema>

export type ProjectManagementProcess =
  | "idle"
  | "customer"
  | "picking_from_template"
  | "edit"

export type ProjectEventPayload = BaseEventPayload & (
  | { type: "LOAD_QUOTE", quoteId: string }
  | { type: "NEW_QUOTE_STARTED" }
  | { type: "PICK_CUSTOMER" }
  | { type: "CUSTOMER_PICKED", customerId: string, street_address: string }
  | { type: "PICK_POST_TEMPLATE" }
  | { type: "POST_TEMPLATES_SELECTED", templateIds: string[] }
  | { type: "QUOTE_CLOSED" }
  | { type: "PICK_TEMPLATE_CANCELLED" }
)

interface EmptyContext {}
export type ProjectStateHandler = StateHandler<ProjectManagementProcess, ProjectEventPayload, EmptyContext>
export type ProjectStateMachine = StateMachine<ProjectManagementProcess, ProjectEventPayload, EmptyContext>
export type ProjectStateTransitions = StateTransitions<ProjectManagementProcess, ProjectEventPayload, EmptyContext>
