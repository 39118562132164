import { auditFieldsSchema, idSchema } from "@/types"
import { z } from "zod"

export const customerSchema = z.object({
  id: idSchema,
  status: z.enum(["published", "draft", "archived"]),
  name: z.string(),
  company_registration_number: z.string(),
  email: z.string(),
  phone_number: z.string(),
  street_address: z.string(),
  zip_code: z.string(),
  city: z.string(),
  municipality: z.string(),
  country_code: z.string(),
  province: z.string(),
  billing_street_address: z.string(),
  billing_country_code: z.string(),
  billing_zip_code: z.string(),
  billing_municipality: z.string(),
  billing_city: z.string(),
  billing_province: z.string(),
  type: z.enum(["company", "person"]),
  currency: z.string(),
  tenant: idSchema,
  ...auditFieldsSchema.shape,
})

export type Customer = z.infer<typeof customerSchema>

export type CustomerStatus = "published" | "draft" | "archived"

export interface BrregCompany {
  organisasjonsnummer: number
  navn: string
  forretningsadresse: {
    postnummer: string
    adresse: string[]
    kommune: string
    land: string
    landkode: string
    poststed: string
  }
}
