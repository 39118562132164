import type { RouteRecordRaw } from "vue-router"
import ChangeCompanyPage from "@/components/onboarding/ChangeCompany.vue"
import CompanyAccountSettings from "@/components/onboarding/CompanyAccountSettings.vue"
import CustomerMobile from "@/pages/customers/Customer.vue"
import CustomersMobile from "@/pages/customers/Customers.vue"
import DrawOnImage from "@/pages/images/DrawOnImage.vue"
import NotFound from "@/pages/NotFound.vue"
import Onboarding from "@/pages/onboarding/Onboarding.vue"
import PostPage from "@/pages/posts/PostPage.vue"
import QuotePage from "@/pages/quotes/QuotePage.vue"
import QuotePreview from "@/pages/quotes/QuotePreview.vue"
import Quotes from "@/pages/quotes/Quotes.vue"
import QuoteSend from "@/pages/quotes/QuoteSend.vue"
import QuoteSettingsPage from "@/pages/quotes/QuoteSettingsPage.vue"
import ReleaseNotes from "@/pages/ReleaseNotes.vue"
import Certificates from "@/pages/settings/Certificates.vue"
import CompanyInvoiceSettings from "@/pages/settings/CompanyInvoiceSettings.vue"
import CompanyLogoAndColors from "@/pages/settings/CompanyLogoAndColors.vue"
import GeneralCompanySettings from "@/pages/settings/GeneralCompanySettings.vue"
import Profile from "@/pages/settings/Profile.vue"
import Settings from "@/pages/settings/Settings.vue"
import SettingsEmployees from "@/pages/settings/SettingsEmployees.vue"
import SettingsPaymentPlanPage from "@/pages/settings/SettingsPaymentPlanPage.vue"
import SettingsQuoteNote from "@/pages/settings/SettingsQuoteNote.vue"
import SettingsQuoteTerms from "@/pages/settings/SettingsQuoteTerms.vue"
import UpdatePassword from "@/pages/settings/UpdatePassword.vue"
import BasicWrapper from "@/pages/shared/BasicWrapper.vue"
import AuthLoginEmail from "@/pages/shared/login/AuthLoginEmail.vue"
import AuthLoginMobile from "@/pages/shared/login/AuthLoginMobile.vue"
import AuthLoginOptions from "@/pages/shared/login/AuthLoginOptions.vue"
import AuthResetPassword from "@/pages/shared/login/AuthResetPassword.vue"
import AuthWrapper from "@/pages/shared/login/AuthWrapper.vue"
import MobileWrapper from "@/pages/shared/MobileWrapper.vue"
import Stats from "@/pages/stats/Stats.vue"
import Templates from "@/pages/templates/Templates.vue"

export const routes: RouteRecordRaw[] = [
  {
    path: "/release-notes",
    name: "release-notes",
    component: ReleaseNotes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mobile/onboarding",
    redirect: "/onboarding",
  },
  {
    path: "/",
    component: BasicWrapper,
    children: [
      {
        path: "/auth",
        name: "auth",
        component: AuthWrapper,
        children: [
          {
            path: "login-options",
            name: "auth.loginOptions",
            component: AuthLoginOptions,
            meta: {
              unprotected: true,
            },
          },
          {
            path: "login-mobile",
            name: "auth.loginMobile",
            component: AuthLoginMobile,
            meta: {
              unprotected: true,
            },
          },
          {
            path: "login-email",
            name: "auth.loginEmail",
            component: AuthLoginEmail,
            meta: {
              unprotected: true,
            },
          },
          {
            path: "reset-password",
            name: "auth.resetPassword",
            component: AuthResetPassword,
            meta: {
              unprotected: true,
            },
          },
        ],
      },
      {
        path: "/",
        name: "MobileWrapper",
        component: MobileWrapper,
        children: [
          {
            path: "quotes",
            name: "quotes",
            component: Quotes,
          },
          {
            path: "stats",
            name: "stats",
            component: Stats,
          },
          {
            path: "reports",
            name: "reports",
            component: Quotes,
          },
          {
            path: "quote/:quoteId?",
            name: "quote",
            component: QuotePage,
          },
          {
            path: "report/:quoteId?",
            name: "report",
            component: QuotePage,
          },
          {
            path: "quote/:quoteId/send",
            name: "quote.send",
            component: QuoteSend,
          },
          {
            path: "quote/:quoteId/settings",
            name: "quote.settings",
            component: QuoteSettingsPage,
          },
          {
            path: "quote/:quoteId/preview",
            name: "quote.preview",
            component: QuotePreview,
          },
          {
            path: "post/:quoteId?/:postId",
            name: "post",
            component: PostPage,
          },
          {
            path: "quotes/image",
            name: "image",
            component: DrawOnImage,
          },
          {
            path: "templates",
            name: "templates",
            component: Templates,
          },
          {
            path: "customers",
            name: "customers",
            component: CustomersMobile,
          },
          {
            path: "customer/:id?",
            name: "customer",
            component: CustomerMobile,
          },
          {
            path: "settings",
            name: "settings",
            component: Settings,
          },
          {
            path: "password-update",
            name: "passwordUpdate",
            component: UpdatePassword,
          },
          {
            path: "settings/logo",
            name: "logo",
            component: CompanyLogoAndColors,
          },
          {
            path: "settings/certificates",
            name: "certificates",
            component: Certificates,
          },
          {
            path: "employees",
            name: "employees",
            component: SettingsEmployees,
          },
          {
            path: "company-invoice",
            name: "invoice",
            component: CompanyInvoiceSettings,
          },
          {
            path: "company-account-settings",
            name: "companyAccountSettings",
            component: CompanyAccountSettings,
          },
          {
            path: "general-company-settings",
            name: "general",
            component: GeneralCompanySettings,
          },
          {
            path: "settings/profile",
            name: "profile",
            component: Profile,
          },
          {
            path: "settings/quote-terms",
            name: "settingsTerms",
            component: SettingsQuoteTerms,
          },
          {
            path: "settings/quote-note",
            name: "settingsNote",
            component: SettingsQuoteNote,
          },
          {
            path: "change-company",
            name: "changeCompany",
            component: ChangeCompanyPage,
          },
          {
            path: "settings/payment-plan",
            name: "paymentPlan",
            component: SettingsPaymentPlanPage,
          },
        ],
      },
      {
        path: "onboarding",
        name: "onboarding",
        component: Onboarding,
        meta: {
          unprotected: true,
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: NotFound,
    meta: {
      unprotected: true,
    },
  },
]
