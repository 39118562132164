import type { Customer, Tenant } from "@/types"

const defaultCustomerValue: Customer = {
  id: "loading12345",
  status: "published",
  user_created: "loading12345",
  date_created: new Date(),
  user_updated: "loading12345",
  date_updated: new Date(),
  name: "Loading...",
  email: "loading@example.com",
  street_address: "Loading...",
  zip_code: "00000",
  city: "Loading...",
  municipality: "Loading...",
  province: "Loading...",
  phone_number: "000-000-0000",
  country_code: "XX",
  company_registration_number: "0000000",
  billing_country_code: "XX",
  billing_zip_code: "00000",
  billing_municipality: "Loading...",
  billing_street_address: "Loading...",
  billing_city: "Loading...",
  billing_province: "Loading...",
  type: "company",
  currency: "NOK",
  tenant: "0",
}

const emptyCustomerValue: Customer = {
  id: "",
  status: "draft",
  company_registration_number: "",
  name: "",
  email: "",
  phone_number: "",
  street_address: "",
  zip_code: "",
  city: "",
  municipality: "",
  province: "",
  country_code: "",
  billing_street_address: "",
  billing_country_code: "",
  billing_zip_code: "",
  billing_city: "",
  billing_municipality: "",
  billing_province: "",
  type: "company",
  currency: "NOK",
  tenant: "",
  user_created: "",
  date_created: new Date(),
  user_updated: "",
  date_updated: new Date(),
}

const defaultTenantValue: Tenant = {
  id: "loading12345",
  name: "Loading...",
  email: "loading@example.com",
  street_address: "Loading...",
  zip_code: "00000",
  city: "Loading...",
  country_code: "XX",
  company_registration_number: "0000000",
  invoice_due_in_days: 0,
  quote_expire_in_days: 0,
  visible_vat: false,
  visible_post_description: false,
  visible_post_price: false,
  visible_post_qty: false,
  logo: "default_logo_path",
  invoice_color: "#edf0f3ff",
  is_production: true,
  subscription_status: "null",
  subscription_id: "loadingSubscription12345",
  subscription_users: 0,
  subscription_email: "loading-subscription@example.com",
  subscription_trial_expire_at: new Date().toISOString(),
  subscription_limit_access: false,
  terms: "Loading...",
  phone_number: "",
  type: "prod",
  currency: "NOK",
  note: null,
  payment_plan: [],
  vat_registered: false,
  certifications: [],
  metrics: {
    projects: {
      total: {
        count: 0,
        subtotal_amount: 0,
        invoiced_amount: 0,
      },
      by_status: {
        draft: {
          count: 0,
          subtotal_amount: 0,
        },
        pending: {
          count: 0,
          subtotal_amount: 0,
        },
        accepted: {
          count: 0,
          subtotal_amount: 0,
        },
        done: {
          count: 0,
          subtotal_amount: 0,
        },
        rejected: {
          count: 0,
          subtotal_amount: 0,
        },
        expired: {
          count: 0,
          subtotal_amount: 0,
        },
      },
    },
  },
  user_created: "",
  user_updated: "",
  date_created: new Date(),
  date_updated: new Date(),
}

export default {
  defaultCustomerValue,
  emptyCustomerValue,
  defaultTenantValue,
}
