<template>
  <LoadableButton
    variant="solid-black"
    class="rounded-3xl"
    :loading="isLoading"
    @click="saveOrUpdate"
  >
    <span class="text-white p-1">{{ t("shared.actions.save") }}</span>
  </LoadableButton>
</template>

<script setup lang="ts">
import { useDeleteStore } from "@/stores/useDeleteStore"
import { ref, watch } from "vue"

const imageStore = useImageStore()
const { activePost } = storeToRefs(usePostModel())
const { handleEvent } = usePostController()
const deleteStore = useDeleteStore()
const isLoading = ref(false)
// Tracks the current image ID being processed for replacement
const currentImageIdToDelete = ref<string>("")

async function saveOrUpdate() {
  if (isLoading.value) { return }

  isLoading.value = true

  try {
    if (imageStore.imageId === "") {
      await moveBackToQuote()
    }
    else {
      // Find the image ID to delete
      currentImageIdToDelete.value = activePost.value?.pictures?.find(
        p => p.directus_files_id.id === imageStore.imageId,
      )?.id ?? ""

      // Set up the delete operation - the watcher will handle the rest
      deleteStore.setItemToDelete(
        currentImageIdToDelete.value,
        "posts_files",
        true,
        t("quote.photos.confirm_replace"),
      )
    }
  }
  catch (error) {
    console.error("Error in saveOrUpdate:", error)
    isLoading.value = false
  }
}

watch(
  () => deleteStore.itemDeleted,
  async (newValue, oldValue) => {
    // Only proceed if this is a new "true" value (not a reset to false)
    if (newValue && !oldValue && deleteStore.itemToDelete?.id === currentImageIdToDelete.value) {
      // Store the ID locally since it will be reset
      const imageId = currentImageIdToDelete.value
      currentImageIdToDelete.value = ""
      await moveBackToQuote()
    }
  },
)

watch(
  () => deleteStore.itemCancelled,
  (newValue, oldValue) => {
    // Only proceed if this is a new "true" value (not a reset to false)
    if (newValue && !oldValue && currentImageIdToDelete.value !== "") {
      // Reset loading state when user cancels the confirmation
      isLoading.value = false
      currentImageIdToDelete.value = ""
    }
  },
)

async function moveBackToQuote() {
  await imageStore.save()
  imageStore.$reset()
  handleEvent({ type: "PHOTOS_UPLOADED" })
  isLoading.value = false
}
</script>
