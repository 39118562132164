import type {
  BaseEventPayload,
  StateHandler,
  StateMachine,
  StateTransitions,
} from "@/types/models/StateMachine"
import type { Ref } from "vue"
import { nextTick } from "vue"

/**
 * Generic function to create a state machine instance that processes events sequentially.
 *
 * @param stateTransitions The state transitions map (first state is used as initial state)
 * @param stateHandlers The handlers for each state
 * @param stateRef The reactive ref that will be kept in sync with the state
 * @param contextRef The reactive ref that will be kept in sync with the context
 * @param name Optional name for the state machine instance (for logging purposes)
 * @returns A state machine instance
 */
export function createStateMachine<
  TState extends string,
  TEvent extends BaseEventPayload,
  TContext,
>(
  stateTransitions: StateTransitions<TState, TEvent, TContext>,
  stateHandlers: StateHandler<TState, TEvent, TContext>,
  stateRef: Ref<TState>,
  contextRef: Ref<TContext>,
  name: string = 'unnamed',
): StateMachine<TState, TEvent, TContext> {
  const initialState = stateRef.value
  let currentState = initialState
  const eventQueue: TEvent[] = []
  let isProcessing = false

  async function processEvent(payload: TEvent): Promise<{ success: boolean, error?: Error }> {
    const eventType = payload.type as TEvent["type"]
    const possibleTransitions = stateTransitions[currentState]?.on[eventType]

    if (!possibleTransitions) {
      const errorMsg = `Event ${payload.type} not allowed in ${currentState}`
      console.warn(`❌ [${name}] ${errorMsg}`)
      return { success: false, error: new Error(errorMsg) }
    }

    const transitions = possibleTransitions

    await stateHandlers[currentState](payload, contextRef.value)

    for (const transition of transitions) {
      const conditionMet = transition.cond
        ? await transition.cond(contextRef.value, payload)
        : true
      if (conditionMet) {
        const nextState = transition.target
        console.info(`✨ [${name}] ${currentState} -> ${nextState} via ${payload.type}`)
        currentState = nextState
        stateRef.value = nextState
        return { success: true }
      }
    }

    const errorMsg = `No matching transition condition for ${payload.type} in ${currentState}`
    console.warn(`❌ [${name}] ${errorMsg}`)
    return { success: false, error: new Error(errorMsg) }
  }

  async function processQueue() {
    if (isProcessing) {
      return
    }
    isProcessing = true

    while (eventQueue.length > 0) {
      const eventToProcess = eventQueue.shift()
      if (eventToProcess) {
        try {
          await nextTick()
          await processEvent(eventToProcess)
        } catch (error) {
          console.error(`[${name}] Error processing event ${eventToProcess.type}:`, error)
        }
      }
    }
    isProcessing = false
  }

  const stateMachine: StateMachine<TState, TEvent, TContext> = {
    name,
    get currentState() {
      return currentState
    },
    set currentState(state: TState) {
      if (state !== currentState) {
        console.warn(`[${name}] State changed directly to ${state}. Consider using events.`)
        currentState = state
        stateRef.value = state
      }
    },
    get context() {
      return contextRef.value
    },

    handleEvent(payload: TEvent): void {
      eventQueue.push(payload)
      void nextTick(processQueue)
    },
  }

  return stateMachine
}
