import type { App } from "vue"
import { createToaster } from "@meforma/vue-toaster"

// Define a more specific interface for the toaster
interface CustomToaster {
  success: (message: string) => void
  error: (message: string) => void
  info: (message: string) => void
  // Add other methods as needed
}

// Extend Vue's ComponentCustomProperties to include our toast property
declare module "vue" {
  interface ComponentCustomProperties {
    $toast: CustomToaster
  }
}

// Use type assertion to specify the exact shape we expect
export const toast = createToaster({
  position: "bottom",
  useDefaultCss: false, // assets/components/toaster.css
}) as CustomToaster

export default {
  install: (app: App) => {
    app.config.globalProperties.$toast = toast
    app.provide("toast", toast)
  },
}
