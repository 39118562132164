import type {
  VersionBundle,
} from "@/utils/versionUtils"
import { config } from "@/config"
import { toast } from "@/plugins/toasterPlugin"
import router from "@/router"
import { useGlobalStore } from "@/stores/globalStore"
import {
  applyUpdate,
  checkForUpdates,
  getCurrentCapacitorVersion,
} from "@/utils/versionUtils"
import { defineStore } from "pinia"
import { ref } from "vue"

export const useVersionStore = defineStore("version", () => {
  const globalStore = useGlobalStore()

  // State - initialize with empty string, will be populated from Capacitor
  const currentVersion = ref("")
  const newVersion = ref("")
  const loading = ref(0)
  const errorStatus = ref<string | null>(null)
  const updateAvailable = ref(false)
  const promptToUpdate = ref(false)
  const downloadedBundle = ref<VersionBundle | null>(null)
  const initialized = ref(false)

  /**
   * Initialize the version from Capacitor
   */
  async function initializeVersion() {
    if (initialized.value) {
      return
    }

    loading.value++

    currentVersion.value = await getCurrentCapacitorVersion()
    initialized.value = true

    loading.value--
  }

  /**
   * Check for version updates using Capacitor
   */
  async function checkVersion() {
    loading.value++
    errorStatus.value = null

    try {
      // First, make sure we have the current version from Capacitor
      if (!initialized.value) {
        await initializeVersion()
      }

      const result = await checkForUpdates(
        currentVersion.value,
        !!globalStore.user?.developer,
      )

      // Update state with version information
      newVersion.value = result.newVersion
      updateAvailable.value = result.updateAvailable

      // Update current version if provided (especially for mobile)
      if (result.currentVersion) {
        currentVersion.value = result.currentVersion
      }

      // Store downloaded bundle for mobile updates
      if (result.downloadedBundle) {
        downloadedBundle.value = result.downloadedBundle
      }

      // Handle errors
      if (result.error) {
        errorStatus.value = result.error
        console.error(`Version check error: ${result.error}`)
        if (globalStore.user?.developer) {
          toast.error(`Version check error: ${result.error}`)
        }
      }
    }
    catch (error) {
      errorStatus.value = error instanceof Error ? error.message : "Failed to check version"
      if (globalStore.user?.developer) {
        toast.error(`Version check error: ${errorStatus.value}`)
        console.error("Version check error:", error)
      }
    }
    finally {
      loading.value--
    }
  }

  /**
   * Initialize periodic version checking
   * Skips version checking in local development mode
   */
  async function initVersionCheck(frequency: number = 1000 * 60 * 2) {
    // Skip version checking in local development mode
    if (!config.isProduction) {
      console.info("Version checking disabled in local development mode")
      return
    }

    await checkVersion()
    setInterval(() => {
      void checkVersion()
    }, frequency)
  }

  /**
   * Handle update process with option to check for new updates if none available
   * @param checkForNewUpdates Whether to check for new updates if none are available
   */
  async function handleUpdate(checkForNewUpdates = true) {
    loading.value++
    if (newVersion.value) {
      await applyUpdate(
        router,
        downloadedBundle.value,
        !!globalStore.user?.developer,
        message => toast.info(message),
        message => toast.error(message),
        message => toast.success(message),
      )
      currentVersion.value = newVersion.value
      updateAvailable.value = false
    }
    else if (checkForNewUpdates) {
      await checkVersion()
    }

    loading.value--
  }

  return {
    // State
    currentVersion,
    newVersion,
    updateAvailable,
    promptToUpdate,
    loading,
    errorStatus,
    initialized,
    downloadedBundle,

    // Actions
    initVersionCheck,
    handleUpdate,
  }
})
