import type { SMSVerificationResponse, Tenant } from "@/types"
import type { TenantTypes } from "@/types/models/TenantTypes"
import type { User } from "@/types/models/UserTypes"
import { withRetry } from "@/api/directus"
import { tryCatch } from "@/utils/errorHandling"
import sanitizePhoneNumber from "@/utils/sanitizeMobileNumber"
import { readMe, updateItem, updateMe as updateMeDirectus } from "@directus/sdk"

async function updateActiveTenant(tenantId: string): Promise<User> {
  return withRetry(
    async () => {
      const response = await client.request(
        updateMeDirectus({
          active_tenant: tenantId,
        }),
      )
      const user = { ...response, tenants: [] } as unknown as User
      return user
    },
    3,
    true,
  )
}

async function updateUserPassword(newPassword: string): Promise<User> {
  const user = (await client.request(
    updateMeDirectus({
      password: newPassword,
    }),
  )) as unknown as User
  return user
}

async function getMe(): Promise<User> {
  return withRetry(
    async () => {
      const response = await client.request(
        readMe({
          fields: [
            "id",
          ] as const,
        }),
      )
      const user = { ...response } as unknown as User
      return user
    },
    3,
    false,
  )
}

async function updateMe(fields: {
  first_name?: string
  last_name?: string
  email?: string
  phone_number?: string
  allow_contact_and_marketing?: boolean
  avatar?: string
  release_notes_last_seen?: string
}): Promise<User> {
  return withRetry(
    async () => {
      const response = await client.request(updateMeDirectus(fields))
      const user = { ...response, tenants: [] } as unknown as User
      return user
    },
    3,
    true,
  )
}

async function updateMyTenant(id: string, fields: Partial<Tenant>): Promise<Tenant> {
  return withRetry(
    async () => {
      return (await client.request(updateItem("tenants", id, fields))) as Tenant
    },
    3,
    true,
  )
}

async function sendUserSMSCode(mobileNumber: string): Promise<boolean> {
  const sanitizedNumber = sanitizePhoneNumber(mobileNumber)
  if (!sanitizedNumber) {
    return false
  }
  try {
    const response = await fetch(
      "https://service.tredco.app/flows/trigger/5f6cfa8b-464a-4f46-a32a-548fea1c66a4",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ mobile: sanitizedNumber }),
      },
    )
    if (await response.json()) {
      console.info("SMS code has been sent", response.body)
      return true
    }
    else {
      console.info("Something went wrong with sending the sms code.")
      return false
    }
  }
  catch (error) {
    return false
  }
}

async function verifyUserSMSCode(mobileObject: {
  mobile: string
  code: string
  createUser: boolean
  tenantType?: TenantTypes
}): Promise<{ status: boolean, user: Pick<User, "email"> | undefined }> {
  mobileObject.mobile = sanitizePhoneNumber(mobileObject.mobile)

  // Fetch the verification response
  const { data: response, error: fetchError } = await tryCatch(
    fetch("https://service.tredco.app/flows/trigger/15bd3480-0686-4675-bbc1-b069b0749dc5", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(mobileObject),
    })
  )
  
  if (fetchError || !response?.ok) {
    console.error("SMS verification failed:", fetchError || "Invalid response")
    return { status: false, user: undefined }
  }

  // Parse the JSON response
  const { data, error: jsonError } = await tryCatch(
    response.json().then(data => data as SMSVerificationResponse)
  )
  
  if (jsonError || data.status !== 200) {
    console.error("SMS verification failed:", jsonError || "Invalid status code")
    return { status: false, user: undefined }
  }

  return { status: true, user: data.data.data }
}

export const users = {
  getMe,
  updateMe,
  updateMyTenant,
  updateUserPassword,
  updateActiveTenant,
  sendUserSMSCode,
  verifyUserSMSCode,
}
