import type { Post, PostFormSchema } from "@/types"

export function sortPostsByOrder(posts: Post[]): Post[] {
  return posts.sort((a, b) => {
    // First sort by sort field (priority)
    const aSort = a.sort ?? 0 // Use 0 as default if sort is null
    const bSort = b.sort ?? 0
    const sortDiff = aSort - bSort
    if (sortDiff !== 0) {
      return sortDiff
    }

    // If sort is equal, sort by creation date
    const aDate = new Date(a.date_created).getTime()
    const bDate = new Date(b.date_created).getTime()
    return aDate - bDate
  })
}

export function calculateNewPostSort(posts: Post[]): number {
  return Math.max(...posts.map(p => p.sort ?? 0), 0) + 1
}

export function getPostById(posts: Post[], id: string): Post | undefined {
  return posts.find(post => post.id === id)
}

export function preparePostTemplate(
  post: Post,
  sort: number,
): Omit<Post, "id"> {
  const { id, phase, ...templateData } = post
  return {
    ...templateData,
    is_template: true,
    phase: null,
    template_reference: id,
    sort,
  }
}

export function preparePostFromTemplate(template: Post): Omit<Post, "id"> {
  const { id, ...postData } = template
  return {
    ...postData,
    is_template: false,
    template_reference: id,
  }
}

export async function sortAndUpdatePosts(postIds: string[]): Promise<void> {
  // lets assum the posts are already sorted just give them sort field a value based on their index
  const postWithNewSort = postIds.map((id, index) => {
    return {
      id,
      sort: index + 1,
    }
  })
  // Update all posts with their new sort values in batches of 10
  await Promise.all(
    postWithNewSort.map(async post =>
      directus.updateGeneric({
        collection: "posts",
        id: post.id,
        data: post,
      }),
    ),
  )
}

/**
 * Creates a new post from a video file, uploads the video, and analyzes it using AI
 *
 * @param params - Object containing all parameters for post creation
 * @param params.videoFile - The video file to process
 * @param params.accessToken - Access token for authentication
 * @param params.confidence - Confidence level of the text extraction
 * @param params.phaseId - ID of the phase to associate with the post
 * @param params.tenantId - ID of the tenant who owns the post
 * @param params.nextSortNumber - Next sort number for the post
 * @returns Promise resolving to an object with the created post, success flag, and optional error message
 */
export async function createPostFromVideo({
  videoFile,
  accessToken,
  phaseId,
  tenantId,
  nextSortNumber,
}: {
  videoFile: File
  accessToken: string
  phaseId: string | null
  tenantId: string
  nextSortNumber: number
}): Promise<{ post: Post | null, success: boolean, error?: string }> {
  // Validate all required data
  const validationErrors: string[] = []

  if (!phaseId) {
    validationErrors.push("No active phase found in the project")
  }
  if (!tenantId) {
    validationErrors.push("No tenant ID found")
  }
  if (!videoFile.type.startsWith("video/")) {
    validationErrors.push("File is not a video")
  }

  if (validationErrors.length > 0) {
    console.error("Validation errors:", validationErrors)
    return { post: null, success: false, error: validationErrors[0] }
  }

  // Prepare the post data
  const postData: PostFormSchema = {
    name: "Processing...",
    phase: phaseId,
    sort: nextSortNumber,
    unit: "pcs",
    description: "",
    qty: "1",
    unit_price: "0",
    is_template: false,
    tenant: tenantId,
    template_reference: null,
  }

  try {
    // Create the post to get an ID
    const newPost = await api.createGeneric<Post>({
      collection: "posts",
      data: postData,
    })

    if (!newPost || typeof newPost !== "object" || !("id" in newPost)) {
      throw new Error("Failed to create post")
    }

    // Upload the video file and create the relation
    // This returns the file ID directly, so we can use it instead of setting up a watcher
    const fileId = await uploadFileWithRelation(
      videoFile,
      tenantId,
      "posts_files",
      "posts_id",
      newPost.id,
    )

    if (!fileId) {
      console.error("Failed to upload video file")
      return { post: newPost, success: true } // Still return success since the post was created
    }

    // Construct the video URL using the file ID
    const videoUrl = `${api.client.url}assets/${fileId}?access_token=${accessToken}`

    // Analyze the video with AI
    try {
      const analyzeResponse = await analyzeVideoWithAI(videoUrl)

      if (analyzeResponse && (analyzeResponse.title || analyzeResponse.description)) {
        newPost.name = analyzeResponse.title
        newPost.description = analyzeResponse.description

        // Update the post with the analysis results
        await api.updateGeneric({
          collection: "posts",
          id: newPost.id,
          data: {
            name: analyzeResponse.title, // Using 'name' instead of 'title' to match schema
            description: analyzeResponse.description,
          },
        })
      }
    }
    catch (analyzeError) {
      console.error("Error analyzing video:", analyzeError)
      // Don't show error to user, as this is an enhancement and not critical
    }

    return { post: newPost, success: true }
  }
  catch (error) {
    console.error("Error creating post from video:", error)
    return { post: null, success: false, error: "Error creating post from video" }
  }
}

// This function is now replaced by the watcher pattern in createPostFromVideo

/**
 * Analyzes a video using the AI API
 *
 * @param videoUrl - URL of the video to analyze
 * @returns Promise resolving to the analysis results
 */
async function analyzeVideoWithAI(videoUrl: string): Promise<{ title: string, description: string }> {
  return api.client.request<{ title: string, description: string }>(() => ({
    path: "/ai/analyze-video",
    method: "POST",
    body: JSON.stringify({
      videoUrl,
      language_code: "no", // Default to Norwegian
    }),
  }))
}
