import type { ProjectManagementProcess, ProjectStateTransitions, QuoteStatuses } from "@/types"

export const useProjectModel = defineStore("projectModel", () => {
  const dataStore = useDataStore()

  // State
  const projectProcess = ref<ProjectManagementProcess>("idle")
  const activeProjectId = ref<string | null>(null)
  const selectedTemplates = ref<string[]>([])
  const activeProject = computed(() => {
    if (!activeProjectId.value) {
      return null
    }
    return dataStore.projectsById[activeProjectId.value]
  })
  const searchTerm = ref("")
  const filterStatus = ref<QuoteStatuses[]>([])

  // Process Management
  const stateTransitions: ProjectStateTransitions = {
    idle: {
      on: {
        NEW_QUOTE_STARTED: [{ target: "customer" }],
        LOAD_QUOTE: [{ target: "edit" }],
      },
    },
    customer: {
      on: {
        CUSTOMER_PICKED: [{ target: "edit" }],
        QUOTE_CLOSED: [{ target: "idle" }],
      },
    },
    edit: {
      on: {
        PICK_CUSTOMER: [{ target: "customer" }],
        PICK_POST_TEMPLATE: [{ target: "picking_from_template" }],
        QUOTE_CLOSED: [{ target: "idle" }],
      },
    },
    picking_from_template: {
      on: {
        POST_TEMPLATES_SELECTED: [{ target: "edit" }],
        PICK_TEMPLATE_CANCELLED: [{ target: "edit" }],
      },
    },
  }

  // Getters for business rules and data access
  const filteredProjects = computed(() => {
    let filtered = dataStore.projects

    if (filterStatus.value.length > 0) {
      filtered = filtered.filter(project =>
        filterStatus.value.includes(project.quote_status),
      )
    }

    if (searchTerm.value) {
      filtered = filtered.filter(project =>
        project.name.toLowerCase().includes(searchTerm.value.toLowerCase())
        || (project.$customer)?.name?.toLowerCase().includes(searchTerm.value.toLowerCase())
        || (project.$customer)?.city?.toLowerCase().includes(searchTerm.value.toLowerCase()),
      )
    }

    return filtered
  })

  // Basic state mutations
  function setSearchTerm(term: string) {
    searchTerm.value = term
  }

  function toggleTemplateSelection(templateId: string) {
    const index = selectedTemplates.value.indexOf(templateId)
    if (index === -1) {
      selectedTemplates.value.push(templateId)
    }
    else {
      selectedTemplates.value.splice(index, 1)
    }
  }

  function clearSelectedTemplates() {
    selectedTemplates.value = []
  }

  return {
    // State
    activeProject,
    activeProjectId,
    searchTerm,
    filterStatus,
    projectProcess,
    selectedTemplates,

    // Process Management
    stateTransitions,
    // Getters
    filteredProjects,

    // Methods
    setSearchTerm,
    toggleTemplateSelection,
    clearSelectedTemplates,
  }
})
