<template>
  <div class="grid-cols-5 desktop:grid">
    <div class="col-span-3 desktop:border-r desktop:pr-4">
      <Quote v-if="!toggleQuotePreview" />
      <QuotePreview v-if="toggleQuotePreview" />
    </div>
    <div
      v-if="!mobile && projectModel.activeProject"
      class="col-span-2 sticky top-5 self-start  mx-4 mb-5 mt-5 rounded-2xl"
    >
      <div class="mb-4 flex items-center">
        <p class="mr-4 grow text-heading-3 text-color-label-1">
          {{ $t("quote.settings.configure_quote") }}
        </p>
        <QuoteStatus
          :current-status="projectModel.activeProject.quote_status"
          @change-status="updateQuoteStatus"
        />
      </div>
      <div class="my-4">
        <Toggle
          :value-left="$t('edit')"
          :value-right="$t('preview')"
          default="left"
          @left-click="toggleQuotePreview = false"
          @right-click="toggleQuotePreview = true"
        />
      </div>
      <QuoteSettings />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { QuoteStatuses } from "@/types/models/ProjectTypes"

const toggleQuotePreview = ref(false)

const projectController = useProjectController()
const projectModel = useProjectModel()
async function updateQuoteStatus(status: QuoteStatuses) {
  if (projectController.formData) {
    projectController.formData.quote_status = status
  }
  await projectController.includeStatusInFilter(status)
  await projectController.handlePartialUpdate(["quote_status"])
}

const mobile = inject("isPhone") as Ref<boolean>
window.scrollTo(0, 0)
</script>
