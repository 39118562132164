<template>
  <SettingsPaymentPlan v-model="paymentItems" mode="quote-settings" class="mt-3" />
</template>

<script setup lang="ts">
import type { PaymentPlan } from "@/types"
import { useProjectController } from "@/stores/projectsController"
import { cloneDeep, isEqual } from "lodash"
import { storeToRefs } from "pinia"

const projectController = useProjectController()
const { activeProject } = storeToRefs(useProjectModel())
const globalStore = useGlobalStore()
const { formData } = storeToRefs(projectController)

const paymentItems = ref<PaymentPlan[]>(
  cloneDeep(formData.value?.payment_plan) || [],
)

const totalAmount = computed(() => {
  if (!globalStore.tenant) {
    return 0
  }
  return calculateTotalIncVat(activeProject.value, globalStore.tenant)
})

provide<ComputedRef<number>>("amount", totalAmount)

watch(
  paymentItems,
  async (payment_plan) => {
    if (
      !formData.value
      || isEqual(payment_plan, formData.value.payment_plan)
    ) {
      return
    }
    formData.value.payment_plan = payment_plan
    await projectController.handlePartialUpdate(["payment_plan"])
  },
  { deep: true },
)
</script>
