<template>
  <div class="rounded-2xl pt-4">
    <span
      class="flex flex-row-reverse text-label-3 text-link-static hover:text-link-hover"
      @click="showConfirmDialog = true"
    >{{ $t("quote.settings.reset_changes") }}</span>

    <div v-if="formData" class="py-2">
      <TextArea
        v-model="formData.terms"
        :rows="20"
        @blur="projectController.handlePartialUpdate(['terms'])"
      />
    </div>
    <MobileDialog
      type="confirm"
      :show="showConfirmDialog"
      :sub-title="$t('quote.settings.reset_changes_confirm')"
      @y="resetChanges"
      @n="showConfirmDialog = false"
    />
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from "@/stores/globalStore"
import { useProjectController } from "@/stores/projectsController"
import { storeToRefs } from "pinia"

const projectController = useProjectController()
const { formData } = storeToRefs(projectController)
const globalStore = useGlobalStore()

const showConfirmDialog = ref(false)

async function resetChanges() {
  if (formData.value) {
    formData.value.terms = globalStore.tenant.terms
    await projectController.handlePartialUpdate(["terms"])
    showConfirmDialog.value = false
  }
}
</script>
