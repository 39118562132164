import type { Employee, Phase, Project, ProjectFormSchema, Tenant } from "@/types"
import type { User } from "@/types/models/UserTypes"
import api from "@/api"

import { t } from "@/localization/i18n"

interface NewProjectContext {
  user: User | undefined
  tenant: Tenant | null
  employee: Employee | null
}

export async function scaffoldNewProject(
  context: NewProjectContext,
): Promise<ProjectFormSchema> {
  const { user, tenant, employee } = context
  if (!user || !tenant || !tenant.id || !employee) {
    console.error("Missing context")
    throw new Error("Missing either user, tenant or employee")
  }

  return {
    name: "",
    customer: "", // Add empty customer field
    contact_person: `${user?.first_name} ${user?.last_name}`,
    quote_status: "draft",
    contact_person_email: user?.email,
    contact_person_phone: user?.phone_number ?? "",
    contact_person_role: t(
      `company-settings.your-account-settings.jobTitleOptions.${employee?.job_title}`,
    ),
    terms: tenant?.terms,
    note: tenant?.note,
    payment_plan: tenant?.payment_plan,
    post_image_size: "sm",
    quote_number: (await api.quotes.getHighestQuoteNumber()) + 1,
    phases: [
      {
        name: "Fase 1",
        tenant: tenant.id,
      },
    ],
    scope: "",
    tenant: tenant.id,
    invoice_due_in_days: tenant.invoice_due_in_days,
    visible_vat: tenant.visible_vat,
    visible_post_description: tenant.visible_post_description,
    visible_post_price: tenant.visible_post_price,
    visible_post_qty: tenant.visible_post_qty,
    discount_amount: 0,
    date_issued: new Date(),
    date_expire: new Date(
      new Date().setDate(new Date().getDate() + (tenant?.quote_expire_in_days || 0)),
    ),
  }
}

interface DuplicateProjectResult {
  project: Partial<Project>
  phases: Array<{
    tenant: string
    name: string
    project?: string // Will be set after project creation
    $posts: Array<{
      tenant: string
      name: string
      description?: string
      unit_price?: string
      qty?: string
      phase?: string // Will be set after phase creation
    }>
  }>
}

export async function duplicateProject(
  originalProject: Project,
  tenantId: string,
): Promise<DuplicateProjectResult> {
  const project: Partial<Project> = {
    tenant: tenantId,
    invoice_due_in_days: originalProject.invoice_due_in_days,
    visible_vat: originalProject.visible_vat,
    visible_post_description: originalProject.visible_post_description,
    visible_post_price: originalProject.visible_post_price,
    visible_post_qty: originalProject.visible_post_qty,
    payment_plan: originalProject.payment_plan,
    contact_person: originalProject.contact_person,
    contact_person_email: originalProject.contact_person_email,
    contact_person_phone: originalProject.contact_person_phone,
    contact_person_role: originalProject.contact_person_role,
    terms: originalProject.terms,
    note: originalProject.note,
    quote_number: (await api.quotes.getHighestQuoteNumber()) + 1,
    customer: originalProject.customer,
    name: `** ${t("copy")} ** ${originalProject.name}`,
    quote_status: "draft",
    picture: null,
    post_image_size: originalProject.post_image_size || "md",
    street_address: originalProject.street_address,
    country_code: originalProject.country_code,
    zip_code: originalProject.zip_code,
    province: originalProject.province,
    municipality: originalProject.municipality,
    city: originalProject.city,
    quote_expire_in_days: originalProject.quote_expire_in_days,
    scope: originalProject.scope,
  }

  if (!originalProject.$phases || originalProject.$phases.length === 0) {
    throw new Error("No phases found in original project")
  }

  const phases = originalProject.$phases.map(phase => ({
    tenant: tenantId,
    name: phase.name,
    $posts: phase.$posts?.map(post => ({
      tenant: tenantId,
      name: post.name,
      description: post.description,
      unit_price: post.unit_price,
      qty: post.qty,
    })) || [],
  })) || []

  return {
    project,
    phases,
  }
}

export async function createProjectWithRelations(
  projectData: DuplicateProjectResult,
): Promise<string> {
  const createdProject = await api.createGeneric<Project>({
    collection: "projects",
    data: projectData.project,
  })

  for (const phase of projectData.phases) {
    phase.project = createdProject.id
    const createdPhase = await api.createGeneric<Phase>({
      collection: "phases",
      data: phase,
    })

    for (const post of phase.$posts) {
      post.phase = createdPhase.id
      await api.createGeneric({
        collection: "posts",
        data: post,
      })
    }
  }

  return createdProject.id
}
