<template>
  <HeaderOnboarding>
    <div class="mb-6 flex flex-col">
      <h2 class="text-heading-3">
        {{ $t("country.country") }}
      </h2>
      <p class="mt-2 text-label-2 text-color-label-1">
        {{ $t("country.promt") }}
      </p>
    </div>
    <LimitedOptions
      :options="options"
      @update:options="handleSelction"
      @selected-id="validateAndSetCurrency"
    />
    <Button
      id="submit-code"
      class="mt-4 w-full"
      size="large"
      submit
      @click="() => onboardingController.handleEvent({ type: 'SELECT_COUNTRY', currency })"
    >
      {{ $t("onboarding.continue_button") }}
    </Button>
  </HeaderOnboarding>
</template>

<script setup lang="ts">
import { useOnboardingController } from "@/stores/onboardingController"
import { currencySchema } from "@/types/models/SharedModelTypes"
import { getLanguageBasedOnIP } from "@/utils/getLanguageBasedOnIp"

const onboardingController = useOnboardingController()
const countryBasedOnIp = await getLanguageBasedOnIP()
const countriesSet = getCountriesConfig(countryBasedOnIp.countryCode).limitedOptionsSet

const currency = ref(countryBasedOnIp.currencyCode)
function validateAndSetCurrency(id: string) {
  const parsedCurrency = currencySchema.safeParse(id)
  currency.value = parsedCurrency.success ? parsedCurrency.data : "NOK"
}

const options = ref(countriesSet)

function handleSelction(updateOptions: typeof options.value) {
  options.value = updateOptions
}
</script>
