import type { CapacitorConfig } from "@capacitor/cli"

const config: CapacitorConfig = {
  appId: "app.tredco.ios",
  appName: "tredco",
  webDir: "dist",
  server: {
    androidScheme: "https",
    allowNavigation: [
      "*",
    ],
  },
  plugins: {
    extConfig: {},
    CapacitorUpdater: {
      autoUpdate: true,
      version: "3.0.74",
    },
  },
}

export default config
