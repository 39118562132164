<template>
  <div>
    <div
      v-if="versionStore.promptToUpdate"
      class="fixed bg-white p-6 shadow-lg" :class="[
        phone ? 'bottom-10 left-2 w-1/2' : 'bottom-2 right-3 w-1/6',
      ]"
    >
      <div class="notification-header" />
      <div class="notification-body">
        <div class="notification-button">
          <p class="pb-3 text-center text-sm">
            {{ $t("new_version_of_tredco", ["Tredco"]) }} <br>{{ $t("is_available")
            }}<br>
          </p>
          <p class="text-center text-xs">
            <span>{{ versionStore.currentVersion }} → {{ versionStore.newVersion }}</span>
          </p>
        </div>
        <div class="refresh-button text-center mt-3">
          <Button @click="versionStore.handleUpdate">
            {{ $t("load_again") }}
          </Button>
        </div>
      </div>
    </div>

    <div
      v-if="showVersionInfo"
      class="fixed top-0 right-0 z-40 pb-safe"
      @click="gotoLinkThatIsBeingWorkedOn"
    >
      <span
        v-if="$route.name && !$route.name.toString().includes('preview.raw')"
        class="mr-3 text-[7px]"
        :class="versionStore.errorStatus ? 'text-grey-300 opacity-70' : 'opacity-20'"
      >
        {{ versionStore.currentVersion || 'Version unknown' }}
        <span v-if="versionStore.errorStatus && isDeveloper" class="text-red-500">!</span>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVersionStore } from "@/stores/appVersionManagementStore"
import { useGlobalStore } from "@/stores/globalStore"
import { useRouter } from "vue-router"

const phone = inject("isPhone") as Ref<boolean>
const showVersionInfo = ref(false)
const globalStore = useGlobalStore()
const isDeveloper = computed(() => !!globalStore.user?.developer)
const versionStore = useVersionStore()

const router = useRouter()
async function gotoLinkThatIsBeingWorkedOn() {
  if (isDeveloper.value && versionStore.errorStatus) {
    console.error("Version check error:", versionStore.errorStatus)
  }

  await router.push({
    name: "offer.preview.raw",
    params: { id: "127a22a7-f3eb-4455-ac5d-5bdc89dfec4a" },
  })
}

// Initialize version checking on component mount
onMounted(async () => {
  try {
    await versionStore.initVersionCheck(1000 * 60 * 1)
  }
  catch (error) {
    console.error("Failed to initialize version checking:", error)
  }
})
</script>
