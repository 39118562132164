<template>
  <div v-if="activeProject" class="rounded-2xl bg-1-static p-4">
    <div class="text-color-label-bold-3 flex justify-between text-label-bold-3">
      <p class="">
        {{ $t("quote.total_excl_tax") }}
      </p>
      <p>{{ formatCurrency(grandTotal) }}</p>
    </div>
    <hr class="border-1 my-3 border-divider">
    <div class="text-color-label-bold-3 flex justify-between text-label-bold-3">
      <p>{{ $t("quote.settings.discount") }}</p>
      <p>
        -
        {{ formatCurrency(Number(activeProject.discount_amount ?? 0)) }}
      </p>
    </div>
    <hr class="border-1 my-3 border-divider">
    <div class="text-color-label-bold-3 flex justify-between text-label-bold-3">
      <p>{{ $t("vat") }}</p>
      <p>{{ formatCurrency(vat) }}</p>
    </div>
    <hr class="border-1 my-3 border-divider">
    <div class="flex items-center justify-between text-heading-3 text-color-label-1">
      <div>
        <p class="capitalize">
          {{ $t("total") }}
        </p>
        <div
          v-if="tenant.vat_registered"
          class="text-label-3 text-color-label-2"
        >
          {{ $t("incl_vat") }}
        </div>
        <div v-else class="text-label-3 text-color-label-2">
          {{ $t("quote.settings.not_vat_registered") }}
        </div>
      </div>
      <p>{{ formatCurrency(totalIncVat) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
const { activeProject } = storeToRefs(useProjectModel())
const globalStore = useGlobalStore()
const { tenant } = storeToRefs(globalStore)

const grandTotal = computed(() => calculateGrandTotal(activeProject.value))
const vat = computed(() => tenant.value ? calculateVat(activeProject.value, tenant.value) : 0)
const totalIncVat = computed(() => tenant.value ? calculateTotalIncVat(activeProject.value, tenant.value) : 0)
</script>
