<template>
  <div
    ref="contentRef"
    :class="class"
    v-html="html"
  />
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue"

const props = defineProps<{
  html: string
  class?: string
}>()

const emit = defineEmits<{
  (e: "video-click", src: string): void
}>()

const contentRef = ref<HTMLElement | null>(null)

function handleVideoInteraction(video: HTMLVideoElement, e: Event) {
  e.preventDefault()
  e.stopPropagation()

  // Try to get source from source tag first
  const sourceElement = video.querySelector("source")
  const videoSrc = sourceElement?.getAttribute("src") || video.getAttribute("src")

  if (videoSrc) {
    emit("video-click", videoSrc)
  }
}

function attachVideoListeners() {
  if (!contentRef.value) { return }

  const videos = contentRef.value.querySelectorAll("video")
  videos.forEach((video) => {
    // Handle both click and touch events
    video.addEventListener("click", e => handleVideoInteraction(video, e))
    video.addEventListener("touchend", e => handleVideoInteraction(video, e))

    // Add visual cues that this is clickable
    video.classList.add(
      "h-[200px]",
      "w-[350px]",
      "rounded-lg",
      "object-cover",
      "cursor-pointer",
      "shadow-sm",
      "transition-transform",
      "hover:scale-[1.02]",
    )
  })
}

onMounted(() => {
  attachVideoListeners()
})

watch(() => props.html, () => {
  setTimeout(attachVideoListeners, 0)
})
</script>
