<template>
  <PageLayout>
    <template #header>
      <TopNav
        :saving="isSubmitting"
        :title="$t('send_quote')"
        :show-additiol-options="false"
        type="edit"
        @back="router.back()"
      />
    </template>
    <template #default>
      <QuoteSettingSummary
        :title="$t('recipients')"
        :sub-title="emailData.email.join(', ')"
        :required="true"
        :expanded="recipientsExpanded"
        @expand="recipientsExpanded = $event"
      >
        <QuoteSettingsRecipient
          v-model="emailData"
          @update:model-value="handleEmailDataUpdate"
        />
      </QuoteSettingSummary>

      <div>
        <div class="px-3 py-2 mt-4 space-y-3 text-sm border rounded-2xl bg-gray-50">
          <p>{{ $t("hello") }},</p>

          <TextArea
            v-model="emailData.body.p_1"
            class="w-full"
            :without-min-height="true"
            :rows="5"
            @blur="handleEmailDataUpdate"
          />
          <TextArea
            v-model="emailData.body.p_2"
            class="w-full"
            :without-min-height="true"
            :rows="5"
            @blur="handleEmailDataUpdate"
          />
          <p class="pb-24">
            {{ $t("best_regards") }},
            <br>
            {{ globalStore.tenant?.name }}
            <br>
            {{ $t("email_note") }}
          </p>
        </div>
        <Button v-if="!mobile" type="primary" class="my-2" size="large" @click="sendEmail">
          {{ $t("send") }}
        </Button>
      </div>
    </template>
    <template #footer-mobile>
      <Button type="primary" class="w-full mr-2" size="large" @click="sendEmail">
        {{ $t("send") }}
      </Button>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import type { Toaster } from "@/types/UiTypes"
import { t } from "@/localization/i18n"
import router from "@/router"

const projectController = useProjectController()
const { formData, isSubmitting } = storeToRefs(projectController)
const { activeProject: project } = storeToRefs(useProjectModel())
const { tenant } = storeToRefs(useGlobalStore())
const toast = inject<Toaster>("toast")
const globalStore = useGlobalStore()

// Create reactive email data with just the user-editable fields
const emailData = ref({
  email: [] as string[],
  cc: [] as string[],
  body: {
    p_1: "",
    p_2: "",
  },
})

const recipientsExpanded = ref(false)

// Set initial values when component is created
onMounted(() => {
  // Initialize with existing project email data or default values
  if (project.value?.email_data) {
    emailData.value = {
      email: project.value.email_data.email || [],
      cc: project.value.email_data.cc || [],
      body: {
        p_1: project.value.email_data.body.p_1 || "",
        p_2: project.value.email_data.body.p_2 || "",
      },
    }
  }
  else {
    // Set default values based on current project and customer
    emailData.value = {
      email: project.value?.$customer?.email ? [project.value.$customer.email] : [],
      cc: [],
      body: {
        p_1: `${globalStore.tenant?.name} ${t("offer_p_1")}`,
        p_2: t("offer_p_2", { creator_email: globalStore.user?.email ?? "" }),
      },
    }
  }
})

// Update project email data in the database when it changes
function handleEmailDataUpdate() {
  console.info("handleEmailDataUpdate called in QuoteSend.vue!", emailData.value)

  if (project.value && formData.value) {
    // Update form data with current email data
    formData.value.email_data = { ...emailData.value }
    // Persist email data to the project
    projectController.handlePartialUpdate(["email_data"])
  }
}

async function sendEmail() {
  if (!project.value || !tenant.value || !formData.value) {
    console.error("Missing either project, tenant or form data")
    return
  }

  try {
    // Make sure to save the latest email data to the project before sending
    handleEmailDataUpdate()

    // Send the email with current email data and let API add dynamic fields
    await api.quotes.sendQuoteEmail(
      project.value,
      tenant.value,
      globalStore.token as string,
      emailData.value,
    )

    // Update project status after sending
    formData.value.quote_status = "pending"
    await projectController.handlePartialUpdate(["quote_status"])
    toast?.success(t("invoice_email_sent"))
    await router.push({ name: "quote", params: { quoteId: project.value.id } })
  }
  catch (error) {
    console.error("Failed to send email:", error)
    toast?.error(t("failed_to_send_email"))
  }
}
const mobile = inject("isPhone") as Ref<boolean>
</script>
