<template>
  <div v-if="mediaItems.length !== 0">
    <ul class="flex" :class="[{ 'flex-wrap': wrapPhotos }]">
      <li
        v-for="item in mediaItems"
        :key="item.id"
        class="overflow-hidden "
        :class="[
          { 'my-1': wrapPhotos },
          sizeClasses[size],
        ]"
      >
        <div class="relative shadow-hovering rounded-xl aspect-[1/1] h-full">
          <!-- Video element for video files -->
          <!-- <video
            v-if="item.type === 'video'"
            :src="mediaUrls[item.id]"
            class="object-cover w-full h-full rounded-xl"
            controls
            preload="metadata"
            @click="handleMediaClick(item.id)"
          >
            Your browser does not support the video tag.
          </video> -->

          <!-- Image element for picture files -->
          <img
            :src="mediaUrls[item.id]"
            alt="Thumbnail"
            class="object-cover w-full h-full rounded-xl"
            @click="handleMediaClick(item.id)"
          >

          <!-- Video indicator overlay -->
          <div
            v-if="item.type === 'video'"
            class="absolute inset-0 flex items-center justify-center pointer-events-none bg-black bg-opacity-20 rounded-xl"
          >
            <div class="bg-black bg-opacity-50 rounded-full p-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
          </div>

          <button
            v-if="allowDelete"
            class="absolute p-[2px] m-2.5 text-black bg-white rounded-full -top-1 -right-1 hover:bg-gray-200"
            @click.stop="handleDelete(item.id)"
          >
            <XMarkIcon class="h-2.5 w-2.5" />
          </button>
        </div>
      </li>
    </ul>

    <!-- Add video player modal -->
    <VideoPlayer
      ref="videoPlayer"
      :src="currentVideoSrc"
      :poster="currentVideoPoster"
      :autoplay="true"
      :controls="true"
      @error="handleVideoError"
    />
  </div>
</template>

<script setup lang="ts">
import type { MediaItem } from "@/types/models/PostTypes"
import { VideoPlayer } from "@/components/shared/video"
import { useGlobalStore } from "@/stores/globalStore"
import { useImageStore } from "@/stores/imageStore"
import { usePostController } from "@/stores/postController"
import { fileUrl } from "@/utils/fileUrl"
import { XMarkIcon } from "@heroicons/vue/24/solid"

type ImageSize = "small" | "medium" | "large"

interface Props {
  mediaItems: MediaItem[]
  wrapPhotos?: boolean
  allowDelete?: boolean
  editMode?: boolean
  size?: ImageSize
}

const props = withDefaults(defineProps<Props>(), {
  wrapPhotos: false,
  allowDelete: false,
  editMode: false,
  size: "medium",
})

const emit = defineEmits<{
  (e: "mediaClick", id: string): void
  (e: "deleteImage", id: string): void
  (e: "error", message: string): void
}>()

const globalStore = useGlobalStore()
const imageStore = useImageStore()
const mediaUrls = ref<{ [id: string]: string }>({})
const videoUrls = ref<{ [id: string]: string }>({})
const videoPlayer = ref<InstanceType<typeof VideoPlayer> | null>(null)
const currentVideoSrc = ref("")
const currentVideoPoster = ref("")

const sizeClasses = {
  small: "h-12 w-12 min-w-[48px] mr-1",
  medium: "h-16 w-16 min-w-[64px] mr-1",
  large: "h-24 w-24 min-w-[96px] mr-3",
} as const

const { handleEvent } = usePostController()
async function handleMediaClick(id: string) {
  // Check if the media is a video
  const mediaItem = props.mediaItems.find(item => item.id === id)

  if (mediaItem?.type === "video") {
    if (!videoUrls.value[id]) {
      // We need to get the full video URL first
      try {
        videoUrls.value[id] = await fileUrl(id, globalStore.token, false)
        openVideoModal(id)
      }
      catch (error) {
        handleVideoError(`Failed to load video: ${error}`)
      }
    }
    else {
      openVideoModal(id)
    }
    return
  }

  if (props.editMode) {
    imageStore.status = "edit"
    imageStore.imageId = id
    imageStore.hydrateStoreWithRemoteImage(await fileUrl(id, globalStore.token))
    handleEvent({ type: "MANAGE_PHOTOS" })
  }
  emit("mediaClick", id)
}

function openVideoModal(id: string) {
  if (videoPlayer.value) {
    currentVideoSrc.value = videoUrls.value[id]
    currentVideoPoster.value = mediaUrls.value[id]
    videoPlayer.value.openModal()
  }
}

function handleVideoError(message: string) {
  console.error(message)
  emit("error", message)
}

async function handleDelete(id: string) {
  emit("deleteImage", id)
}

// Load media URLs when mediaItems change
watch(
  () => props.mediaItems,
  async (newItems) => {
    for (const item of newItems) {
      mediaUrls.value[item.id] = await fileUrl(item.id, globalStore.token, true)
    }
  },
  { immediate: true },
)
</script>
