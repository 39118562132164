import type { PDFEmailData } from "@/pdf-generator/types"
import type { Project, Tenant } from "@/types"
import { client, withRetry } from "@/api/directus"
import { getSecondaryColor } from "@/composables/color"
import { config } from "@/config"
import { readItems } from "@directus/sdk"
import axios from "axios"
import { ref } from "vue"

async function getHighestQuoteNumber(): Promise<number> {
  return withRetry(
    async () => {
      const project = (await client.request(
        readItems("projects", {
          fields: ["quote_number"],
          sort: ["-quote_number"],
          limit: 1,
        }),
      )) as Project[]

      if (project.length === 0) {
        return 10001
      }
      return project[0].quote_number
    },
    3,
    false,
  )
}

async function downloadPdf(
  project: Project,
  tenant: Tenant,
  userId: string,
  accessToken: string,
): Promise<string> {
  const colors = ref({ primary: "#000000", secondary: "#000000", customized: false })
  colors.value.primary = tenant.invoice_color ?? "#000000"
  colors.value.secondary = getSecondaryColor(colors.value.primary)
  const language = localStorage.getItem("tredco-app-language") ?? "en"
  await client.refresh()
  try {
    if (config.generatePdfLocally === "web") {
      const pdfGeneratorPath = `?project_id=${project.id}&access_token=${accessToken}&user_id=${userId}&language=${language}`
      const localPdfUrl = `${config.generatePdfUrl}${pdfGeneratorPath}`
      return localPdfUrl
    }
    else {
      const response = await axios.post(`${config.generatePdfUrl}`, {
        "name": project.name,
        "project": project.id,
        "tenant": tenant.id,
        language,
        "access_token": accessToken,
        "user_id": userId,
        /* "footer-text": "Tredco - text to be inserted", */
        "primary-color": colors.value.primary,
        "secondary-color": colors.value.secondary,
      })

      if (!config.isProduction && config.generatePdfLocally === "function") {
        alert(
          `It is not possible to open the file via the browser, it has to be accessed directly on disk. ${response.data.url}`,
        )
      }

      return response.data.url ?? ""
    }
  }
  catch (error) {
    console.error(error)
  }
  return ""
}

async function sendQuoteEmail(
  project: Project,
  tenant: Partial<Tenant>,
  accessToken: string,
  emailData: { email: string[], cc: string[], body: { p_1: string, p_2: string } },
): Promise<string> {
  if (!emailData) {
    throw new Error("Missing email data")
  }

  const colors = ref({ primary: "#000000", secondary: "#000000", customized: false })
  colors.value.primary = tenant.invoice_color ?? "#000000"
  colors.value.secondary = getSecondaryColor(colors.value.primary)

  // Create complete email data by adding dynamic fields
  const completeEmailData: PDFEmailData = {
    ...emailData,
    subject: project.name,
    contact_person: project.contact_person || "",
    creator_email: project.contact_person_email || "",
    tenant_name: tenant.name || "",
  }

  await client.refresh()
  await axios.post(
    config.sendQuoteEmailUrl,
    {
      "project": project.id,
      "tenant": tenant.id,
      "name": project.$customer?.name || "",
      "access_token": accessToken,
      "language": localStorage.getItem("tredco-app-language"),
      "primary-color": colors.value.primary,
      "secondary-color": colors.value.secondary,
      "data": completeEmailData,
    },
    {
      responseType: "blob",
    },
  )
  return ""
}

export const quotes = {
  getHighestQuoteNumber,
  downloadPdf,
  sendQuoteEmail,
}
