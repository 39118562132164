<template>
  <div v-if="tenant">
    <div v-if="tenant.subscription_status === 'new_user_trial'">
      <h1 class="mb-2 text-heading-3">
        {{ userDisplayName }}
      </h1>
      <div class="text-label-2">
        <template v-if="getQuotesLeft <= 0">
          <div class="mb-5 text-label-bold-1 text-white bg-error drop-shadow-tr-shadow rounded-xl p-2">
            {{ $t("subscription.promt_to_subscribe") }}
          </div>
        </template>
        <template v-else>
          {{ $t("subscription.trial_message", [15]) }}
          <div class="mb-5 text-label-2">
            {{ $t("subscription.days_left", [getQuotesLeft]) }}
          </div>
        </template>
      </div>

      <div class="flex gap-4">
        <Button
          size="large"
          type="primary"
          :loading="subscriptionStore.loading"
          @click="handleSubscribe"
        >
          {{ $t("subscription.subscribe") }}
        </Button>
        <Button
          size="large"
          type="white"
          @click="goToArticle"
        >
          {{ $t("subscription.read-more") }}
        </Button>
      </div>
    </div>

    <div v-if="tenant.subscription_limit_access">
      <div class="mb-2 text-heading-3 text-white bg-error drop-shadow-tr-shadow rounded-xl p-2">
        {{ $t("subscription.subscription_expired") }}
      </div>
      <div class="mb-8 text-label-2 text-white bg-error drop-shadow-tr-shadow rounded-xl p-2">
        {{ $t("subscription.subscription_expired_promt") }}
      </div>
      <div class="flex gap-4">
        <Button
          size="large"
          type="primary"
          :loading="subscriptionStore.loading"
          @click="handleSubscribe"
        >
          {{ $t("subscription.subscribe") }}
        </Button>
        <Button
          size="large"
          type="white"

          @click="goToArticle"
        >
          {{ $t("subscription.read-more") }}
        </Button>
      </div>
    </div>

    <div
      v-if="
        !tenant.subscription_limit_access
          && tenant.subscription_status !== 'new_user_trial'
      "
    >
      <h1 class="mb-2 text-heading-3">
        {{ userDisplayName }}
      </h1>
      <div class="text-label-2">
        {{ $t("subscription.status.stripe_active") }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDataStore } from "@/stores/dataStore"
import { useSubscriptionStore } from "@/stores/subscriptionStore"
import { computed, toValue } from "vue"

const { t } = useI18n()

const dataStore = useDataStore()
const tenant = computed(() =>
  toValue(dataStore.currentUser?.$tenant && dataStore.currentUser?.$tenant),
)
const subscriptionStore = useSubscriptionStore()

const userDisplayName = computed(() => {
  return `${t("welcome.welcome_title")} ${dataStore.currentUser?.first_name}`
})

function goToArticle() {
  window.open("https://tredco.no/2024/12/06/vi-introduserer-abonnementer", "_blank")
}

// const getDaysLeft = computed(() => {
//   const today = new Date()
//   const trialEnd = new Date(tenant.value?.subscription_trial_expire_at as string)
//   const diffTime = trialEnd.getTime() - today.getTime()
//   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
//   return diffDays
// })

const getQuotesLeft = computed(() => {
  if (!tenant.value) {
    return 15
  }
  return 15 - tenant.value?.metrics.projects.total.count
})

function handleSubscribe() {
  subscriptionStore.handleSubscribe()
}
</script>
