<template>
  <div v-if="!isInitialized" class="flex flex-col items-center justify-center min-h-screen bg-1-static">
    <div class="flex flex-col items-center justify-center text-center">
      <img src="/img/tredco.svg" alt="App Logo" class="w-56 h-48 desktop:h-48 desktop:w-72 rounded-xl logo-pulse">
    </div>
  </div>
  <template v-else>
    <DeleteDirectusItem />
    <RouterView />
    <Toaster />
    <NewBuildStatus />
  </template>
</template>

<script setup lang="ts">
import type { CountrySetting } from "@/config/countries"

const isInitialized = ref(false)
const phone = ref<boolean>(false)
const globalStore = useGlobalStore()

function updateRootFontSize(isMobile: boolean) {
  // Set 16px as base for desktop, 14px for mobile
  const rootFontSize = isMobile ? "16px" : "16px"
  document.documentElement.style.fontSize = rootFontSize
}

phone.value = isPhone()
const bgColorsInverted = ref<boolean>(false)
provide("isPhone", phone)
provide("bgColorsInverted", bgColorsInverted)

function assetUrl(assetId: string) {
  const accessToken = globalStore.token
  return `${api.client.url}assets/${assetId}?access_token=${accessToken}`
}

provide("assetUrl", assetUrl)
const firestoreStore = useFirestoreStore()
firestoreStore.init().then(() => {
  // Firebase initialized
}).catch(() => {
  console.error("Error initializing app")
}).finally(() => {
  setTimeout(() => {
    isInitialized.value = true
  }, 1000) // Give a short delay to ensure Firebase is ready
})

watch(
  phone,
  () => {
    phone.value = isPhone()
    updateRootFontSize(phone.value)

    if (phone.value) {
      bgColorsInverted.value = false
    }
    else {
      bgColorsInverted.value = true
    }
  },
  { immediate: true },
)

watch(
  bgColorsInverted,
  () => {
    const bg1 = "#ffffffff"
    const bg2 = "#edf0f3ff"
    if (!bgColorsInverted.value) {
      document.documentElement.style.setProperty("--color-dynamic-1", bg1)
      document.documentElement.style.setProperty("--color-dynamic-2", bg2)
    }
    else {
      document.documentElement.style.setProperty("--color-dynamic-1", bg2)
      document.documentElement.style.setProperty("--color-dynamic-2", bg1)
    }
  },
  { immediate: true },
)

window.addEventListener("resize", () => {
  phone.value = isPhone()
  updateRootFontSize(phone.value)
})

const countrySetting = ref<CountrySetting | undefined>(undefined)

provide("countrySetting", countrySetting)
watch(
  () => globalStore.tenant,
  (tenant) => {
    countrySetting.value = undefined
    if (!tenant) {
      return
    }
    countrySetting.value = tenant.$countrySetting
  },
)
</script>

<style scoped>
.logo-pulse {
  animation: pulse 3s infinite cubic-bezier(0.4, 0, 0.6, 1);
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.7;
    transform: scale(0.98);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
