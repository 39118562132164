<template>
  <div v-if="globalStore.tenant" class="w-10 aspect-square">
    <RoundImage
      :src="logo"
      :allow-edit="allowEdit"
      :border-color="globalStore.tenant.invoice_color"
      :update-image-contex="directusContext"
    >
      <p
        class="flex items-center justify-center w-full h-full text-label-1 text-color-label-2"
        :style="`color: ${globalStore.tenant.invoice_color}`"
      >
        {{ initials }}
      </p>
    </RoundImage>
  </div>
</template>

<script setup lang="ts">
import type { DirectusContext } from "@/types/ApiTypes"
import type { ComputedRef } from "vue"

interface Props {
  allowEdit?: boolean
}
withDefaults(defineProps<Props>(), {
  allowEdit: true,
})

const globalStore = useGlobalStore()
const logo = ref<string>("")

const initials = computed(() => {
  const firstInitial = (globalStore.user?.first_name ?? "").charAt(0)
  const lastInitial = (globalStore.user?.last_name ?? "").charAt(0)
  return (firstInitial + lastInitial).toUpperCase()
})

watchEffect(async () => {
  if (!globalStore.user?.avatar) {
    return
  }
  logo.value = `${api.client.url}assets/${globalStore.user?.avatar}?access_token=${globalStore.token}&key=thumbnail`
})

const directusContext = computed(() => ({
  collection: "directus_users",
  id: globalStore.user?.id,
  data: { avatar: "" },
})) as ComputedRef<DirectusContext>
</script>
