import type { Ref } from "vue"

interface TextAreaHeightProps {
  defaultRows: number
  isExpanded: Ref<boolean>
  input: Ref<HTMLTextAreaElement | null>
  text: Ref<string | null>
}

export function useTextAreaHeight(props: TextAreaHeightProps) {
  const collapsedHeight = props.defaultRows * 38

  const updateHeight = (height?: number) => {
    const textarea = props.input.value
    if (!textarea) { return }
    textarea.style.height = "auto"

    if (height) {
      textarea.style.height = `${height}px`
    }
    else if (props.input.value) {
      textarea.style.height = `${props.input.value?.scrollHeight + 34}px`
    }
  }

  watch(
    () => props.text.value,
    () => {
      updateHeight()
    },
    { immediate: true },
  )

  watch(() => props.isExpanded.value, (newValue) => {
    if (newValue) {
      updateHeight()
    }
    else {
      updateHeight(collapsedHeight)
    }
  }, { immediate: true })
}
