<template>
  <PDFPage v-bind="{ colors, tenant, project }">
    <div class="absolute left-[-14mm] top-[-14mm] w-[210mm]">
      <img
        v-if="images.projectPicture"
        :src="getFullImageUrl(images.projectPicture)"
        class="max-h-[90mm] w-full object-cover"
        @load="loaded(images.projectPicture)"
      >
    </div>

    <!-- Main content section
        Layout structure:
        1. Company logo (or name if no logo) - max 90mm square
        2. Certifications row - horizontally distributed, 15.9mm height
        3. Project name - two lines max, centered
        4. Quote number - smaller text below project name

        All elements are centered both horizontally and vertically in the page
        Images use object-contain to maintain aspect ratio without cropping
      -->
    <div class="flex h-full w-full items-center justify-center">
      <div class="flex flex-col gap-y-16 items-center justify-between">
        <div>
          <img
            v-if="images.logo"
            :src="getFullImageUrl(images.logo)"
            class="mb-[5mm] mt-[40mm] max-h-[100mm] max-w-[100mm] object-contain"
            @load="loaded(images.logo)"
          >
          <div
            v-else
            id="logo"
            class="mb-[4.2mm] text-heading-3"
            :style="`color: ${colors.primary};`"
          >
            {{ tenant.name }}
          </div>
          <div v-if="tenant.certifications?.length" class=" w-full flex justify-around gap-[2.8mm]">
            <img
              v-for="cert in tenant.certifications"
              :key="cert.directus_files_id"
              :src="getFullImageUrl(cert.directus_files_id)"
              class="h-[19.9mm] w-auto object-contain"
              @load="loaded(cert.directus_files_id)"
            >
          </div>
        </div>
        <div>
          <h2
            class="mb-[4.2mm] line-clamp-2 overflow-hidden break-words text-center text-heading-2"
          >
            {{ project.name }}
          </h2>
          <!-- hide temporarily to see if this is needed -->
          <!-- <h4 class="text-center text-label-bold-3">
            {{ $t("quote.quote_number") }} {{ project.quote_number }}
          </h4> -->
        </div>
      </div>
    </div>

    <div
      class="absolute bottom-0 flex w-full gap-[4.2mm] rounded-[2.8mm] bg-2-static p-[5.6mm]"
    >
      <img
        v-if="images.avatar"
        id="avatar"
        :src="getFullImageUrl(images.avatar)"
        class="h-[25.9mm] w-[25.9mm] shrink-0 rounded-full object-cover"
        @load="loaded(images.avatar)"
      >
      <div class="text-caption-2">
        <div class="text-label-bold-1">
          {{ user.first_name }} {{ user.last_name }}
        </div>
        <div v-if="jobTitle" class="pb-[2.8mm]" :style="`color: ${colors.primary};`">
          {{ $t(`company-settings.your-account-settings.jobTitleOptions.${jobTitle}`) }}
        </div>
        <div>{{ user.email }}</div>
        <a
          class="text-color-link-static"
          :href="`tel:${getLocalPhoneNumber(user.phone_number)}`"
        >
          {{ getLocalPhoneNumber(user.phone_number) }}
        </a>
      </div>
    </div>
  </PDFPage>
</template>

<script setup lang="ts">
import type { Color, PdfProject, PdfTenant, PdfUser } from "@/pdf-generator/types"
import { primaryKey } from "@/utils/primaryKey"
import { getLocalPhoneNumber } from "@/utils/sanitizeMobileNumber"
import { computed, defineEmits, defineProps, inject } from "vue"

import PDFPage from "../base/PDFPage.vue"

const props = defineProps<{
  project: PdfProject
  colors: Color
  tenant: PdfTenant
  user: PdfUser
  jobTitle?: string // employee.job_title
}>()

const emit = defineEmits(["ready"])

const assetUrl = inject("assetUrl") as (assetId: string) => string
const imagesLoading: Record<string, boolean> = {}

const images = computed(() => {
  const imgs = {
    logo: primaryKey(props.tenant.logo || ""),
    projectPicture: primaryKey(props.project.picture || ""),
    avatar: props.user.avatar || "",
  }

  return imgs
})

function getFullImageUrl(pictureId: string) {
  imagesLoading[pictureId] = true
  return assetUrl(pictureId)
}
function loaded(pictureId: string) {
  imagesLoading[pictureId] = false
  checkItemReady()
}

function checkItemReady() {
  if (Object.values(imagesLoading).some(Boolean)) { return }
  emit("ready")
}
</script>
