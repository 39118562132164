<template>
  <div class="mr-2 mt-6">
    <div
      class="my-1 flex"
      :class="{
        'text-label-bold-3 text-color-label-2': mode === 'quote-settings',
        'text-label-bold-1 text-color-label-1': mode === 'settings',
      }"
    >
      <p class="grow">
        {{ $t("quote.payment_plan.percentage_left") }}
      </p>
      <p class="text-color-label-1">
        {{ 100 - percentage }}%
      </p>
    </div>
    <div
      v-if="mode === 'quote-settings'"
      class="flex text-label-bold-3 text-color-label-2"
    >
      <p class="grow">
        {{ $t("quote.payment_plan.total_left") }}
      </p>
      <p class="text-color-label-1">
        {{ formatCurrency(amountUsed) }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  percentage: number
  mode?: "settings" | "quote-settings"
}
const props = withDefaults(defineProps<Props>(), {
  percentage: 100,
})

const amount = inject<ComputedRef<number> | number>("amount")
const totalAmount = computed(() => typeof amount === 'number' ? amount : amount?.value ?? 0)

const amountUsed = computed(() => {
  if (props.percentage === 0) {
    return 0
  }
  else {
    return totalAmount.value * ((100 - props.percentage) / 100)
  }
})
</script>
