<template>
  <PageLayout v-if="tenant">
    <template #header>
      <div class="flex items-center justify-between">
        <h2 class="text-heading-2">
          {{ $t("tenant.stats.title") }}
        </h2>
      </div>
    </template>
    <template #default>
      <!-- Overview Section -->
      <div class="mb-8">
        <h2 class="mb-4 text-heading-3">
          {{ $t("tenant.stats.overview") }}
        </h2>
        <div class="grid grid-cols-1 gap-4 desktop:grid-cols-2">
          <Card :clickable="false" class="flex flex-col">
            <CardHeader>
              <span class="text-label-bold-2 text-color-label-2">{{
                $t("tenant.stats.total_projects")
              }}</span>
            </CardHeader>
            <CardContent>
              <div class="text-heading-2 mt-3">
                {{ tenant?.metrics.projects.total.count }}
              </div>
            </CardContent>
          </Card>
          <Card :clickable="false" class="flex flex-col">
            <CardHeader>
              <div class="text-label-bold-2 text-color-label-2">
                {{ $t("tenant.stats.total_amount") }}
              </div>
            </CardHeader>
            <CardContent>
              <div class="text-heading-2 mt-3">
                {{ formatCurrency(tenant?.metrics.projects.total.subtotal_amount) }}
              </div>
            </CardContent>
          </Card>
          <!--
          <Card :clickable="false" class="flex flex-col">
            <CardHeader>
              <span class="text-label-bold-2 text-color-label-2">{{
                $t("tenant.stats.total_invoiced")
              }}</span>
            </CardHeader>
            <CardContent>
              <span class="text-heading-2">{{
                formatCurrency(currentTenant?.metrics.projects.total.invoiced_amount)
              }}</span>
            </CardContent>
          </Card>
          -->
        </div>
      </div>

      <!-- Projects by Status Section -->
      <div class="mb-32">
        <h2 class="mb-4 text-heading-3">
          {{ $t("tenant.stats.by_status") }}
        </h2>
        <div class="grid grid-cols-1 gap-4 desktop:grid-cols-2">
          <Card v-for="status in orderedStatuses" :key="status" :clickable="false">
            <CardHeader>
              <div class="flex items-center justify-between">
                <span
                  class="text-label-bold-2"
                  :class="getStatusColor(status as QuoteStatuses)"
                >{{ $t(`statuses.${status}`) }}</span>
                <span class="text-label-bold-2 text-color-label-2">{{
                  $t("tenant.stats.total_amount")
                }}</span>
              </div>
            </CardHeader>
            <CardContent>
              <div class="flex items-center justify-between mt-1">
                <span class="text-heading-3">{{ tenant.metrics.projects.by_status[status].count }}
                  {{ $t("tenant.stats.projects") }}</span>
                <span class="text-heading-3">
                  {{
                    formatCurrency(
                      tenant.metrics.projects.by_status[status].subtotal_amount,
                    )
                  }}
                </span>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import type { QuoteStatuses } from "@/types/models/ProjectTypes"
import type { Ref } from "vue"
import PageLayout from "@/components/shared/PageLayout.vue"
import Card from "@/components/ui/card/Card.vue"
import CardContent from "@/components/ui/card/CardContent.vue"
import CardHeader from "@/components/ui/card/CardHeader.vue"
import { useDataStore } from "@/stores/dataStore"
import { formatCurrency } from "@/utils/companyUtils"
import { computed, inject, onMounted, toValue } from "vue"

const store = useDataStore()
const tenant = computed(() =>
  toValue(store.currentUser?.$tenant && store.currentUser?.$tenant),
)

const showBottomNav = inject("showBottomNav") as Ref<boolean>
onUnmounted(() => {
  showBottomNav.value = false
})

onMounted(() => {
  showBottomNav.value = true
})

interface StatusDesign {
  status: QuoteStatuses
  class: string
  hidden?: boolean
}

const statuses: Record<QuoteStatuses, StatusDesign> = {
  accepted: { status: "accepted", class: "text-color-success" },
  done: { status: "done", class: "text-color-done", hidden: true },
  draft: { status: "draft", class: "text-color-link-static" },
  pending: { status: "pending", class: "text-color-warning" },
  rejected: { status: "rejected", class: "text-color-error", hidden: true },
  expired: { status: "expired", class: "text-color-label-3", hidden: true },
} as const

const orderedStatuses = computed(() => {
  const keys = Object.keys(statuses) as QuoteStatuses[]
  return keys.filter(status => tenant.value?.metrics.projects.by_status?.[status])
})

function getStatusColor(status: QuoteStatuses) {
  return statuses[status]?.class || ""
}
</script>
