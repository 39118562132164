import type { Employee, FirebaseAuthResponse } from "@/types"
import api from "@/api"
import { t } from "@/localization/i18n"
import { toast } from "@/plugins/toasterPlugin"
import router from "@/router"
import { tryCatch } from "@/utils/errorHandling"
import { FirebaseAuthentication } from "@capacitor-firebase/authentication"
import { defineStore } from "pinia"
import { computed, ref, watch } from "vue"

export const useGlobalStore = defineStore("global", () => {
  // State
  const currentUserId = ref<string>("")
  const loading = ref(false)
  const showMessageVerificationCodeSentMessage = ref(false)
  const showError = ref(false)
  const isLoggedIn = ref(false)
  const passwordResetStatus = ref<"not-started" | "started" | "completed">("not-started")
  const hydrated = ref(false)

  const dataStore = useDataStore()
  const user = computed(() => dataStore.currentUser ?? undefined)
  const tenant = computed(() => user.value?.$tenant ?? null)
  const tenants = computed(() => Object.values(dataStore.tenantsById))
  const employee = computed(() => {
    const employees = Object.values(dataStore.employeesById)
    const foundEmployee = employees.find(employee => employee.user.id === currentUserId.value)
    if (!foundEmployee) {
      return null
    }
    return foundEmployee
  })

  const token = ref<string | null>(null)
  const firebaseToken = ref<string | null>(null)
  const lastVisitedPage = ref("/")
  const loginStatus = ref<
    "email" | "phone" | "waiting-verification" | "completed" | "error"
  >("phone")
  const mobileNumber = ref("")

  // Watch for tenant changes and handle side effects
  watch(
    () => user.value,
    async (newUser) => {
      if (!newUser) {
        return
      }
      if (!newUser.active_tenant) {
        await router.push({ name: "onboarding" })
        return
      }

      await checkUserHasAnCompatableRole()

      const activeTenant = dataStore.tenantsById[newUser.active_tenant]
      if (activeTenant) {
        localStorage.setItem("tenant", activeTenant.id)
      }
    },
    { immediate: true },
  )

  // Actions
  async function hydrate(redirectToLoginWhenNotLoggedIn = true) {
    await checkAuth(redirectToLoginWhenNotLoggedIn)
    if (isLoggedIn.value) {
      // Use tryCatch to wrap the entire hydration process
      const { data: hydrationResult, error } = await tryCatch(
        Promise.all([
          authenticateWithFirebase(),
          api.users.getMe(),
        ]),
      )

      if (error) {
        console.error("Error during hydration:", error)
        showError.value = true
        return
      }

      const [_, user] = hydrationResult

      currentUserId.value = user.id
      isLoggedIn.value = true
      hydrated.value = true
    }
  }

  async function loginEmail(values: { email: string, password: string }) {
    loading.value = true

    const loginResult = await tryCatch(
      api.client.login(values.email, values.password),
    )

    if (loginResult.error) {
      console.error(loginResult.error)
      showError.value = true
      loading.value = false
      return
    }

    loginStatus.value = "completed"
    await hydrate()
    showError.value = false
    loading.value = false
  }

  async function loginPhone(phoneNumber: string) {
    showMessageVerificationCodeSentMessage.value = true
    mobileNumber.value = phoneNumber
    loading.value = true

    const { data: smsResult, error } = await tryCatch(
      api.users.sendUserSMSCode(phoneNumber),
    )

    if (error) {
      console.error("Error sending SMS code:", error)
      showError.value = true
      showMessageVerificationCodeSentMessage.value = false
      loading.value = false
      return
    }

    if (smsResult) {
      loginStatus.value = "waiting-verification"
      showError.value = false
    }
    else {
      showError.value = true
      showMessageVerificationCodeSentMessage.value = false
    }

    loading.value = false
  }

  async function verifyCode(code: number) {
    loginStatus.value = "waiting-verification"
    loading.value = true

    const { data: verifyResult, error } = await tryCatch(
      api.users.verifyUserSMSCode({
        code: code.toString(),
        mobile: mobileNumber.value,
        createUser: false,
      }),
    )

    if (error) {
      console.error("Error verifying SMS code:", error)
      showError.value = true
      loginStatus.value = "phone"
      loading.value = false
      showMessageVerificationCodeSentMessage.value = false
      return
    }
    if (verifyResult.status && verifyResult.user?.email) {
      const user = verifyResult.user

      await loginEmail({
        email: user.email,
        password: code.toString(),
      })

      loading.value = false
    }
    else {
      showError.value = true
      loginStatus.value = "phone"
      loading.value = false
      showMessageVerificationCodeSentMessage.value = false
      console.error("user email missing")
    }
    showMessageVerificationCodeSentMessage.value = false
  }

  async function resetPassword(newPassword: string) {
    loading.value = true

    const { data: _, error } = await tryCatch(
      api.users.updateUserPassword(newPassword),
    )

    if (error) {
      console.error("Failed to update password:", error)
      toast.error(t("auth.password_update_failed"))
      showError.value = true
    }
    else {
      passwordResetStatus.value = "completed"
      toast.success(t("auth.password_updated"))
    }

    loading.value = false
  }

  async function setTenant(tenantId: string) {
    if (!dataStore.tenantsById[tenantId]) {
      console.error(`Tenant ${tenantId} not found`)
      return
    }

    const { data: _, error } = await tryCatch(
      api.users.updateActiveTenant(tenantId),
    )

    if (error) {
      console.error(`Error updating active tenant:`, error)
    }
  }

  async function checkAuth(redirectToLoginWhenNotLoggedIn = true) {
    const { data, error } = await tryCatch(
      api.client.refresh(),
    )
    if (error) {
      resetState()
      if (redirectToLoginWhenNotLoggedIn) {
        await router.push({ name: "auth.loginOptions" })
      }
      return
    }

    if (!data) {
      await handleLogout(redirectToLoginWhenNotLoggedIn)
    }
    else {
      isLoggedIn.value = true
      // Optionally redirect to last visited page
      // await router.push({ path: lastVisitedPage.value })
    }
  }

  async function handleLogout(redirectToLoginWhenNotLoggedIn: boolean) {
    console.info("User is not logged in anymore, redirecting to login page")
    resetState()
    if (redirectToLoginWhenNotLoggedIn) {
      await router.push({ name: "auth.loginOptions" })
    }
  }

  async function signOut() {
    try {
      localStorage.setItem("logging-out", "true")
      await api.client.logout()
    }
    catch (error) {
      console.error("Error logging out", error)
    }

    localStorage.removeItem("tenant")
    resetState()
    await router.push({ name: "auth.loginOptions" })
  }

  async function checkUserHasAnCompatableRole() {
    if (
      user.value?.role !== "a54e1e8d-308c-4c24-86a0-6855c33381e5"
      && user.value?.role !== "ae36873d-9100-4a81-bd98-4a87855d8b91"
    ) {
      toast.error(
        "The role you have is not compatable with what the application supports. Please contact support.",
      )
      await signOut()
    }
  }

  async function deleteTenant() {
    if (!tenant.value) {
      console.error("Cannot delete tenant: tenant is undefined")
      return
    }
    loading.value = true
    await api.callN8nWorkflow({
      tenant_id: tenant.value.id,
      path: "/delete-tenant",
    })
    loading.value = false
    await signOut()
  }

  type AccessTypes = keyof NonNullable<Employee["access"]>

  function requireTheFollowingAccess(accessType: AccessTypes): ComputedRef<boolean> {
    const dataStore = useDataStore()

    return computed(() => {
      const currentEmployeeRef = dataStore.currentUser?.$employee
      const currentEmployee = toValue(currentEmployeeRef)

      if (currentEmployee?.role === "admin") {
        return true
      }

      if (currentEmployee?.access) {
        return currentEmployee.access[accessType] || false
      }

      return false
    })
  }

  async function authenticateWithFirebase() {
    if (!isLoggedIn.value) {
      const error = new Error("Cannot authenticate with Firebase: User is not logged in")
      console.error(error.message)
      return
    }

    const { data: tokenResult, error } = await tryCatch<FirebaseAuthResponse>(api.client.request(() => ({
      path: "/firebase-auth",
      method: "POST",
    })))

    if (error) {
      console.error("Failed to get Firebase token from directus:", error)
      return
    }

    // Sign in with Firebase using the token
    const { data: _, error: signInError } = await tryCatch(FirebaseAuthentication.signInWithCustomToken({
      token: tokenResult.firebaseToken,
    }))

    if (signInError) {
      console.error("Firebase authentication failed:", signInError)
      showError.value = true
      return
    }

    // Set the token in the store
    firebaseToken.value = tokenResult.firebaseToken
  }

  // Initialize PostHog and watch for user/tenant changes
  const { identifyUser } = usePostHog()
  watch(
    () => [user.value, tenant.value],
    async () => {
      if (user.value && tenant.value) {
        await identifyUser(user.value, tenant.value)
      }
    },
    { immediate: true },
  )

  function resetState() {
    loading.value = false
    showMessageVerificationCodeSentMessage.value = false
    showError.value = false
    isLoggedIn.value = false
    hydrated.value = false
  }

  return {
    // State
    currentUserId,
    user,
    tenant,
    tenants,
    employee,
    loading,
    showError,
    isLoggedIn,
    token,
    firebaseToken,
    lastVisitedPage,
    loginStatus,
    showMessageVerificationCodeSentMessage,
    mobileNumber,
    passwordResetStatus,
    hydrated,

    // Actions
    hydrate,
    loginEmail,
    loginPhone,
    verifyCode,
    resetPassword,
    setTenant,
    checkAuth,
    signOut,
    checkUserHasAnCompatableRole,
    deleteTenant,
    resetState,
    requireTheFollowingAccess,
  }
})
