import type { ISchema } from "@/types"
import { defineStore } from "pinia"
import { ref } from "vue"

export const useDeleteStore = defineStore("delete", () => {
  const itemToDelete = ref<{
    id: string
    collection: keyof ISchema
  } | null>(null)
  const message = ref<string | undefined>(undefined)
  const confirmBeforeDelete = ref<boolean>(true)
  const itemDeleted = ref<boolean>(false)
  const itemCancelled = ref<boolean>(false)

  function setItemToDelete(
    id: string,
    collection: keyof ISchema,
    confirm: boolean = true,
    deleteMessage?: string,
  ) {
    itemToDelete.value = { id, collection }
    confirmBeforeDelete.value = confirm
    message.value = deleteMessage
    // Reset deletion state flags when setting up a new item
    itemDeleted.value = false
    itemCancelled.value = false
  }

  function clearItemToDelete() {
    itemToDelete.value = null
    // Signal that the operation was cancelled
    itemCancelled.value = true
  }

  /**
   * Reset all state flags after a deletion process completes
   * Call this after handling both successful deletions and cancellations
   */
  function resetState() {
    itemToDelete.value = null
    message.value = undefined
    itemDeleted.value = false
    itemCancelled.value = false
  }

  return {
    itemToDelete,
    setItemToDelete,
    clearItemToDelete,
    resetState,
    itemDeleted,
    itemCancelled,
    message,
    confirmBeforeDelete,
  }
})
