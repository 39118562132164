<template>
  <div>
    <Button
      class="w-full desktop:w-auto"
      type="secondary"
      size="large"
      :disabled="versionStore.loading > 0"
      :loading="versionStore.loading > 0"
      @click="versionStore.handleUpdate"
    >
      <template v-if="!versionStore.updateAvailable">
        {{ $t("app_up_to_date") }}
      </template>
      <template v-else>
        <template v-if="isNativePlatform()">
          <div class="flex flex-col items-center">
            <span>{{ $t("app_update_available") }} ({{ versionStore.newVersion }})</span>
            <span class="text-xs opacity-75">
              {{ versionStore.downloadedBundle ? $t("app_update_ready") : $t("app_update_downloading") }}
            </span>
          </div>
        </template>
        <template v-else>
          {{ $t("app_update_available") }} ({{ versionStore.newVersion }})
        </template>
      </template>
    </Button>
  </div>
</template>

<script setup lang="ts">
import { useVersionStore } from "@/stores/appVersionManagementStore"
import { isNativePlatform } from "@/utils/versionUtils"

const versionStore = useVersionStore()
</script>
