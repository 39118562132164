<template>
  <PageLayout :form-page="true">
    <template #header>
      <TopNav
        :title="$t('company-settings.quotes.terms')"
        :show-additiol-options="false"
        type="edit"
        :saving="tenantSettingsStore.inProgress"
        @back="$router.back()"
      >
        <template #options>
          <Button
            v-if="!mobile"
            type="primary"
            class="w-full mb-3 desktop:w-auto"
            @click="$router.back()"
          >
            {{ $t("shared.actions.save") }}
          </Button>
        </template>
      </TopNav>
    </template>
    <template #default>
      <form v-if="globalStore.tenant">
        <TextArea
          v-model="globalStore.tenant.terms"
          class="px-2 py-3 rounded-xl"
          :label="$t('quote.terms_section_title')"
          :rows="20"
          @blur="tenantSettingsStore.updateTenant({ terms: globalStore.tenant.terms })"
        />
      </form>
    </template>
    <template #footer-mobile>
      <Button
        type="primary"
        class="w-full mb-3 desktop:w-auto"
        size="large"
        @click="$router.back()"
      >
        {{ $t("shared.actions.save") }}
      </Button>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import { useGlobalStore } from "@/stores/globalStore"
import { useTenantStore } from "@/stores/tenantSettingsStore"

const globalStore = useGlobalStore()
const tenantSettingsStore = useTenantStore()

const mobile = inject("isPhone") as Ref<boolean>
</script>
