<template>
  <div>
    <BottomDrawerSlot
      :show-options="showModal"
      :title="modalTitle"
      @close="closeModal"
    >
      <div class="video-player w-full">
        <!-- Loading state -->
        <div v-if="loading" class="flex flex-col items-center justify-center py-4">
          <ClipLoader :size="30" color="#0055FF" />
          <p class="text-label-2 mt-2">
            {{ $t('common.loading') || 'Loading' }}...
          </p>
        </div>

        <!-- Error state -->
        <div v-else-if="error" class="flex flex-col items-center justify-center py-4">
          <p class="text-error text-center">
            {{ error }}
          </p>
          <button
            class="mt-4 px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors duration-300"
            @click="retryLoading"
          >
            {{ $t('common.retry') || 'Retry' }}
          </button>
        </div>

        <!-- Video player -->
        <div v-else class=" w-full pb-8">
          <div class="w-full max-w-full overflow-hidden box-border px-1 ">
            <video
              ref="videoElement"
              :src="videoSrc"
              :poster="posterSrc"
              :autoplay="autoplay"
              :controls="controls"
              :loop="loop"
              :muted="muted"
              class="w-full desktop:max-h-[700px] rounded-lg object-contain"
              :class="videoClasses"
              playsinline
              webkit-playsinline
              @play="$emit('play')"
              @pause="$emit('pause')"
              @ended="$emit('ended')"
              @error="handleVideoError"
              @loadedmetadata="$emit('loaded', $event)"
            >
              {{ $t('common.browser_not_supported') || 'Your browser does not support the video tag.' }}
            </video>
          </div>

          <!-- Custom controls when showCustomControls is true -->
          <!-- <div v-if="!controls && showCustomControls" class="absolute inset-0 flex items-center justify-center">
            <button
              @click="togglePlayPause"
              class="bg-black bg-opacity-50 rounded-full p-3 text-white hover:bg-opacity-70 transition-opacity"
              :title="isPlaying ? ($t('common.pause') || 'Pause') : ($t('common.play') || 'Play')"
            >
              <svg v-if="isPlaying" xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </button>
          </div> -->
        </div>
      </div>
    </BottomDrawerSlot>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from "vue"
import ClipLoader from "vue-spinner/src/ClipLoader.vue"

interface Props {
  src: string
  poster?: string
  autoplay?: boolean
  controls?: boolean
  loop?: boolean
  muted?: boolean
  showCustomControls?: boolean
  videoClasses?: string
  modalTitle?: string
  initiallyVisible?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  poster: "",
  autoplay: false,
  controls: true,
  loop: false,
  muted: false,
  showCustomControls: false,
  videoClasses: "",
  modalTitle: "Video",
  initiallyVisible: false,
})

const emit = defineEmits<{
  (e: "play"): void
  (e: "pause"): void
  (e: "ended"): void
  (e: "error", error: string): void
  (e: "loaded", event: Event): void
  (e: "modal-open"): void
  (e: "modal-close"): void
}>()

const videoElement = ref<HTMLVideoElement | null>(null)
const loading = ref(true)
const error = ref<string | null>(null)
const isPlaying = ref(false)
const videoSrc = ref(props.src)
const posterSrc = ref(props.poster)
const showModal = ref(props.initiallyVisible)

watch(() => props.src, (newSrc) => {
  videoSrc.value = newSrc
  loading.value = true
  error.value = null
  loadVideo()
})

function loadVideo() {
  loading.value = true
  error.value = null

  // Simulate loading
  setTimeout(() => {
    loading.value = false
  }, 500)
}

function handleVideoError(e: Event) {
  loading.value = false
  error.value = `Unable to load video: ${(e.target as HTMLVideoElement)?.error?.message || "Unknown error"}`
  emit("error", error.value)
}

function retryLoading() {
  loadVideo()
}

function togglePlayPause() {
  if (videoElement.value) {
    if (videoElement.value.paused) {
      videoElement.value.play()
    }
    else {
      videoElement.value.pause()
    }
  }
}

function openModal(src?: string, posterUrl?: string) {
  if (src) {
    videoSrc.value = src
  }

  if (posterUrl) {
    posterSrc.value = posterUrl
  }

  loading.value = true
  error.value = null
  showModal.value = true
  emit("modal-open")
  loadVideo()
}

function closeModal() {
  showModal.value = false
  emit("modal-close")

  // Pause video when modal is closed
  if (videoElement.value && !videoElement.value.paused) {
    videoElement.value.pause()
  }
}

function updatePlayingState() {
  if (videoElement.value) {
    isPlaying.value = !videoElement.value.paused
  }
}

onMounted(() => {
  loadVideo()

  if (videoElement.value) {
    videoElement.value.addEventListener("play", updatePlayingState)
    videoElement.value.addEventListener("pause", updatePlayingState)
    videoElement.value.addEventListener("ended", updatePlayingState)
  }
})

defineExpose({
  openModal,
  closeModal,
})
</script>
