import type { Customer } from "@/types"
import type { BrregCompany } from "@/types/models/CustomerTypes"

/**
 * Capitalizes the first letter of a string and makes the rest lowercase
 * @param text The string to capitalize
 * @returns The capitalized string
 */
export function capitalizeFirstLetter(text: string): string {
  if (text === "") {
    return text
  }
  return text[0].toUpperCase() + text.substring(1).toLowerCase()
}

/**
 * Converts a company name to proper format with special handling for AS/ASA
 * @param name The company name to format
 * @returns The formatted company name
 */
export function formatCompanyName(name: string): string {
  const nameFragments = name.split(" ").map(capitalizeFirstLetter)
  const lastIndex = nameFragments.length - 1
  if (["Asa", "As"].includes(nameFragments[lastIndex])) {
    nameFragments[lastIndex] = nameFragments[lastIndex].toUpperCase()
  }
  return nameFragments.join(" ")
}

/**
 * Formats a city name with proper capitalization
 * @param name The city name to format
 * @returns The formatted city name
 */
export function formatCityName(name: string): string {
  const nameFragments = name.split(" ").map((word: string, index: number) => {
    return word.length > 1 || index === 0
      ? capitalizeFirstLetter(word)
      : word.toLowerCase()
  })
  return nameFragments.join(" ").trim()
}

/**
 * Generates the URL for searching companies in Brønnøysundregistrene
 * @param name The company name to search for
 * @returns The search API URL
 */
export function getBrregSearchUrl(name: string): string {
  return `https://data.brreg.no/enhetsregisteret/api/enheter?navn=${name}&konkurs=false&size=10`
}

/**
 * Generates the URL for fetching a specific company by organization number
 * @param orgNumber The organization number
 * @returns The API URL for fetching the company
 */
export function getBrregCompanyUrl(orgNumber: string): string {
  return `https://data.brreg.no/enhetsregisteret/api/enheter?organisasjonsnummer=${orgNumber}`
}

/**
 * Creates a customer object from Brønnøysundregistrene company data
 * @param companyInfo The company information from Brreg
 * @param tenantId The tenant ID
 * @param userId The user ID
 * @param currency The currency to use
 * @returns A new customer object
 */
export function createCustomerFromBrregCompany(
  companyInfo: BrregCompany,
  tenantId: string,
  userId: string,
  currency: string,
): Customer {
  return {
    id: "",
    status: "draft",
    name: formatCompanyName(companyInfo.navn),
    company_registration_number: `${companyInfo.organisasjonsnummer}`,
    email: "",
    phone_number: "",
    street_address: companyInfo.forretningsadresse.adresse[0],
    zip_code: companyInfo.forretningsadresse.postnummer,
    city: formatCityName(companyInfo.forretningsadresse.poststed),
    municipality: formatCityName(companyInfo.forretningsadresse.kommune),
    country_code: companyInfo.forretningsadresse.land,
    province: companyInfo.forretningsadresse.kommune,
    billing_street_address: companyInfo.forretningsadresse.adresse[0],
    billing_country_code: companyInfo.forretningsadresse.land,
    billing_zip_code: companyInfo.forretningsadresse.postnummer,
    billing_municipality: formatCityName(companyInfo.forretningsadresse.kommune),
    billing_city: formatCityName(companyInfo.forretningsadresse.poststed),
    billing_province: companyInfo.forretningsadresse.kommune,
    type: "company",
    currency: currency || "NOK",
    tenant: tenantId || "",
    user_created: userId || "",
    user_updated: userId || "",
    date_created: new Date(),
    date_updated: new Date(),
  }
}

/**
 * Filters customers based on a search query
 * @param customers The list of customers to filter
 * @param searchQuery The search query
 * @returns Filtered list of customers
 */
export function filterCustomers(customers: Customer[], searchQuery: string): Customer[] {
  const q = searchQuery.trim().toLocaleLowerCase()
  if (!q) {
    return customers
  }

  return customers.filter((customer) => {
    return (
      customer.name.toLowerCase().includes(q)
      || customer.city?.toLowerCase().includes(q)
    )
  })
}

/**
 * Processes Brreg search results to format company names
 * @param results Raw results from Brreg API
 * @returns Processed company list with formatted names
 */
export function processBrregResults(results: BrregCompany[]): BrregCompany[] {
  return results.map(item => ({
    ...item,
    navn: formatCompanyName(item.navn),
  }))
}
