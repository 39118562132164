<template>
  <div>
    <IconOptions class="w-10 h-10 p-2 mr-3" @click="() => (showOptions = true)" />
    <BottomDrawerSlot :show-options="showOptions" :level="1" @close="() => (showOptions = false)">
      <Button
        v-if="postProcess === 'editing'"
        type="secondary"
        size="large"
        class="w-full mb-4"
        @click="handleCovertToTemplate"
      >
        <div class="flex items-center">
          <IconTemplate class="mr-1 w-7 text-color-link-static" />
          <p>{{ t("save_as_template") }}</p>
        </div>
      </Button>
      <Button
        type="secondary"
        size="large"
        class="w-full mb-10"
        @click="handleDeletePost"
      >
        <p class="text-color-error">
          {{ t("shared.actions.delete") }}
        </p>
      </Button>
    </BottomDrawerSlot>
  </div>
</template>

<script setup lang="ts">
const { handleEvent } = usePostController()
const { activePost, postProcess } = storeToRefs(usePostModel())
const deleteStore = useDeleteStore()
const showOptions = ref<boolean>(false)

async function handleDeletePost() {
  if (activePost.value) {
    deleteStore.setItemToDelete(activePost.value.id, "posts")
  }
}

watch(
  () => deleteStore.itemDeleted,
  async (newValue) => {
    if (newValue && activePost.value?.id === deleteStore.itemToDelete?.id) {
      handleEvent({ type: "CLOSE_POST" })
      showOptions.value = false
      deleteStore.itemDeleted = false
    }
  },
)

function handleCovertToTemplate() {
  if (activePost.value) {
    handleEvent({ type: "CONVERT_TO_TEMPLATE", postId: activePost.value.id })
    showOptions.value = false
  }
}
</script>
