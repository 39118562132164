<template>
  <div>
    <label
      v-if="label != ''"
      :class="{ 'text-error': errorMessage }"
      class="block mb-1 text-label-2 text-color-label-3"
    >
      {{ label }}
    </label>
    <div class="relative">
      <div v-if="prefix" class="absolute inset-y-0 left-0 flex items-center pl-3">
        <span class="text-gray-500 sm:text-sm" :class="textClassNames">{{ prefix }}</span>
      </div>
      <input
        ref="inputRef"
        v-model="fieldValue"
        :name="name"
        class="w-full bg-white rounded-lg"
        :type="type"
        :inputmode="inputModeComputed"
        :class="classNames"
        :placeholder="placeholder"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :data-testid="dataTestid"
        @input="handleInput"
        @focus="handleFocus"
        @change="handleChange"
        @blur="blur"
        @keyup.enter="onEnterButton"
      >
      <div v-if="suffix" class="absolute inset-y-0 right-0 flex items-center pr-3">
        <span class="text-gray-500 sm:text-sm" :class="textClassNames">{{ suffix }}</span>
      </div>
      <div ref="textWidthRef" class="absolute invisible whitespace-nowrap">
        {{ fieldValue }}
      </div>
      <div class="absolute bottom-7" :style="{ left: `${textWidth}px` }">
        <SaveState :is-loading="loadingComputed" type="symbol" />
      </div>
    </div>

    <p v-show="errorMessage || meta.valid" class="error-message">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script setup lang="ts">
import type { DirectusContext } from "@/types/ApiTypes"
import api from "@/api"
import { useField } from "vee-validate"

interface Props {
  label?: string
  name?: string
  type?: "text" | "password" | "email" | "number" | "date"
  modelValue?: string | number
  placeholder?: string
  disabled?: boolean
  textSize?: "normal" | "lg" | "xl"
  bold?: boolean
  id?: string
  rules?: string | undefined
  autocomplete?: string
  prefix?: string
  suffix?: string
  focus?: boolean
  selectContent?: boolean
  removeFormatting?: boolean
  directusContext?: DirectusContext
  directusProperty?: string
  loading?: boolean
  dataTestid?: string
}

const props = withDefaults(defineProps<Props>(), {
  label: "",
  name: "",
  type: "text",
  placeholder: "",
  modelValue: "",
  disabled: false,
  textSize: "normal",
  bold: false,
  id: "",
  rules: undefined,
  autocomplete: "off",
  prefix: "",
  suffix: "",
  focus: false,
  selectContent: false,
  removeFormatting: false,
  directusContext: undefined,
  directusProperty: undefined,
  loading: false,
  dataTestid: undefined,
})

const emit = defineEmits(["update:modelValue", "keyup.enter", "blur", "saving"])

function handleInput(event: Event) {
  if (event.target) {
    fieldValue.value = (event.target as HTMLInputElement).value
    calculateTextWidth()
  }
}

const {
  value: fieldValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(props.name, props.rules, {
  initialValue: props.modelValue,
})

function onEnterButton() {
  emit("keyup.enter")
}

async function blur() {
  handleBlur()
  emit("blur")
  calculateTextWidth()
  await updateFieldOnServer()
}

watch(
  () => fieldValue.value,
  (newValue) => {
    emit("update:modelValue", newValue)
    calculateTextWidth()
  },
)
watch(
  () => props.modelValue,
  () => {
    fieldValue.value = props.modelValue
    calculateTextWidth()
  },
)

const loadingComputed = ref(false)
const loadingInternal = ref(false)
async function updateFieldOnServer() {
  if (!props.directusContext || !props.directusProperty || !meta.valid) { return }
  const { collection, id } = props.directusContext
  if (!collection || !props.directusProperty || !id) { return }
  loadingInternal.value = true
  const context = {
    collection,
    id,
    property: props.directusProperty,
    data: { [props.directusProperty]: props.modelValue },
  }
  emit("saving", true)
  await api.updateGeneric(context)
  emit("saving", false)
  loadingInternal.value = false
}

watch(loadingInternal, () => {
  loadingComputed.value = loadingInternal.value
})
watch(
  () => props.loading,
  () => {
    loadingComputed.value = props.loading
  },
)

const inputModeComputed = computed(() => {
  if (props.type === "number") { return "numeric" }
  if (props.type === "email") { return "email" }
  return "text"
})

const inputRef = ref(null) as Ref<HTMLInputElement | null>
watch(
  () => props.focus,
  (newValue) => {
    if (newValue) {
      nextTick(() => {
        inputRef.value?.focus()
      })
    }
  },
  { immediate: true },
)

function handleFocus() {
  if (props.selectContent) {
    nextTick(() => {
      setTimeout(() => {
        inputRef.value?.select()
      }, 5)
    })
  }
}

const textWidthRef = ref(null) as Ref<HTMLDivElement | null>
const textWidth = ref(0)
function calculateTextWidth() {
  if (textWidthRef.value) {
    textWidth.value = textWidthRef.value.offsetWidth + 20
  }
}

const classNames = computed(() => ({
  "border-red-500 focus:ring-red-500 focus:border-red-500": errorMessage.value,
  "border-divider focus:ring-indigo-500 focus:border-indigo-500": !errorMessage.value,
  "text-lg leading-6": props.textSize === "lg",
  "text-heading-2": props.textSize === "xl",
  "text-base": props.textSize === "normal",
  "font-extrabold": props.bold,
  "pl-11": props.prefix,
  "reset-form-styles": props.removeFormatting,
}))
const textClassNames = computed(() => ({
  "text-lg leading-6": props.textSize === "lg",
  "text-heading-2": props.textSize === "xl",
  "text-base": props.textSize === "normal",
  "font-extrabold": props.bold,
}))
</script>

<style scoped lang="postcss">
.error-message {
  @apply text-label-3;
  @apply font-normal;
  @apply text-error;
}

.reset-form-styles {
  all: unset;
  width: 100%;
}
</style>
