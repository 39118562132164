import { auditFieldsSchema, idSchema } from "@/types"
import { z } from "zod"

export const employeeSchema = z.object({
  id: idSchema,
  status: z.enum(["invited", "active", "inactive", "archived"]),
  job_title: z.string(),
  tenant: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string(),
  phone_number: z.string(),
  role: z.enum(["admin", "employee"]),
  invite_message: z.string(),
  access: z.object({
    account_management: z.boolean(),
    employee_management: z.boolean(),
  }).nullable(),
  user: z.object({
    email: z.string(),
    first_name: z.string(),
    id: z.string(),
    last_name: z.string(),
    phone_number: z.string(),
  }),
  ...auditFieldsSchema.shape,
})

export type JobTitles = "managing_director" | "sales_consultant" | "employee"

export type Employee = z.infer<typeof employeeSchema>
