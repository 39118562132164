/**
 * Video validation utilities
 * Contains functions for validating video files
 */

import type { VideoValidationResult } from "@/types"

/**
 * Default accepted video MIME types
 */
export const DEFAULT_ACCEPTED_VIDEO_TYPES = [
  "video/mp4",
  "video/webm",
  "video/quicktime",
  "video/x-msvideo",
]

/**
 * Default maximum video file size in bytes (100MB)
 */
export const DEFAULT_MAX_VIDEO_SIZE = 100 * 1024 * 1024

/**
 * Validates a video file against accepted types
 * @param file Video file to validate
 * @param acceptedTypes Array of accepted MIME types
 * @returns Validation result for file type
 */
export function validateVideoType(
  file: File,
  acceptedTypes: string[] = DEFAULT_ACCEPTED_VIDEO_TYPES,
): { isValid: boolean, error?: string } {
  if (!acceptedTypes.includes(file.type)) {
    return {
      isValid: false,
      error: `Invalid file type. Accepted types: ${acceptedTypes.join(", ")}`,
    }
  }

  return { isValid: true }
}

/**
 * Validates a video file against size constraints
 * @param file Video file to validate
 * @param maxSize Maximum allowed size in bytes
 * @returns Validation result for file size
 */
export function validateVideoSize(
  file: File,
  maxSize: number = DEFAULT_MAX_VIDEO_SIZE,
): { isValid: boolean, error?: string } {
  if (file.size > maxSize) {
    const maxSizeMB = Math.round(maxSize / (1024 * 1024))
    return {
      isValid: false,
      error: `File size exceeds the maximum allowed size (${maxSizeMB}MB)`,
    }
  }

  return { isValid: true }
}

/**
 * Validates a video file against accepted types and size constraints
 * @param file Video file to validate
 * @param acceptedTypes Array of accepted MIME types
 * @param maxSize Maximum allowed size in bytes
 * @returns Complete validation result
 */
export function validateVideo(
  file: File,
  acceptedTypes: string[] = DEFAULT_ACCEPTED_VIDEO_TYPES,
  maxSize: number = DEFAULT_MAX_VIDEO_SIZE,
): VideoValidationResult {
  // Check file type
  const typeValidation = validateVideoType(file, acceptedTypes)
  if (!typeValidation.isValid) {
    return typeValidation
  }

  // Check file size
  const sizeValidation = validateVideoSize(file, maxSize)
  if (!sizeValidation.isValid) {
    return sizeValidation
  }

  // All validations passed
  return {
    isValid: true,
    file,
  }
}
