import type { Project, ProjectFormSchema, ProjectStateHandler, QuoteStatuses } from "@/types"
import router from "@/router"
import { projectFormSchema } from "@/types"

export const useProjectController = defineStore("projectController", () => {
  const { projectId } = storeToRefs(useFirestoreStore())
  const { loadCollection } = useFirestoreStore()
  const globalStore = useGlobalStore()
  const dataStore = useDataStore()

  // Import functions from model
  const { clearSelectedTemplates } = useProjectModel()

  // Import state from model
  const { activeProject, projectProcess, filterStatus, activeProjectId } = storeToRefs(useProjectModel())
  const { nextSortNumber } = storeToRefs(usePostModel())
  const route = useRoute()

  // State
  const isLoading = ref(false)

  // Form handling
  const {
    formData,
    formErrors,
    validateForm,
    resetForm,
    isValid,
    isDirty,
    isSubmitting,
    handleSubmit,
    handlePartialUpdate,
    updateFormData,
    mode,
    id,
  } = useDirectusForm<ProjectFormSchema, Project>({
    schema: projectFormSchema,
    validateOnChange: true,
    directusCollection: "projects",
    debug: true,
  })

  // State-specific event handlers
  const stateHandlers: ProjectStateHandler = {
    idle: async (payload) => {
      switch (payload.type) {
        case "LOAD_QUOTE":
          activeProjectId.value = payload.quoteId
          projectId.value = payload.quoteId
          await router.push({
            name: "quote",
            params: { quoteId: payload.quoteId },
          })
          break
        case "NEW_QUOTE_STARTED": {
          resetForm()
          const context = {
            user: globalStore.user,
            tenant: globalStore.tenant,
            employee: globalStore.employee,
          }
          const newProject = await scaffoldNewProject(context)
          updateFormData(newProject)
          await router.push({ name: "customers" })
          break
        }
      }
    },
    customer: async (payload) => {
      switch (payload.type) {
        case "CUSTOMER_PICKED":

          if (formData.value) {
            formData.value.customer = payload.customerId
            formData.value.name = payload.street_address
          }
          if (formData.value && mode.value === "update") {
            await handlePartialUpdate(["customer"])
          }
          if (activeProjectId.value) {
            await router.push({ name: "quote", params: { quoteId: activeProjectId.value } })
          }
          else {
            await router.push({ name: "quote" })
          }

          break
        case "QUOTE_CLOSED":
          activeProjectId.value = null
          projectId.value = null
          await router.push({ name: "quotes" })
          break
        case "PICK_CUSTOMER":
          await router.push({ name: "customers" })
          break
      }
    },
    edit: async (payload) => {
      switch (payload.type) {
        case "QUOTE_CLOSED":
          activeProjectId.value = null
          projectId.value = null
          await router.push({
            name: "quotes",
          })
          break
        case "PICK_CUSTOMER":
          await router.push({
            name: "customers",
          })
          break
        case "PICK_POST_TEMPLATE":
          await router.push({
            name: "templates",
          })
          break
      }
    },
    picking_from_template: async (payload) => {
      switch (payload.type) {
        case "POST_TEMPLATES_SELECTED":
          if (activeProject.value?.$phases?.length) {
            const phase = activeProject.value.$phases[0].id
            let currentSort = nextSortNumber.value

            for (const templateId of payload.templateIds) {
              const template = dataStore.postTemplatesById[templateId]
              if (template) {
                const postData = preparePostFromTemplate(template)
                await directus.createGeneric({
                  collection: "posts",
                  data: {
                    ...postData,
                    sort: currentSort++,
                    phase,
                    tenant: globalStore.tenant?.id,
                  },
                })
              }
            }

            clearSelectedTemplates()
            await router.push({
              name: "quote",
              params: { quoteId: activeProjectId.value },
            })
            toast.success(t("templates.template_converted_to_post_message", { count: payload.templateIds.length }))
          }
          break
        case "PICK_TEMPLATE_CANCELLED":
          await router.push({
            name: "quote",
            params: { quoteId: activeProjectId.value },
          })
          break
      }
    },
  }

  // Get the project model once
  const projectModel = useProjectModel()

  // Create the state machine with the state handlers
  const { handleEvent } = createStateMachine(
    projectModel.stateTransitions,
    stateHandlers,
    projectProcess,
    ref({}),
    'SM:Project'
  )

  async function updateProjectFilter(statuses: QuoteStatuses[]) {
    filterStatus.value = statuses
    isLoading.value = true
    try {
      if (statuses.length > 0) {
        await loadCollection("projects", globalStore.tenant?.id ?? null, { statuses })
      }
      else {
        await loadCollection("projects", globalStore.tenant?.id ?? null)
      }
    }
    catch (error) {
      console.error("Failed to update statuses:", error)
    }
    finally {
      isLoading.value = false
    }
  }

  async function includeStatusInFilter(status: QuoteStatuses) {
    filterStatus.value = [...filterStatus.value, status]
    await updateProjectFilter(filterStatus.value)
  }

  async function duplicate(id: string) {
    isLoading.value = true

    // Set up a one-time watcher to wait for the project to be hydrated
    const unwatch = watch(
      activeProject,
      async (project) => {
        if (project && project.id === id && (project.$phases?.length ?? 0) > 0) {
          const tenantId = globalStore.tenant?.id
          if (!tenantId) {
            throw new Error("No tenant ID available")
          }
          unwatch()
          const duplicateData = await duplicateProject(project, tenantId)
          const createdProjectId = await createProjectWithRelations(duplicateData)

          handleEvent({ type: "LOAD_QUOTE", quoteId: createdProjectId })
          activeProjectId.value = createdProjectId
          isLoading.value = false
        }
      },
      { immediate: true, deep: true },
    )
    activeProjectId.value = null
    activeProjectId.value = id
    projectId.value = id
  }

  // Watch for route changes to update active project
  watch(
    () => route.params.quoteId,
    async (quoteId) => {
      if (typeof quoteId === "string" && quoteId !== activeProjectId.value) {
        handleEvent({ type: "LOAD_QUOTE", quoteId })
      }
    },
    { immediate: true },
  )

  // Wait for project data to be loaded
  watch(() => activeProject.value, (project) => {
    if (project) {
      updateFormData(project)
      mode.value = "update"
      id.value = project.id
    }
  }, { immediate: true })

  // Watch for form validity to create project
  watch(() => isValid.value, async (newValue) => {
    if (newValue && mode.value === "create") {
      const project = await handleSubmit()
      if (project && "id" in project) {
        projectId.value = project.id
        activeProjectId.value = project.id
        await router.push({ name: "quote", params: { quoteId: project.id } })
      }
    }
  })

  return {
    // State
    isLoading,

    // Form state and methods
    formData,
    formErrors,
    validateForm,
    isValid,

    isDirty,
    isSubmitting,
    handlePartialUpdate,
    handleSubmit,

    // Actions
    updateProjectFilter,
    includeStatusInFilter,
    handleEvent,
    duplicate,
  }
})
