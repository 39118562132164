<template>
  <PageLayout>
    <template #header>
      <TopNav
        :title="$t('quote.settings.configure_quote')"
        :show-additiol-options="false"
        :saving="isSubmitting"
        @back="$router.back()"
      />
    </template>

    <template #default>
      <div class="mb-40">
        <QuoteSettings class="mt-4" @valid="(val: boolean) => (settingsInInvalidState = val)" />
      </div>
    </template>
    <template #footer-mobile>
      <Button
        v-if="!settingsInInvalidState"
        type="primary"
        class="w-full mr-2"
        size="large"
        @click="showQuote"
      >
        {{ $t("preview") }}
      </Button>
      <p v-else class="flex justify-center my-1 text-label-1 text-color-label-3">
        {{ $t("quote.settings.missing_information") }}
      </p>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import router from "@/router"

const route = useRoute()
const { activeProject } = storeToRefs(useProjectModel())
const { isSubmitting } = storeToRefs(useProjectController())
const settingsInInvalidState = ref(true)

async function showQuote() {
  await router.push({
    name: "quote.preview",
    params: {
      quoteId: activeProject.value?.id,
    },
  })
}
</script>

<style lang="scss" scoped></style>
