<template>
  <div class="ml-auto">
    <svg
      class="text-color-link-static"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.70421 18.7042L8.28999 17.29L13.5829 11.9971L8.28999 6.70421L9.70421 5.29L16.4113 11.9971L9.70421 18.7042Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>

<script setup lang="ts"></script>
