import { employeeSchema, idSchema, tenantSchema } from "@/types"
import { z } from "zod"

export const roleSchema = z.object({
  id: z.union([
    z.literal("ae36873d-9100-4a81-bd98-4a87855d8b91"),
    z.literal("a54e1e8d-308c-4c24-86a0-6855c33381e5"),
    z.string(),
  ]),
  name: z.string(),
})

export const directusUserSchema = z.object({
  active_tenant: idSchema,
  developer: z.boolean(),
  release_notes_last_seen: z.string(),
  phone_number: z.string(),
  tag: z.array(z.union([
    z.literal("beta-user"),
    z.literal("developer"),
    z.literal("production"),
  ])).nullable().optional(),
  role: z.union([
    z.literal("ae36873d-9100-4a81-bd98-4a87855d8b91"),
    z.literal("a54e1e8d-308c-4c24-86a0-6855c33381e5"),
  ]),
})

export const userSchema = directusUserSchema.extend({
  id: idSchema,
  avatar: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string(),
  $employee: z.lazy(() => employeeSchema).optional(),
  $tenant: z.lazy(() => tenantSchema).optional(),
})

export type User = z.infer<typeof userSchema>
export type Role = z.infer<typeof roleSchema>
export type DirectusUser = z.infer<typeof directusUserSchema>
