<template>
  <div :class="[$attrs.class, { 'min-h-[102px]': !withoutMinHeight }]">
    <label
      v-if="name !== ''"
      :class="{ 'text-red-700': error }"
      class="block mb-1 text-label-2 text-color-label-3"
    >
      {{ name }}
    </label>
    <div class="relative">
      <textarea
        :id="id"
        ref="input"
        v-model="localFieldValue"
        :class="classNames"
        :placeholder="placeholder"
        :disabled="disabled"
        :rows="rows"
        class="block w-full bg-white rounded-lg border-divider text-label-2 text-color-label-3"
        @blur="handleBlur"
        @focus="handleFocus"
        @click="handleClick"
      />
      <div v-if="mobile" class="absolute z-10 bottom-2 right-2">
        <BaseButton
          variant="secondary"
          class="p-1 rounded-lg"
          @click.prevent="isExpanded = !isExpanded"
        >
          <ChevronDownIcon v-if="!isExpanded && !isFocused" class="w-5 h-5" />
          <ChevronUpIcon v-else class="w-5 h-5 !text-color-link-static" />
        </BaseButton>
      </div>
      <div class="absolute bottom-8 right-8">
        <SaveState :is-loading="loading" type="symbol" />
      </div>
    </div>
    <Info
      v-if="error"
      :text="error"
      type="error"
      size="small"
      class="mt-1"
    />
  </div>
</template>

<script setup lang="ts">
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline"

const props = withDefaults(
  defineProps<{
    id?: string
    name?: string
    modelValue?: string | null
    placeholder?: string
    displayValue?: string
    disabled?: boolean
    error?: string
    textSize?: "normal" | "lg"
    bold?: boolean
    withoutMinHeight?: boolean
    rows?: number
    loading?: boolean
  }>(),
  {
    id: undefined,
    name: "",
    placeholder: "",
    modelValue: "",
    displayValue: undefined,
    disabled: false,
    error: "",
    textSize: "normal",
    bold: false,
    withoutMinHeight: false,
    rows: 3,
    loading: false,
  },
)

const emit = defineEmits(["update:modelValue", "blur", "saving"])
const input = ref<HTMLTextAreaElement | null>(null)
const mobile = inject("isPhone") as Ref<boolean>
const isExpanded = inject("isExpanded", ref(!mobile.value))
const isFocused = ref(false)

defineExpose({ input })

const localFieldValue = computed({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
})

useTextAreaHeight({
  defaultRows: props.rows,
  isExpanded,
  input,
  text: localFieldValue,
})

const classNames = computed(() => ({
  "border-red-500 focus:ring-red-500 focus:border-red-500": props.error,
  "border-gray focus:ring-indigo-500 focus:border-indigo-500": !props.error,
  "text-lg leading-6": props.textSize === "lg",
  "text-sm": props.textSize === "normal",
  "font-extrabold": props.bold,
}))

function handleBlur() {
  isFocused.value = false
  if (mobile.value) {
    isExpanded.value = false
  }
  else {
    isExpanded.value = true
  }
  emit("blur")
}

function handleFocus() {
  isExpanded.value = true
  isFocused.value = true
}

function handleClick(event: MouseEvent) {
  if (mobile.value && !isExpanded.value) {
    event.preventDefault()
    isExpanded.value = true
    input.value?.blur()
  }
}
</script>
