import type { BaseEventPayload, StateHandler, StateMachine, StateTransitions } from "@/types/models/StateMachine"
import { auditFieldsSchema, idSchema } from "@/types"
import { z } from "zod"

// Validates decimal numbers but keeps them as strings
const decimalStringSchema = z.string()
  .transform(val => val === "" ? 0 : Number(val))
  .pipe(z.number().multipleOf(0.01, "Verdien må være et tall med maksimalt 2 desimaler"))
  .transform(val => val.toString())

export const postFilesSchema = z.object({
  id: idSchema,
  directus_files_id: z.object({
    id: idSchema,
  }),
  type: z.enum(["picture", "video"]),
})

// Media item interface for use in components
export interface MediaItem {
  id: string
  type: z.infer<typeof postFilesSchema.shape.type>
}

// Base form schema for post creation/editing
export const postFormSchema = z.object({
  name: z.string()
    .min(1, "Navnet må være minst 1 tegn")
    .max(100, "Navnet kan ikke være lengre enn 100 tegn"),
  phase: idSchema.nullable(),
  sort: z.number().nullable(),
  unit: z.string(),
  description: z.string().max(2300, "Beskrifningen kan ikke være lengre enn 2000 tegn"),
  qty: decimalStringSchema,
  unit_price: decimalStringSchema,
  is_template: z.boolean(),
  tenant: idSchema,
  template_reference: z.string().nullable(),
})
export type PostFormSchema = z.infer<typeof postFormSchema>

// Full post schema including system fields
export const postSchema = postFormSchema.extend({
  id: idSchema,
  pictures: z.array(z.lazy(() => postFilesSchema)).optional(),
  status: z.string(),
  ...auditFieldsSchema.shape,
})

export type Post = z.infer<typeof postSchema>
export type PostFiles = z.infer<typeof postFilesSchema>

// Post state machine types
export type PostManagementProcess =
  | "idle"
  | "editing"
  | "photo"

export type PostEvent = BaseEventPayload & (
  | { type: "NEW_POST_STARTED", phaseId?: string, isTemplate?: boolean }
  | { type: "NEW_POST_FROM_VIDEO", videoFile: File, extractedText: string }
  | { type: "SAVE_POST" }
  | { type: "AUTO_SAVE_POST" }
  | { type: "EDIT_POST", postId: string }
  | { type: "EDIT_TEMPLATE", postId: string }
  | { type: "MANAGE_PHOTOS" }
  | { type: "PHOTOS_UPLOADED" }
  | { type: "PHOTO_REMOVED" }
  | { type: "PHOTO_CANCELLED" }
  | { type: "PREVIEW_POST" }
  | { type: "BACK_TO_EDIT" }
  | { type: "CLOSE_POST" }
  | { type: "CONVERT_TO_TEMPLATE", postId: string }
)

interface PostContext {
  upsertedSuccessfully: boolean
}
export type PostStateHandler = StateHandler<PostManagementProcess, PostEvent, PostContext>
export type PostStateMachine = StateMachine<PostManagementProcess, PostEvent, PostContext>
export type PostStateTransitions = StateTransitions<PostManagementProcess, PostEvent, PostContext>
