<template>
  <div :class="$attrs.class" class="relative mt-1 h-fit min-h-[42px]">
    <div>
      <div
        class="relative"
        @click="() => (optionsOpen = options.length > 0 || (value.length >= 3 && !loading))"
      >
        <TextFieldVal
          ref="input"
          v-model="value"
          :placeholder="placeholder"
          :label="name"
          type="text"
          :name="name"
          @focus="optionsOpen = options.length > 0 || (value.length >= 3 && !loading)"
        />

        <IconSearch :size="28" class="absolute bottom-2 right-2 flex items-center pr-2" />
      </div>
    </div>
    <ul
      v-if="optionsOpen || loading"
      ref="optionsPanelEl"
      class="absolute z-10 mt-1 max-h-[250px] w-full overflow-x-visible overflow-y-scroll rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      tabindex="-1"
    >
      <li
        v-if="loading || options.length === 0"
        class="relative cursor-default select-none overflow-x-clip py-2 pl-3 pr-4 text-left"
      >
        <span v-if="loading">{{ $t("loading") }}...</span>
        <span v-else>{{ $t("no_result") }}</span>
      </li>
      <li
        v-for="(option, i) in options"
        :key="option.value"
        class="relative cursor-default select-none overflow-x-clip py-2 pl-3 pr-4 text-left hover:bg-gray-100"
        :class="{ 'border-t': i > 0 }"
        @click="handleSelect(option)"
      >
        <slot name="option" :option="option">
          <span>{{ option }}</span>
        </slot>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside, useVModel, watchPausable } from "@vueuse/core"

interface Props {
  options?: Array<unknown>
  placeholder?: string
  name?: string
  error?: boolean
  modelValue?: string
  loading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
  placeholder: "",
  name: "",
  error: false,
  modelValue: "",
  loading: false,
})
const emit = defineEmits(["update:modelValue", "select", "search"])

const options = toRef(props, "options")

/**
 * Watch for changes in options list
 * Opens the options panel when options are available and not in loading state
 */
watch(options, () => {
  if (options.value.length > 0 || (value.value.length >= 3 && !props.loading)) {
    optionsOpen.value = true
  }
})

const value = useVModel(props, "modelValue", emit)

const optionsOpen = ref(false)
/**
 * Reference to the options panel element for click-outside detection
 */
const optionsPanelEl = ref(null)

/**
 * Close options panel when clicking outside
 */
onClickOutside(optionsPanelEl, () => (optionsOpen.value = false))

/**
 * Search function to handle user input
 * - Clears existing options
 * - Emits search event with current value if length >= 3
 * - Loading state is managed by the parent component through the loading prop
 */
function search() {
  options.value.length = 0

  if (value.value.length >= 3) {
    emit("search", value.value)
  }
  else {
    optionsOpen.value = false
  }
}

const { pause, resume } = watchPausable(value, (newSearch: string, oldSearch: string) => {
  if (newSearch === oldSearch) {
    return
  }
  search()
})

async function handleSelect(option) {
  pause()
  emit("select", option)
  optionsOpen.value = false
  await nextTick()
  resume()
}

const input = ref<HTMLInputElement>()

defineExpose({ input })
</script>
