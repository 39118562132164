<template>
  <div class="min-h-screen p-3 bg-gradient-to-br from-navy-blue to-turquoise desktop:p-6">
    <div class="max-w-3xl mx-auto mt-4 space-y-6">
      <header class="flex items-center justify-between">
        <div class="flex items-center gap-5 ">
          <BellIcon class="w-8 h-8 text-white" />
          <h1 class="text-white text-heading-2">
            {{ $t('release_notes.header') }}
          </h1>
        </div>
        <Button size="medium" @click="router.push('/')">
          {{ $t('close') }}
        </Button>
      </header>

      <!-- Features Section -->
      <section class="space-y-6">
        <template v-for="note in featureNotes" :key="note.id">
          <div class="p-6 shadow-sm rounded-xl bg-white/95">
            <div class="flex items-center gap-4 mb-6">
              <div class="p-2 rounded-lg bg-primary/10">
                <StarIcon v-if="note.type === 'feature'" class="w-5 h-5 text-primary" />
                <BugAntIcon v-else class="w-5 h-5 text-semantic-error" />
              </div>
              <h2 class="text-heading-3 text-color-label-1">
                {{ format(new Date(note.date_implemented), 'MMM d, yyyy').toUpperCase() }}
              </h2>
            </div>
            <HtmlContent
              :html="note[language] || ''"
              class="prose max-w-none text-color-label-1"
              @video-click="openVideoModal"
            />
          </div>
        </template>
      </section>
    </div>
  </div>

  <!-- Video Modal -->
  <Transition name="fade">
    <div v-if="isVideoModalOpen" class="video-modal" @click="closeVideoModal">
      <XMarkIcon class="w-8 h-8 video-modal-close" />
      <div class="relative" @click.stop>
        <video
          v-if="activeVideo"
          ref="videoRef"
          controls
          :src="activeVideo.src"
          class="video-player"
          playsinline
          @touchstart.stop
          @touchmove.stop
          @touchend.stop
        />
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import HtmlContent from "@/components/shared/HtmlContent.vue"
import { useDataStore } from "@/stores/dataStore"
import { BellIcon, BugAntIcon, StarIcon, XMarkIcon } from "@heroicons/vue/24/solid"
import { format } from "date-fns"
import { storeToRefs } from "pinia"
import { computed, nextTick, ref } from "vue"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"

const { locale } = useI18n({ useScope: "global" })
const router = useRouter()
const dataStore = useDataStore()
const { releaseNotesById } = storeToRefs(dataStore)
const language = locale.value === "no-NO" ? "noNO" : "enUS" as const

const sortedNotes = computed(() => {
  if (!releaseNotesById.value) { return [] }
  return Object.values(releaseNotesById.value)
    .filter(note => note.status === "published")
    .sort((a, b) => new Date(b.date_implemented).getTime() - new Date(a.date_implemented).getTime())
})

const featureNotes = computed(() =>
  sortedNotes.value.filter(note => note.type === "feature"),
)

const activeVideo = ref<{ src: string } | null>(null)
const isVideoModalOpen = ref(false)
const videoRef = ref<HTMLVideoElement | null>(null)

function openVideoModal(src: string) {
  activeVideo.value = { src }
  isVideoModalOpen.value = true
  // Use nextTick to ensure video element is mounted
  nextTick(() => {
    if (videoRef.value) {
      videoRef.value.play()
    }
  })
}

function closeVideoModal() {
  if (videoRef.value) {
    videoRef.value.pause()
  }
  isVideoModalOpen.value = false
  activeVideo.value = null
}
</script>

<style>
.prose ul {
  @apply space-y-6 list-disc ml-4;
}

.prose h2 {
  @apply text-color-label-1 text-heading-3 my-4;
}

.prose li {
  @apply text-base leading-relaxed mb-12;
}

.prose hr {
  @apply my-2;
}

.prose video {
  @apply rounded-lg mt-4 h-[200px] w-[350px] object-cover cursor-pointer shadow-sm transition-transform hover:scale-[1.02];
}

.prose strong {
  @apply text-color-label-1 font-semibold;
}

.video-modal {
  @apply fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm;
}

.video-modal video {
  @apply max-h-[80vh] max-w-[90vw] rounded-xl shadow-lg;
}

.video-modal-close {
  @apply absolute top-4 right-4 text-white hover:opacity-80 cursor-pointer;
}

.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-200;
}

.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
</style>
