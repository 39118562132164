import type { DirectusCollection, ISchema } from "@/types"
import { isEqual, pick } from "lodash"
import { useI18n } from "vue-i18n"
import { z } from "zod"

/**
 * Options for configuring the useDirectusForm composable.
 */
export interface UseFormOptions<ZodForm> {
  schema: z.ZodType<ZodForm> // Zod schema for form validation
  validateOnChange?: boolean // Whether to validate on every change (default: true)
  directusCollection: keyof ISchema // The Directus collection to interact with
  debug?: boolean // Enable debug logging (default: false)
}

/**
 * A Vue 3 composable for managing forms with Directus integration and Zod validation.
 * Provides reactive form state, validation, and submission logic with high cohesion and low coupling.
 *
 * @param options - Configuration options for the form.
 * @returns An object with form state, validation methods, and submission handlers.
 */
export function useDirectusForm<ZodForm, T extends DirectusCollection>({
  schema,
  validateOnChange = true,
  directusCollection,
  debug = false,
}: UseFormOptions<ZodForm>) {
  const { t } = useI18n()

  // --- Reactive State Management ---
  const formData = ref<ZodForm>() // Current form data
  const formErrors = ref<Record<string, string>>({}) // Validation errors for each field
  const isDirty = ref(false) // Tracks if form has unsaved changes
  const isSubmitting = ref(false) // Indicates if form is being submitted
  const mode = ref<"create" | "update">("create") // Form mode: create or update
  const id = ref<string | number | undefined>(undefined) // ID of the record being edited
  const lastLoadedData = ref<ZodForm>() // Last loaded data for dirty checking
  const touched = ref<Record<string, boolean>>({}) // Tracks touched fields
  const attemptedSubmit = ref(false) // Indicates if submission was attempted
  const isUpdatingFormData = ref(false) // Prevents validation during programmatic updates

  // --- Form Initialization and Reset ---

  /**
   * Resets the form to its initial state, clearing all data and errors.
   */
  const resetForm = () => {
    formErrors.value = {}
    isDirty.value = false
    formData.value = undefined
    lastLoadedData.value = undefined
    id.value = undefined
    mode.value = "create"
    touched.value = {}
    attemptedSubmit.value = false
  }

  /**
   * Updates the form with new data and resets relevant state.
   *
   * @param newData - The new form data to load.
   */
  const updateFormData = (newData: ZodForm | undefined) => {
    formData.value = newData ? { ...newData } : undefined
    lastLoadedData.value = newData ? { ...newData } : undefined
    isDirty.value = false
    touched.value = {}
    attemptedSubmit.value = false
  }

  // --- Validation Logic ---

  const validateField = (fieldName: keyof ZodForm) => {
    if (!(schema instanceof z.ZodObject)) {
      // Optionally, you can throw an error or simply return if the schema isn’t an object:
      touched.value[fieldName as string] = true
      return
    }

    try {
      // Create a properly typed object for the pick method
      const pickObject: { [key: string]: true } = { [fieldName as string]: true }
      const partialSchema = schema.pick(pickObject)
      partialSchema.parse({ [fieldName]: formData.value?.[fieldName] })
      delete formErrors.value[fieldName as string]
    }
    catch (error: unknown) {
      if (error instanceof z.ZodError) {
        const fieldError = error.errors.find(err => err.path[0] === fieldName)
        if (fieldError) {
          formErrors.value[fieldName as string] = t(fieldError.message)
        }
      }
    }
    touched.value[fieldName as string] = true
  }

  /**
   * Validates the entire form and updates the error state.
   *
   * @returns True if the form is valid, false otherwise.
   */
  const validateForm = () => {
    if (isUpdatingFormData.value) {
      return true
    }

    const result = schema.safeParse(formData.value)
    if (!result.success) {
      formErrors.value = result.error.errors.reduce((acc: Record<string, string>, error) => {
        const field = error.path[0] as string
        acc[field] = t(error.message)
        return acc
      }, {})

      if (debug) {
        console.info("[Form Validation Errors]:", {
          collection: directusCollection,
          errors: formErrors.value,
          formData: formData.value,
        })
      }
      return false
    }

    isUpdatingFormData.value = true
    formData.value = result.data
    setTimeout(() => (isUpdatingFormData.value = false), 0)

    formErrors.value = {}
    return true
  }

  // --- Submission Logic ---

  /**
   * Handles form submission, either creating or updating a record in Directus.
   *
   * @param onSubmit - Optional custom submission handler.
   * @returns The created or updated record, or undefined if submission fails.
   */
  const handleSubmit = async (onSubmit?: (data: ZodForm) => Promise<T>): Promise<T | undefined> => {
    isSubmitting.value = true
    attemptedSubmit.value = true

    if (!validateForm()) {
      console.warn("Submission failed: Form validation failed:", formErrors.value)
      isSubmitting.value = false
      return undefined
    }

    try {
      let fieldsKeys: (keyof ZodForm)[] = []
      let formFields = pick(formData.value, fieldsKeys)
      if (schema instanceof z.ZodObject) {
        fieldsKeys = Object.keys(schema.shape as Record<string, unknown>) as (keyof ZodForm)[]
        formFields = pick(formData.value, fieldsKeys)
      }

      isDirty.value = false

      if (onSubmit && formData.value) {
        return await onSubmit(formData.value)
      }
      else if (directusCollection) {
        if (mode.value === "update") {
          return await api.updateGeneric<T>({
            collection: directusCollection,
            id: id.value as string,
            data: formFields,
          })
        }
        else {
          return await api.createGeneric<T>({
            collection: directusCollection,
            data: formFields,
          })
        }
      }
      throw new Error("No valid submission handler found")
    }
    finally {
      isSubmitting.value = false
    }
  }

  /**
   * Handles partial updates for specific fields in an existing record.
   * Note: The entire form is validated to ensure data integrity and catch any cross-field validation issues,
   * even though only a subset of fields is being updated.
   *
   * @param fieldNames - The fields to update.
   * @returns The updated record, or undefined if the update fails.
   */
  const handlePartialUpdate = async (fieldNames: (keyof ZodForm)[]) => {
    isSubmitting.value = true
    attemptedSubmit.value = true

    if (!validateForm()) {
      console.warn("Submission failed: Form validation failed:", formErrors.value)
      isSubmitting.value = false
      return
    }

    if (!directusCollection || mode.value !== "update" || !id.value) {
      console.warn("handlePartialUpdate requires update mode and directusCollection")
      return
    }

    const formFields = pick(formData.value, fieldNames)
    isDirty.value = false

    try {
      return await api.updateGeneric<T>({
        collection: directusCollection,
        id: id.value as string,
        data: formFields,
      })
    }
    finally {
      isSubmitting.value = false
    }
  }

  // --- Utility Functions ---

  /** Computed property indicating if the form is valid based on the schema. */
  const isValid = computed(() => schema.safeParse(formData.value).success)

  // Watch form data for changes if validateOnChange is enabled
  if (validateOnChange) {
    watch(
      formData,
      () => {
        if (lastLoadedData.value && formData.value) {
          isDirty.value = !isEqual(formData.value, lastLoadedData.value)
        }
        validateForm()
      },
      { deep: true },
    )
  }

  // --- Return API ---
  return {
    formData,
    formErrors,
    isDirty,
    isValid,
    isSubmitting,
    validateField,
    validateForm,
    handleSubmit,
    handlePartialUpdate,
    resetForm,
    updateFormData,
    mode,
    id,
    touched,
    attemptedSubmit,
  }
}
