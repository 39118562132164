import type { Vue3TouchEventsOptions } from "vue3-touch-events"
import { config } from "@/config"
import { PostHogPlugin } from "@/plugins/posthog"
import { CapacitorUpdater } from "@capgo/capacitor-updater"
import { autoAnimatePlugin } from "@formkit/auto-animate/vue"
import { defineCustomElements } from "@ionic/pwa-elements/loader"
import * as Sentry from "@sentry/vue"
import { createHead } from "@vueuse/head"
import { createPinia } from "pinia"
import { createApp } from "vue"
import Vue3TouchEvents from "vue3-touch-events"
import VueKonva from "vue-konva"
import ClipLoader from "vue-spinner/src/ClipLoader.vue"
import App from "./App.vue"
import { i18n } from "./localization/i18n"
import globalStorePlugin from "./plugins/global-store-plugin"
import toasterPlugin from "./plugins/toasterPlugin"
import router from "./router"
import "./assets/css/styles.css"

// Properly handle the Promise returned by notifyAppReady
CapacitorUpdater.notifyAppReady()
  .catch((error) => {
    console.error("Failed to notify app ready:", error)
  })

const head = createHead()

defineCustomElements(window).catch((error) => {
  console.error("Failed to define custom elements:", error)
})
const app = createApp(App)

// Create pinia instance first so we can access stores
const pinia = createPinia()
app.use(pinia)

// Get version information
const versionStore = useVersionStore()
const appVersion = versionStore.currentVersion

if (config.isProduction) {
  Sentry.init({
    app,
    logErrors: true,
    dsn: config.sentryDns,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    integrations: [
      // Sentry.replayIntegration()
    ],
    tracesSampleRate: 0.1,
    // Add version information to all events
    beforeSend(event) {
      event.release = appVersion
      return event
    },
  })
}

app.component("ClipLoader", ClipLoader)
app.use<Vue3TouchEventsOptions>(Vue3TouchEvents, {
  disableClick: false,
})
app.use(VueKonva)
app.use(router)
app.use(head)
app.use(i18n)
app.use(toasterPlugin)
app.use(autoAnimatePlugin)
app.use(PostHogPlugin)

// Global store plugin must be initialized after pinia
app.use(globalStorePlugin)

app.mount("#app")
