import type { Customer, Tenant } from "@/types"

import type { OnboardingContext, OnboardingEvent, OnboardingProcess, OnboardingStateHandler, OnboardingStateTransitions } from "@/types/models/OnboardingTypes"
import { useDataStore } from "@/stores/dataStore"
import { useGlobalStore } from "@/stores/globalStore"

// State machine imports above
import * as onboardingUtils from "@/utils/onboardingUtils"
import seperateFullName from "@/utils/seperateFullName"
import { createStateMachine } from "@/utils/stateMachine"
import { defineStore } from "pinia"
import { computed, ref } from "vue"
import { useRouter } from "vue-router"

export const useOnboardingController = defineStore("onboarding", () => {
  // Dependencies
  const globalStore = useGlobalStore()
  const dataStore = useDataStore()
  const router = useRouter()

  // Used for tenant selection in OnboardingAccountExists
  const getTenantOptions = () => {
    const options = []
    for (const tenant of Object.values(dataStore.tenantsById)) {
      options.push({
        label: tenant.name,
        id: tenant.id,
        selected: false,
      })
    }
    return options
  }

  // Total number of steps in the onboarding process (excluding idle and completed states)
  const TOTAL_STEPS = 6 // selectingCountry, enteringPhone, verifyingCode, creatingProfile, enteringCompanyInfo, uploadingLogo

  // State
  const onboardingProcess = ref<OnboardingProcess>("idle")
  const contextRef = ref<OnboardingContext>({
    currency: "NOK",
    mobileNumber: "",
    tenantType: "prod",
    error: false,
    isExistingUser: false,
    operationSuccessful: false,
    loading: false,
    currentStep: 1, // Start at step 1 for human-readable step number
    totalSteps: TOTAL_STEPS,
  })
  const logoName = ref("")
  const tenant = ref<Tenant>({} as Tenant)

  // State transitions
  const stateTransitions: OnboardingStateTransitions = {
    idle: {
      on: {
        START_ONBOARDING: [
          { target: "accountExists", cond: context => context.isExistingUser },
          { target: "landingPage" },
        ],
        RESET: [{ target: "idle" }],
      },
    },
    landingPage: {
      on: {
        CONTINUE: [{ target: "selectingCountry" }],
        BACK: [{ target: "landingPage" }],
        RESET: [{ target: "landingPage" }],
      },
    },
    selectingCountry: {
      on: {
        SELECT_COUNTRY: [{ target: "enteringPhone" }],
        BACK: [{ target: "landingPage" }],
        RESET: [{ target: "landingPage" }],
      },
    },
    enteringPhone: {
      on: {
        SUBMIT_PHONE: [{ target: "verifyingCode" }],
        BACK: [{ target: "selectingCountry" }],
        RESET: [{ target: "landingPage" }],
      },
    },
    verifyingCode: {
      on: {
        VERIFY_CODE: [
          { target: "creatingProfile", cond: context => context.operationSuccessful },
          { target: "verifyingCode" },
        ],
        BACK: [{ target: "enteringPhone" }],
        RESET: [{ target: "landingPage" }],
      },
    },
    creatingProfile: {
      on: {
        SUBMIT_PROFILE: [{ target: "enteringCompanyInfo" }],
        BACK: [{ target: "verifyingCode" }],
        RESET: [{ target: "landingPage" }],
      },
    },
    accountExists: {
      on: {
        SELECT_EXISTING_TENANT: [{ target: "enteringCompanyInfo" }],
        CREATE_NEW_TENANT: [{ target: "enteringCompanyInfo" }],
        RESET: [{ target: "landingPage" }],
      },
    },
    enteringCompanyInfo: {
      on: {
        SUBMIT_COMPANY: [{ target: "uploadingLogo" }],
        BACK: [{ target: "creatingProfile" }],
        RESET: [{ target: "landingPage" }],
      },
    },
    uploadingLogo: {
      on: {
        UPLOAD_LOGO: [{ target: "completed" }],
        SKIP_LOGO: [{ target: "completed" }],
        BACK: [{ target: "enteringCompanyInfo" }],
        RESET: [{ target: "landingPage" }],
      },
    },
    completed: {
      on: {
        RESET: [{ target: "landingPage" }],
      },
    },
  }

  // State handlers
  const stateHandlers: OnboardingStateHandler = {
    idle: async (payload: OnboardingEvent) => {
      switch (payload.type) {
        case "START_ONBOARDING":
          // Reset any previous errors
          contextRef.value.error = false

          // Check if user is already logged in
          await globalStore.hydrate(false)
          contextRef.value.isExistingUser = globalStore.isLoggedIn
          break
        case "RESET":
          resetState()
          break
      }
    },
    landingPage: async (payload: OnboardingEvent) => {
      switch (payload.type) {
        case "CONTINUE":
          // User wants to continue from landing page to country selection
          break
        case "BACK":
          // Reset state when going back
          resetState()
          break
        case "RESET":
          resetState()
          break
      }
    },
    selectingCountry: async (payload: OnboardingEvent) => {
      switch (payload.type) {
        case "SELECT_COUNTRY":
          contextRef.value.currency = payload.currency
          break
        case "BACK":
          resetState()
          break
        case "RESET":
          resetState()
          break
      }
    },
    enteringPhone: async (payload: OnboardingEvent) => {
      switch (payload.type) {
        case "SUBMIT_PHONE": {
          contextRef.value.loading = true
          contextRef.value.mobileNumber = payload.mobileNumber
          const { data: success } = await tryCatch(onboardingUtils.sendSmsCode(payload.mobileNumber))
          contextRef.value.error = !success
          contextRef.value.loading = false
        }
          break
        case "BACK":
          break
        case "RESET":
          resetState()
          break
      }
    },
    verifyingCode: async (payload: OnboardingEvent) => {
      switch (payload.type) {
        case "VERIFY_CODE": {
          contextRef.value.loading = true
          const { data: result } = await tryCatch(onboardingUtils.verifySmsCode(
            payload.code,
            contextRef.value.mobileNumber,
            contextRef.value.tenantType,
          ))
          if (result && result.status) {
            await globalStore.loginEmail({
              email: result.user?.email ?? "",
              password: payload.code.toString(),
            })
            contextRef.value.operationSuccessful = true
          }
          else {
            contextRef.value.error = true
            contextRef.value.operationSuccessful = false
          }
          contextRef.value.loading = false
          break
        }
        case "BACK":
          contextRef.value.error = false
          break
        case "RESET":
          resetState()
          break
      }
    },
    creatingProfile: async (payload: OnboardingEvent) => {
      switch (payload.type) {
        case "SUBMIT_PROFILE": {
          contextRef.value.loading = true

          const { data: profileUpdateSuccess } = await tryCatch(onboardingUtils.updateUserProfile({
            email: payload.email,
            fullName: payload.fullName,
          }))

          if (!profileUpdateSuccess) {
            contextRef.value.error = true
            contextRef.value.loading = false
            return
          }

          if (globalStore.tenant) {
            await onboardingUtils.updateTenant(globalStore.tenant.id, {
              email: payload.email,
              name: "ny eier",
            })

            await onboardingUtils.sendWelcomeEmail(
              globalStore.tenant.id,
              seperateFullName(payload.fullName).firstName,
              payload.email,
              "nb",
            )
          }

          contextRef.value.loading = false
          break
        }
        case "BACK":
          break
        case "RESET":
          resetState()
          break
      }
    },
    accountExists: async (payload: OnboardingEvent) => {
      switch (payload.type) {
        case "SELECT_EXISTING_TENANT": {
          await globalStore.setTenant(payload.tenantId)
          contextRef.value.currency = globalStore.tenant?.currency ?? "NOK"
          break
        }
        case "CREATE_NEW_TENANT": {
          if (!globalStore.user?.id) {
            contextRef.value.error = true
            return
          }

          contextRef.value.loading = true
          const tenantId = await onboardingUtils.createNewTenantForUser(globalStore.user.id)

          if (tenantId) {
            await globalStore.hydrate()
            await globalStore.setTenant(tenantId)
          }
          else {
            contextRef.value.error = true
          }
          contextRef.value.loading = false
          break
        }
        case "RESET":
          resetState()
          break
      }
    },
    enteringCompanyInfo: async (payload: OnboardingEvent) => {
      switch (payload.type) {
        case "SUBMIT_COMPANY": {
          contextRef.value.loading = true

          if (globalStore.tenant) {
            const updatedTenant = await onboardingUtils.updateTenantOwner(
              payload.company,
              globalStore.tenant.id,
              contextRef.value.currency,
              globalStore.user?.phone_number ?? "",
              globalStore.tenant.email ?? "",
            )

            if (updatedTenant) {
              tenant.value = updatedTenant
            }
            else {
              contextRef.value.error = true
            }
          }
          else {
            contextRef.value.error = true
          }

          contextRef.value.loading = false
          break
        }
        case "BACK":
          break
        case "RESET":
          resetState()
          break
      }
    },
    uploadingLogo: async (payload: OnboardingEvent) => {
      switch (payload.type) {
        case "UPLOAD_LOGO":
        case "SKIP_LOGO":
          // Both actions lead to completion
          break
        case "BACK":
          break
        case "RESET":
          resetState()
          break
      }
    },
    completed: async (payload: OnboardingEvent) => {
      switch (payload.type) {
        case "RESET":
          resetState()
          break
      }
    },
  }

  // Update current step whenever the process changes
  watch(onboardingProcess, (newProcess) => {
    switch (newProcess) {
      case "idle":
        contextRef.value.currentStep = 0
        break
      case "landingPage":
        contextRef.value.currentStep = 1
        break
      case "selectingCountry":
        contextRef.value.currentStep = 2
        break
      case "enteringPhone":
        contextRef.value.currentStep = 3
        break
      case "verifyingCode":
        contextRef.value.currentStep = 4
        break
      case "accountExists":
        contextRef.value.currentStep = 5
        break
      case "creatingProfile":
        contextRef.value.currentStep = 5
        break
      case "enteringCompanyInfo":
        contextRef.value.currentStep = 6
        break
      case "uploadingLogo":
        contextRef.value.currentStep = 7
        break
      case "completed":
        contextRef.value.currentStep = 8
        break
    }
  }, { immediate: true })

  // Create the state machine
  const { handleEvent } = createStateMachine(
    stateTransitions,
    stateHandlers,
    onboardingProcess,
    contextRef,
    "SM:Onboarding",
  )

  // Legacy actions (for backward compatibility)
  async function loginPhone(mobileNumber: string) {
    handleEvent({ type: "SUBMIT_PHONE", mobileNumber })
  }

  async function verifyCode(code: number) {
    handleEvent({ type: "VERIFY_CODE", code })
  }

  async function updateProfile(values: { email: string, fullName: string }) {
    handleEvent({ type: "SUBMIT_PROFILE", ...values })
  }

  async function updateTenantOwner(owner: Partial<Customer>) {
    // Using type assertion to ensure type safety
    const event: OnboardingEvent = { type: "SUBMIT_COMPANY", company: owner }
    handleEvent(event)
  }

  async function updateInvoiceColor(color: string) {
    if (!globalStore.tenant?.id) {
      return
    }

    contextRef.value.loading = true
    await onboardingUtils.updateInvoiceColor(globalStore.tenant.id, color)
    contextRef.value.loading = false
  }

  // Start the onboarding process
  async function startOnboarding() {
    handleEvent({ type: "START_ONBOARDING" })
  }

  function resetState() {
    onboardingProcess.value = "idle"
    contextRef.value = {
      currency: "NOK",
      mobileNumber: "",
      tenantType: "prod",
      error: false,
      isExistingUser: false,
      operationSuccessful: false,
      loading: false,
      currentStep: 1,
      totalSteps: TOTAL_STEPS,
    }
    logoName.value = ""
    tenant.value = {} as Tenant
  }

  // Add new tenant for existing user
  async function addNewAccountToUser() {
    if (!globalStore.user?.id) {
      return false
    }

    contextRef.value.loading = true
    const tenantId = await onboardingUtils.createNewTenantForUser(globalStore.user.id)

    if (tenantId) {
      await globalStore.hydrate()
      await globalStore.setTenant(tenantId)
      // Use the available user properties to create a profile
      const email = globalStore.user?.email || ""
      const fullName = globalStore.user?.phone_number || "" // Using phone_number as fallback since fullName isn't available
      handleEvent({ type: "SUBMIT_PROFILE", email, fullName })
      return true
    }

    contextRef.value.error = true
    contextRef.value.loading = false
    return false
  }

  // Handle selected tenant for existing user
  async function handleSelectedTenant(id: string) {
    await globalStore.setTenant(id)
    contextRef.value.currency = globalStore.tenant?.currency ?? "NOK"

    // Use the available user properties to create a profile
    const email = globalStore.user?.email || ""
    const fullName = globalStore.user?.phone_number || "" // Using phone_number as fallback since fullName isn't available
    handleEvent({ type: "SUBMIT_PROFILE", email, fullName })
  }

  watch(
    () => onboardingProcess.value,
    async (process) => {
      if (process === "completed") {
        await router.push({ name: "quotes" })
      }
    },
    { immediate: true },
  )

  return {
    // State
    onboardingProcess,
    currency: computed(() => contextRef.value.currency),
    loading: computed(() => contextRef.value.loading),
    error: computed(() => contextRef.value.error),
    mobileNumber: computed(() => contextRef.value.mobileNumber),
    logoName,
    tenantType: computed({
      get: () => contextRef.value.tenantType,
      set: (value) => { contextRef.value.tenantType = value },
    }),
    tenant,

    // Step information
    currentStep: computed(() => contextRef.value.currentStep),
    totalSteps: computed(() => contextRef.value.totalSteps),

    // State machine
    handleEvent,

    // Actions
    loginPhone,
    verifyCode,
    updateProfile,
    updateTenantOwner,
    updateInvoiceColor,
    startOnboarding,
    resetState,

    // New actions
    addNewAccountToUser,
    handleSelectedTenant,
    getTenantOptions,
  }
})
