<template>
  <Button
    v-if="showButton"
    class="z-50 right-4 top-4"
    size="small"
    type="primary"
    @click="handleClick"
  >
    <div class="flex items-center gap-2">
      <BellIcon class="w-5 h-5" />
      <span class="font-medium">{{ $t('release_notes.new') }}</span>
      <ArrowRightIcon class="w-5 h-5" />
    </div>
  </Button>
</template>

<script setup lang="ts">
import api from "@/api"
import { useDataStore } from "@/stores/dataStore"
import { ArrowRightIcon, BellIcon } from "@heroicons/vue/24/solid"
import { storeToRefs } from "pinia"
import posthog from "posthog-js"
import { computed, watch } from "vue"
import { useRouter } from "vue-router"

const dataStore = useDataStore()
const { releaseNotesById, currentUser } = storeToRefs(dataStore)

const showButton = computed(() => {
  if (!currentUser.value || !releaseNotesById.value) { return false }

  const lastSeen = new Date(currentUser.value.release_notes_last_seen)
  const now = new Date()

  const hasNewNotes = Object.values(releaseNotesById.value).some((note) => {
    const dateImplemented = new Date(note.date_implemented)
    return note.status === "published"
      && dateImplemented > lastSeen
      && dateImplemented <= now
  })
  return hasNewNotes
})

const router = useRouter()

async function handleClick() {
  posthog.capture("release_notes_button_clicked")
  await markAsSeen()
  router.push("/release-notes")
}

async function markAsSeen() {
  if (!currentUser.value) { return }
  await api.users.updateMe({
    release_notes_last_seen: new Date().toISOString(),
  })
}

// Track when the button becomes visible
watch(showButton, (isVisible) => {
  if (isVisible) {
    posthog.capture("release_notes_button_shown")
  }
}, { immediate: true })
</script>
