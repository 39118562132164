<template>
  <div
    v-if="showInfoBox"
    class="relative w-full gap-12 p-4 bg-center bg-no-repeat bg-cover shadow-sm rounded-2xl bg-welcome-gradient text-color-button-primary"
  >
    <div class="flex gap-4">
      <SubscriptionsWidget class="grow" />
      <div>
        <ReleaseNotesButton />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"

const showInfoBox = ref(true)

// Function: Single purpose - initialize visibility state
function initializeVisibility() {
  const storedPreference = localStorage.getItem("showInfoBox")
  showInfoBox.value = storedPreference !== "false"
}

onMounted(initializeVisibility)
</script>
