<template>
  <div class="mt-8 rounded-xl bg-1-static">
    <div>
      <Toggle
        :value-left="$t('quote.settings.percentage')"
        :value-right="$t('quote.settings.spesific_amount')"
        class="mb-4"
        @left-click="showPercentage = true"
        @right-click="showPercentage = false"
      />

      <TextFieldVal
        v-if="showPercentage"
        v-model="percent"
        :label="$t('quote.settings.percentage')"
        type="number"
        class="mb-3"
        suffix="%"
      />
      <TextFieldVal
        v-if="!showPercentage"
        v-model="discount"
        :label="$t('amount')"
        type="number"
        :select-content="true"
        class="mb-3"
        :suffix="$t('quote.currency')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProjectModel } from "@/stores/projectModel"
import { useProjectController } from "@/stores/projectsController"
import { formatNumber } from "@/utils/companyUtils"
import { storeToRefs } from "pinia"

const projectController = useProjectController()
const { formData } = storeToRefs(projectController)
const { activeProject } = storeToRefs(useProjectModel())

const showPercentage = ref(false)
const discount = ref(Number(formData.value?.discount_amount || 0))
const percent = ref("0")

// Initialize percent if discount exists
watchEffect(() => {
  if (activeProject.value && discount.value > 0) {
    percent.value = formatNumber(
      (discount.value / calculateGrandTotal(activeProject.value)) * 100,
    ).toString()
  }
})

// Handle percentage changes
watch([percent, showPercentage], async (newVal, oldVal) => {
  if (newVal[0] === oldVal[0] || showPercentage.value === false || newVal[0] === "" || !activeProject.value) {
    return
  }

  const total = calculateGrandTotal(activeProject.value)
  discount.value = Math.round(total * (Number(percent.value) / 100))
  if (formData.value) {
    formData.value.discount_amount = Math.round(discount.value)
    await projectController.handlePartialUpdate(["discount_amount"])
  }
})

// Handle direct discount amount changes
watch([discount, showPercentage], async (newVal, oldVal) => {
  if (
    Number(newVal[0]) === Number(oldVal[0])
    || showPercentage.value === true
    || newVal[0] === 0
    || !activeProject.value
  ) {
    return
  }

  if (formData.value) {
    formData.value.discount_amount = Math.round(discount.value)
    percent.value = formatNumber(
      (Number(discount.value) / calculateGrandTotal(activeProject.value)) * 100,
    ).toString()
    await projectController.handlePartialUpdate(["discount_amount"])
  }
})
</script>
