import type {
  Post,
  PostFormSchema,
  PostStateHandler,
} from "@/types"

import { useDirectusAutoForm } from "@/composables/forms/useDirectusAutoForm"
import { postFormSchema } from "@/types"
import { tryCatch } from "@/utils/errorHandling"
import { createPostFromVideo } from "@/utils/postUtils"

export const usePostController = defineStore("postController", () => {
  const globalStore = useGlobalStore()

  // Import functions from model
  const postModel = usePostModel()

  const {
    activePost,
    postProcess,
    activePostId,
    nextSortNumberForTemplates,
    nextSortNumber,
  } = storeToRefs(postModel)

  // Get non-reactive store values
  const { stateTransitions } = postModel
  const { activeProject } = storeToRefs(useProjectModel())
  const contextRef = ref({ upsertedSuccessfully: false })
  // State
  const isLoading = ref(false)

  // Form handling with the new auto form composable
  const {
    formData,
    formErrors,
    isValid,
    isDirty,
    isSubmitting,
    validateForm,
    handleFieldChanges,
    initForm,
    cancelForm,
    id,
    formProcess,
  } = useDirectusAutoForm<PostFormSchema, Post>({
    schema: postFormSchema,
    validateOnChange: false,
    directusCollection: "posts",
    debug: true,
  })

  // State-specific event handlers
  const stateHandlers: PostStateHandler = {
    idle: async (payload) => {
      switch (payload.type) {
        case "NEW_POST_STARTED":
          if (!globalStore.tenant?.id) {
            return
          }
          activePostId.value = null
          initForm({
            initialData: {
              name: "",
              phase: payload.phaseId ?? null,
              sort: payload.isTemplate
                ? nextSortNumberForTemplates.value
                : nextSortNumber.value,
              unit: "pcs",
              description: "",
              qty: "1",
              unit_price: "0",
              is_template: payload.isTemplate ?? false,
              tenant: globalStore.tenant?.id,
              template_reference: null,
            },
          })
          break

        case "NEW_POST_FROM_VIDEO":
          {
            const phaseId = activeProject.value?.$phases?.[0]?.id
            const tenantId = globalStore.tenant?.id

            if (!tenantId || !globalStore.token || !phaseId) {
              return
            }

            activePostId.value = null
            const result = await createPostFromVideo({
              videoFile: payload.videoFile,
              accessToken: globalStore.token,
              phaseId,
              tenantId,
              nextSortNumber: nextSortNumber.value,
            })

            if (result.success) {
              // Initialize the form with the created post data
              if (result.post) {
                initForm({
                  id: result.post.id,
                  initialData: result.post,
                })
                toast.success("Post created from video")
              }
            }
          }
          break

        case "EDIT_POST":
          // Set the active post ID and let the watcher handle form initialization
          activePostId.value = payload.postId
          break
      }
    },

    editing: async (payload) => {
      switch (payload.type) {
        case "MANAGE_PHOTOS":
          // Handle photo upload logic
          break
        case "AUTO_SAVE_POST":
          // Process any pending field changes which will trigger partial updates
          await handleFieldChanges()
          break
        case "SAVE_POST":
          if (isValid.value) {
            // Validate all fields and process any pending changes
            validateForm()
            await handleFieldChanges()
            contextRef.value.upsertedSuccessfully = true
          }
          else {
            contextRef.value.upsertedSuccessfully = false
          }
          break
        case "CLOSE_POST":
          cancelForm()
          activePostId.value = null
          break
        case "CONVERT_TO_TEMPLATE":
          if (formData.value && activePost.value) {
            const templateData = preparePostTemplate(
              activePost.value,
              nextSortNumberForTemplates.value,
            )

            const { data, error } = await tryCatch<Post>(
              api.createGeneric({
                collection: "posts",
                data: templateData,
              }),
            )

            if (data && !error) {
              toast.success(t("templates.post_added_as_template_message"))
            }
            else if (error) {
              toast.error(t("shared.errors.failed_to_create_template"))
            }
          }
          break
      }
    },
    photo: async (payload) => {
      switch (payload.type) {
        case "PHOTOS_UPLOADED":
          await router.push({
            name: "quote",
            params: { quoteId: activeProject.value?.id },
          })
          break
        case "PHOTO_REMOVED":
          await router.push({
            name: "quote",
            params: { quoteId: activeProject.value?.id },
          })
          break
        case "PHOTO_CANCELLED":
          await router.push({
            name: "quote",
            params: { quoteId: activeProject.value?.id },
          })
          break
        case "CLOSE_POST":
          cancelForm()
          activePostId.value = null
          break
      }
    },
  }

  const { handleEvent } = createStateMachine(
    stateTransitions,
    stateHandlers,
    postProcess,
    contextRef,
    "SM:Post",
  )

  watch(
    () => activePost.value,
    (post) => {
      if (post && post.id !== id.value) {
        initForm({
          id: post.id,
          initialData: post,
        })
      }
    },
    { immediate: true },
  )

  // Watch for changes in the form process state to sync with the post model state
  watch(formProcess, (newFormProcess) => {
    if (newFormProcess === "editing") {
      const newPostId = id.value
      if (newPostId && typeof newPostId === "string" && !activePostId.value) {
        activePostId.value = newPostId
      }
    }
  })

  /**
   * Start a new post or template based on the current form data
   */
  function startNewPostOrTemplate() {
    if (isValid.value && formData.value) {
      const shouldCreateTemplate = formData.value.is_template
      const phaseId = formData.value.phase

      handleEvent({ type: "CLOSE_POST" })
      if (shouldCreateTemplate) {
        handleEvent({ type: "NEW_POST_STARTED", phaseId: undefined, isTemplate: true })
      }
      else if (phaseId) {
        handleEvent({ type: "NEW_POST_STARTED", phaseId })
      }
      else {
        console.warn("No phase found for the active post")
      }
    }
    else {
      validateForm()
    }
  }

  return {
    // State
    isLoading,

    // Form
    formData,
    formErrors,
    isValid,
    isDirty,
    isSubmitting,
    formProcess,

    // Actions
    handleEvent,
    startNewPostOrTemplate,
  }
})
