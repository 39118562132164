<template>
  <PageLayout :outside-padding="false" :form-page="true">
    <template #header>
      <div class="px-4">
        <TopNav
          :title="$t('onboarding.logo_1')"
          :show-additiol-options="false"
          type="edit"
          :devider="false"
          :saving="isLoading"
          @back="$router.back()"
        />
      </div>
    </template>
    <template #default>
      <Logo
        :button-title="$t('shared.actions.save')"
        :secondary-button-title="$t('cancel')"
        @secondry-clicked="$router.go(-1)"
        @loading="(loading) => isLoading = loading"
      />
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
const colorPreview = ref<HTMLDivElement | null>(null)
const isLoading = ref(false)

onMounted(async () => {
  await nextTick()
  if (colorPreview.value) {
    colorPreview.value.style.height = "640px"
  }
})
</script>
