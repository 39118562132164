<template>
  <div class="flex my-4">
    <ImageUpload
      :has-photos="hasPhotos"
      @capture-complete="handleImageCapture"
    />
    <ImageGallery
      :media-items="mediaItems"
      :wrap-photos="false"
      :allow-delete="true"
      :edit-mode="true"
      class="overflow-x-auto"
      @media-click="handleMediaClick"
      @delete-image="handleImageDelete"
    />
  </div>
</template>

<script setup lang="ts">
import type { MediaItem } from "@/types/models/PostTypes"

const { activePost } = storeToRefs(usePostModel())
const { handleEvent } = usePostController()
const imageStore = useImageStore()
const deleteStore = useDeleteStore()

const hasPhotos = computed(() => (activePost.value?.pictures?.length ?? 0) > 0)
const mediaItems = computed<MediaItem[]>(() =>
  activePost.value?.pictures?.map(item => ({
    id: item.directus_files_id.id,
    type: item.type,
  })) ?? [],
)

async function handleImageCapture(file: File) {
  handleEvent({ type: "MANAGE_PHOTOS" })
  await imageStore.handleImage(file)
}

async function handleImageDelete(id: string) {
  if (!activePost.value) {
    return
  }

  const images = activePost.value.pictures
  const imageIdToDelete = images?.find(p => p.directus_files_id.id === id)?.id ?? ""

  if (!imageIdToDelete) {
    console.error("No image id found on post for file with id ", id)
    return
  }

  deleteStore.setItemToDelete(
    imageIdToDelete,
    "posts_files",
  )

  // Watch for deletion confirmation
  watch(
    () => deleteStore.itemDeleted,
    async (deleted) => {
      if (deleted && deleteStore.itemToDelete?.id === imageIdToDelete) {
        if (!activePost.value) {
          return
        }
        deleteStore.clearItemToDelete()
        deleteStore.itemDeleted = false
      }
    },
    { immediate: true },
  )
}

function handleMediaClick(id: string) {
  // For now, just pass through the click event
  // In the future, we might want to handle videos differently than images
  // For example, opening a video player modal for videos
  console.info("Media clicked:", id)
}
</script>
