<template>
  <p class="mb-2 text-label-bold-1">
    {{ $t("onboarding.logo_2") }}
  </p>
  <div
    class="mb-6 flex items-center rounded-xl bg-2-static p-4"
    @click="handleFileSelection"
  >
    <div v-if="!selectedFileName" class="grow">
      <p class="text-label-bold-1 text-color-link-static">
        {{ $t("onboarding.logo_2") }}
      </p>
      <p class="text-label-2 text-color-label-3">
        {{ $t("onboarding.logo_3") }}
      </p>
    </div>
    <div v-if="selectedFileName" class="grow">
      <p class="text-label-2 text-color-label-1">
        {{ selectedFileName }}
      </p>
    </div>
    <div>
      <p v-if="onboardingStore.loading" class="text-label-2 text-color-link-static">
        {{ $t("onboarding.logo_3") }}
      </p>
      <IconArrowUp />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useOnboardingStore } from "@/stores/onboardingStore"
import { useTenantStore } from "@/stores/tenantSettingsStore"
import { getColorPalette } from "@/utils/companyUtils"
import { captureImage, urlToFile } from "@/utils/fileUtils"

const emit = defineEmits<{
  (e: "fileSelected", file: File, url: string): void
}>()
const tenantSettingsStore = useTenantStore()
const onboardingStore = useOnboardingStore()

const selectedFileName = ref("")
async function handleFileSelection() {
  const image = await captureImage()
  if (!image?.webPath) {
    return
  }

  const file = await urlToFile(image.webPath)
  if (!file) {
    return
  }

  selectedFileName.value = file.name
  emit("fileSelected", file, image.webPath)

  // Update logo preview and color palette
  const img = new Image()
  img.src = image.webPath
  tenantSettingsStore.logoUrl = img.src
  img.onload = () => {
    tenantSettingsStore.colorPalette = getColorPalette(img)
  }
}

onMounted(async () => {
  try {
    tenantSettingsStore.logoUrl = await tenantSettingsStore.getCompanyLogoUrl
    const img = new Image()
    img.src = tenantSettingsStore.logoUrl
    img.crossOrigin = "Anonymous"
    img.onload = () => {
      tenantSettingsStore.colorPalette = getColorPalette(img)
    }
  }
  catch (error) {
    console.error(error)
  }
})
</script>
