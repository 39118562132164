<template>
  <div
    v-if="employee?.status === 'invited' || employee?.status === 'inactive'"
    class="p-4 my-4 bg-center bg-no-repeat bg-cover shadow-xl rounded-xl bg-welcome-gradient"
  >
    <h3 class="mb-2 font-semibold text-white text-heading-3">
      {{ statusTitle }}
    </h3>
    <p class="mb-4 text-white text-label-2">
      {{ statusDescription }}
    </p>
    <Button
      v-if="employee?.status === 'invited'"
      :disabled="!isProfileComplete || isAccepting"
      :loading="isAccepting"
      @click="acceptInvite"
    >
      {{ $t("employees.accept_invite") }}
    </Button>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/localization/i18n"
import { useDataStore } from "@/stores/dataStore"
import { useEmployeeController } from "@/stores/employeeController"
import { useGlobalStore } from "@/stores/globalStore"
import { createToaster } from "@meforma/vue-toaster"

const globalStore = useGlobalStore()
const employeeController = useEmployeeController()
const dataStore = useDataStore()
const toaster = createToaster({ position: "bottom" })

const isAccepting = ref(false)

const employee = computed(() => {
  const employeeId = globalStore.employee?.id
  return employeeId ? dataStore.employeesById[employeeId] : undefined
})

const isProfileComplete = computed(() => {
  if (!globalStore.tenant || !globalStore.user) {
    return false
  }
  const user = globalStore.user
  return !!(user.first_name && user.last_name && user.email && user.phone_number)
})

const statusTitle = computed(() => {
  if (employee.value?.status === "invited") {
    return t("employees.invite_pending")
  }
  else if (employee.value?.status === "inactive") {
    return t("employees.account_deactivated")
  }
  return ""
})

const statusDescription = computed(() => {
  if (employee.value?.status === "invited") {
    return t("employees.invite_description", { companyName: globalStore.tenant?.name })
  }
  else if (!employee.value || employee.value?.status === "inactive") {
    return t("employees.deactivated_description")
  }
  return ""
})

async function acceptInvite() {
  if (!isProfileComplete.value || !employee.value) {
    return
  }

  isAccepting.value = true
  try {
    employeeController.setEditing(employee.value)
    await employeeController.activateEmployee()
  }
  catch (error) {
    console.error("Error accepting invite:", error)
    toaster.error(t("employees.invite_accepted_error"))
  }
  finally {
    isAccepting.value = false
  }
}
</script>
