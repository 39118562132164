<template>
  <div class="flex items-center">
    <div class="grow">
      <TopNav
        :type="formProcess === 'creating' ? 'new' : 'edit'"
        :title="title"
        :saving="isSubmitting"
        hide-save-button
        @exit="handleEvent({ type: 'CLOSE_POST' })"
        @back="handleEvent({ type: 'CLOSE_POST' })"
      >
        <template #options>
          <PostOptions />
        </template>
      </TopNav>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

provide<boolean>("editing", true)
const { isSubmitting, formData, formProcess } = storeToRefs(usePostController())
const { handleEvent } = usePostController()

const title = computed(() => {
  // Use the formProcess to determine if we're creating a new post
  const isNewPost = formProcess.value === 'creating'
  
  if (formData.value?.is_template) {
    return isNewPost ? t("templates.new_template") : t("edit_as_template")
  } else {
    return isNewPost ? t("quote.new_post") : t("edit_post")
  }
})
</script>
