import { TinyColor } from "@ctrl/tinycolor"

export function getSecondaryColor(primary: string): string {
  console.log("🚀 - file: color.ts:5 - primary:", primary)
  const color = new TinyColor(primary)
  const hsv = color.toHsv()
  hsv.s = hsv.s * 100 >= 15 && hsv.v * 100 > 15 ? 15 : 0 / 100
  hsv.v = 95 / 100
  return new TinyColor(hsv).toHexString()
}
