<template>
  <div v-if="formData" class="bg-1-static h-[85dvh] desktop:h-auto">
    <PostHeaderMobile />
    <div class="pt-4 post-card">
      <GrammarCheckWrapper v-model="formData.name" field-id="post-title">
        <TextArea
          id="post-title"
          v-model="formData.name"
          :name="$t('post_labels.title')"
          class="mb-4"
          :rows="1"
          :error="formErrors.name"
          @blur="() => handleEvent({ type: 'AUTO_SAVE_POST' })"
        />
      </GrammarCheckWrapper>
      <GrammarCheckWrapper
        v-model="formData.description"
        field-id="post-description"
        :available-options="['grammar', 'clarity', 'formal', 'structure']"
      >
        <div class="relative">
          <TextArea
            id="post-description"
            v-model="formData.description"
            :name="$t('post_labels.description')"
            :rows="4"
            :error="formErrors.description"
            @blur="() => handleEvent({ type: 'AUTO_SAVE_POST' })"
          />
          <Bob
            class="absolute top-0 right-3"
            :context="{
              title: formData?.name,
              field: 'quote_line_item_description',
              language: $i18n.locale,
            }"
            @done="updateDescription"
            @streaming="updateDescriptionDisplayText"
          />
        </div>
      </GrammarCheckWrapper>
      <div v-auto-animate>
        <PostPhotos v-if="formProcess === 'editing'" />
        <div v-else class="w-full h-16" />
      </div>
      <div class="flex mb-4 mt-4">
        <TextFieldVal
          :model-value="formData.qty"
          type="number"
          rules="decimal"
          text-size="normal"
          :label="$t('post_labels.quantity')"
          name="qty"
          class="w-1/4"
          :select-content="true"
          :error-message="formErrors.qty"
          @update:model-value="formData.qty = $event.toString()"
          @blur="() => handleEvent({ type: 'AUTO_SAVE_POST' })"
        />

        <SelectBox
          translation-path="units"
          class="w-1/4 mx-2"
          :selected-value="selectedUnit"
          :label="$t('post_labels.unit')"
          :error="formErrors.unit"
          @select="updateUnit"
        />

        <TextFieldVal
          :model-value="formData.unit_price"
          text-size="normal"
          type="number"
          rules="decimal"
          class="w-2/4"
          :label="`${$t('post_labels.unit_price')} ${$t(`units.${selectedUnit}`)}`"
          name="unit_price"
          :select-content="true"
          :suffix="countryInfo?.currencyCode"
          :error-message="formErrors.unit_price"
          @update:model-value="formData.unit_price = $event.toString()"
          @blur="() => handleEvent({ type: 'AUTO_SAVE_POST' })"
        />
      </div>
      <div class="flex justify-between w-full">
        <p class="text-label-1 text-color-label-2">
          {{ $t("quote.total_excl_tax") }}
        </p>
        <p class="mr-3 text-label-1 text-color-label-1">
          {{
            formatCurrency(Number(formData.unit_price) * Number(formData.qty))
          }}
        </p>
      </div>
    </div>
    <div class="w-full p-4">
      <Button
        icon="plus"
        type="secondary"
        class="w-full mt-4 mb-3"
        size="large"
        @click="startNewPostOrTemplate"
      >
        {{ $t("quote.save_and_new_post") }}
      </Button>
      <Button class="w-full" size="large" type="primary" @click="handleEvent({ type: 'SAVE_POST' })">
        {{ $t("shared.actions.save") }}
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
const postController = usePostController()
const { handleEvent, startNewPostOrTemplate } = postController
const { formData, formErrors, formProcess } = storeToRefs(postController)

const globalStore = useGlobalStore()
provide<boolean>("editing", true)

// No need for a separate validateField function as validation is handled by the controller

const countryInfo = computed(() => {
  if (!globalStore.tenant) {
    return undefined
  }
  return getCountriesConfig().map.get(globalStore.tenant.country_code)
})

const selectedUnit = ref(formData.value?.unit || "pcs")

function updateUnit(unit: string) {
  if (!formData.value) {
    return
  }
  selectedUnit.value = unit
  formData.value.unit = unit
  handleEvent({ type: "AUTO_SAVE_POST" })
}

function updateDescriptionDisplayText(description: string) {
  if (!formData.value) {
    return
  }
  formData.value.description = description
}

function updateDescription(description: string) {
  if (!formData.value) {
    return
  }
  formData.value.description = description
  handleEvent({ type: "AUTO_SAVE_POST" })
}

// Use startNewPostOrTemplate from the controller
</script>
