import type { QuoteStatuses } from "@/types"
import { auditFieldsSchema, currencySchema, idSchema, paymentPlanSchema } from "@/types"
import { z } from "zod"

export const tenantFilesSchema = z.object({
  id: idSchema,
  directus_files_id: idSchema,
  tenants_id: idSchema,
  tenant: idSchema,
  ...auditFieldsSchema.shape,
})

export const tenantSchema = z.object({
  id: idSchema,
  name: z.string(),
  email: z.string(),
  phone_number: z.string(),
  street_address: z.string(),
  zip_code: z.string(),
  city: z.string(),
  country_code: z.string(),
  company_registration_number: z.string(),
  invoice_due_in_days: z.number(),
  quote_expire_in_days: z.number(),
  visible_vat: z.boolean(),
  visible_post_description: z.boolean(),
  visible_post_price: z.boolean(),
  visible_post_qty: z.boolean(),
  invoice_color: z.string(),
  is_production: z.boolean(),
  subscription_status: z.string(),
  subscription_id: z.string(),
  subscription_users: z.number(),
  subscription_email: z.string(),
  subscription_trial_expire_at: z.string(),
  subscription_limit_access: z.boolean(),
  terms: z.string().nullable(),
  note: z.string().nullable(),
  payment_plan: z.array(paymentPlanSchema).optional(),
  logo: idSchema.optional(),
  certifications: z.array(z.lazy(() => tenantFilesSchema)),
  type: z.string(),
  currency: currencySchema,
  vat_registered: z.boolean(),
  metrics: z.any(), // TenantMetrics type needs to be defined
  ...auditFieldsSchema.shape,
  $countrySetting: z.any().optional(), // CountrySetting type needs to be defined
})

export type TenantTypes = "prod" | "developer" | "test-onboarding"

export interface TenantMetrics {
  projects: {
    total: {
      count: number
      subtotal_amount: number
      invoiced_amount: number
    }
    by_status: {
      [K in QuoteStatuses]: {
        count: number
        subtotal_amount: number
      }
    }
  }
}

export type SubscriptionStatus =
  | "null"
  | "new_user_trial"
  | "no_subscription"
  | "stripe_trialing"
  | "stripe_active"
  | "stripe_incomplete"
  | "stripe_incomplete_expired"
  | "stripe_past_due"
  | "stripe_canceled"
  | "stripe_unpaid"
  | "stripe_paused"
  | "manual_invoice"

export type Tenant = z.infer<typeof tenantSchema>
export type TenantFiles = z.infer<typeof tenantFilesSchema>
