<template>
  <BottomActionSlot v-if="formData">
    <div class="flex justify-between w-full mb-4">
      <p class="text-label-1 text-color-label-2">
        {{ $t("quote.total_excl_tax") }}
      </p>
      <p class="text-label-1 text-color-label-1">
        {{ formatCurrency(calculateGrandTotal(activeProject)) }}
      </p>
    </div>

    <div class="grid grid-cols-3 gap-3">
      <div class="flex flex-col justify-center">
        <QuoteStatus
          :current-status="formData.quote_status"
          @change-status="updateQuoteStatus"
        />
      </div>

      <VideoRecorder
        v-if="isMobile && (user?.tag?.includes('beta-user') || user?.tag?.includes('developer'))"
        @video-processed="handleVideoProcessed"
      />
      <!-- Empty div for desktop to maintain grid layout -->
      <div v-else />

      <div class="flex flex-col justify-center">
        <Button
          type="secondary"
          @click="goToQuoteSettings"
        >
          <div class="flex items-center gap-2 pl-2">
            <p> {{ $t("quote.continue") }}</p>
            <IconArrowForward class="text-primary" />
          </div>
        </Button>
      </div>
    </div>
  </BottomActionSlot>
</template>

<script setup lang="ts">
import type { ProcessedVideoData, QuoteStatuses } from "@/types"
import VideoRecorder from "@/components/shared/VideoRecorder/index.vue"
import router from "@/router"
import { formatCurrency } from "@/utils/companyUtils"
import { storeToRefs } from "pinia"

const projectController = useProjectController()
const postController = usePostController()
const { formData } = storeToRefs(projectController)
const { activeProject } = storeToRefs(useProjectModel())
const { user } = storeToRefs(useGlobalStore())

// Check if we're on a mobile device
const isMobile = inject<Ref<boolean>>("isPhone", ref(false))

async function updateQuoteStatus(status: QuoteStatuses) {
  if (!formData.value) {
    return
  }
  formData.value.quote_status = status
  await projectController.handlePartialUpdate(["quote_status"])
}

async function goToQuoteSettings() {
  await router.push({
    name: "quote.settings",
    params: {
      quoteId: activeProject?.value?.id,
    },
  })
}

async function handleVideoProcessed(result: ProcessedVideoData) {
  // Calculate file size in MB with 2 decimal places
  const fileSizeInMB = result.file ? (result.file.size / (1024 * 1024)).toFixed(2) : 0

  // Log the input parameters
  console.info("Video processed with the following parameters:", {
    fileName: result.file?.name,
    fileType: result.file?.type,
    fileSize: `${fileSizeInMB} MB`,
    extractedText: result.text,
  })
  // Create a new post from the video
  if (!result.file) {
    console.error("Missing video file")
    return
  }

  postController.handleEvent({
    type: "NEW_POST_FROM_VIDEO",
    videoFile: result.file,
    extractedText: result.text,
  })
}
</script>
