import type { Languages } from "@/types/models/SharedModelTypes"
import { languageSchema } from "@/types/models/SharedModelTypes"
import Danish from "@/localization/da-DK/master.json"
import German from "@/localization/de-DE/master.json"
import English from "@/localization/en-US/master.json"
import Finish from "@/localization/fi-FI/master.json"
import French from "@/localization/fr-FR/master.json"
import Norwegian from "@/localization/no-NO/master.json"
import { getLanguageBasedOnIP } from "@/utils/getLanguageBasedOnIp"
import { email, numeric, required } from "@vee-validate/rules"
import { configure, defineRule } from "vee-validate"
import { createI18n } from "vue-i18n"
import { z } from "zod"

// Zod error map type
type ZodErrorMap = Parameters<typeof z.setErrorMap>[0]

// Map for transforming legacy language codes to full locale codes
// TODO: Remove this legacy mapping once all clients have updated (by Q3 2025)

const legacyLocaleMap: Record<string, Languages> = {
  nb: "no-NO",
  en: "en-US",
  fr: "fr-FR",
  de: "de-DE",
  fi: "fi-FI",
  da: "da-DK",
}

const messages = {
  "no-NO": Norwegian,
  "en-US": English,
  "fr-FR": French,
  "de-DE": German,
  "fi-FI": Finish,
  "da-DK": Danish,
}

// Set en default language because en because internet speed is so good
// the automaticat detection will be fast enough compared to more
// 3rd world countries
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: "no-NO",
  fallbackLocale: "no-NO",
  silentFallbackWarn: true,
  messages,
})

const t = i18n.global.t

// Set up validation rules
defineRule("required", required)
defineRule("email", email)
defineRule("numeric", numeric)
defineRule("decimal", (value: number) => {
  if (isNaN(Number(value))) {
    return false
  }
  return true
})

configure({
  generateMessage: (ctx) => {
    // console.log("🚀 - file: i18n.ts - line 37 - ctx", ctx)
    const key = `validations.${ctx.rule?.name}`
    const message = i18n.global.t(key, { property: ctx.field })
    if (!message) {
      console.warn(`[vee-validate] Missing message for rule "${ctx.rule?.name}"`)
      return ""
    }

    return message
  },
})

// Asynchronously update the language
async function updateLanguage() {
  // First check if there's a language parameter in the URL
  const params = new URLSearchParams(window.location.search)
  const urlLanguage = params.get("language")
  
  // If URL has a language parameter, use it
  if (urlLanguage) {
    console.log("Language specified in URL:", urlLanguage)
    
    // Try to parse the language using schema
    const parsedLanguage = languageSchema.safeParse(urlLanguage)
    if (parsedLanguage.success) {
      i18n.global.locale.value = parsedLanguage.data
      return
    }
    
    // TODO: Remove this legacy locale mapping once all clients have updated (by Q3 2025)
    // Try legacy locale mapping
    const legacyLocale = legacyLocaleMap[urlLanguage]
    if (legacyLocale) {
      i18n.global.locale.value = legacyLocale
      return
    }
    
    console.warn("Invalid language code in URL, falling back to stored language")
  }
  
  // If no valid URL parameter, proceed with normal flow
  let langFromLocalStorage = window.localStorage.getItem("tredco-app-language") as Languages | null
  if (langFromLocalStorage === null) {
    langFromLocalStorage = (await getLanguageBasedOnIP()).language
    window.localStorage.setItem("tredco-app-language", langFromLocalStorage)
  }

  // Transform legacy language codes if found in localStorage
  const transformedLang = legacyLocaleMap[langFromLocalStorage] || langFromLocalStorage
  i18n.global.locale.value = transformedLang
  // Update localStorage with the new format if it was a legacy code
  if (transformedLang !== langFromLocalStorage) {
    window.localStorage.setItem("tredco-app-language", transformedLang)
  }
}

updateLanguage()

// Configure Zod error messages
const zodErrorMap: ZodErrorMap = (issue, ctx) => {
  let message: string

  switch (issue.code) {
    case z.ZodIssueCode.invalid_type:
      if (issue.received === "undefined" || issue.received === "null") {
        message = t("shared.validations.required")
      }
      else {
        message = t("shared.validations.invalid_type", { expected: issue.expected, received: issue.received })
      }
      break
    case z.ZodIssueCode.too_small:
      message = issue.type === "string"
        ? t("shared.validations.min_length", { minimum: issue.minimum })
        : t("shared.validations.min_value", { minimum: issue.minimum })
      break
    case z.ZodIssueCode.too_big:
      message = issue.type === "string"
        ? t("shared.validations.max_length", { maximum: issue.maximum })
        : t("shared.validations.max_value", { maximum: issue.maximum })
      break
    case z.ZodIssueCode.invalid_string:
      if (issue.validation === "email") {
        message = t("shared.validations.email")
      }
      else if (issue.validation === "url") {
        message = t("shared.validations.url")
      }
      else {
        message = t("shared.validations.invalid_string")
      }
      break
    default:
      message = ctx.defaultError
  }

  return { message }
}

z.setErrorMap(zodErrorMap)

export { i18n, legacyLocaleMap, t }
