<template>
  <HeaderOnboarding>
    <div class="mb-6 flex flex-col">
      <h2 class="text-heading-3 text-color-label-1">
        {{ $t("onboarding.account_exists") }}
      </h2>
      <div class="text-label-2 text-color-label-1">
        <p v-if="globalStore.user" class="mt-2">
          {{ $t("onboarding.account_exists_message", [globalStore.user.email]) }}
        </p>
      </div>
    </div>
    <div class="text-label-bold-1 text-color-label-1">
      <p class="my-2">
        {{ $t("onboarding.account_exists_update-account") }}
      </p>
    </div>
    <LimitedOptions
      :options="options"
      type="navigation"
      @selected-id="handleSelectedId"
    />
    <Button
      id="submit-code"
      class="mt-8 w-full"
      :loading="onboardingController.loading"
      size="large"
      submit
      @click="addNewAccountToUser"
    >
      {{ $t("onboarding.account_exists_add_new_account_to_this_user") }}
    </Button>
    <Button
      id="submit-code"
      class="mt-3 w-full"
      type="secondary"
      size="large"
      submit
      @click="() => $router.push({ name: 'quotes' })"
    >
      {{ $t("onboarding.account_exists_back_to_login") }}
    </Button>
  </HeaderOnboarding>
</template>

<script setup lang="ts">
import type { Toaster } from "@/types/UiTypes"
import { useOnboardingController } from "@/stores/onboardingController"

const globalStore = useGlobalStore()
const onboardingController = useOnboardingController()
const dataStore = useDataStore()
const toast = inject<Toaster>("toast") as Toaster
const options = ref<{ label: string, id: string, selected: boolean }[]>([])

watchEffect(() => {
  options.value = []
  for (const tenant of Object.values(dataStore.tenantsById)) {
    options.value.push({
      label: tenant.name,
      id: tenant.id,
      selected: false,
    })
  }
})

async function addNewAccountToUser() {
  onboardingController.handleEvent({ type: "CREATE_NEW_TENANT" })
  if (onboardingController.error) {
    toast.error("Something went wrong, please try again later.")
  }
}

function handleSelectedId(id: string) {
  onboardingController.handleEvent({ type: "SELECT_EXISTING_TENANT", tenantId: id })
}
</script>
