<template>
  <div v-if="phase" class="h-0 overflow-clip">
    <PDFPage v-bind="{ colors, tenant, project }">
      <div ref="insidePage" />
      <SharedPDFHeader
        v-bind="{ colors, tenant }"
        @ready="measurements.header = $event"
      />
      <QuotePDFPhaseDescriptor
        v-bind="{ project, phaseIndex }"
        @ready="measurements.phaseDescriptor = $event"
      />
      <QuotePDFItemsTable
        :content="{
          posts: postItems,
          nextPage: true,
          previousPage: true,
          total: true,
        }"
        v-bind="{ project }"
        @ready="setMeasurementTable"
      />
      <QuotePDFItemsSummary
        v-bind="{ colors, project, tenant }"
        @ready="measurements.summary = $event"
      />
    </PDFPage>
  </div>
</template>

<script setup lang="ts">
import type {
  Color,
  HeightData,
  Measurements,
  PdfProject,
  PdfTenant,
} from "@/pdf-generator/types"

import type { Post } from "@/types"

import { computed, defineEmits, defineProps, ref, watch } from "vue"
import SharedPDFHeader from "../../base/PDFHeader.vue"
import PDFPage from "../../base/PDFPage.vue"
import QuotePDFItemsSummary from "../items/QuotePDFItemsSummary.vue"
import QuotePDFItemsTable from "../items/QuotePDFItemsTable.vue"
import QuotePDFPhaseDescriptor from "./QuotePDFPhaseDescriptor.vue"

const props = defineProps<{
  colors: Color
  tenant: PdfTenant
  project: PdfProject
  phaseIndex: number
}>()

const emit = defineEmits<{
  ready: [measurements: Measurements]
}>()

const phase = computed(() => {
  return props.project.phases[props.phaseIndex]
})

const postItems = computed(() => {
  return ((phase.value.posts as Post[]) || []).map((p, i) => {
    return { index: i + 1, value: p }
  })
})

const measurements = ref<Partial<Measurements>>({})
function setMeasurementTable(measurement: HeightData) {
  measurements.value.table = measurement
}

const insidePage = ref<HTMLElement | null>(null)

watch(
  () => measurements.value,
  (newMeasurements) => {
    if (
      !newMeasurements.header
      || !newMeasurements.phaseDescriptor
      || !newMeasurements.summary
      || !newMeasurements.table
      || !insidePage.value
    ) {
      return
    }
    const pageSize = insidePage.value?.parentElement?.clientHeight ?? 1000
    newMeasurements.page = pageSize

    emit("ready", newMeasurements as Measurements)
  },
  { deep: true },
)
</script>
