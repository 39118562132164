import type { PostManagementProcess, PostStateTransitions } from "@/types"
import { sortPostsByOrder } from "@/utils/postUtils"
import { defineStore } from "pinia"
import { computed, ref } from "vue"

export const usePostModel = defineStore("postModel", () => {
  const dataStore = useDataStore()

  // State
  const postProcess = ref<PostManagementProcess>("idle")
  const activePostId = ref<string | null>(null)
  // Template selection moved to project model
  const activePost = computed(() => {
    if (!activePostId.value) {
      return null
    }
    const postFromProject = dataStore.postsById[activePostId.value]
    if (postFromProject) {
      return postFromProject
    }
    return dataStore.postTemplatesById[activePostId.value]
  })
  const phase = computed(() => {
    const phases = Object.values(dataStore.phasesById)
    if (!phases.length) {
      return null
    }
    return phases[0]
  })

  // Process Management
  const stateTransitions: PostStateTransitions = {
    idle: {
      on: {
        NEW_POST_STARTED: [{ target: "editing" }],
        NEW_POST_FROM_VIDEO: [{ target: "idle" }],
        EDIT_POST: [{ target: "editing" }],
      },
    },
    editing: {
      on: {
        SAVE_POST: [{ target: "idle", cond: context => context.upsertedSuccessfully }, { target: "editing" }],
        AUTO_SAVE_POST: [{ target: "editing" }],
        MANAGE_PHOTOS: [{ target: "photo" }],
        CLOSE_POST: [{ target: "idle" }],
        CONVERT_TO_TEMPLATE: [{ target: "editing" }],
      },
    },
    photo: {
      on: {
        PHOTOS_UPLOADED: [{ target: "editing" }],
        PHOTO_REMOVED: [{ target: "editing" }],
        PHOTO_CANCELLED: [{ target: "editing" }],
        CLOSE_POST: [{ target: "idle" }],
      },
    },
  }

  // Getters
  const sortedPosts = computed(() => sortPostsByOrder(Object.values(dataStore.postsById)))
  const nextSortNumber = computed(() => {
    return (sortedPosts.value[sortedPosts.value.length - 1]?.sort ?? 0) + 1 || 1
  })
  const sortedPostTemplates = computed(() => sortPostsByOrder(Object.values(dataStore.postTemplatesById)))
  const nextSortNumberForTemplates = computed(() => {
    return (sortedPostTemplates.value[sortedPostTemplates.value.length - 1]?.sort ?? 0) + 1 || 1
  })

  // Template selection functions moved to project model

  return {
    // State
    activePost,
    activePostId,
    postProcess,

    // Process Management
    stateTransitions,

    // Getters
    phase,
    sortedPosts,
    sortedPostTemplates,
    nextSortNumber,
    nextSortNumberForTemplates,
  }
})
