import { z } from "zod"

export const currencySchema = z.enum(["NOK", "ZAR", "CAD", "EUR"])
export type Currency = z.infer<typeof currencySchema>

export const languageSchema = z.enum(["en-US", "no-NO", "fr-FR", "de-DE", "fi-FI", "da-DK"])
export type Languages = z.infer<typeof languageSchema>

export interface CommonFields {
  status?: "draft" | "published" | "archived"
  tenant?: string
}

// Basic schemas
export const idSchema = z.string()
export const dateSchema = z.date()

// Reusable schemas
export const auditFieldsSchema = z.object({
  date_created: dateSchema,
  date_updated: dateSchema.nullable(),
  user_created: idSchema,
  user_updated: idSchema.nullable(),
})

// Entity schemas
export const imageSchema = z.object({
  id: idSchema,
})

export const paymentPlanSchema = z.object({
  id: z.number(),
  description: z.string(),
  percentage: z.number().min(0, "Percentage cannot be negative").max(100, "Percentage cannot be more than 100"),
})
export type PaymentPlan = z.infer<typeof paymentPlanSchema>

export const pictureSchema = z.object({
  id: idSchema,
  directus_files_id: idSchema,
})

export type Image = z.infer<typeof imageSchema>
export type Picture = z.infer<typeof pictureSchema>
export type ID<T extends { id: string }> = T["id"]
