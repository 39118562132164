/**
 * Web platform video recording utilities
 * Contains functions for recording video in web browsers using MediaRecorder API
 */

/**
 * Interface for recording UI elements
 */
export interface RecordingUIElements {
  videoPreviewElement?: HTMLVideoElement
  controlsContainer?: HTMLElement
  stopButton?: HTMLElement
  cancelButton?: HTMLElement
}

/**
 * Requests camera and microphone permissions
 * @returns Promise resolving to media stream
 */
export async function requestMediaPermissions(): Promise<MediaStream> {
  try {
    return await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    })
  }
  catch (error) {
    console.error("Error accessing camera and microphone:", error)
    throw error
  }
}

/**
 * Creates a display stream from a media stream (video only)
 * @param stream Original media stream with audio and video
 * @returns New media stream with video tracks only
 */
export function createDisplayStream(stream: MediaStream): MediaStream {
  const displayStream = new MediaStream()

  // Only add video tracks to the display stream (no audio)
  stream.getVideoTracks().forEach((track) => {
    displayStream.addTrack(track)
  })

  return displayStream
}

/**
 * Sets up the video preview with the display stream
 * @param videoElement Video element to set up
 * @param displayStream Media stream for display
 */
export function setupVideoPreview(
  videoElement: HTMLVideoElement,
  displayStream: MediaStream,
): void {
  videoElement.srcObject = displayStream
}

/**
 * Initializes the MediaRecorder for web recording
 * @param stream Media stream to record
 * @returns MediaRecorder instance
 */
export function initializeMediaRecorder(stream: MediaStream): MediaRecorder {
  const mediaRecorder = new MediaRecorder(stream)
  return mediaRecorder
}

/**
 * Stops all tracks in the provided media streams
 * @param streams Media streams to stop
 */
export function stopMediaTracks(...streams: MediaStream[]): void {
  streams.forEach((stream) => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop())
    }
  })
}
