<template>
  <div>
    <div class="flex justify-center">
        <IconButton
          class="bg-primary"
          @click="openVideoRecorder"
        >
          <VideoCameraIcon class="text-white bg-primary rounded-full h-16 w-16 p-3" />
        </IconButton>
    </div>

    <BottomDrawerSlot
        title=" "
        :show-options="showVideoRecorder"
        @close="closeVideoRecorder"
      >
      <div class="w-full flex flex-col items-center relative">
        <RecordingUI
          v-if="isRecording"
          :is-native-platform="Capacitor.isNativePlatform()"
          @stop="stopRecording(false)"
          @video-element="handleVideoElement"
        />

        <VideoPreview
          v-else-if="videoUrl"
          :video-url="videoUrl"
          @discard="cancel"
          @use="useVideo"
          @repeat="discardVideo"
        />

        <ErrorState
          v-else-if="recordingError"
          :error="recordingError"
          @retry="retryRecording"
          @cancel="cancel"
        />
      </div>
    </BottomDrawerSlot>
  </div>
</template>

<script setup lang="ts">
import {
  cancelNativeRecording,
  createDisplayStream,
  createPreviewFrameConfig,
  hasCameraCapabilities,
  initializeMediaRecorder,
  initializeVideoRecorder,
  // Native recording functions
  isVideoRecorderAvailable,
  requestMediaPermissions,
  setupVideoPreview,
  startNativeRecording,
  stopMediaTracks,
  stopNativeRecording,
  videoUrlToFile,
} from "@/utils/video"
import { Capacitor } from "@capacitor/core"
import { nextTick, onMounted, ref } from "vue"
import { VideoCameraIcon } from "@heroicons/vue/24/solid"

import ErrorState from "./ErrorState.vue"
// Import sub-components
import RecordingUI from "./RecordingUI.vue"
import VideoPreview from "./VideoPreview.vue"

const emit = defineEmits(["videoProcessed", "cancel"])

// Maximum recording time in seconds (1 minute)
const MAX_RECORDING_TIME = 60

const showVideoRecorder = ref(false)
const isRecording = ref(false)
const videoUrl = ref<string | null>(null)
const videoFile = ref<File | null>(null)
const recordingError = ref<string | null>(null)

// Refs for UI elements
const liveVideoPreview = ref<HTMLVideoElement | null>(null)

// Recording state variables
let mediaRecorder: MediaRecorder | null = null
let recordedChunks: Blob[] = []
let mediaStream: MediaStream | null = null
let displayStream: MediaStream | null = null
let recordingTimeout: number | null = null

function openVideoRecorder() {
  if (!hasCameraCapabilities()) {
    toast.error("Camera is not available on this device")
    return
  }
  showVideoRecorder.value = true
}

function closeVideoRecorder() {
  showVideoRecorder.value = false
  stopRecording(true)
}

// Start recording automatically when component is mounted
watch(showVideoRecorder, async () => {
  if (showVideoRecorder.value) {
    await startRecording()
  }
})

// Handle the video element reference from the RecordingUI component
function handleVideoElement(element: HTMLVideoElement) {
  console.info("[VideoRecorder] Received video element from RecordingUI")
  liveVideoPreview.value = element

  // If we already have a display stream, set it up on the video element
  if (displayStream && liveVideoPreview.value) {
    setupVideoPreview(liveVideoPreview.value, displayStream)
  }
}

async function startRecording() {
  try {
    recordingError.value = null
    recordedChunks = []

    // Check if the device has camera capabilities
    if (!hasCameraCapabilities()) {
      throw new Error("Camera not available on this device")
    }

    console.info("[VideoRecorder] Starting recording on platform:", Capacitor.getPlatform())

    // Set recording state
    isRecording.value = true

    // Wait for Vue to update the DOM with our UI elements
    await nextTick()

    if (Capacitor.isNativePlatform()) {
      // Handle native recording
      try {
        if (!isVideoRecorderAvailable()) {
          throw new Error("Native video recorder not available")
        }

        console.info("[VideoRecorder] Using native recording")

        // Create preview frame configuration
        const previewFrame = createPreviewFrameConfig()

        // Initialize the video recorder
        await initializeVideoRecorder(previewFrame)

        // Start recording
        await startNativeRecording()

        console.info("[VideoRecorder] Native recording started successfully")

        // Set timeout to automatically stop recording after MAX_RECORDING_TIME
        recordingTimeout = window.setTimeout(() => {
          console.info("[VideoRecorder] Auto-stopping recording after max time")
          stopRecording(false)
        }, MAX_RECORDING_TIME * 1000)
      }
      catch (error) {
        console.error("[VideoRecorder] Error starting native recording:", error)
        throw error
      }
    }
    else {
      // Web recording implementation
      try {
        // Request camera and microphone permissions
        mediaStream = await requestMediaPermissions()

        // Create a clone of the stream for display purposes
        displayStream = createDisplayStream(mediaStream)

        // Set up the video preview if the element exists
        if (liveVideoPreview.value) {
          console.info("[VideoRecorder] Setting up video preview on existing element")
          setupVideoPreview(liveVideoPreview.value, displayStream)
        }
        else {
          console.info("[VideoRecorder] No video preview element available yet")
        }

        // Initialize MediaRecorder and start recording
        mediaRecorder = initializeMediaRecorder(mediaStream)

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            recordedChunks.push(event.data)
          }
        }

        // Start recording
        mediaRecorder.start()
        console.info("[VideoRecorder] Web recording started")

        // Note: We don't need to set a timeout here as the RecordingUI component
        // will handle auto-stopping after MAX_RECORDING_TIME
      }
      catch (error) {
        console.error("[VideoRecorder] Error starting web recording:", error)
        throw error
      }
    }
  }
  catch (error) {
    console.error("[VideoRecorder] Failed to record video:", error)
    recordingError.value = Capacitor.isNativePlatform()
      ? "Camera access failed. Please check your app permissions."
      : "Failed to record video. Please try again."
    isRecording.value = false
    cleanupRecording()
  }
  finally {
    // Ensure recording state is properly set if there was an error
    if (recordingError.value) {
      isRecording.value = false
    }
  }
}

// Stop recording (isCancel = true for cancel, false for stop)
async function stopRecording(isCancel: boolean = false) {
  console.info(`[VideoRecorder] ${isCancel ? "Canceling" : "Stopping"} recording`)

  if (!isRecording.value) {
    return
  }

  // Clear the auto-stop timeout if it exists
  if (recordingTimeout !== null) {
    clearTimeout(recordingTimeout)
    recordingTimeout = null
  }

  isRecording.value = false

  try {
    if (Capacitor.isNativePlatform()) {
      // Handle native recording stop
      if (isCancel) {
        await cancelNativeRecording()
        cleanupRecording()
        return
      }

      const result = await stopNativeRecording()

      // Fix: Check for webPath instead of videoUrl
      if (result && result.webPath) {
        console.info("[VideoRecorder] Native recording successful, video URL:", result.webPath)
        videoUrl.value = result.webPath

        try {
          // Convert the video URL to a file
          videoFile.value = await videoUrlToFile(result.webPath, "recorded-video.mp4", "video/mp4")
        }
        catch (error) {
          console.error("[VideoRecorder] Error converting video URL to file:", error)
          recordingError.value = "Failed to process the recorded video."
        }
      }
      else {
        recordingError.value = "Failed to capture video."
      }
    }
    else {
      // Handle web recording stop
      if (isCancel) {
        cleanupRecording()
        return
      }

      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        // Stop the media recorder
        mediaRecorder.stop()

        // Wait for the last data to be available
        await new Promise<void>((resolve) => {
          if (mediaRecorder) {
            mediaRecorder.onstop = () => {
              resolve()
            }
          }
          else {
            resolve()
          }
        })

        // Create a blob from the recorded chunks
        if (recordedChunks.length > 0) {
          const blob = new Blob(recordedChunks, { type: "video/webm" })
          videoUrl.value = URL.createObjectURL(blob)

          try {
            // Convert the blob to a file
            videoFile.value = new File([blob], "recorded-video.webm", { type: "video/webm" })
          }
          catch (error) {
            console.error("[VideoRecorder] Error converting blob to file:", error)
            recordingError.value = "Failed to process the recorded video."
          }
        }
        else {
          recordingError.value = "No video data was captured."
        }
      }
    }
  }
  catch (error) {
    console.error("[VideoRecorder] Error stopping recording:", error)
    recordingError.value = "Failed to stop recording. Please try again."
  }
  finally {
    // Clean up recording resources if there was an error
    if (!videoUrl.value && !isCancel) {
      cleanupRecording()
    }
  }
}

// Clean up recording resources
function cleanupRecording() {
  console.info("[VideoRecorder] Cleaning up recording resources")

  // Clear the auto-stop timeout if it exists
  if (recordingTimeout !== null) {
    clearTimeout(recordingTimeout)
    recordingTimeout = null
  }

  // Stop and clean up media tracks
  if (mediaStream) {
    stopMediaTracks(mediaStream)
    mediaStream = null
  }

  if (displayStream) {
    stopMediaTracks(displayStream)
    displayStream = null
  }

  // Reset recording state
  mediaRecorder = null
  recordedChunks = []
}

// Retry recording after an error
function retryRecording() {
  recordingError.value = null
  startRecording()
}

// Cancel the recording process
function cancel() {
  stopRecording(true)
  closeVideoRecorder()
}

// Discard the recorded video
function discardVideo() {
  console.info("[VideoRecorder] Discarding video")

  if (videoUrl.value) {
    URL.revokeObjectURL(videoUrl.value)
  }

  videoUrl.value = null
  videoFile.value = null

  // Restart recording
  startRecording()
}

// Process and use the recorded video
async function useVideo() {
  closeVideoRecorder()
  if (!videoFile.value) {
    console.error("[VideoRecorder] No video file available")
    return
  }

  emit("videoProcessed", {
    file: videoFile.value,
  })
}
</script>
