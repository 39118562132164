<template>
  <PageLayout :form-page="true">
    <template #header>
      <TopNav
        :title="$t('company-settings.certificates.title')"
        @back="$router.push({ name: 'settings' })"
      />
    </template>

    <template #default>
      <div class="mx-auto max-w-7xl space-y-8">
        <!-- Description -->
        <Info
          :text="$t('company-settings.certificates.description')"
          type="info"
          size="medium"
        />

        <!-- Certificate Gallery -->
        <div class="mt-4 rounded-lg shadow-sm">
          <div v-if="certificateMediaItems.length">
            <ImageGallery
              :media-items="certificateMediaItems"
              :wrap-photos="true"
              :allow-delete="true"
              size="large"
              class="mt-4"
              @delete-image="handleCertificateDelete"
            />
          </div>
          <Info
            v-else
            :text="$t('company-settings.certificates.no_certificates')"
            type="info"
            size="medium"
          />

          <!-- Certificate Upload -->
          <div class="mt-6">
            <ImageUpload
              :has-photos="Boolean(certificateMediaItems.length)"
              @capture-complete="handleCertificateUpload"
            />
          </div>
        </div>
      </div>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import type { MediaItem } from "@/types/models/PostTypes"
import { useGlobalStore } from "@/stores/globalStore"
import { uploadFileWithRelation } from "@/utils/fileUtils"

// Principles Applied:
// 1. Single Responsibility: Component only handles certificate management
// 2. Meaningful Names: Clear variable and function names
// 3. High Cohesion: Related functionality grouped together

const globalStore = useGlobalStore()
const { tenant } = storeToRefs(globalStore)
const deleteStore = useDeleteStore()

// Computed property to extract media items from certificates
const certificateMediaItems = computed<MediaItem[]>(() =>
  tenant.value?.certifications?.map(cert => ({
    id: cert.directus_files_id,
    type: "picture", // Assuming all certificates are images
  })) || [],
)

// Handle certificate upload
async function handleCertificateUpload(file: File) {
  try {
    // Upload file and create relationship
    await uploadFileWithRelation(
      file,
      tenant.value?.id || "",
      "tenants_files",
      "tenants_id",
      tenant.value?.id || "",
    )
  }
  catch (error) {
    console.error("Failed to upload certificate:", error)
  }
}

// Handle certificate deletion
async function handleCertificateDelete(id: string) {
  if (!tenant.value) {
    return
  }

  const certifications = tenant.value.certifications
  const certificationIdToDelete = certifications.find(c => c.directus_files_id === id)?.id ?? ""

  if (!certificationIdToDelete) {
    console.error("No certification id to delete")
    return
  }

  deleteStore.setItemToDelete(
    certificationIdToDelete,
    "tenants_files",
  )

  // Watch for deletion confirmation
  watch(
    () => deleteStore.itemDeleted,
    async (deleted) => {
      if (deleted && deleteStore.itemToDelete?.id === certificationIdToDelete) {
        // Reset delete store state
        deleteStore.clearItemToDelete()
        deleteStore.itemDeleted = false
      }
    },
    { immediate: true },
  )
}
</script>
