import { z } from "zod"

/**
 * Validates data against a Zod schema and logs any validation errors
 * @param data The data to validate
 * @param schema The Zod schema to validate against
 * @param entityType The type of entity being validated (for error logging)
 * @returns The validated data, or the original data if validation fails
 */
export function validateData<T>(
  data: T,
  schema: z.ZodSchema<T>,
  entityType: string,
): T {
  try {
    return schema.parse(data)
  }
  catch (error) {
    if (error instanceof z.ZodError) {
      const idInfo = typeof data === "object" && data !== null && "id" in data
        ? ` (ID: ${(data as Record<string, unknown>).id})`
        : ""
      console.error(
        `Validation error for ${entityType}${idInfo}:`,
        JSON.stringify(error.errors, null, 2),
      )
    }
    return data
  }
}
