<template>
  <div>
    <IconOptions class="w-10 h-10 p-2 mr-3" @click="() => (showOptions = true)" />
    <BottomDrawerSlot :show-options="showOptions" @close="() => (showOptions = false)">
      <Button
        type="secondary"
        size="large"
        class="w-full mb-10"
        @click="attemptDeleteCustomer(customerId)"
      >
        <div class="text-color-error">
          {{ t("shared.actions.delete") }}
        </div>
      </Button>
      <MobileDialog
        :sub-title="t('customers.cannot_delete_customer')"
        type="info"
        :show="showCannotDeleteInfo"
        @close="showCannotDeleteInfo = false"
      >
        <hr class="my-3">
        <div v-for="p of dataStore.getCustomerProjects(customerId)" :key="p.name">
          <RouterLink
            :to="{ name: 'quote', params: { quoteId: p.id } }"
            class="text-label-2 text-color-link-static hover:text-color-link-hover"
          >
            {{ p.name }}
          </RouterLink>
        </div>
        <hr class="my-3">
      </MobileDialog>
    </BottomDrawerSlot>
  </div>
</template>

<script setup lang="ts">
import type { Customer } from "@/types"
import { t } from "@/localization/i18n"
import { useDataStore } from "@/stores/dataStore"
import { useDeleteStore } from "@/stores/useDeleteStore"
import { useRouter } from "vue-router"

interface Props {
  customerId: string
}

const props = defineProps<Props>()
const showCannotDeleteInfo = ref(false)
const router = useRouter()
const showOptions = ref(false)

const customer = ref<Customer | undefined>(undefined)
const deleteStore = useDeleteStore()
const dataStore = useDataStore()

async function attemptDeleteCustomer(id: string) {
  customer.value = dataStore.customersById[id]
  if (dataStore.getCustomerProjects(id).length) {
    showCannotDeleteInfo.value = true
  }
  else {
    deleteStore.setItemToDelete(id, "customers")
  }

  watch(
    () => deleteStore.itemDeleted,
    async (newValue) => {
      if (newValue && deleteStore.itemToDelete?.id === id) {
        await router.push({ name: "customers" })
        deleteStore.itemDeleted = false
      }
    },
  )
}
</script>
