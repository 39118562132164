import type { Tenant, User } from "@/types"
import { config } from "@/config"
import { App as CapacitorApp } from "@capacitor/app"
import { Capacitor } from "@capacitor/core"
import { Device } from "@capacitor/device"
import posthog from "posthog-js"

let initialized = false
let currentToken: string | null = null

async function gatherPlatformInfo(tenant?: Tenant) {
  try {
    if (Capacitor.isNativePlatform()) {
      const deviceInfo = await Device.getInfo()
      const appInfo = await CapacitorApp.getInfo()

      posthog.register({
        platform: deviceInfo.platform,
        os_version: deviceInfo.osVersion,
        manufacturer: deviceInfo.manufacturer,
        model: deviceInfo.model,
        app_build: appInfo.build,
        app_type: "native",
        tenant_id: tenant?.id,
        is_production: tenant?.is_production,
      })
    }
    else {
      posthog.register({
        platform: "web",
        browser: navigator.userAgent,
        app_type: "web",
        tenant_id: tenant?.id,
        is_production: tenant?.is_production,
      })
    }
  }
  catch (error) {
    console.error("Error gathering platform info:", error)
  }
}

export function usePostHog() {
  // Function to initialize or reinitialize PostHog with a specific token
  const initializePostHog = async (token: string) => {
    if (initialized && token === currentToken) {
      return
    }

    // If already initialized with a different token, reset PostHog
    if (initialized) {
      posthog.reset()
    }

    posthog.init(token, {
      api_host: config.postHogHost,
      capture_pageview: false, // We handle this manually with router
      persistence: "localStorage",
      session_recording: {
        maskAllInputs: false,
      },
    })

    // Gather platform info once during initialization
    await gatherPlatformInfo(undefined)
    initialized = true
    currentToken = token

    // Get version information
    const versionStore = useVersionStore()
    const appVersion = versionStore.currentVersion

    // Add global event properties to include version in all events
    posthog.register({
      app_hash_version: appVersion,
      app_version: appVersion,
    })

    window.addEventListener("error", (event) => {
      posthog.capture("error", {
        message: event.message,
        stack: event.error?.stack,
        filename: event.filename,
        lineno: event.lineno,
        colno: event.colno,
      })
    })
  }

  // Function to update user identification
  const identifyUser = async (user: User | undefined, tenant: Tenant | undefined) => {
    if (!user || !tenant) {
      return
    }

    // Initialize or switch PostHog project based on user type and tenant production status
    const useDevToken = user.developer || !tenant.is_production
    const token = useDevToken ? config.postHogDevToken : config.postHogToken
    await initializePostHog(token)

    // Identify the user with their properties and tenant context
    posthog.identify(user.id, {
      email: user.email,
      name: `${user.first_name} ${user.last_name}`.trim(),
      phone_number: user.phone_number,
      developer: user.developer,
      tenant_id: tenant.id,
      tenant_name: tenant.name,
      is_production: tenant.is_production,
    })

    // Update platform info with tenant context
    await gatherPlatformInfo(tenant)
  }

  return {
    posthog,
    identifyUser,
  }
}
