import type { DirectusCollection, ISchema } from "@/types"
import type { DirectusContext, N8nWorklfow } from "@/types/ApiTypes"
import type {
  UnpackList,
} from "@directus/sdk"
import type { AxiosError } from "axios"
import { config } from "@/config"
import { directusResponseSchema } from "@/types/ApiTypes"
import { Preferences } from "@capacitor/preferences"
import {
  authentication,
  createDirectus,
  createItem,
  createItems,
  readMe,
  rest,
  updateItem,
  uploadFiles,
} from "@directus/sdk"
import { createToaster } from "@meforma/vue-toaster"

const toaster = createToaster()

interface StorageInterface {
  get: () => Promise<any>
  set: (data: any) => Promise<void>
}

class CrossPlatformStorage implements StorageInterface {
  private readonly STORAGE_KEY = "directus-data"

  async get(): Promise<any> {
    try {
      const { value } = await Preferences.get({ key: this.STORAGE_KEY })
      const auth_data = value ? JSON.parse(value) : {}
      const globalStore = useGlobalStore()
      globalStore.token = auth_data.access_token
      return auth_data
    }
    catch (error) {
      console.error("Error reading from storage:", error)
      return {}
    }
  }

  async set(data: any): Promise<void> {
    try {
      const loggingOut = localStorage.getItem("logging-out") === "true"
      if (!data.refresh_token && !loggingOut) {
        return
      }
      if (loggingOut) {
        localStorage.removeItem("logging-out")
      }
      const globalStore = useGlobalStore()
      globalStore.token = data.access_token
      await Preferences.set({
        key: this.STORAGE_KEY,
        value: JSON.stringify(data),
      })
    }
    catch (error) {
      console.error("Error writing to storage:", error)
    }
  }
}

const storage = new CrossPlatformStorage()

export const client = createDirectus<ISchema>(config.apiBaseBath)
  .with(rest())
  .with(
    authentication("json", {
      storage,
      autoRefresh: true,
      msRefreshBeforeExpires: 60 * 1000,
    }),
  )

// Utility function for retrying Directus operations
export async function withRetry<T>(
  fn: () => Promise<T>,
  retries = 3,
  isCreateOp = false,
): Promise<T> {
  try {
    return await fn()
  }
  catch (error) {
    const axiosError = error as AxiosError
    console.error(axiosError)

    // Handle 409 as a success case
    if (axiosError.response?.status === 409) {
      console.info("[AUTH] 409 Record not unique")
      return {} as T
    }

    console.info("[AUTH] will call client.refresh() from withRetry")
    await client.refresh()
    if (retries > 0) {
      if (
        isCreateOp
        && (axiosError.response?.status === 401
          || axiosError.response?.status === 403
          || axiosError.response?.status === 500)
      ) {
        await new Promise(resolve => setTimeout(resolve, 500))
        console.info("retries left: ", retries, "isCreateOp: ", isCreateOp)
        return withRetry(fn, retries - 1, isCreateOp)
      }
      else if (!isCreateOp) {
        await new Promise(resolve => setTimeout(resolve, 500))
        return withRetry(fn, retries - 1, isCreateOp)
      }
      else if (axiosError.response?.status === 400) {
        throw axiosError
      }
    }
    toaster.error(
      `
      <div class="text-left">
        <p class="mb-3 text-white">Something went wrong.</p>
        <button  class="px-4 mt-1 mb-3 py-1 bg-white hover:bg-red-200 active:bg-red-300 text-red-800 font-semibold rounded-md shadow-sm transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50">Refresh page</button>
      </div>
    `,
      {
        type: "error",
        duration: 10000,
        position: "bottom",
        dangerouslyHTML: true,
        onClick: () => {
          window.location.href = "/"
        },
      },
    )
    throw axiosError
  }
}

async function updateGeneric<T extends DirectusCollection>(directusContext: DirectusContext): Promise<T> {
  const { collection, id, data } = directusContext
  if (!collection || !id || !data) {
    throw new Error("Missing collection, id or data")
  }

  if (Array.isArray(data)) {
    throw new TypeError("No support for updating multiple items")
  }

  return withRetry(
    async () => {
      const item = data as Partial<UnpackList<T>>
      const response = await client.request(updateItem(collection, id, item))
      return response as T
    },
    3,
    true,
  )
}

async function createManyGeneric<T extends DirectusCollection>(directusContext: DirectusContext): Promise<T[]> {
  const { collection, data } = directusContext
  if (!Array.isArray(data)) {
    throw new TypeError("Data must be an array when using createManyGeneric")
  }
  const items = data as UnpackList<T>[]
  if (!items.length) {
    return []
  }

  return withRetry(
    async () => {
      const response = await client.request(createItems(collection, items))
      if (!response) {
        throw new Error("Failed to create items")
      }
      return response as T[]
    },
    3,
    true,
  )
}

async function createGeneric<T extends DirectusCollection>(directusContext: DirectusContext): Promise<T> {
  const { collection, data } = directusContext

  return withRetry(
    async () => {
      if (Array.isArray(data)) {
        throw new TypeError("Use createManyGeneric for creating multiple items")
      }

      // Handle single item using createItem
      const item = data as UnpackList<T>
      const response = await client.request(createItem(collection, item))
      return response as T
    },
    3,
    true,
  )
}

async function uploadFile(file: File, tenantId: string): Promise<string | undefined> {
  const acceptedTypes = [
    // Image types
    "image/jpeg",
    "image/png",
    "image/webp",
    // Video types
    "video/mp4",
    "video/webm",
    "video/quicktime", // .mov files
    "video/x-msvideo", // .avi files
  ]
  if (!acceptedTypes.includes(file.type)) {
    throw new Error(`Invalid file type: ${file.type}. Accepted types are: ${acceptedTypes.join(", ")}`)
  }

  const bodyFormData = new FormData()
  bodyFormData.append("tenant", tenantId)
  bodyFormData.append("file", file)

  try {
    const uploadResponse = await client.request(uploadFiles(bodyFormData))
    if (!uploadResponse?.id) {
      throw new Error("Failed to upload file")
    }
    return uploadResponse?.id
  }
  catch (error) {
    const axiosError = error as AxiosError
    console.error(axiosError)
    return undefined
  }
}

async function callN8nWorkflow(n8nWorkflow: N8nWorklfow): Promise<unknown> {
  try {
    const response = await fetch(`${config.n8nWorkflowUrl}`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${await api.client.getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...n8nWorkflow,
        token: await api.client.getToken(),
      }),
    })

    if (response.ok) {
      console.info("n8n workflow successfully called")
      return await response.json()
    }
    else {
      throw new Error("Something went wrong with calling the n8n workflow.")
    }
  }
  catch (error: any) {
    throw new Error("Something went wrong with calling the n8n workflow.", error)
  }
}

export default {
  callN8nWorkflow,
  uploadFile,
  client,
  readMe,
  updateGeneric,
  createGeneric,
  createManyGeneric,
}
