<template>
  <div class="space-y-3">
    <div v-for="(_, index) in stringsList" :key="index" class="flex items-end">
      <TextFieldVal
        v-model="stringsList[index]"
        :name="title"
        :label="title"
        rules="email"
        class="flex-grow"
        type="email"
        @blur="$emit('update:modelValue', stringsList)"
      />
      <IconRemove
        v-if="index >= minValues"
        class="m-2 text-static"
        @click="deleteItem(index)"
      />
    </div>

    <Button class="w-full" type="secondary" size="small" @click="addItem">
      <div class="flex items-center gap-x-1">
        <IconAdd />
        <p>{{ addValueText }}</p>
      </div>
    </Button>
  </div>
</template>

<script setup lang="ts">
import { useVModel } from "@vueuse/core"

interface Props {
  title: string
  modelValue: string[]
  minValues: number
  addValueText: string
}

const props = withDefaults(defineProps<Props>(), {
  title: "",
  minValues: 0,
  addValueText: "Add value",
})

const emit = defineEmits(["update:modelValue", "keyup.enter"])
const stringsList = useVModel(props, "modelValue", emit)

while (props.minValues > stringsList.value.length) {
  addItem()
}

function addItem() {
  stringsList.value.push("")
}

function deleteItem(index: number) {
  stringsList.value.splice(index, 1)
  emit("update:modelValue", stringsList.value)
}
</script>
