<template>
  <div
    class="w-full pt-20 rounded-t-3xl"
    :style="{ backgroundColor: tenantStore.selectedColor }"
  >
    <LogoPhoto :logo-url="tenantStore.logoUrl" />

    <div class="h-full">
      <div class="py-4 rounded-t-3xl bg-1-static text-label-1">
        <p class="flex items-center justify-center text-label-2">
          {{ $t("onboarding.logo_1") }}
        </p>
      </div>
      <hr>
      <div class="h-full p-6 bg-1-static pb-28">
        <LogoUpload @file-selected="handleFileSelected" />
        <LogoColorPicker />
        <div class="flex gap-4">
          <Button
            id="submit-logo"
            :loading="isLoading"
            class="w-full mt-12"
            size="large"
            @click="saveLogoAndPrimaryColor"
          >
            {{ buttonTitle }}
          </Button>
          <Button
            id="cancel-logo"
            class="w-full mt-12"
            type="secondary"
            size="large"
            @click="() => emit('secondryClicked')"
          >
            {{ secondaryButtonTitle }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DirectusContext } from "@/types/ApiTypes"
import type { Toaster } from "@/types/UiTypes"
import { useGlobalStore } from "@/stores/globalStore"
import { useTenantStore } from "@/stores/tenantSettingsStore"
import { uploadFileToServer } from "@/utils/fileUtils"

interface Props {
  buttonTitle?: string
  secondaryButtonTitle?: string
}

withDefaults(defineProps<Props>(), {
  buttonTitle: "save",
  secondaryButtonTitle: "cancel",
})

const emit = defineEmits(["updated", "secondryClicked", "loading"])
const globalStore = useGlobalStore()
const tenantStore = useTenantStore()
const selectedFile = ref<File | null>(null)
const isLoading = ref(false)
const toast = inject<Toaster>("toast") as Toaster

async function handleFileSelected(file: File) {
  selectedFile.value = file
}

async function saveLogoAndPrimaryColor(): Promise<void> {
  if (!globalStore.tenant?.id) {
    throw new Error("Tenant id not found")
  }
  if (tenantStore.selectedColor.toLocaleLowerCase() === "#163BAE".toLocaleLowerCase()) {
    tenantStore.error = true
    toast.error(t("onboarding.logo_8"))
    return
  }

  isLoading.value = true
  emit("loading", true)
  try {
    if (selectedFile.value) {
      const directusContext: DirectusContext = {
        collection: "tenants",
        id: globalStore.tenant.id,
        data: { logo: "" },
      }
      await uploadFileToServer(selectedFile.value, globalStore.tenant.id, directusContext)
    }
    await tenantStore.updateInvoiceColor(tenantStore.selectedColor)
    globalStore.tenant.invoice_color = tenantStore.selectedColor
    emit("updated")
    tenantStore.error = false
  }
  catch (error) {
    console.error("Error saving logo or color:", error)
    toast.error(t("onboarding.logo_error"))
  }
  finally {
    isLoading.value = false
    emit("loading", false)
  }
}

onMounted(async () => {
  // Ensure logo is properly initialized when the page is refreshed
  if (!tenantStore.logoUrl) {
    try {
      tenantStore.logoUrl = await tenantStore.getCompanyLogoUrl
    }
    catch (error) {
      console.error("Error fetching logo URL:", error)
    }
  }
})
</script>
