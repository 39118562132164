import type { Project } from "@/types/models/ProjectTypes"
import type { PdfPhase, PdfProject } from "../types"

/**
 * Adapts a Project to PdfProject format, ensuring type safety and complete field mapping
 */
export function adaptProjectToPdfProject(project: Project): PdfProject {
  if (!project) {
    throw new Error("Project is required")
  }

  // first create a clone of project
  const projectClone = { ...project }
  const phases = projectClone.$phases
  // delete projectClone.$phases

  const customer = projectClone.$customer
  // delete projectClone.$customer

  if (!customer || !phases) {
    throw new Error("Customer or phases is required for PDF generation")
  }

  const pdfProject: PdfProject = {
    ...projectClone,
    customer,
    phases: adaptPhases(phases),
  }
  return pdfProject
}

function adaptPhases(phases: Project["$phases"]): PdfPhase[] {
  if (!phases) {
    return []
  }

  return phases.map((phase) => {
    const posts = phase.$posts
    posts?.forEach((post) => {
      post.pictures = post.pictures ?? []
    })
    // delete phase.$posts
    return {
      ...phase,
      posts: posts ?? [],
    }
  })
}
