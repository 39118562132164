<template>
  <div
    ref="buttonRef"
    v-on-click-outside="() => (showDropdownPanel = false)"
    class="relative"
  >
    <BaseButton
      class="rounded-md"
      :variant="variant"
      :hide-border="false"
      @click="showDropdownPanel = !showDropdownPanel"
    >
      <slot />
    </BaseButton>
    <div v-auto-animate>
      <div
        v-if="showDropdownPanel"
        class="absolute z-40  max-w-[350px] w-max bg-white divide-y  rounded-lg shadow-flying mt-2" :class="[
          isRightAligned ? 'right-0' : 'left-0',
        ]"
        @click="showDropdownPanel = false"
      >
        <slot name="dropdownPanel" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ButtonVariants } from "@/types/UiTypes"
import { vOnClickOutside } from "@vueuse/components"
import { useElementBounding, useWindowSize } from "@vueuse/core"

interface Props {
  variant?: ButtonVariants
}

withDefaults(defineProps<Props>(), {
  variant: "secondary",
})

const buttonRef = ref<HTMLElement | null>(null)
const showDropdownPanel = ref<boolean>(false)
const { left, update } = useElementBounding(buttonRef, {
  windowResize: true,
  windowScroll: true,
  immediate: true,
})
const { width: windowWidth } = useWindowSize()

const isRightAligned = computed(() => {
  if (!buttonRef.value) { return false }
  const dropdownWidth = 350 // max-w-[350px] from template
  const spaceOnRight = windowWidth.value - left.value
  return spaceOnRight < dropdownWidth
})

// Watch for position changes and panel visibility
watch(
  [showDropdownPanel, () => buttonRef.value?.offsetLeft],
  async ([isOpen]) => {
    if (isOpen) {
      await nextTick()
      update()
    }
  },
  { immediate: true },
)
</script>

<style></style>
