import type { Router } from "vue-router"
import { Capacitor } from "@capacitor/core"
import { CapacitorUpdater } from "@capgo/capacitor-updater"
import axios from "axios"
import config from "../../capacitor.config"

// Types
export interface VersionBundle {
  id: string
  version: string
}

export interface VersionCheckResult {
  currentVersion: string
  newVersion: string
  updateAvailable: boolean
  downloadedBundle: VersionBundle | null
  error: string | null
}

/**
 * Determines if the app is running on a native platform
 */
export function isNativePlatform(): boolean {
  return Capacitor.isNativePlatform()
}

/**
 * Gets the current version from Capacitor
 * For native platforms, this uses the actual Capacitor version
 * For web, it uses the version from the Capacitor config
 */
export async function getCurrentCapacitorVersion(): Promise<string> {
  // For native platforms, get the current version from Capacitor
  if (isNativePlatform()) {
    const currentInfo = await CapacitorUpdater.current()
    // If the version is empty or "0.0.0", use the fallback version
    return currentInfo.bundle.version
  }

  return config.plugins?.CapacitorUpdater?.version ?? "0.0.0"
}

/**
 * Gets the latest version from version.json for web platforms
 */
export async function getLatestWebVersion(): Promise<string | null> {
  try {
    // Add a cache-busting parameter to prevent caching
    const response = await axios.get(`/version.json?t=${Date.now()}`)
    if (response.data && response.data.version) {
      return response.data.version
    }
    return null
  }
  catch (error) {
    console.error("Error fetching version.json:", error)
    return null
  }
}

/**
 * Checks for available updates using platform-specific methods.
 * For native platforms, uses Capacitor Updater to check and download updates.
 * For web platforms, compares with version from version.json.
 *
 * @param currentVersion Current version identifier
 * @param isDeveloper Whether the current user is a developer (for logging)
 */
export async function checkForUpdates(
  currentVersion: string,
  isDeveloper: boolean,
): Promise<VersionCheckResult> {
  try {
    // Get the latest version - different approach for web vs. native
    let newVersion = ""

    if (isNativePlatform()) {
      // For native platforms, use Capacitor Updater
      let latestVersion = null
      try {
        latestVersion = await CapacitorUpdater.getLatest()
        newVersion = latestVersion?.version || "0.0.0"
      }
      catch (error: unknown) {
        if (error && typeof error === "object" && "message" in error && typeof error.message === "string") {
          if (error.message === "No new version available") {
            if (isDeveloper) {
              console.info("No new version available from Capacitor:", error)
            }
            return {
              currentVersion,
              newVersion: "",
              updateAvailable: false,
              downloadedBundle: null,
              error: "",
            }
          }
        }
        else {
          if (isDeveloper) {
            console.error("Error getting latest version from Capacitor:", error)
          }
          return {
            currentVersion,
            newVersion: "",
            updateAvailable: false,
            downloadedBundle: null,
            error: "Failed to get latest version from Capacitor",
          }
        }
      }
    }
    else {
      // For web, check version.json
      const webVersion = await getLatestWebVersion()
      if (webVersion === null) {
        if (isDeveloper) {
          console.error("Failed to get latest web version from version.json")
        }
        return {
          currentVersion,
          newVersion: "",
          updateAvailable: false,
          downloadedBundle: null,
          error: "Failed to get latest web version",
        }
      }
      newVersion = webVersion
    }

    // Check if update is available by comparing versions
    const updateAvailable = newVersion !== currentVersion && newVersion !== "0.0.0"

    // If update is available and we're on a native platform, download the bundle
    let downloadedBundle: VersionBundle | null = null
    if (updateAvailable && isNativePlatform()) {
      try {
        // For native platforms, we need the URL from Capacitor Updater
        const latestVersion = await CapacitorUpdater.getLatest()

        downloadedBundle = await CapacitorUpdater.download({
          version: newVersion,
          url: latestVersion.url,
        })

        if (isDeveloper) {
          console.info(`Bundle downloaded: Version=${downloadedBundle.version}, ID=${downloadedBundle.id}`)
        }
      }
      catch (error) {
        if (isDeveloper) {
          console.error("Failed to download bundle:", error)
        }
      }
    }

    return {
      currentVersion,
      newVersion,
      updateAvailable,
      downloadedBundle,
      error: null,
    }
  }
  catch (error) {
    return {
      currentVersion,
      newVersion: "",
      updateAvailable: false,
      downloadedBundle: null,
      error: error instanceof Error ? error.message : "Failed to check version",
    }
  }
}

/**
 * Applies an update based on platform type
 */
export async function applyUpdate(
  router: Router,
  bundle: VersionBundle | null,
  isDeveloper: boolean,
  logInfo?: (message: string) => void,
  logError?: (message: string) => void,
  logSuccess?: (message: string) => void,
): Promise<boolean> {
  if (isNativePlatform()) {
    return updateMobileApp(bundle, isDeveloper, logInfo, logError, logSuccess)
  }
  else {
    return updateWebApp(router)
  }
}

/**
 * Updates the web application by reloading the page
 */
async function updateWebApp(router: Router): Promise<boolean> {
  try {
    // For web, just reload the page to get the latest version
    if (router.currentRoute.value.path === "/") {
      window.location.reload()
    }
    else {
      // If not on home page, navigate to home first then reload
      // This ensures a clean state when the app reloads
      await router.push("/")
      setTimeout(() => {
        window.location.reload()
      }, 100)
    }
    return true
  }
  catch (error) {
    console.error("Failed to update web app:", error)
    return false
  }
}

/**
 * Updates the mobile application using Capacitor Updater
 */
async function updateMobileApp(
  bundle: VersionBundle | null,
  isDeveloper: boolean,
  logInfo?: (message: string) => void,
  logError?: (message: string) => void,
  logSuccess?: (message: string) => void,
): Promise<boolean> {
  try {
    if (!bundle) {
      if (logError) {
        logError("No update bundle available")
      }
      return false
    }

    if (logInfo) {
      logInfo("Preparing to update...")
    }

    // Set the next bundle to be loaded after app restart
    await CapacitorUpdater.set({
      id: bundle.id,
    })

    if (logSuccess) {
      logSuccess("Update ready! Restarting app...")
    }

    // Delay to allow the success message to be seen
    await new Promise(resolve => setTimeout(resolve, 1500))

    // Reload the app to apply the update
    await CapacitorUpdater.reload()
    return true
  }
  catch (error) {
    if (isDeveloper) {
      console.error("Failed to update mobile app:", error)
    }
    if (logError) {
      logError(error instanceof Error
        ? `Update failed: ${error.message}`
        : "Update failed for unknown reason")
    }
    return false
  }
}
