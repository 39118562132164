import type { QuoteStatuses } from "@/types/models/ProjectTypes"
import {
  loadCustomers,
  loadEmployees,
  loadPhases,
  loadPosts,
  loadPostTemplates,
  loadProjects,
  loadReleaseNotes,
  loadTenant,
  loadTenants,
  loadUser,
} from "@/api/loadFirebaseCollections"

import { FirebaseFirestore } from "@capacitor-firebase/firestore"
import { initializeApp } from "firebase/app"

interface QueryParams {
  tenant?: never
  projects?: {
    statuses?: QuoteStatuses[]
  }
  customers?: object
  employees?: object
  tenants?: object
  user?: never
  phases?: {
    projectId: string
  }
  posts?: {
    projectId: string
  }
  postTemplates?: never
  releaseNotes?: never
}

export const useFirestoreStore = defineStore("firestore", () => {
  const firebaseConfig = {
    apiKey: "AIzaSyA8fZ7xFzVYRPREYBuYZA2rkPS-hOgaJqA",
    authDomain: "tredco-app.firebaseapp.com",
    projectId: "tredco-app",
    storageBucket: "tredco-app.appspot.com",
    messagingSenderId: "332061708869",
    appId: "1:332061708869:web:efee7b27791af12b32c92c",
    measurementId: "G-KS2QTC7T83",
  }

  async function init() {
    try {
      initializeApp(firebaseConfig)
      await setupWatchers()
      return await Promise.resolve()
    }
    catch (error: any) {
      // Handle case where Firebase is already initialized
      if (error.code === "app/duplicate-app") {
        await setupWatchers()
        return Promise.resolve()
      }
      return Promise.reject(error)
    }
  }

  const projectId: Ref<string | null> = ref(null)
  const dataStore = useDataStore()
  const globalStore = useGlobalStore()
  const subscriptions = ref<Record<string, string | null>>({
    tenant: null,
    projects: null,
    customers: null,
    employees: null,
    tenants: null,
    user: null,
    phases: null,
    posts: null,
    postTemplates: null,
    releaseNotes: null,
  })

  const loadCollection = async <T extends keyof QueryParams>(
    collection: T,
    newTenantId: string | null,
    queryParams?: QueryParams[T],
  ) => {
    if (subscriptions.value[collection]) {
      await FirebaseFirestore.removeSnapshotListener({
        callbackId: subscriptions.value[collection],
      })
    }

    if (newTenantId === null) {
      return
    }

    switch (collection) {
      case "tenant":
        subscriptions.value.tenant = await loadTenant(newTenantId)
        break
      case "projects":
        subscriptions.value.projects = await loadProjects(
          newTenantId,
          (queryParams as QueryParams["projects"])?.statuses || [
            "accepted",
            "draft",
            "pending",
          ],
        )
        break
      case "customers":
        subscriptions.value.customers = await loadCustomers(newTenantId)
        break
      case "employees":
        subscriptions.value.employees = await loadEmployees(newTenantId)
        break
      case "tenants":
        subscriptions.value.tenants = await loadTenants()
        break
      case "user":
        subscriptions.value.user = await loadUser()
        break
      case "phases":
        if (!queryParams || !("projectId" in queryParams)) {
          throw new Error("Project ID is required for loading phases")
        }
        subscriptions.value.phases = await loadPhases(newTenantId, queryParams.projectId)
        break
      case "posts":
        if (!queryParams || !("projectId" in queryParams)) {
          throw new Error("Project ID is required for loading posts")
        }
        subscriptions.value.posts = await loadPosts(newTenantId, queryParams.projectId)
        break
      case "postTemplates":
        subscriptions.value.postTemplates = await loadPostTemplates(newTenantId)
        break
      case "releaseNotes":
        subscriptions.value.releaseNotes = await loadReleaseNotes()
        break
    }
  }

  async function setupWatchers() {
    // Watch for user changes
    watch(
      () => globalStore.currentUserId,
      async (newUserId, _oldUserId) => {
        if (newUserId) {
          try {
            await loadCollection("user", "")
            await loadCollection("tenants", "")
          }
          catch (error) {
            console.error("[Firestore] Error loading user collection:", error)
          }
        }
      },
      { immediate: true },
    )

    // Watch for tenant changes
    watch(
      () => globalStore.tenant?.id,
      async (newTenantId, oldTenantId) => {
        // Skip if we don't have both tenant and user IDs
        if (!newTenantId || !globalStore.currentUserId) {
          return
        }
        // Redirect to home page because tenant ID has changed and you might be in a project
        // that is not part of the new tenant
        if (oldTenantId) {
          await router.push("/")
        }

        // Load collections whenever we have a valid tenant ID
        try {
          await Promise.all([
            loadCollection("projects", newTenantId),
            loadCollection("customers", newTenantId),
            loadCollection("employees", newTenantId),
            loadCollection("postTemplates", newTenantId),
            loadCollection("releaseNotes", newTenantId),
          ])
        }
        catch (error) {
          console.error("[Firestore] Error loading tenant collections:", error)
        }
      },
      { immediate: true },
    )
  }

  watch(
    [
      () => dataStore.currentUser?.active_tenant,
      () => projectId.value,
    ],
    async ([newTenantId, newProjectId]) => {
      if (!newProjectId || !newTenantId) {
        return
      }

      await Promise.all([
        loadCollection("phases", newTenantId, {
          projectId: newProjectId,
        }),
        loadCollection("posts", newTenantId, {
          projectId: newProjectId,
        }),
      ])
    },
    { immediate: true },
  )

  return {
    subscriptions,
    loadCollection,
    projectId,
    init,
  }
})
