<template>
  <draggable
    v-if="posts.length"
    v-model="orderedPosts"
    tag="ol"
    class="mb-40 mt-2"
    item-key="id"
    :animation="150"
    handle=".handle"
    @change="handleChange"
  >
    <template #item="{ element: post }">
      <li>
        <div class="flex items-center gap-2">
          <slot name="before-post" :post="post" />
          <div class="flex-1">
            <PostSummary
              :post="post"
              :currency="currentUser?.$tenant?.currency"
              @click="$emit('postClick', post.id)"
            />
          </div>
        </div>
      </li>
    </template>
  </draggable>
</template>

<script setup lang="ts">
import type { Post } from "@/types"
import { storeToRefs } from "pinia"
import { computed, onUnmounted, ref, watch } from "vue"
import draggable from "vuedraggable"

const props = defineProps<Props>()

defineEmits<{ (e: "postClick", postId: string): void }>()

const { currentUser } = storeToRefs(useDataStore())

interface Props {
  posts: Post[]
}

const internalPosts = ref<Post[]>([])
const shouldUpdateFromProps = ref(true)
let updateTimeoutId: number | null = null

watch(
  () => props.posts,
  (posts) => {
    if (shouldUpdateFromProps.value) {
      internalPosts.value = [...posts]
    }
  },
  { immediate: true },
)

const orderedPosts = computed({
  get: () => internalPosts.value,
  set: (newValue: Post[]) => { internalPosts.value = newValue },
})

onUnmounted(() => {
  if (updateTimeoutId !== null) {
    window.clearTimeout(updateTimeoutId)
  }
})

async function handleChange(event: any) {
  if (event.moved) {
    const reorderedIds = internalPosts.value.map(post => post.id)
    if (reorderedIds.length) {
      shouldUpdateFromProps.value = false
      await sortAndUpdatePosts(reorderedIds)
      if (updateTimeoutId !== null) {
        window.clearTimeout(updateTimeoutId)
      }
      updateTimeoutId = window.setTimeout(() => {
        shouldUpdateFromProps.value = true
        updateTimeoutId = null
      }, 1000)
    }
  }
}
</script>
