<template>
  <div v-if="currentStatus">
    <p v-if="showStatusAsLabel" class="text-label-3" :class="status[currentStatus].class">
      {{ $t(`statuses.${currentStatus}`) }}
    </p>
    <Button
      v-else
      icon="chevron"
      :size="mobile ? 'medium' : 'small'"
      type="secondary"
      :class="{ 'w-full': fullWidth }"
      :hide-border="hideBorder"
      @click="showOptions = true"
    >
      <p v-if="displayOptions === 'last-selected'" :class="status[currentStatus].class">
        {{ $t(`statuses.${currentStatus}`) }}
      </p>

      <div
        v-else-if="statuses.length"
        :class="{ 'max-w-40': mobile }"
        class="flex truncate"
      >
        <div v-for="(s, index) in statuses" :key="index">
          <p class="ml-2" :class="status[s].class">
            {{ $t(`statuses.${s}`) }}
          </p>
        </div>
      </div>
      <p v-else>
        Filter
      </p>
    </Button>

    <BottomDrawerSlot :show-options="showOptions" @close="closeOptions">
      <div v-for="(s, index) in Object.keys(status)" :key="index">
        <Button
          v-if="displayOptions === 'last-selected'"
          class="w-full mb-4"
          size="large"
          :type="
            status[s as QuoteStatuses].status === currentStatus ? 'primary' : 'secondary'
          "
          @click="setStatus(status[s as QuoteStatuses].status)"
        >
          <div class="flex">
            <p
              :class="[
                { 'text-white': status[s as QuoteStatuses].status === currentStatus },
                status[s as QuoteStatuses].class,
              ]"
            >
              {{ $t(`statuses.${status[s as QuoteStatuses].status}`) }}
            </p>
          </div>
        </Button>
        <Button
          v-else
          class="w-full mb-4"
          size="large"
          type="secondary"
          @click="setStatus(status[s as QuoteStatuses].status)"
        >
          <div class="flex items-center justify-center w-full">
            <IconCheckMark
              v-if="statuses.includes(s as QuoteStatuses)"
              class="mr-2"
              :class="[status[s as QuoteStatuses].class]"
            />
            <IconHidden
              v-else-if="status[s as QuoteStatuses].hidden"
              class="mr-2 opacity-50 text-black-blue"
            />
            <p :class="[status[s as QuoteStatuses].class]">
              {{ $t(`statuses.${status[s as QuoteStatuses].status}`) }}
            </p>
          </div>
        </Button>
      </div>
    </BottomDrawerSlot>
  </div>
</template>

<script setup lang="ts">
import type { QuoteStatuses } from "@/types/models/ProjectTypes"
import confetti from "canvas-confetti"

interface Props {
  currentStatus?: QuoteStatuses
  selectedStatuses?: QuoteStatuses[]
  showStatusAsLabel?: boolean
  hideBorder?: boolean
  fullWidth?: boolean
  displayOptions?: "last-selected" | "select-deselect"
}

const props = withDefaults(defineProps<Props>(), {
  currentStatus: undefined,
  showStatusAsLabel: false,
  hideBorder: false,
  fullWidth: true,
  displayOptions: "last-selected",
  selectedStatuses: [],
})

const emit = defineEmits(["change-status", "selected-statuses"])

interface StatusDesign {
  status: QuoteStatuses
  class: string
  hidden?: boolean
}

const status: Record<QuoteStatuses, StatusDesign> = {
  draft: { status: "draft", class: "text-color-link-static" },
  pending: { status: "pending", class: "text-color-warning" },
  accepted: { status: "accepted", class: "text-color-success" },
  done: { status: "done", class: "text-color-done", hidden: true },
  rejected: { status: "rejected", class: "text-color-error", hidden: true },
  expired: { status: "expired", class: "text-color-label-3", hidden: true },
}

const statuses = ref<QuoteStatuses[]>(props.selectedStatuses)

function setStatus(status: QuoteStatuses) {
  if (props.displayOptions === "last-selected") {
    showOptions.value = false
  }
  if (statuses.value.includes(status)) {
    statuses.value = statuses.value.filter(s => s !== status)
  }
  else {
    statuses.value = [...statuses.value, status]
  }

  emit("change-status", status)
}

function closeOptions() {
  showOptions.value = false
  emit("selected-statuses", statuses.value)
}

const showOptions = ref(false)
provide<Ref<boolean>>("showOptions", showOptions)
const mobile = inject("isPhone") as Ref<boolean>

onMounted(() => {
  watch(
    () => props.currentStatus,
    (newValue, oldValue) => {
      if (newValue === "accepted" && oldValue !== "accepted" && oldValue !== undefined) {
        triggerFireworks()
      }
    },
  )
})
function triggerFireworks() {
  const duration = 5 * 1000
  const animationEnd = Date.now() + duration
  const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }

  function randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min
  }

  const interval = setInterval(() => {
    const timeLeft = animationEnd - Date.now()

    if (timeLeft <= 0) {
      return clearInterval(interval)
    }

    const particleCount = 50 * (timeLeft / duration)
    // since particles fall down, start a bit higher than random
    confetti(
      Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.1, 0.9), y: Math.random() - 0.2 },
      }),
    )
  }, 250)
}
</script>

<style lang="scss" scoped></style>
