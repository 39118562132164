import type { Customer, Tenant } from "@/types"
import type { Currency } from "@/types/models/SharedModelTypes"
import type { TenantTypes } from "@/types/models/TenantTypes"
import api from "@/api"
import { useGlobalStore } from "@/stores/globalStore"
import seperateFullName from "@/utils/seperateFullName"
import { defineStore } from "pinia"

export const StatusValues = [
  "not-started",
  "country",
  "require-phone",
  "provide-code",
  "profile",
  "company",
]

type StatusTypes = (typeof StatusValues)[number] | "logo" | "completed"

interface OnboardingState {
  currency: Currency
  status: StatusTypes
  loading: boolean
  updatingInvoiceColor: boolean
  error: boolean
  mobileNumber: string
  logoName: string
  globalStore: ReturnType<typeof useGlobalStore>
  tenantType: TenantTypes
  tenant: Tenant
}

export const useOnboardingStore = defineStore("onboarding", {
  state: (): OnboardingState => ({
    currency: "ZAR",
    status: "not-started",
    loading: false,
    updatingInvoiceColor: false,
    mobileNumber: "",
    logoName: "",
    error: false,
    globalStore: useGlobalStore(),
    tenantType: "prod",
    tenant: {} as Tenant,
  }),
  actions: {
    async loginPhone(mobileNumber: string) {
      this.mobileNumber = mobileNumber
      this.loading = true
      const result = await api.users.sendUserSMSCode(this.mobileNumber)
      if (result) {
        this.status = "provide-code"
      }
      else {
        this.error = true
      }
      this.loading = false
    },
    async verifyCode(code: number) {
      this.loading = true
      const result = await api.users.verifyUserSMSCode({
        code: code.toString(),
        mobile: this.mobileNumber,
        createUser: true,
        tenantType: this.tenantType,
      })
      if (result.status) {
        await this.globalStore.loginEmail({
          email: result.user?.email ?? "",
          password: code.toString(),
        })
        this.status = "profile"
        this.loading = false
      }
      else {
        this.error = true
        this.loading = false
      }
    },
    async updateProfile(values: { email: string, fullName: string }) {
      this.loading = true

      try {
        await api.users.updateMe({
          email: values.email,
          first_name: seperateFullName(values.fullName).firstName,
          last_name: seperateFullName(values.fullName).lastName,
        })
      }
      catch (error) {
        console.error(error)
        this.error = true
        this.loading = false
        return
      }

      if (this.globalStore.tenant) {
        await api.users.updateMyTenant(this.globalStore.tenant.id, {
          email: values.email,
          name: "ny eier",
        })

        this.status = "company"

        await api.callN8nWorkflow({
          path: "/welcome-email",
          tenant_id: this.globalStore.tenant.id,
          first_name: seperateFullName(values.fullName).firstName,
          email: values.email,
          language: "nb",
        })
      }
      this.loading = false
    },
    async updateTenantOwner(owner: Customer) {
      this.loading = true

      const payload = owner as any as Partial<Tenant>

      payload.email = this.globalStore.tenant?.email ?? ""
      payload.phone_number = this.globalStore.user?.phone_number ?? ""
      payload.currency = this.currency
      payload.country_code = this.currency === "NOK" ? "NO" : "ZA"
      if (this.globalStore.tenant) {
        const tenantId = this.globalStore.tenant.id
        const tenant = await api.users.updateMyTenant(tenantId, {
          ...payload,
        })

        if (tenant) {
          this.status = "logo"
        }
      }
      else {
        this.error = true
      }
      this.loading = false
    },
    async updateInvoiceColor(color: string) {
      if (!this.globalStore.tenant?.id) {
        return
      }
      this.updatingInvoiceColor = true
      const tenantId = this.globalStore.tenant.id
      if (tenantId) {
        await api.users.updateMyTenant(tenantId, {
          invoice_color: color,
        })
      }
      this.updatingInvoiceColor = false
    },
    async determineStatus() {
      await this.globalStore.hydrate(false)
      if (this.globalStore.isLoggedIn) {
        this.status = "profile"
      }
    },
    resetState() {
      this.status = "not-started"
      this.loading = false
      this.error = false
    },
  },
})
