<template>
  <HeaderOnboarding>
    <p class="text-heading-3 text-color-label-1">
      {{ $t("onboarding.require_phone_1") }}
    </p>
    <p class="mt-2 text-label-2 text-color-label-1">
      {{ $t("onboarding.require_phone_2") }}
    </p>
    <form class="w-full" @submit.prevent="submitForm">
      <PhoneInput
        :focus="true"
        name="mobile"
        class="my-5"
        :placeholder="t('auth.mobile_number')"
      />
      <div>
        <Button
          id="submit-phone"
          class="w-full"
          size="large"
          :loading="onboardingController.loading"
          submit
        >
          {{ $t("onboarding.continue_button") }}
        </Button>
      </div>
    </form>
  </HeaderOnboarding>
</template>

<script setup lang="ts">
import { t } from "@/localization/i18n"
import { useOnboardingController } from "@/stores/onboardingController"
import { useForm } from "vee-validate"
import * as Yup from "yup"

const onboardingController = useOnboardingController()

const schema = Yup.object().shape({
  mobile: Yup.number().required().typeError(t("customers.phone.validation_message")),
})

const { handleSubmit } = useForm<Yup.InferType<typeof schema>>({
  validationSchema: schema,
})
const submitForm = handleSubmit((values) => {
  onboardingController.handleEvent({ type: "SUBMIT_PHONE", mobileNumber: values.mobile.toString() })
})
</script>
