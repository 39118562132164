// Shared Model Types
import {
  auditFieldsSchema,
  dateSchema,
} from "@/types/models/SharedModelTypes"
import { z } from "zod"

export const releaseNotesSchema = z.object({
  id: z.string(),
  status: z.string(),
  date_implemented: dateSchema,
  en: z.string(),
  nb: z.string(),
  type: z.string(),
  enUS: z.string(),
  noNO: z.string(),
  ...auditFieldsSchema.shape,
})

export const promptLibrarySchema = z.object({
  id: z.string(),
  status: z.string(),
  language: z.string(),
  purpose: z.string().optional(),
  prompt: z.string(),
  ...auditFieldsSchema.shape,
})

export type ReleaseNotes = z.infer<typeof releaseNotesSchema>
export type PromptLibrary = z.infer<typeof promptLibrarySchema>
