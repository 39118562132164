import type { Customer } from "@/types"
import type { DirectusContext } from "@/types/ApiTypes"
import type { BrregCompany } from "@/types/models/CustomerTypes"
import api from "@/api"
import { useDataStore } from "@/stores/dataStore"
import { useGlobalStore } from "@/stores/globalStore"
import initials from "@/types/initialValues"
import {
  createCustomerFromBrregCompany,
  filterCustomers,
  getBrregSearchUrl,
  processBrregResults,
} from "@/utils/customerUtils"
import { defineStore } from "pinia"
import { computed, ref, watchEffect } from "vue"
import { useRoute } from "vue-router"

export const useCustomerStore = defineStore("customer", () => {
  // State
  const filtered = ref<Customer[]>([])
  const customer = ref<Customer | null>(null)
  const companies = ref<BrregCompany[]>([])
  const loadingCounter = ref(0)
  const query = ref("")
  const brregCompanySelected = ref(false)
  const status = ref<"new" | "edit">("new")
  const globalStore = useGlobalStore()
  const dataStore = useDataStore()
  const route = useRoute()

  // Computed
  const customers = computed(() => {
    return Object.values(dataStore.customersById)
  })

  // Setup customer loading based on route
  watchEffect(() => {
    const customerId = route.params.id as string
    if (customerId) {
      const foundCustomer = dataStore.customersById[customerId]
      if (foundCustomer) {
        customer.value = foundCustomer
        status.value = "edit"
      }
    }
    else {
      status.value = "new"
      customer.value = structuredClone(initials.emptyCustomerValue)
      brregCompanySelected.value = false // Reset when creating a new customer
    }
  })

  // Getters
  const showBrregSearchBox = computed(() =>
    brregCompanySelected.value === false
    && customer.value?.type === "company"
    && status.value === "new"
    && globalStore.tenant?.currency === "NOK",
  )

  // Helper Functions are now moved to customerUtils.ts

  // Actions
  function search(searchQuery: string) {
    if (!customers.value.length) {
      return
    }
    filtered.value = filterCustomers(customers.value, searchQuery)
  }

  async function searchBrreg(searchQuery: string) {
    if (searchQuery.length >= 3) {
      loadingCounter.value++
      try {
        const result: BrregCompany[]
          = (await (await fetch(getBrregSearchUrl(searchQuery))).json())?._embedded?.enheter ?? []
        companies.value = processBrregResults(result)
      }
      finally {
        loadingCounter.value--
      }
    }
  }

  function handleSelect(companyInfo: BrregCompany) {
    customer.value = createCustomerFromBrregCompany(
      companyInfo,
      globalStore.tenant?.id || "",
      globalStore.user?.id || "",
      globalStore.tenant?.currency || "NOK",
    )
    brregCompanySelected.value = true
  }

  async function create(): Promise<Customer> {
    if (!customer.value) {
      throw new Error("Customer is not defined")
    }

    loadingCounter.value++
    try {
      const createdCustomer = await api.customers.createCustomer(customer.value)
      return createdCustomer
    }
    catch (error) {
      console.error("Error creating customer:", error)
      throw error
    }
    finally {
      loadingCounter.value--
    }
  }

  const directusContext = computed((): DirectusContext | undefined => {
    if (status.value === "edit" && customer.value?.id) {
      return {
        collection: "customers",
        id: customer.value.id,
      }
    }
    return undefined
  })

  return {
    // State
    customers,
    filtered,
    customer,
    companies,
    loadingCounter,
    query,
    brregCompanySelected,
    status,

    // Getters
    showBrregSearchBox,
    directusContext,

    // Actions
    search,
    searchBrreg,
    handleSelect,
    create,
  }
})
