<template>
  <div>
    <div
      class="group flex gap-3 rounded-xl bg-1-static p-4"
      :class="{ 'bg-2-hover': mode === 'quote-settings' }"
      @click="showEditDrawer = true"
    >
      <div>
        <span
          class="rounded-full bg-2-static px-3 py-2 text-caption-1 text-color-label-2"
        >{{ item.id }}</span>
      </div>
      <div class="grow space-y-4" :class="{ 'max-w-64': mode === 'quote-settings' }">
        <p
          class="text-label-2 text-color-label-3"
          :class="{
            'line-clamp-1 overflow-hidden break-words': mode === 'quote-settings',
          }"
        >
          {{ item.description }}
        </p>
        <div class="text-label-2 text-color-label-2">
          <span class="mr-2 text-color-label-1">{{ item.percentage }}%</span>
          <span v-if="mode === 'settings'">{{
            $t("quote.payment_plan.percentage_of_total")
          }}</span>
          <span v-else class="text-label-bold-2">{{ formatCurrency(amountUsed) }}</span>
        </div>
      </div>
      <div class="space-y-3">
        <!-- <Button
          size="small"
          class="w-full"
          type="secondary"
          @click="showEditDrawer = true"
          >{{ $t("shared.actions.change") }}</Button
        > -->
        <!-- <PencilIcon
          class="h-5 w-5 cursor-pointer text-color-link-static"
          :class="{ 'hidden group-hover:inline': !mobile }"
          @click="showEditDrawer = true"
        /> -->
        <IconExit
          class="cursor-pointer !text-color-error"
          :class="{ 'hidden group-hover:block': !mobile }"
          @click.stop="emit('deleted', item.id)"
        />
        <!-- <Button
          size="small"
          class="w-full"
          type="secondary"
          @click="emit('deleted', item.id)"
        >
          <span class="text-color-error">{{ $t("shared.actions.delete") }}</span>
        </Button> -->
      </div>
    </div>
    <BottomDrawerSlot
      :title="$t('quote.settings.new_payment_plan_item')"
      :show-options="showEditDrawer"
      @close="showEditDrawer = false"
    >
      <TextFieldVal
        v-model="localItem.percentage"
        name="percentage"
        type="number"
        class="mb-3"
        rules="required"
        :select-content="true"
        suffix="%"
        :label="$t('quote.settings.percentage')"
        @keyup.enter="save"
      />
      <TextArea
        v-model="localItem.description"
        rules="required"
        :name="$t('description')"
        class="mb-3"
        :label="$t('description')"
        @keyup.enter="save"
      />

      <!-- <p class="mb-5 text-label-bold-1 text-color-label-1">R 22000</p> -->
      <Button size="large" class="my-4 w-full" @click="save">
        {{
          $t("shared.actions.save")
        }}
      </Button>
    </BottomDrawerSlot>
  </div>
</template>

<script setup lang="ts">
import type { PaymentPlan } from "@/types"

interface Props {
  item: PaymentPlan
  showEditDrawerForId: number | null
  mode?: "settings" | "quote-settings"
}

const props = defineProps<Props>()
const emit = defineEmits(["update:item", "deleted"])
const showEditDrawer = ref(false)

const localItem = ref({ ...props.item })
watch(
  () => props.item,
  (newItem) => {
    localItem.value = { ...newItem }
  },
  { immediate: true },
)

watch(
  () => props.showEditDrawerForId,
  (id) => {
    if (id === props.item.id) {
      setTimeout(() => {
        showEditDrawer.value = true
      }, 5)
    }
  },
  { immediate: true },
)

function save() {
  emit("update:item", localItem.value)
  showEditDrawer.value = false
}

const amount = inject<ComputedRef<number> | number>("amount")
const total = computed(() => typeof amount === 'number' ? amount : amount?.value ?? 0)
const amountUsed = computed(() => {
  if (localItem.value.percentage === "0") {
    return 0
  }
  else {
    return total.value * (Number(localItem.value.percentage) / 100)
  }
})

const mobile = inject("isPhone") as Ref<boolean>
</script>
