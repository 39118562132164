<template>
  <div class="relative flex flex-col bg-black h-dvh">
    <div class="absolute left-0 right-0">
      <div class="flex items-center justify-around w-full py-6">
        <IconExit class="m-2 text-white " @click="handleEvent({ type: 'PHOTO_CANCELLED' })" />
        <p class="text-white">
          {{ $t('quote.photos.review_photo') }}
        </p>
        <SaveOrReplacePhoto />
      </div>
    </div>

    <DrawingControls />

    <div class="flex items-center justify-center flex-1 overflow-hidden">
      <div class="max-w-3xl max-h-full mx-auto">
        <canvas
          ref="canvasRef"
          class="max-w-full max-h-[calc(100vh-250px)] object-contain"
          :width="imageStore.stageConfig.width"
          :height="imageStore.stageConfig.height"
          @mousedown="imageStore.handleMouseDown"
          @mousemove="imageStore.handleMouseMove"
          @mouseup="imageStore.handleMouseUp"
          @mouseleave="imageStore.handleMouseUp"
          @touchstart.prevent="imageStore.handleMouseDown"
          @touchmove.prevent="imageStore.handleMouseMove"
          @touchend.prevent="imageStore.handleMouseUp"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useImageStore } from "@/stores/imageStore"
import { usePostController } from "@/stores/postController"

const imageStore = useImageStore()
const { handleEvent } = usePostController()

const showOptions = ref(false)
provide<Ref<boolean>>("showOptions", showOptions)

const canvasRef = ref<HTMLCanvasElement | null>(null)

onMounted(async () => {
  await nextTick()
  if (canvasRef.value) {
    imageStore.initCanvas(canvasRef.value)
  }
})

// Watch for image changes and reinitialize canvas if needed
watch([() => imageStore.imageSrc, () => canvasRef.value], async ([newImageSrc, newCanvasRef]) => {
  if (newCanvasRef && newImageSrc) {
    await nextTick()
    imageStore.initCanvas(newCanvasRef)
  }
}, { immediate: true })
</script>

<style scoped>
canvas {
  touch-action: none; /* Prevents default touch actions like scrolling */
}

.fixed {
  /* Ensure toolbar doesn't interfere with touch events on canvas */
  pointer-events: auto;
}
</style>
