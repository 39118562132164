<template>
  <PageLayout v-if="project && tenant">
    <template #header>
      <div class="flex items-center">
        <IconArrowBackward @click="router.back()" />
        <p class="ml-4 grow py-2 text-label-bold-1">
          {{ $t("preview") }}
        </p>

        <div class="flex flex-wrap justify-end gap-3">
          <Button
            v-if="!mobile"
            type="primary"
            class="mr-4"
            size="medium"
            @click="goToSendEmail"
          >
            {{ $t("send_via_email") }}
          </Button>
          <DownloadPdfButton
            class="mr-4"
            :project="project"
            :tenant="tenant"
            :user="user"
            :access-token="token as string"
          />
        </div>
      </div>
    </template>

    <div class="max-w-full pt-6">
      <div class="hide-scrollbar mx-auto max-h-[75dvh] overflow-y-auto">
        <ScalablePDFPageNew
          :project="adaptProjectToPdfProject(project)"
          :tenant="tenant"
          :colors="colors"
        />
      </div>
    </div>

    <template #footer-mobile>
      <Button type="primary" class="mr-2 w-full" size="large" @click="goToSendEmail">
        {{ $t("send_via_email") }}
      </Button>
    </template>
  </PageLayout>
  <AtomSpinner v-else />
</template>

<script setup lang="ts">
import type { Tenant } from "@/types"
import { getSecondaryColor } from "@/composables/color"
import { adaptProjectToPdfProject } from "@/pdf-generator/adapters/projectAdapter"

import router from "@/router"

const route = useRoute()
const { tenant, user, token } = storeToRefs(useGlobalStore())

const { activeProject: project } = storeToRefs(useProjectModel())

const quoteId = route.params.quoteId as string

const colors = ref({ primary: "#0000ff", secondary: "#8888ff", customized: false })
colors.value = calculateColor(tenant.value)

function calculateColor(tenant?: Tenant) {
  const primary = tenant?.invoice_color ?? "#000000"
  const secondary = getSecondaryColor(primary)
  const customized = primary !== "#000000"
  return { primary, secondary, customized }
}

async function goToSendEmail() {
  await router.push({
    name: "quote.send",
    params: {
      quoteId,
    },
  })
}

const mobile = inject("isPhone") as Ref<boolean>
</script>
