<template>
  <h1 class="mb-4 text-xl font-bold">
    {{ $t("auth.login") }}
  </h1>
  <form class="w-full " data-testid="login-form" @submit.prevent="submitForm">
    <div class="pb-4">
      <TextFieldVal
        data-testid="email-input"
        name="email"
        :placeholder="t('email')"
        text-size="normal"
        :bold="false"
        class="pb-4"
        type="email"
      />

      <TextFieldVal
        data-testid="password-input"
        name="password"
        :placeholder="t('auth.password')"
        type="password"
        text-size="normal"
        :bold="false"
        class="pb-2"
      />
      <MessageAlert :show="globalStore.showError" type="error" class="w-full" data-testid="login-error">
        {{ $t("auth.wrong_email_password") }}
      </MessageAlert>
    </div>

    <Button class="w-full" :loading="globalStore.loading" submit data-testid="login-submit-btn">
      {{ $t("auth.login_button") }}
    </Button>
  </form>

  <div v-if="globalStore.loginStatus === 'email'" class="mt-3">
    <router-link
      to="/auth/reset-password"
      class="text-xs text-blue-500 hover:underline"
      data-testid="reset-password-link"
    >
      {{ $t("auth.reset_password") }}
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/localization/i18n"
import { useGlobalStore } from "@/stores/globalStore"
import { useForm } from "vee-validate"
import * as Yup from "yup"

const globalStore = useGlobalStore()

const schema = Yup.object().shape({
  email: Yup.string().required().email("Epost må være en gyldig epost"),
  password: Yup.string().required("Passord er påkrevd"),
})

const { handleSubmit } = useForm<Yup.InferType<typeof schema>>({
  validationSchema: schema,
})

const router = useRouter()

const submitForm = handleSubmit(form => globalStore.loginEmail(form))

watch(
  () => globalStore.hydrated,
  async (isLoggedIn) => {
    if (isLoggedIn) {
      await router.push({ name: "quotes" })
    }
  },
  { immediate: true },
)
</script>
