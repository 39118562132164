<template>
  <div
    class="flex flex-col max-h-dvh min-h-dvh bg-static desktop:items-center"
    :class="{ 'bg-desktop': !isPhone, 'bg-static': isPhone }"
  >
    <div class="bg-static pt-safe" />
    <div class="pl-6 my-4">
      <IconTredcoFull @click="reset()" />
    </div>

    <div class=" flex grow overflow-y-auto desktop:w-[50%] max-w-2xl  desktop:justify-center desktop:rounded-3xl ">
      <div class="flex w-full flex-col rounded-t-3xl desktop:rounded-3xl  bg-1-static desktop:max-h-[80vh] ">
        <OnboardingLandingPage v-if="onboardingProcess === 'landingPage'" />
        <OnboardingCountry v-if="onboardingProcess === 'selectingCountry'" />
        <OnboardingSendMobileCode v-if="onboardingProcess === 'enteringPhone'" />
        <OnboardingProvideMobileCode v-if="onboardingProcess === 'verifyingCode'" />
        <OnboardingAccountExists v-if="onboardingProcess === 'accountExists'" />
        <OnboardingProfile v-if="onboardingProcess === 'creatingProfile'" />
        <OnboardingCompany v-if="onboardingProcess === 'enteringCompanyInfo'" />
        <OnboardingLogo v-if="onboardingProcess === 'uploadingLogo'" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useOnboardingController } from "@/stores/onboardingController"
import { storeToRefs } from "pinia"

const onboardingController = useOnboardingController()
const { handleEvent, startOnboarding } = onboardingController
const { onboardingProcess } = storeToRefs(onboardingController)

handleEvent({ type: "RESET" })
startOnboarding()

function reset() {
  handleEvent({ type: "RESET" })
}

const isPhone = inject("isPhone") as Ref<boolean>
</script>

<style scoped lang="postcss">
.bg-desktop {
  @apply bg-center bg-no-repeat bg-cover fixed inset-0;
  background-image: url("/img/onboarding-bg.jpg");
  min-height: 100vh;
  min-width: 100vw;
}
</style>
