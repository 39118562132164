<template>
  <PageLayout>
    <template #header>
      <div class="flex items-center justify-between">
        <h2 class="text-heading-2">
          {{ $t("quotes_mobile.quotes") }}
        </h2>
        <div class="ml-auto" />
        <QuoteStatus
          current-status="draft"
          :selected-statuses="projectModel.filterStatus"
          :hide-border="true"
          display-options="select-deselect"
          @selected-statuses="projectController.updateProjectFilter"
        />
        <Button
          v-if="!mobile"
          icon="plus"
          size="medium"
          data-testid="new-quote-button"
          :disabled="tenant?.subscription_limit_access"
          @click="newProject()"
        >
          {{ $t("quotes_mobile.new_quote") }}
        </Button>
      </div>
      <SearchInputV2
        v-if="Object.keys(dataStore.projectsById).length > 7"
        v-model="projectModel.searchTerm"
        class="mt-4"
        @reset="() => (projectModel.searchTerm = '')"
      />
    </template>
    <template #default>
      <InfoBox class="mb-3" />
      <div class="grid grid-cols-2 gap-3 mb-32">
        <template v-if="projectModel.filteredProjects.length === 0">
          <div v-for="(p, i) in placeholder" :key="i">
            <QuoteSummary />
          </div>
        </template>
        <template v-else>
          <div v-for="q in projectModel.filteredProjects" :key="q.id">
            <QuoteSummary
              :quote="q"
              @click="q.id && gotoQuote(q.id)"
              @duplicate="prepareProjectDuplcate"
            />
          </div>
        </template>
      </div>
      <div v-if="mobile" class="fixed inset-x-0 px-4 bottom-20 pb-safe">
        <Button
          :disabled="tenant?.subscription_limit_access"
          icon="plus"
          class="w-full"
          size="large"
          @click="newProject()"
        >
          {{ $t("quotes_mobile.new_quote") }}
        </Button>
      </div>
      <MobileDialog
        :title="$t('quotes_mobile.copy_quote_prompt')"
        :loading="projectController.isLoading"
        :show="showDuplicateDialog"
        @y="duplicate"
        @n="() => (showDuplicateDialog = false)"
      />
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
const projectIdToDuplicate = ref<string>("")
const showDuplicateDialog = ref(false)

const projectModel = useProjectModel()
const dataStore = useDataStore()
const projectController = useProjectController()
const postModel = usePostModel()
const postController = usePostController()

if (projectModel.projectProcess !== "idle") {
  projectController.handleEvent({ type: "QUOTE_CLOSED" })
}

if (postModel.postProcess !== "idle") {
  postController.handleEvent({ type: "CLOSE_POST" })
}

async function gotoQuote(quoteId: string) {
  if (!showDuplicateDialog.value) {
    projectController.handleEvent({ type: "LOAD_QUOTE", quoteId })
  }
}

async function newProject() {
  projectController.handleEvent({ type: "NEW_QUOTE_STARTED" })
}

async function duplicate() {
  await projectController.duplicate(projectIdToDuplicate.value)
  showDuplicateDialog.value = false
}

function prepareProjectDuplcate(id: string) {
  projectIdToDuplicate.value = id
  showDuplicateDialog.value = true
}

const placeholder = Array.from({ length: 10 }).fill(undefined)
const showBottomNav = inject("showBottomNav") as Ref<boolean>
onUnmounted(() => {
  showBottomNav.value = false
})

onMounted(async () => {
  showBottomNav.value = true
})
const mobile = inject("isPhone") as Ref<boolean>

const tenant = computed(() =>
  toValue(dataStore.currentUser?.$tenant && dataStore.currentUser?.$tenant),
)
</script>
