/**
 * Video conversion utilities
 * Contains functions for converting video formats and types
 */

/**
 * Converts a video URL to a File object
 * @param videoPath URL of the video
 * @param filename Optional custom filename
 * @param mimeType Optional MIME type
 * @returns Promise resolving to a File object
 */
export async function videoUrlToFile(
  videoPath: string,
  filename?: string,
  mimeType?: string,
): Promise<File | null> {
  try {
    // Fetch the video data
    const response = await fetch(videoPath)
    const blob = await response.blob()

    // Create a File object from the blob
    const actualFilename = filename || `video_${new Date().getTime()}.${blob.type.split("/")[1] || "webm"}`
    const actualType = mimeType || blob.type
    const file = new File([blob], actualFilename, { type: actualType })

    return file
  }
  catch (error) {
    console.error("Error converting video URL to file:", error)
    return null
  }
}

/**
 * Gets the file extension from a MIME type
 * @param mimeType MIME type string
 * @returns File extension without the dot
 */
export function getExtensionFromMimeType(mimeType: string): string {
  const parts = mimeType.split("/")
  return parts.length > 1 ? parts[1] : "mp4"
}

/**
 * Gets the MIME type from a file extension
 * @param extension File extension (with or without dot)
 * @returns MIME type string
 */
export function getMimeTypeFromExtension(extension: string): string {
  // Remove dot if present
  const ext = extension.startsWith(".") ? extension.substring(1) : extension

  // Map of common video extensions to MIME types
  const mimeTypeMap: Record<string, string> = {
    mp4: "video/mp4",
    webm: "video/webm",
    mov: "video/quicktime",
    avi: "video/x-msvideo",
    mkv: "video/x-matroska",
  }

  return mimeTypeMap[ext.toLowerCase()] || "video/mp4"
}

/**
 * Creates a video file with the specified name and type
 * @param blob Video data as Blob
 * @param filename Desired filename
 * @param mimeType MIME type for the file
 * @returns File object
 */
export function createVideoFile(
  blob: Blob,
  filename: string,
  mimeType?: string,
): File {
  return new File(
    [blob],
    filename,
    { type: mimeType || blob.type || "video/mp4" },
  )
}
