<template>
  <div>
    <label v-if="label != ''" class="block mb-1 text-label-2 text-color-label-3">
      {{ label }}
    </label>
    <div ref="target" class="relative">
      <button
        type="button"
        class="relative w-full py-2 pl-3 text-left truncate bg-white border rounded-md shadow-sm cursor-pointer text-label-2 min-h-10 border-divider focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
        @click="toggleOptions"
      >
        <slot name="selected">
          <span>{{ t(`${props.translationPath}.${props.selectedValue}`) }}</span>
        </slot>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
          <IconChevronDown />
        </span>
      </button>

      <div
        v-if="optionsOpen"
        class="fixed inset-0 z-[100]"
        @click="optionsOpen = false"
      >
        <ul
          class="absolute py-1 overflow-auto bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none"
          :style="dropdownStyle"
          @click.stop
        >
          <li
            v-for="(option, i) in options"
            :key="option"
            class="relative py-2 pl-3 pr-4 text-left cursor-pointer select-none text-label-2 hover:bg-gray-100"
            :class="{ 'border-t': i > 0 }"
            @click="handleOptionSelect(option)"
          >
            <slot name="option" :option="option">
              <span>{{ option }}</span>
            </slot>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DirectusContext } from "@/types/ApiTypes"
import api from "@/api"
import translations from "@/localization//en-US/master.json"
import { t } from "@/localization/i18n"
import { onClickOutside, useElementBounding, useWindowSize } from "@vueuse/core"

interface Props {
  options?: Array<string>
  selectedValue: string
  label?: string
  translationPath: string
  directusContext?: DirectusContext
}

const props = withDefaults(defineProps<Props>(), {
  options: [],
  selectedValue: "",
  label: "",
  translationPath: "",
  directusContext: undefined,
})

const emit = defineEmits(["select"])
const tObjectReverse = {}
const options = ref<unknown[]>([])
if (props.options.length > 0) {
  options.value = props.options
}
else {
  const tObject = props.translationPath
    .split(".")
    .reduce((obj, key) => obj && obj[key], translations)

  Object.keys(tObject).forEach((key) => {
    tObjectReverse[t(`${props.translationPath}.${key}`)] = key
  })

  options.value = Object.keys(tObjectReverse)
}

async function selectOption(value: string) {
  const tObjectKey = tObjectReverse[value]
  emit("select", tObjectKey)
  if (props.directusContext) {
    const { collection, property, id } = props.directusContext
    if (!collection || !property || !id) { return }
    await api.updateGeneric({ collection, id, data: { [property]: tObjectKey } })
  }
}

const target = ref<HTMLElement | null>(null)
const optionsOpen = ref(false)

// Use VueUse's useElementBounding with more precise positioning
const targetBounds = useElementBounding(target)

// Add window size tracking
const { height: windowHeight } = useWindowSize()

// Update dropdown position calculation
const dropdownStyle = computed(() => {
  const dropdownHeight = 240 // max-h-60 = 15rem = 240px
  const spaceBelow = windowHeight.value - targetBounds.bottom.value
  const spaceAbove = targetBounds.top.value
  const showBelow = spaceBelow >= dropdownHeight || spaceBelow >= spaceAbove

  return {
    position: "fixed" as const,
    width: `${targetBounds.width.value}px`,
    left: `${targetBounds.left.value}px`,
    [showBelow ? "top" : "bottom"]: showBelow
      ? `${targetBounds.bottom.value + 5}px`
      : `${windowHeight.value - targetBounds.top.value + 5}px`,
    maxHeight:
      `${Math.min(dropdownHeight, showBelow ? spaceBelow - 10 : spaceAbove - 10)}px`,
    zIndex: 100,
  }
})

function toggleOptions() {
  optionsOpen.value = !optionsOpen.value
}

function handleOptionSelect(value: string) {
  selectOption(value)
  optionsOpen.value = false
}

// Add click outside handling
onClickOutside(target, () => {
  optionsOpen.value = false
})

// Clean up when component is unmounted
onUnmounted(() => {
  optionsOpen.value = false
})
</script>
