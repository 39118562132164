<template>
  <div>
    <p
      v-if="!loading"
      class="cursor-pointer text-caption-1 text-color-link-static hover:text-color-link-hover"
      @click="executeChatGTP"
    >
      {{ $t("auto_describe.name") }}
    </p>
    <clip-loader :loading="loading" color="#004DE7" size="20px" />
  </div>
</template>

<script setup lang="ts">
import type { Toaster } from "@/types/UiTypes"
import api from "@/api"
import { readItems } from "@directus/sdk"

const props = withDefaults(
  defineProps<{
    context: {
      title?: string
      description?: string
      field?: "quote_line_item_description" | "quote_line_item_tcs"
      language?: string
    }
  }>(),
  {
    context: _props => ({ title: "generisk post beskrivelse" }),
  },
)
const emit = defineEmits(["start", "done", "streaming"])
const text = ref("")
const save = ref(true)
const chatGtPConnected = ref(false)
const prompt = ref("")
const { t } = useI18n()
const toast = inject<Toaster>("toast")
const loading = ref(false)

await getPrompt()
const user_data_object = ref({
  auth_token: `Bearer ${await getToken()}`,
  user_input: props.context,
  prompt: { prompt: prompt.value },
})

watch(
  () => props.context,
  async () => {
    user_data_object.value = {
      auth_token: `Bearer ${await getToken()}`,
      user_input: props.context,
      prompt: { prompt: prompt.value },
    }
  },
  { deep: true },
)

function executeChatGTP() {
  if (chatGtPConnected.value) {
    text.value = ""
    const user_data = JSON.stringify(user_data_object.value)
    console.log("user_data", user_data_object.value)
    ws.send(user_data)
  }
}

emit("start")

console.log("Connecting to server ...")
const ws = new WebSocket("wss://tredco-v1-chatgtp.deno.dev")
ws.onopen = () => handleConnected(ws)
ws.onmessage = m => handleMessage(m.data)
ws.onclose = () => handle_socket_close()
ws.onerror = e => handleError(e)

function handleMessage(data: string) {
  if (data === "Invalid user.") {
    toast?.error(t("auto_describe.chatgpt_invalid_user"))
    save.value = false
    loading.value = false
  }
  else {
    if (data === "[START]") {
      loading.value = true
    }
    else if (data === "[END]") {
      emit("done", text.value)
      loading.value = false
    }
    else {
      text.value += data
      emit("streaming", text.value)
    }
  }
}

async function getToken() {
  const auth_token = await api.client.getToken()
  return auth_token
}

function handle_socket_close() {
  chatGtPConnected.value = false
  console.log("Closed connection to server ...")
}

function handleConnected(ws: WebSocket) {
  console.log("Connected to server ...")
  chatGtPConnected.value = true
}

function handleError(e: Event | ErrorEvent) {
  console.error("An error occurred:", e)
}

async function getPrompt() {
  const promptFromDirectus = await api.client.request(
    readItems("prompt_library", {
      fields: ["*"],
      filter: {
        language: {
          _eq: props.context.language,
        },
        purpose: {
          _eq: props.context.field,
        },
        status: {
          _eq: "published",
        },
      },
      sort: ["-date_updated"],
      limit: 1,
    }),
  )

  if (promptFromDirectus && promptFromDirectus?.length !== 0) {
    const promtObject = promptFromDirectus[0] as { prompt: string }
    if (promtObject && promtObject.hasOwnProperty("prompt")) {
      prompt.value = promtObject.prompt
    }
    else {
      prompt.value = ""
    }
  }
  else {
    console.info("no prompt found")
    prompt.value = "no prompt found"
  }
}

onUnmounted(() => {
  ws.close()
})
</script>
