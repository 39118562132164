/**
 * Native platform video recording utilities
 * Contains functions for recording video on native platforms using Capacitor
 */

import type { VideoResult } from "@/types"
import type {
  VideoRecorderPreviewFrame,
} from "@capacitor-community/video-recorder"
import {
  VideoRecorder,
  VideoRecorderCamera,
  VideoRecorderQuality,
} from "@capacitor-community/video-recorder"
import { Capacitor } from "@capacitor/core"

/**
 * Checks if the VideoRecorder plugin is available
 * @returns True if the plugin is available, false otherwise
 */
export function isVideoRecorderAvailable(): boolean {
  return Capacitor.isPluginAvailable("VideoRecorder")
}

/**
 * Creates a preview frame configuration for the camera
 * @returns Preview frame configuration
 */
export function createPreviewFrameConfig(): VideoRecorderPreviewFrame {
  return {
    id: "video-record",
    stackPosition: "front", // Show camera on top
    width: "fill", // Use full width
    height: Math.round(window.innerHeight * 0.7), // Use 70% of screen height
    x: 0, // Position from left
    y: 0, // Position from top
    borderRadius: 8, // Rounded corners
  }
}

/**
 * Initializes the VideoRecorder plugin
 * @param previewFrame Preview frame configuration
 */
export async function initializeVideoRecorder(
  previewFrame: VideoRecorderPreviewFrame,
): Promise<void> {
  try {
    await VideoRecorder.initialize({
      camera: VideoRecorderCamera.BACK, // Use back camera by default
      quality: VideoRecorderQuality.MAX_1080P, // Use 1080p quality
      previewFrames: [previewFrame],
      autoShow: true, // Automatically show the camera preview
    })
  }
  catch (error) {
    console.error("Error initializing video recorder:", error)
    throw error
  }
}

/**
 * Starts recording using the VideoRecorder plugin
 */
export async function startNativeRecording(): Promise<void> {
  try {
    await VideoRecorder.startRecording()
  }
  catch (error) {
    console.error("Error starting recording:", error)
    throw error
  }
}

/**
 * Stops recording and destroys the camera
 * @returns Promise resolving to the recording result
 */
export async function stopNativeRecording(): Promise<VideoResult | null> {
  try {
    // Stop recording
    const result = await VideoRecorder.stopRecording()

    // Destroy the camera
    await VideoRecorder.destroy()

    // Check if we have a valid result
    if (!result || !result.videoUrl) {
      console.warn("No video URL returned from native recorder")
      return null
    }

    // Return the result in a standardized format
    return {
      path: result.videoUrl,
      webPath: result.videoUrl,
      format: "mp4", // Native recordings are typically MP4
    }
  }
  catch (error) {
    console.error("Error stopping recording:", error)

    // Try to clean up even if there was an error
    try {
      await VideoRecorder.destroy()
    }
    catch (cleanupError) {
      console.error("Error cleaning up video recorder:", cleanupError)
    }

    return null
  }
}

/**
 * Cancels recording and destroys the camera
 */
export async function cancelNativeRecording(): Promise<void> {
  try {
    // Stop recording without saving
    await VideoRecorder.stopRecording()

    // Destroy the camera
    await VideoRecorder.destroy()
  }
  catch (error) {
    console.error("Error canceling recording:", error)

    // Try to clean up even if there was an error
    try {
      await VideoRecorder.destroy()
    }
    catch (cleanupError) {
      console.error("Error cleaning up video recorder:", cleanupError)
    }
  }
}
