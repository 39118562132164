<template>
  <div class="bottom-0 mb-6 h-full w-full rounded-t-3xl bg-1-static px-6 ">
    <div class="flex items-center py-3">
      <IconArrowBackward class="pl-4" @click="goBack" />
      <p class="mx-auto text-label-2 text-color-label-3">
        {{ $t("onboarding.step", [currentStep, totalSteps]) }}
      </p>
    </div>
    <hr class="border-1 mb-2 border-divider">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { useOnboardingController } from "@/stores/onboardingController"
import { storeToRefs } from "pinia"

const onboardingController = useOnboardingController()
const { currentStep, totalSteps } = storeToRefs(onboardingController)

// Get step information directly from the controller

function goBack() {
  onboardingController.handleEvent({ type: "BACK" })
}
</script>
