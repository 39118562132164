<template>
  <div class="w-full flex flex-col items-center">
    <!-- Video preview for web recording -->
    <div v-if="!isNativePlatform" class="w-full relative mb-6">
      <video
        ref="videoPreviewElement"
        autoplay
        playsinline
        muted
        class="w-full max-h-[500px] rounded-lg overflow-hidden"
      />
    </div>

    <!-- Recording status message -->
    <!-- <p class="text-body-2 text-center mb-6">
      {{ isNativePlatform
        ? ($t('quote.recording_native_message') || 'Your device is handling the recording.')
        : ($t('quote.recording_web_message') || 'Speak clearly and position yourself in the frame.')
      }}
    </p> -->

    <!-- Circular recording button with progress -->
    <div class="relative mb-6">
      <!-- Circular progress indicator -->
      <svg class="w-20 h-20" viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          r="46"
          fill="transparent"
          stroke="#E5E7EB"
          stroke-width="8"
        />
        <circle
          cx="50"
          cy="50"
          r="46"
          fill="transparent"
          stroke="#0055FF"
          stroke-width="8"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="progressOffset"
          transform="rotate(-90 50 50)"
          class="transition-all duration-300 ease-in-out"
        />
      </svg>

      <!-- Recording button -->
      <button
        class="absolute m-2 inset-0 flex items-center justify-center bg-white rounded-full shadow-md hover:shadow-lg transition-shadow"
        @click="stopRecording"
      >
        <!-- Recording indicator (pulsing red dot) -->
        <div class="w-6 h-6 rounded-full bg-error animate-pulse" />
      </button>
    </div>

    <!-- Recording time -->
    <p class="text-label-1 mb-2">
      {{ formattedTime }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from "vue"

defineProps<{
  isNativePlatform: boolean
}>()

const emit = defineEmits(["stop", "video-element"])

const videoPreviewElement = ref<HTMLVideoElement | null>(null)
const recordingTime = ref(0)
const maxRecordingTime = 60 // 60 seconds = 1 minute
const radius = 46 // Circle radius

// Format time as MM:SS
const formattedTime = computed(() => {
  const minutes = Math.floor(recordingTime.value / 60)
  const seconds = recordingTime.value % 60
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
})

// Calculate the circumference of the circle
const circumference = computed(() => {
  return 2 * Math.PI * radius
})

// Calculate progress circle offset
const progressOffset = computed(() => {
  const progress = recordingTime.value / maxRecordingTime
  return circumference.value * (1 - progress)
})

// Timer for recording
let timer: number | null = null

// Start timer when component is mounted
onMounted(() => {
  console.log("[RecordingUI] Component mounted, starting timer")

  // Reset recording time
  recordingTime.value = 0

  // Start a new timer
  timer = window.setInterval(() => {
    recordingTime.value++
    console.log(`[RecordingUI] Recording time: ${recordingTime.value}s, Progress: ${(recordingTime.value / maxRecordingTime * 100).toFixed(1)}%`)

    // Auto-stop recording when max time is reached
    if (recordingTime.value >= maxRecordingTime) {
      console.log("[RecordingUI] Max recording time reached, auto-stopping")
      stopRecording()
    }
  }, 1000)

  // Expose the video element to the parent component if we're on web
  if (videoPreviewElement.value) {
    emit("video-element", videoPreviewElement.value)
  }
})

// Clean up timer when component is unmounted
onUnmounted(() => {
  console.log("[RecordingUI] Component unmounted, cleaning up timer")
  if (timer !== null) {
    clearInterval(timer)
    timer = null
  }
})

// Watch for changes to the video element reference
watch(videoPreviewElement, (newValue) => {
  if (newValue) {
    emit("video-element", newValue)
  }
})

// Stop recording
function stopRecording() {
  console.log("[RecordingUI] Stopping recording")
  if (timer !== null) {
    clearInterval(timer)
    timer = null
  }
  emit("stop")
}
</script>
