<template>
  <div ref="payment_plan" class="pt-[5.6mm]">
    <p class="text-heading-3 text-color-label-1">
      {{ $t("quote.settings.payment_plan") }}
    </p>
    <p class="mb-4 text-caption-2 text-color-label-2">
      {{ $t("quote.settings.pdf_explanation") }}
    </p>
    <div v-for="item in props.items" :key="item.id" class="mb-2">
      <div class="grid grid-cols-12 items-center rounded-lg bg-2-static px-4 py-2">
        <div class="col-span-9 text-label-3 text-color-label-1">
          {{ item.description }}
        </div>
        <div class="col-span-1 text-right text-caption-2 text-color-label-2">
          {{ item.percentage }}%
        </div>
        <div class="col-span-2 text-right text-caption-2 text-color-label-1">
          {{ formatCurrency(amountUsed(item.percentage)) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PaymentPlan } from "@/types"
import { formatCurrency } from "@/utils/companyUtils"

import { defineEmits, defineProps, ref, watchEffect } from "vue"

const props = defineProps<{
  items: PaymentPlan[]
  total: number
}>()

const emit = defineEmits<{
  ready: [value: number]
}>()

function amountUsed(percentage: number) {
  if (percentage === 0) {
    return 0
  }
  else {
    return Number(props.total) * (Number(percentage) / 100)
  }
}

const payment_plan = ref<HTMLElement | null>(null)
watchEffect(() => {
  if (!payment_plan.value) { return }
  emit("ready", payment_plan.value.clientHeight)
})
</script>
