import type { Customer, Tenant } from "@/types"
import type { BrregCompany } from "@/types/models/CustomerTypes"
import type { User } from "@/types/models/UserTypes"
import api from "@/api"
import { useGlobalStore } from "@/stores/globalStore"
import { useOnboardingStore } from "@/stores/onboardingStore"
import { defineStore } from "pinia"

interface TenantSettingsState {
  isLoading: boolean
  error: boolean
  selectedCompany: BrregCompany | null
  onBoardingStore: ReturnType<typeof useOnboardingStore>
  globalStore: ReturnType<typeof useGlobalStore>
  user: User | null
  inProgress: boolean
  logoUrl: string
  colorPalette: string[]
  selectedColor: string
  fileEvent: Event | null
  tenant: Partial<Tenant>
  owner: Customer | undefined
  isUpdatingProfile: boolean
  searchStatus: SearchStatus
  searchResults: BrregCompany[] | undefined
  defaultSearchSize: number
}
enum SearchStatus {
  SelectCompany = "SelectCompany",
  Loading = "Loading",
  None = "None",
}

export const useTenantStore = defineStore("tenant", {
  state: (): TenantSettingsState => ({
    isLoading: false,
    error: false,
    selectedCompany: null,
    onBoardingStore: useOnboardingStore(),
    user: null,
    tenant: {},
    inProgress: false,
    owner: undefined,
    logoUrl: "",
    colorPalette: ["#009688", "#FF6F61", "#FFC107", "#8E24AA"],
    selectedColor: "",
    fileEvent: null,
    globalStore: useGlobalStore(),
    isUpdatingProfile: false,
    searchStatus: SearchStatus.None,
    searchResults: undefined,
    defaultSearchSize: 12,
  }),
  actions: {
    async updateProfile(values: {
      email?: string
      first_name: string
      last_name: string
    }) {
      try {
        this.isUpdatingProfile = true
        await api.users.updateMe({
          email: values.email,
          first_name: values.first_name,
          last_name: values.last_name,
        })
        this.isUpdatingProfile = false
        console.info("Variable updated successfully!")
      }
      catch (error) {
        console.error("Error updating user fields", error)
      }
      finally {
        this.isUpdatingProfile = false
      }
    },
    async updateInvoiceColor(color: string) {
      this.isLoading = true
      if (this.globalStore.tenant?.id) {
        await api.users.updateMyTenant(this.globalStore.tenant.id, {
          invoice_color: color,
        })
      }
      this.isLoading = false
    },
    async updateTenant(values: Partial<Tenant>) {
      this.inProgress = true
      if (this.globalStore.tenant?.id) {
        await api.users.updateMyTenant(this.globalStore.tenant.id, values)
      }
      this.inProgress = false
    },
    async updateTenantOwner(owner: Customer) {
      const wantedProps = [
        "name",
        "company_registration_number",
        "email",
        "phone_number",
        "street_address",
        "zip_code",
        "city",
        "municipality",
        "country_code",
        "province",
        "billing_country_code",
        "billing_zip_code",
        "billing_city",
        "billing_municipality",
      ] as const
      type WantedProp = (typeof wantedProps)[number]

      const propsToUpdate: Pick<Customer, WantedProp> = {
        phone_number: "",
        email: "",
        name: "",
        street_address: "",
        zip_code: "",
        city: "",
        country_code: "",
        company_registration_number: "",
        province: "",
        municipality: "",
        billing_country_code: "",
        billing_zip_code: "",
        billing_city: "",
        billing_municipality: "",
      }

      const setProp = <T extends WantedProp>(prop: T) => {
        propsToUpdate[prop] = owner[prop]
      }
      wantedProps.forEach(prop => setProp(prop))

      if (!this.globalStore.tenant?.id) {
        throw new Error("Tenant id not found")
      }
      try {
        const tenant = await api.users.updateMyTenant(this.globalStore.tenant.id, {
          ...propsToUpdate,
        })
        await this.globalStore.setTenant(tenant.id)
        console.info("Id", this.globalStore.tenant.id)
      }
      catch (error) {
        console.error("Error updating tenant owner", error)
      }
    },
    async updateTenantInvoiceSettings(values: Partial<Tenant>) {
      try {
        if (!this.globalStore.tenant?.id) {
          throw new Error("Tenant id not found")
        }
        const tenantId = this.globalStore.tenant.id
        if (tenantId) {
          this.tenant = await api.users.updateMyTenant(tenantId, values)
          Object.assign(this.globalStore.tenant, values)
        }
      }
      catch (error) {
        console.error("Error updating tenant invoice settings", error)
      }
    },
    changeCompany(name?: string, regNumber?: string) {
      if (!this.owner) {
        throw new Error("owner not defined")
      }
      this.owner.name = name ?? ""
      this.owner.company_registration_number = regNumber ?? ""
    },

    async searchCompany(searchQuery: string, size?: number) {
      this.isLoading = true
      if (searchQuery.length >= 3) {
        this.searchStatus = SearchStatus.Loading
        console.info(this.searchStatus)
        const result: BrregCompany[]
          = (await (await fetch(this.searchApiUrl(searchQuery, size))).json())?._embedded?.enheter ?? []

        this.searchResults = result.map(item => ({
          ...item,
          navn: this.convertCompanyNameFormat(item.navn),
        }))
        this.isLoading = false
        if (this.searchResults.length > 0) {
          this.searchStatus = SearchStatus.SelectCompany
        }
        else {
          this.searchStatus = SearchStatus.None
        }
        console.info("done!")
        console.info("selected company", this.selectedCompany)
      }
    },

    convertCompanyNameFormat(name: string): string {
      const nameFragments: string[] = name.split(" ").map(this.capitalizeFirstLetter)
      const lastIndex = nameFragments.length - 1
      if (["Asa", "As"].includes(nameFragments[`${lastIndex}`])) {
        nameFragments[`${lastIndex}`] = nameFragments[`${lastIndex}`].toUpperCase()
      }
      return nameFragments.join(" ")
    },
    capitalizeFirstLetter(text: string): string {
      if (text === "") {
        return text
      }
      return text[0].toUpperCase() + text.substring(1).toLowerCase()
    },
    searchApiUrl(compName: string, size?: number): string {
      if (!size) {
        size = this.defaultSearchSize
      }
      return `https://data.brreg.no/enhetsregisteret/api/enheter?navn=${compName}&konkurs=false&size=${size}`
    },
  },
  getters: {
    async getCompanyLogoUrl(): Promise<string> {
      try {
        const logo = this.globalStore.tenant?.logo
        if (logo) {
          return `${api.client.url}assets/${logo}?access_token=${this.globalStore.token}`
        }
      }
      catch (error) {
        console.error("Error fetching the logo URL:", error)

        return ""
      }

      return ""
    },

    async getTenantInvoiceColor(): Promise<string | null> {
      const invoiceColor = this.globalStore.tenant?.invoice_color ?? null
      return invoiceColor
    },
  },
})
